.flannel-franchise-front-page {
  .uk-container {
    max-width: 930px;
    margin: 0 auto;
  }
  
  .content-column {
    max-width: $max-width;
    margin: 20px auto 50px;
    @media all and ( min-width: $screen-md ) {
      margin: 70px auto 50px;
      max-width: 930px;
    }
    
    ul {
      list-style-type: none;

      li {
        list-style-type: none;
        line-height: 1.17;
        font-size: 16px;
        margin-bottom: 10px;
        @media all and ( min-width: $screen-md ) {
          font-size: 20px;
        }

        &:before {
          content: '•';
          color: $red;
          font-size: 20px;
          display: inline-block;
          text-indent: -25px;
        }
      }
    }
  }
  
  .uk-large-container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .franchise-tagline-container {
    margin-bottom: -10px;
    z-index: 20;
    position: relative;
    padding: 0 15px;
    @media all and ( min-width: $screen-md ) {
      margin-bottom: -25px;
    }
  }
  
  .franchise-video-container {
    margin: 0 auto -45px;
    max-width: 930px;
    
    .flannel-video-thumbnail {
      max-width: none; 
    
      &:before,
      video:before {
        color: $white;
        text-shadow: 0 3px 3px $black;
        background: rgba( 206, 31, 46, .8 );
        padding: 10px 15px;
        font-size: 20px;
        border-radius: 50%;
        @media all and ( min-width: $screen-md ) {
          padding: 30px 40px;
          font-size: 40px;
        }
      }
    }
  }
  
  .flannel-testimonial-container {
    clip-path: polygon( 0 0, 100% 0, 100% 85%, 0 100%);
  }

  .flannel-franchise-news-row {
    margin-bottom: 150px;
    z-index: 12;
    margin-top: -100px;
    @media all and ( min-width: $screen-md ) {
      margin-top: 0;
    }
      
    .plaid-background {
      background: $red;
      background-image: url( $assetImagePath + '/patterns/pattern.png' );
      margin-top: 50px; 
      padding: 50px;
      position: absolute;
      height: 100%;
      clip-path: polygon( 0 0, 100% 0%, 100% 90%, 0% 100% );
    }

    .flannel-news-container {
      margin-left: 0;
      position: relative;
      margin-bottom: 50px;
      @media all and ( min-width: $screen-md ) {
        margin-left: 20%;
      }

      .news-background {
        background: $whitesmoke; 
        padding: 20px;
        @media all and ( min-width: $screen-md ) {
          padding: 70px;
        }

        .flannel-max-width {
          max-width: $max-width;
          margin: 0 auto;
          @media all and ( min-width: $screen-md ) {
            max-width: 820px;
            margin: 0;
          }          
        }

        .news-container {
          padding: 0 0 30px;
          @media all and ( min-width: $screen-md ) {
            padding: 30px 0 50px 30px;
          }
          
          > div {
            padding-top: 10px;
            @media all and ( min-width: $screen-sm ) {
              padding-top: 0;
            }
          }

          span {
            @include open-bold;
            margin-left: 0;
            font-size: 20px;
            display: block;
            color: $black;
            margin-top: -20px;
            padding-left: 0;
            @media all and ( min-width: $screen-sm ) {
              margin-top: 0;
              padding-left: 20px;
            }
            
            @media all and ( min-width: $screen-md ) {
              font-size: 24px;
            }
            
            @media all and ( min-width: $screen-lg ) {
              padding-left: 0;
            }
          }
          
          img {
            max-height: 120px;
            max-width: 120px;
          }
        }
      }
    }
  }

  .flannel-image-column {
    img {
      margin-bottom: 30px;
      @media all and ( min-width: $screen-md ) {
        margin-bottom: 90px;
      }
    }
  }
  
  .request-container {
    padding-bottom: 0;
    position: relative;
    @media all and ( min-width: $screen-sm ) {
      padding-bottom: 75px;    
    }
    
    em, i {
      color: $white;
    }
  }
  
  .franchise-form-container {
    padding: 10px 20px;
    background: $white;
    @media all and ( min-width: $screen-md ) {
      padding: 20px 40px;
    }

    .ginput_container_consent {
      margin-left: 25px;

      input[ type="checkbox" ] {
        -webkit-appearance: none;
        margin-left: -25px !important;
        height: 20px;
        width: 20px;
        position: relative;
        bottom: -4px;
        border: 2px solid $black;
        border-radius: 0;
        background: $white;

        &:checked {
          background-image: url( $assetImagePath + '/misc/check.svg' );
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      label {
        color: $black !important;
        font-size: 16px !important;
      }
      
      a {
        &:active,
        &:hover,
        &:focus {
          color: $grey;
          text-decoration: none;
        }
      }
      
      i {
        display: none;
      }
    }
  }
}

.flannel-franchise-contact-page {
  margin-top: 20px;
  @media all and ( min-width: $screen-md ) {
    margin-top: 70px;
  }
  
  .uk-container {
    max-width: 1050px;
    margin: 0 auto;
  }
  
  .content-container {
    min-height: auto;
    padding-bottom: 20px;
    max-width: $max-width;
    margin: 0 auto;
    @media all and ( min-width: $screen-md ) {
      min-height: 500px;
      max-width: none;
      padding-bottom: 40px;
    }
  }
  
  .contact-container {
    position: relative;
    padding: 0 0 75px;
    max-width: 450px;
    margin: 0 auto;
    
    .form-container {
      padding: 15px;
      background: $white;
      z-index: 11;
      position: relative;                   
      box-shadow: -20px 20px 0 0 rgba( 0, 0, 0, 0.15 );
      @media all and ( min-width: $screen-md ) {
        padding: 40px;
      }
      
      .ginput_container_consent {
        margin-left: 25px;
  
        input[ type="checkbox" ] {
          -webkit-appearance: none;
          margin-left: -25px !important;
          height: 20px;
          width: 20px;
          position: relative;
          bottom: -4px;
          border: 2px solid $black;
          border-radius: 0;
          background: $white;
  
          &:checked {
            background-image: url( $assetImagePath + '/misc/check.svg' );
            background-position: center;
            background-repeat: no-repeat;
          }
        }
  
        label {
          color: $black !important;
          font-size: 16px !important;
        }
        
        a {
          &:active,
          &:hover,
          &:focus {
            color: $grey;
            text-decoration: none;
          }
        }
        
        i {
          display: none;
        }
      }
    }
  }
    
  .form-background {
    right: -30px;
    width: 100%;
    background: rgba( 81, 12, 18, 0.83 );
    background-image: url( $assetImagePath + '/patterns/pattern.png' );
    position: absolute;
    top: -40px;
    background-blend-mode: difference;
    height: 120%;
    @media all and ( min-width: $screen-md ) {
      top: -70px;
    }
    
    @media all and ( min-width: $screen-md ) {
      right: -130px;
      width: 120%;
    }
  }
}

.minus-eighty {
  margin-top: -80px
}


.flannel-franchise-pagination-container {
  background: $black;
  padding: 40px 0;
  z-index: 0;
  position: relative;
  margin-bottom: -25px;

  .nav-border {
    border-top: 3px solid $white;
    border-bottom: 3px solid $white;
  }

  .pagination-nav,
  .mobile-pagination-nav {
    display: flex;
    flex-direction: row;
    color: $white;
    align-items: center;
    margin: 40px 0;

    .nav-icon {
      font-size: 35px; 
    }

    span {
      @include open-bold;
      font-size: 14px;
      line-height: 2;
      text-transform: uppercase;
    }

    p {
      @include axe;
      font-size: 45px;
      line-height: 1;
      margin: 0;
    }
  }
  
  a {
    &:active,
    &:hover,
    &:focus {
      text-decoration: none;

      p,
      i {
        color: $red;
      }
    }
  }
  
  .mobile-pagination-nav {   
    justify-content: center;
    margin: 0;
    
    span {
      padding: 0 15px;
    }
  }

  img {
    opacity: 1;
  }
}

.nav-footer-container {
  padding: 30px;
  background: $red;
  background-image: url( $assetImagePath + '/patterns/pattern.png' );
  
  a {
    @include open-con;
    color: $white;
    text-transform: uppercase;
    font-size: 20px;
  }
  
  img.national-nav-header {
    width: auto;
    margin: 0 auto 50px;
  }
}

.franchise-main-nav-container {
  position: absolute;
  top: 30px;
  right: 70px;
  @media all and ( min-width: $screen-sm ) {
    right: 100px;
  }
}

.franchise-mobile-nav-container {
  position: absolute;
  top: 50px;
  left: 100px;
}

.franchise-menu-image {
  position: absolute;
  top: 0;
  right: 40px;
}
