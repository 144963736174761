.flannel-information-container {   
  margin-left: 0;
  margin-right: 0;
  position: relative;
  @media all and ( min-width: $screen-xl ) {
    margin-left: -175px;
    margin-right: -30px;
  }
  
  .image-column {
    display: none;
    position: relative;
    @media all and ( min-width: $screen-md ) {
      display: block;
    }
  }

  .content-column {
    background: $whitesmoke; 
    margin-top: 0;
    padding: 30px 15px;
    display: flex;
    font-size: 20px;
    flex-direction: column;
    justify-content: center;
    @media all and ( min-width: $screen-sm ) {
      padding: 40px 40px 50px;
      margin: 50px auto 0;
      max-width: $max-width;
    }
    
    @media all and ( min-width: $screen-md ) {
      padding: 80px 40px 151px 80px;
      max-width: 900px;
      margin-left: -45px;
    }
    
    h2, h3 {
      margin-bottom: 10px;
    }
    
    ul {
      padding-left: 35px;
      margin-top: 0;
      
      li {
        list-style-type: none;
        position: relative;
        margin-bottom: 10px;
      
        &::before {
          content: '•';
          position: absolute;
          left: -25px;
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
}
