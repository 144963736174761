.flannel-pagination-container-bkgd {
  background: $black;
  padding: 67px 0 117px;
  z-index: 0;
  position: relative;
  
  .flannel-pagination-container {
    max-width: 1200px;
    margin: 0 auto;
  
    .pagination-nav {
      display: inline-flex;
      flex-direction: row;
      color: $white;
      align-items: center; 

      i {
        font-size: 35px;
        margin: 0 15px;      
      }

      span {
        @include open-bold;
        font-size: 14px;
        line-height: 2;
        text-transform: uppercase;
      }

      p {
        @include axe;
        font-size: 45px;
        line-height: 1;
        margin: 0;
      }
    
      &:active,
      &:hover,
      &:focus {
        text-decoration: none;

        p {
          color: $red;
        }
      }
    }

    img {
      opacity: .1;
    }
  }
}