.flannel-blockquote {
  width: 80%;
  background: $red;
  padding: 30px;
  margin: 0 30px 30px;
  position: relative;
  overflow: hidden;
  @media all and ( min-width: $screen-sm ) {
    width: 50%;
    padding: 50px;
  }
  
  &:after {
    content: "";
    background-image: url( $assetImagePath + '/axes/axes_black.svg' );
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    right: -28px;
    opacity: .2;
    width: 100%;
    height: 113%;
  }
  
  blockquote {
    font-weight: bold;
    font-style: italic;
    line-height: 1.46;
    font-size: 16px;
    color: $white;
    margin: 0;
    position: relative;
    z-index: 2;
    @media all and ( min-width: $screen-md ) {
      font-size: 24px;
    }
  }
}
