.flannel-index-page {
  .uk-container {
    width: 100%;
    max-width: 1020px;
    padding: 0 10px;
    margin-top: -15px;
    @media all and ( min-width: $screen-sm ) {
      padding: 0 15px;
      margin-top: 0;
    }
    
    .content-container {
      max-width: $max-width;
      margin: 0 auto 50px;
      @media all and ( min-width: $screen-md ) {
        max-width: none;
//        margin: 15px auto 50px;
      }
            
      &.extra-fifteen {
        margin-top: 15px;
      }
    }
  }
  
  .content-row-container {
    padding: 0 15px;
    @media all and ( min-width: $screen-sm ) {
      padding: 0 30px;
    }
    
    @media all and ( min-width: $screen-md ) {
      padding: 0 30px 40px;
    }
    
    a {
      &:active,
      &:hover,
      &:focus {
        color: $grey;
        text-decoration: none;
      }
    }
  }
}

.minus-sixty {
  margin-top: -60px;
}
