header.flannel-page-header {
  margin-bottom: 30px; 
  position: relative;
  box-shadow: 0px 6px rgba( 0, 0, 0, 0.2);
  
  img {
    width: 163px;
    margin-bottom: 50px;
    @media all and ( min-width: $screen-sm ) {
      width: auto;
    }
  }
  
  .wp-post-image {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
    
  .title-container {
    z-index: 9;
    padding: 30px;
    position: relative;
  }
}
