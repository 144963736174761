.content-row-container {
  background: $whitesmoke;
  padding: 0 30px;
//  max-width: $max-width;
  margin: 0 auto;
  @media all and ( min-width: $screen-sm ) {
    padding: 0 30px 40px;
  }
  
  @media all and ( min-width: $screen-md ) {
    max-width: none;
  }
  
  &:nth-child(2n) {
    background: $white;
    
    .uk-container .content-grid {
      flex-direction: column-reverse !important;
      right: 0;
      left: 0;
      @media all and ( min-width: $screen-md ) {
        flex-direction: row-reverse !important;
      }
      
      @media all and ( min-width: $screen-lg ) {
        left: -50px;
      }
      
      .content-column {
        padding: 0;
        max-width: $max-width;
        margin: 0 auto;
        @media all and ( min-width: $screen-md ) {
          padding: 20px 0 0 100px;
        }        
      }
    }
  }
  
  .uk-container {
    padding: 50px 0;
    width: 100%;
    max-width: 1020px; 
    position: relative;
  
    .content-grid {
      display: flex;
      position: relative; 
      top: 0; 
      right: 0; 
      width: 100%;
      height: 100%;
      flex-direction: column-reverse;
      @media all and ( min-width: $screen-md ) {
        flex-direction: row;
      }
      
      @media all and ( min-width: $screen-lg ) {
        right: -50px;
      }
      
      a:after {
        @include fa;
        content: "\f30b";
        margin-left: 10px;        
      }
    
     .flannel-button a:after,
      a.flannel-video-thumbnail:after{
        content: "";
        display: none;
      }
      
      .content-column,
      .media-column {
        width: 100%;
        @media all and ( min-width: $screen-md ) {
          width: 50%;
        }
      }
      
      .content-column {
        position: relative;
        max-width: $max-width;
        margin: 0 auto;
        top: -20px;
        padding: 0;
        @media all and ( min-width: $screen-md ) {
          padding: 20px 100px 0 0;
        }        
      }
      
      .media-column {
        position: relative; 
        top: -70px;
        margin: 0 auto;
        text-align: center;
        max-width: 490px;
        min-height: 40px;
        @media all and ( min-width: $screen-sm ) {
          top: -100px;
        }
        
        img {
          width: 100%;
          object-fit: contain;
        }
        
        .calendar-container {
          position: absolute;
          top: -20px;
          right: 10px;
          width: 90px;
          text-align: center;
          @media all and ( min-width: $screen-md ) {
            width: 130px;
            right: 20px;
          }
          
          .calendar-header {
            @include open-con;
            background: $red; 
            color: $white;
            font-size: 18px;
            padding: 7px 8px;
            @media all and ( min-width: $screen-md ) {
              font-size: 23px;
              padding: 7px 10px;
            }
          }
          
          .calendar-body {
            background: $white;
            color: $black;
            font-size: 45px;
            line-height: 1;
            @media all and ( min-width: $screen-md ) {
              font-size: 75px;
            }
          }
        }
        
        .content-media {
          box-shadow: -20px 20px 0 0 $red;
        }
      }
    }
  }
}
