.flannel-red-promo-box {
  background: $red;
  padding: 30px;
  color: $white;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  overflow: hidden;
  @media all and ( min-width: $screen-xl ) {
    margin-left: -120px;
    margin-right: 120px;
  }
  
  &:after {
    content: "";
    background-image: url( $assetImagePath + '/axes/axes_black.svg' );
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    bottom: -75px;
    right: -25px;
    opacity: .2;
    width: 100%;
    height: 100%;
  }
  
  .flannel-button a {
    background: $black;
    
    &:active,
    &:hover,
    &:focus {
      color: $red !important;
    }
  }
}
