form {  
  .gform_body {
    div.ginput_container {
      width: 100% !important;
      
      span {
        padding-right: 0;
      }
    }
    
    li {
      position: relative;
      z-index: 2;
    }
    
    input {
      border: none;
      border-bottom: 2px solid $silver;
      border-radius: 0;
      outline: none;
      background: transparent;
      margin: 0 !important;
      
      &:active,
      &:focus {
        border-bottom: 2px solid $black;
      }
    }

    textarea {
      border: 2px solid $silver;
      outline: none;

      &:active,
      &:focus {
        border: 2px solid $black;
      }
    }

    label {
      color: $black;
    }

    .gfield_required {
      color: $red;
    }

    .ginput_container_consent {
      margin-left: 25px;

      input[ type="checkbox" ] {
        -webkit-appearance: none;
        margin-left: -25px;
        height: 20px;
        width: 20px;
        position: relative;
        bottom: -4px;
        border: 2px solid $black;
        border-radius: 0;
        background: $white;

        &:checked {
          background-image: url( $assetImagePath + '/misc/check.svg' );
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      label {
        color: $black !important;
        font-size: 16px !important;
      }
      
      a {
        &:active,
        &:hover,
        &:focus {
          color: $grey;
          text-decoration: none;
        }
      }
      
      i {
        display: none;
      }
    }

    .validation_message {
      color: $red;
      margin-top: -12px;
    }

    .ginput_container span:not( .ginput_price ) {
      margin-bottom: 0;
      display: inline;
    }

    li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
      border: none;
      border-bottom: 2px solid $red;
    }

    li.gfield_error textarea {
      border: 2px solid $red;
    }

    .form-background {
      background: rgba( 81, 12, 18, 0.83 );
      background-image: url( $assetImagePath + '/patterns/pattern.png' );
      position: absolute;
      right: -30px;
      top: -30px;
      width: 100%;
      height: 100%;
    }
  }
}

.gform_confirmation_message {
  @include axe;
  padding: 50px 0;
  font-size: 40px;
  color: $black;
  height: 300px;
  line-height: 1;
  text-align: center;
}

form .gform_footer {
  text-align: center;

  .button {
    @include open-con;
    background: $red;
    color: $white;
    padding: 20px 60px;
    text-align: center;
    border: none;
    text-transform: uppercase;
    position: relative;
    z-index: 22;

    &:active,
    &:hover,
    &:focus {
      border: none;
      color: $black;
      cursor: pointer;
    }
  }
  
  input[ type="submit" ] {
    appearance: none;
    border-radius: 0;
  }
  

}

/**
 * Local Footer Subscription Form
 */
form.flannel-subscription-container {
  .gform_wrapper {
    margin: 0;
  }

  div.validation_error {
    display: none;
  }

  .gform_footer {
    position: relative;
    top: 0;
    right: 0;
    text-align: left;
    width: 100%;
    @media all and ( min-width: $screen-md ) {
      position: absolute;
      text-align: right;
      width: 25%;
    }

    .button {
      padding: 10px 20px;
      font-size: 20px !important;
    }
  }
}

// Remove preset borders + spacing
.gform_wrapper form li.gfield.gfield_error, {
  margin-bottom: 0!important;
  border-top: none;
  border-bottom: none;
  padding-bottom: 0;
  padding-top: 0;
}

// Remove screen reader text from UI
.gform_wrapper .screen-reader-text {
  display: none;
}

// Prevent phone format message from overlapping input field
.gform_wrapper form .ginput_container_phone .instruction {
  margin-top: 0;
}






// Set special-label class, move label over input field
.gform_wrapper .special-label > label {
  position: relative;
  top: 35px;
  z-index: -1;
  transition: all .35s;
}

// Animate special-label class above input field if focused on or 
.gform_wrapper .special-label.has-focus-or-value > label {
  @include open-bold;
  color: $grey;
  font-size: 12px;
  top: 10px;
  transition: all .35s;
  }

// Style select option
.gform_wrapper li.gfield select {
  appearance: none;
  max-width: 100%;
  width: 100%;
  border: 0;
  border-bottom: 2px solid $silver;
  padding: 5px 20px 5px 4px;
  background-color: transparent;
  outline: none;
  border-radius: 0;
  background-image: url( $assetImagePath + '/misc/chevron-down.svg' );
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 14px;
}

// Message section spacing
.gform_wrapper .message-label label {
  margin-top: 40px;
}
