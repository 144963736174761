.page-404-container {
  .content-container {
    max-width: $max-width;
    margin: 0 auto;
    padding: 20px;
    @media all and ( min-width: $screen-sm ) {
      padding: 50px 100px 50px 50px;
    }
    
    h2 {
      color: $red;
      margin: 0;
    }
    
    h3 {
      margin: 0 0 15px;
    }
    
    p {
      margin-bottom: 30px;
    }
    
    a {
      color: $red;
      
      &::after {
        @include fa;
        content: "\f30b";
        margin-left: 10px;
      }

      &:active,
      &:hover,
      &:focus {
        color: $grey;
        text-decoration: none;
      }
    }
  }
  
  .broken-column {
    background-image: url( $assetImagePath + '/patterns/pattern.png' );
    margin-right: -200px; 
    padding: 50px; 
    min-height: 500px;
    position: relative;
    
    img {
      position: absolute;
      left: -100px;
    }
  }
}
