.footer-content-container {
  table.uk-table {
    @include open-semi;
    font-size: 20px;
    line-height: 1.9;
    max-width: 280px;
    margin: 0 auto;

    td {
      padding: 0;
      text-align: left;

      &:last-of-type {
        text-align: right;
      }
    }
  }
}