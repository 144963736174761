.flannel-video-thumbnail {
  @include flex-center;
  z-index: 10;
  position: relative;
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;    

  &::before {
    @include fa;
    content: "\f04b";
    position: absolute;
    color: $red;
    font-size: 40px;
    z-index: 12;
    font-weight: bold;
    text-shadow: 0 3px 3px $black;
  }
}
