.flannel-parent-nav {
  display: flex;
  justify-content: center;
  margin: 0;
  position: relative;
  width: 100%;
  z-index: 222;

  ul {
    display: flex;
    margin: 0;
    align-items: center;
  }

  li {
    list-style-type: none;
    padding: 0;
    margin: 0 25px;

    .nav-image-container:after {
      content: '';
      display: block;
      height: 100%;
      background-size: cover;
      background-position: center;
    }

    a {
      @include open-con;
      color: $white;
      font-size: 20px;
      font-stretch: condensed;
      text-transform: uppercase;
      font-weight: bold;
      min-height: 0;
      padding: 15px 0;
      border-bottom: 5px solid transparent;

      &:active,
      &:hover,
      &:focus {
        color: $red;

        &::after,
        i {
          color: $red;
        }
      }
    }
    
    i {
      margin-left: 5px;
      color: $white;
      font-size: 18px;
      margin-left: 6px;
      font-weight: 500;      
    }

    .nav-children {
      flex-direction: column;
      align-items: flex-start;
      padding: 22px 0;
    }

    .uk-navbar-dropdown {
      margin-top: 0;
      box-shadow: -20px -20px 0 0 rgba( 0, 0, 0, 0.15 );
      width: 500px;
      z-index: -2;
      min-height: 200px;
      padding: 0;

      &.uk-open {
        display: flex;
        flex-direction: row-reverse;
      }

      .uk-drop-bottom {
        margin-top: 0;
      }

      a,
      i {
        color: $black !important;
        font-size: 16px;
        border: none !important;
        padding: 0;
        margin: 10px 0;

        &:active,
        &:hover,
        &:focus {
          color: $matterhorn !important;
        }
      }
      
      i {
        font-size: 12px !important;
      }
    }
  }

  .uk-active > a,
  .current-page-parent > a {
    color: $white !important; // override .uk-navbar settings

    &:active,
    &:hover,
    &:focus {
      color: $matterhorn;
    }
  }

  // National Theme
  .flannel-black-bkgd {
    .uk-active > a,
    .current-page-parent > a,
    .current-menu-parent > a {
      border-bottom: 5px solid $red;
    }
    
    .current-menu-parent .uk-active > a {
      color: $red !important;
    }
    
    a:active,
    a:hover,
    a:focus,
    a.uk-open {
      color: $red !important;
      
      i {
        color: $red !important;
      }
    }
    
    .uk-navbar-dropdown a {
      &:active,
      &:hover,
      &:focus {
        color: $red !important;
      }
    }
    
    .red-nav-button a {
      &:active,
      &:hover,
      &:focus {
        color: $black !important;
      }
    }
  }

  // Local Theme
  .flannel-red-bkgd {
    .uk-active > a,
    .current-page-parent > a,
    .active.uk-parent a,
    .current-menu-parent > a {
      border-bottom: 5px solid $black;
    }
    
    .current-menu-parent .current_page_item > a {
      color: $red !important;
    }
    
    li {
      a:active,
      a:hover,
      a:focus,
      a.uk-open {
        color: $black !important;
        opacity: 1;
        
        i {
          color: $black;
        }
      }
      
      &.black-nav-button a {
        padding: 0;
        
        &:active,
        &:hover,
        &:focus {
          color: $red !important;
        }
      }

      .uk-navbar-dropdown a {
        &:active,
        &:hover,
        &:focus {
          color: $red !important;
        }
      }
    }
  }
}

// Required for live site
nav.flannel-red-nav > div {
  width: 100%;
}



// Mobile menu styling
.mobile-header-nav {
  margin-top: 50px;
  
  i {
    margin-left: 5px;
    color: $white;
    font-size: 18px;
    margin-left: 6px;
    font-weight: 500;
  }
  
  a {
    &:active,
    &:hover,
    &:focus {
      color: $red;
      
      i {
        color: $red;
      }
    }
  }
  
  .red-nav-button {
    span {
      color: $white;
    }
    
    &:active,
    &:hover,
    &:focus {
      span {
        color: $black;
      }
    }
  }
}
