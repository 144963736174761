.flannel-local-footer {
  background: $black;
  padding-top: 40px;
  
  .background-image-container {
    position: absolute;
    top: 40px;
    margin: 0 auto;
    clip-path: polygon( 0 0, 100% 10%, 100% 100%, 0% 100% );
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.4) 25%, rgba(0,0,0,0.4) 75%, rgba(0,0,0,1) 100%);
    max-width: 1200px;
    margin-right: 15px;
    @media all and ( min-width: $screen-sm ) {
      margin-right: 30px;
    }
    
    @media all and ( min-width: $screen-md ) {
      margin-right: 40px;
    }
    
    img {
      width: 100%;
      height: 100%;
      min-height: 640px;
      object-fit: cover;
      padding-right: 0px;
      @media all and ( min-width: $screen-sm ) {
        padding-right: 0px;
      }
      
      @media all and ( min-width: $screen-md ) {
//        padding-right: 80px;
      }
      
      @media all and ( min-width: $screen-lg ) {
        padding-right: 0;
      }
    }
    
    .background-shadow {
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgb(0,0,0);
      background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.8) 25%, rgba(0,0,0,0.6) 50%, rgba(0,0,0,0.8) 75%, rgba(0,0,0,1) 100%);
      top: 0;
    }
  }

  .footer-content-container {
    color: $white;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    
    .local-info-container {
      margin-bottom: 30px;
      padding-right: 30px;
    }
    
    .logo-container {
      position: relative;
      margin-bottom: 30px;
      
      .logo img {
        display: block;
        width: 170px;
        margin: 0 auto 35px;
      }
    }
    
    p {
      @include open-bold;
      font-size: 20px;
      line-height: 1.4;
    }
  }
  
  .footer-form-container {
    background: $white;
    padding: 15px 20px;
    overflow: hidden;
    position: relative;
    text-align: left;
    max-width: $max-width;
    margin: 0 auto;
    height: 100%;
    @media all and ( min-width: $screen-md ) {
      padding: 40px;
      max-width: none;
    }
    
    @media all and ( min-width: $screen-lg ) {
      padding: 40px 100px;
    }
    
    &::after {
      content: "";
      background-image: url( $assetImagePath + '/patterns/pattern2.png' );
      background-repeat: repeat;
      opacity: .1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      z-index: 0;        
    }
    
    &.uk-grid > * {
      padding: 0 !important;
      @media all and ( min-width: $screen-md ) {
        padding: 0 0 0 30px;
      }
    }
    
    .footer-form-ax {
      position: absolute;
      top: -36px;
      right: -36px;
      width: 132px;
      z-index: -2;
    }
    
    .form-content-container {
      h2 {
        font-size: 45px;
        line-height: 1;
        margin: 0;
      }
      
      p {
        font-size: 14px;
        margin: 0;
      }
    }
    
    .subscription-form-container {
      margin: 20px 0 0;
      width: 100%;
      @media all and ( min-width: $screen-md ) {
        width: calc( 60% - 1px );
        margin: -15px 0 0;
      }
      
      .gform_wrapper {
        margin: 0;
        
        .top_label input[ type="text" ] {
          width: 100%;
          @media all and ( min-width: $screen-sm ) {
            width: 65%;
          }
        }
        
        ul li.gfield {
          margin-top: 0;
        }
      }
      
      form .gform_body .ginput_container_consent {
        margin: 20px 0 0;
        @media all and ( min-width: $screen-md ) {
          margin: 0;
        }
        
        label {
          font-size: 14px !important;
        }
      }
      
      .flannel-subscription-container .gform_footer {
        margin: 20px 0 0;
        padding: 0;
        @media all and ( min-width: $screen-md ) {
          margin: 0;
        }
      }
      
      .gform_confirmation_message {
        @include axe;
        font-size: 32px;
        color: $black;
        line-height: 1;
        height: auto;
        padding: 20px;
        text-align: center;
        margin-top: 0;
        @media all and ( min-width: $screen-md ) {
          padding: 0 0 0 80px;
          margin-top: 15px;
        }
      }
    }
  }
  
  .info-icon {
    @include flex-center;
    border-radius: 50%;
    border: 3px solid $white;
    margin: 0 auto;
    width: 50px;
    height: 50px;
  }
}
