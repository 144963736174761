.flannel-local-contact-page {
  .uk-container {
    max-width: 1050px;
    margin: 0 auto;
    
    .content-container {
      margin-top: 20px;
      margin-bottom: -200px;
      max-width: $max-width;
      margin-left: auto;
      margin-right: auto;
      @media all and ( min-width: $screen-md ) {
        margin-top: 60px;
        margin-bottom: 80px;
      }
    }
  }
  
  .icon-container {
    @include flex-center;
    border-radius: 50%;
    border: 3px solid $matterhorn;
    margin-right: 20px;
//    width: 100%;
    min-width: 40px;
    height: 40px;  
//    margin-bottom: 10px;
    align-self: start;
    margin: 0 auto 10px;
    @media all and ( min-width: $screen-xs ) {
      min-width: 50px;
      height: 50px;
      margin: 0 15px 0 0;
    }
    
    i {
      font-size: 1em;
      @media all and ( min-width: $screen-xs ) {
        font-size: 1.33em; 
      }
    }
  }
  
  .info-container {
    @include open-bold;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    font-size: 20px;
    line-height: 1.1;
    flex-direction: column;
    @media all and ( min-width: $screen-xs ) {
      flex-direction: row;
    }
    
    .address-container {
      text-align: center;
      @media all and ( min-width: $screen-xs ) {
        text-align: left;
      }
    }
    
    span {
      display: block;
    }
    
    a {      
      line-height: 28px;
      
      &:active,
      &:hover,
      &:focus {
        color: $grey;
        text-decoration: none;
      }
    }
  }
  
  .store-hours-container {
//    display: block;
    
    .icon-container {
      float: left;
    }
    
    table.uk-table {
      margin: 0;
      display: inline;
      
      td {
        padding: 0 24px 0 0;
        line-height: 1.75;
        vertical-align: top;
      }
    }
  }
  
  .contact-container {
    position: relative;
    padding: 120px 0 0;
    margin-bottom: 0;
    @media all and ( min-width: $screen-md ) {
      margin-bottom: 200px;
    }
    
    .form-container {
      padding: 20px;
      background: $white;
      z-index: 11;
      max-width: 480px;
      margin: 0 auto;
      position: relative;                   
      box-shadow: -20px 20px 0 0 rgba( 0, 0, 0, 0.15 );
      @media all and ( min-width: $screen-md ) {
        padding: 40px;
      }
      
      &:after {
        content: "";
        background-image: url( $assetImagePath + '/axes/axes_black.svg' );
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        bottom: -30px;
        right: 0;
        left: 0;
        margin: 0 auto;
        opacity: .1;
        width: 80%;
        height: 300px;
      }
      
      form .gform_body .ginput_container_consent {
        margin-left: 30px;
        
        input[ type="checkbox" ] {
          margin-left: -25px !important;
        }
      }
    }
    
    .form-background { 
      background: rgba( 81, 12, 18, 0.83 );
      background-image: url( $assetImagePath + '/patterns/pattern.png' );
      background-blend-mode: difference;
      position: absolute;
      right: -30px;
      top: -30px;
      width: 70%;
      height: 185%;
      z-index: -1;
      @media all and ( min-width: $screen-md ) {
        width: 100%;
      }
    }
  }
  
  .flannel-map-container {
    z-index: 32;
    position: relative;
    margin-bottom: 150px;
    width: 100%;
    max-width: 500px;
  }
  
  .contact-footer-border {
    position: relative;
    width: 100%;
    margin-top: -120px;
    margin-bottom: 20px;
    height: 150px;
    background: $black;
    z-index: 0;
    clip-path: polygon( 100% 0, 100% 100%, 0 100%, 0 100% );
    @media all and ( min-width: $screen-md ) {
      margin-top: -300px;
      margin-bottom: 60px;
    }
  }
  
  .flannel-pagination-container-bkgd {
    padding: 20px;
    margin-top: -20px;
    @media all and ( min-width: $screen-sm ) {
      padding: 20px 30px;
    }
    
    @media all and ( min-width: $screen-md ) {
      padding: 80px 40px 35px;
      margin-top: -60px;
    }
    
    .flannel-pagination-container img {
      display: none;
    }
  }
}
