.flannel-local-front-page {
  header {
    .flannel-header-grid {
      padding-top: 35px;  
    }
    
    .flannel-video-thumbnail {
      max-width: none;
    }
    
    .logo-container {
      margin-top: 15px;
      padding: 0 0 0 30px;
      @media all and ( min-width: $screen-md ) {
        padding: 0; 
      }
      
      img {
        width: 162px;
        margin-top: 30px;
        @media all and ( min-width: $screen-sm ) {
          width: 187px;
          margin-top: 0;
        }

        &.visual-image {
          width: auto;
          margin: 0 auto;
        }
      }
    }
    
    .mobile-bkgd-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    .title-container {
      margin: 30px 15px 0;
      padding: 0 0 0 30px;
      @media all and ( min-width: $screen-md ) {
        margin: 30px 0 0;
        padding: 0;
      }
    }
  }
  
  .uk-container {
    padding: 0;
    @media all and ( min-width: $screen-md ) {
      padding: inherit;
    }
  }
  
  .hours-and-review-container {
    max-width: 270px;
    margin: 30px auto 0;
    
    .pentagon-container {
      @include open-semi;
      font-size: 20px;
      line-height: 28px;
      padding: 10px 30px 40px;
      margin: 0 0 -60px;
      
       &::after {
         opacity: .85;
      }
      
      .hours {
        font-size: 16px;
        color: $silver;
        text-transform: none;
        line-height: 28px;
        font-weight: 600;
      }
    }
    
    img {
      width: auto;
      margin: 0 auto 5px;
    }
    
    .flannel-total-rating {
      text-decoration: underline;
      
      &:active,
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
  
  .flannel-content-header {
    padding: 40px 0 0 40px;
  }
  
  .local-main-container {
    margin-top: 30px; 
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    @media all and ( min-width: $screen-md ) {
      margin-bottom: 0;
      margin-top: 40px;
      max-width: 1080px;
    }
    
    .interactive-map { 
      height: 367px;
    }

    .content-column {
      padding: 0 30px 40px 0;
      background: $white;
      min-height: 400px;
      @media all and ( min-width: $screen-md ) {
        padding: 0 40px 40px 0;
      }
    }
    
    .opening-content-column {
      top: -50px;  
      display: flex;
      width: 80%;
      z-index: 1;
      right: 0;
      flex-direction: column;
      align-items: center;
      @media all and ( min-width: $screen-md ) {
        margin: -40px 60px 0 0;
      }

      .flannel-plaid-obi {
        width: 300px; 
        background: $red;
        padding: 140px 20px;
        margin-top: -45px;
        background-image: url( $assetImagePath + '/patterns/pattern.png' );
      }
    }

    // Overwrite styles
    .three-columns-container {
      background: transparent;
      margin-top: -150px;
    }
  }
  
  .flannel-local-testimonial-container {
    margin-top: -40px;
    
    .flannel-testimonial-container {
      height: 760px;
      clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%);
      margin-bottom: -250px;
    }
  }
  
  .three-columns-container {
    margin-top: 0;
    @media all and ( min-width: $screen-sm ) {
      margin-top: 60px;
    }
    
    @media all and ( min-width: $screen-md ) {
      margin-top: 0;
    }
    
    .uk-first-column {
      margin-top: -50px;
      margin-bottom: 50px;
      @media all and ( min-width: $screen-md ) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
  
  .opening-soon-background-divider {
    clip-path: polygon( 0 0, 100% 100%, 100% 100%, 0% 100% );
    background: $black;
    padding-top: 90px;
    margin-bottom: -1px;
  }

    .flannel-widget-container {
      max-width: $max-width;
      margin: 0 auto;
      padding-right: 30px;
      @media all and ( min-width: $screen-md ) {
        max-width: none;
      }

      .calendar-container {
        .calendar-header-container {
          background: $red;
          background-image: url( $assetImagePath + '/patterns/pattern.png' );
          padding: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          h3 {
            text-shadow: -6px -4px 0 rgba( 0, 0, 0, 0.8 );
            font-size: 40px;
            line-height: 1;
            @media all and ( min-width: $screen-md ) {
              font-size: 60px;
            }
          }
        }

        .calendar-events-container {
          padding: 20px 20px 1px;
          background: $white;
          @media all and ( min-width: $screen-md ) {
            padding: 35px 35px 1px;          
          }

          li {
            list-style: none;
            margin: 0 0 20px;
            display: flex; 
            align-items: center;
            @media all and ( min-width: $screen-md ) {
              margin: 0 0 40px;
            }

            .date-container {
              @include axe;
              border-radius: 6px;
              display: inline-block;
              padding: 10px;
              position: relative;
              margin-right: 20px;
              text-align: center;
              min-width: 42px;
              font-size: 22px;
              line-height: 0.97;
              color: $black;
              @media all and ( min-width: $screen-md ) {
                font-size: 26.4px;
                min-width: 50px;
                margin-right: 25px;
              }
              
              img {
                position: absolute;
                top: -6px;
                left: 1px;
              }

              span {
                display: block;
              }
            }

            a {
              @include open-bold;
              margin: 0;
              text-transform: uppercase;
              font-size: 16px;
              color: $black;
              @media all and ( min-width: $screen-md ) {
                font-size: 20px; 
              }
              
              &:active,
              &:hover,
              &:focus {
                color: $red;
                text-decoration: none;
              }
            }
          }
        }
      }

      .promo-image-container {
        position: relative;
        max-height: 400px;
        margin: 0 -30px;
        overflow: hidden;
        @media all and ( min-width: $screen-md ) {
          margin: 65px 0 0;
        }
        
        &:active,
        &:hover,
        &:focus {
          opacity: .8;
        }

        .promo-content-container {
          position: absolute;
          bottom: 0;
          z-index: 12;
          padding: 20px;
          @media all and ( min-width: $screen-md ) {
            padding: 30px;
          }
          
          h2 {
            font-size: 40px;
            line-height: 1;            
            margin-bottom: 5px;
            @media all and ( min-width: $screen-sm ) {
              font-size: 45px;
            }
            
            @media all and ( min-width: $screen-md ) {
              font-size: 60px;
              line-height: 53px;
              margin-bottom: 20px;
            }
          }

          p {
            @include open-con;
            text-transform: uppercase;
            font-size: 19px;
            line-height: 1.47;
            letter-spacing: 6.5px;
            color: $white;
          }
        }

        .image-container {
          @include flex-center;
          height: 100%;
          width: 100%;
          overflow: hidden;
        }
      }

      .promo-text-container {
        background: $red;
        background-image: url( $assetImagePath + '/patterns/pattern.png' );
        margin: 0 -30px;
        @media all and ( min-width: $screen-md ) {
          margin: 40px 0 0;
        }

        ul {
          margin: 0 20px 20px;
          @media all and ( min-width: $screen-md ) {
            margin: 20px;
          }
        }

        li {
          background: $white;
          margin: 20px 0 0;
          padding: 20px;
          color: $black;
        }

        h2, h3, h4 {
          color: $black;
          font-size: 28px;
          @media all and ( min-width: $screen-sm ) {
            font-size: 40px;
          }
        }
        
        p {
          font-size: 14px;
          line-height: 22px;
          color: $matterhorn;
          @media all and ( min-width: $screen-sm ) {
            font-size: 20px;
            line-height: 28px;
          }
        }

        a {
          @include open-con;
          text-transform: uppercase;
          color: $black;
          font-size: 13px;
          letter-spacing: 6px;
          line-height: 1;
          @media all and ( min-width: $screen-sm ) {
            font-size: 19px;
          }

          &:after {
            @include fa;
            content: "\f30b";
            margin-left: 10px;        
          }
          
          &:active,
          &:hover,
          &:focus {
            text-decoration: none;
            color: $red;
          }
        }

        .promo-text-nav-container {
          @include open-con;
          text-align: center;
          background: $white;
          margin: 10px 20px 0;
          padding: 20px 0 10px;
          color: $matterhorn;
          letter-spacing: 4px;

          a {
            color: $matterhorn;
            font-size: 20px;
            line-height: 1.4;
            margin: 0 4px;
            letter-spacing: 7px;

            &:after {
              content: "";
              display: none;
            }
            
            &:active,
            &:hover,
            &:focus {
              text-decoration: none;
              color: $red;
            }
          }
        }
      }

      .wall-of-fame-container {
        margin-top: 40px;
        
        h2 {
          padding: 0 30px;
        }
        
        span {
          @include open-bold;
          font-size: 16px;
          position: absolute;
          left: 0;
          right: 0;
          top: 25%;
          text-transform: uppercase;
          color: $white;
        }
        
        .high-score-table thead th,
        .high-score-table td {
          padding: 15px 30px;
        }
        
        a {
          &:active,
          &:hover,
          &:focus {
            text-decoration: none;
          }
        }
      }
    }

    .social-links-parent-container {
      margin-top: 50px;
      @media all and ( min-width: $screen-lg ) {
        margin-top: -30px;
      }      
      
      h2 {
        padding: 0;
        margin: 0;
      }
      
      .social-links-container {
        margin: 15px 0 50px;
      }
      
      a {
        font-size: 30px;
        margin: 0 30px 0 0;
        
        &:active,
        &:hover,
        &:focus {
          color: $red;
        }
      }
    }
}
