.flannel-contact-page {
  .uk-container {
    max-width: 1100px;
    margin: 0 auto;
    padding-top: 0;
    @media all and ( min-width: $screen-md ) {
      padding-top: 30px;
    }
  }
  
  .content-container {
    min-height: auto;
    max-width: $max-width;
    margin: 0 auto;
    padding-bottom: 40px;
    @media all and ( min-width: $screen-md ) {
      min-height: 650px;
      max-width: none;
    }
    
    h2 {
      margin-bottom: 10px;
    }
    
    h4 {
      text-align: center;
      margin-bottom: 40px;
      @media all and ( min-width: $screen-xs ) {
        text-align: left;
      }
    }
    
    p {
      margin: 10px 0;
    }
    
    a {
      &:active,
      &:hover,
      &:focus {
        color: $grey;
        text-decoration: none;
      }
    }
  }
  
  .icon-container {
    @include flex-center;
    border-radius: 50%;
    border: 3px solid $matterhorn;
    margin-right: 20px;
    min-width: 40px;
    height: 40px;  
    margin: 0 auto 10px;
    align-self: start;
    @media all and ( min-width: $screen-xs ) {
      min-width: 50px;
      height: 50px;
      margin: 0 15px 0 0;
    }
    
    i {
      font-size: 1em;
      @media all and ( min-width: $screen-xs ) {
        font-size: 1.33em; 
      }
    }
  }
  
  .info-container {
    @include open-bold;
    font-size: 20px;
    line-height: 1.4;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    flex-direction: column;
    @media all and ( min-width: $screen-xs ) {
      flex-direction: row;
    }
    
    .address-container {
      text-align: center;
      @media all and ( min-width: $screen-xs ) {
        text-align: left;
      }
    }
    
    span {
      display: block;
    }
    
    a {
      font-size: 14px;
      line-height: 28px;
    }
  }
  
  .contact-container {
    position: relative;
    padding: 0 0 50px;
    max-width: 450px;
    float: none;
    margin: 0 auto;
    @media all and ( min-width: $screen-md ) {
      margin: 0;
      padding: 0;
      float: right;
    }
    
    .form-container {
      padding: 15px;
      background: $white;
      z-index: 11;
      position: relative;
      overflow: hidden;
      min-height: 470px;
      box-shadow: -20px 20px 0 0 rgba( 0, 0, 0, 0.15 );
      @media all and ( min-width: $screen-md ) {
        padding: 0 40px 40px;
      }
      
      &:after {
        content: "";
        background-image: url( $assetImagePath + '/axes/axes_black.svg' );
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        bottom: -30px;
        right: 0;
        left: 0;
        margin: 0 auto;
        opacity: .1;
        width: 80%;
        height: 300px;
      }
      
      .gform_body div.ginput_container {
        width: auto !important;
      }
      
      div.gform_wrapper.gform_validation_error {
        padding-top: 30px;
        margin-top: 0;
        
        li.gfield.gfield_error {
          background: transparent;
        }
        
        div.validation_error {
          margin-bottom: 0;
        }
      }
      
      .ginput_container_consent {
        margin: 30px 0 0 25px;
        
        input {
          margin-left: -25px !important;
        }
      }
      
      .gform_footer input[ type="submit" ] {
        font-size: 23px !important;
      }
    }
    
    .form-background {
      background: rgba( 81, 12, 18, 0.83 );
      background-image: url( $assetImagePath + '/patterns/pattern.png' );
      background-blend-mode: difference;
      position: absolute;
      right: -55px;
      top: -66px;
      width: 100%;
      z-index: -4;
      height: 145%;
    }
  }
}

.minus-eighty {
  margin-top: -80px
}
