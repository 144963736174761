.flannel-detail-page {
  .uk-container {
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 0;
    padding: 0 20px;
    @media all and ( min-width: $screen-sm ) {
      padding: 0 30px;
    }
    
    @media all and ( min-width: $screen-md ) {
      padding-top: 30px;
    }
    
    .content-container {
      max-width: $max-width;
      margin: 0 auto 30px;
      padding-bottom: 60px;
      padding-right: 10px;
      @media all and ( min-width: $screen-md ) {
        margin: 0 auto;
      }
    }
    
    .promo-column {
      max-width: 490px;
//      float: none;
      margin: 0 auto;
      @media all and ( min-width: $screen-md ) {
//        float: right;
//        margin-right: 85px;
      }
      
      .flannel-detail-video {
        margin-bottom: 60px;
      }

      .promo-background {
        background: $red;
        background-image: url( $assetImagePath + '/patterns/pattern.png' );
        position: absolute; 
        right: -40px;
        top: -60px;
        width: 100%;
        height: 120%;
        clip-path: polygon( 0 0, 100% 0, 100% 100%, 30% 100% );
      }
    }
  }
  
  .flannel-information-container {
    margin: 0 -20px;
    @media all and ( min-width: $screen-md ) {
      margin: 0;
    }
    
    .content-column {
      padding: 30px 20px;
      margin: 50px auto 0;
      @media all and ( min-width: $screen-md ) {
        padding: 80px 40px 151px 80px;
      }
    }
  }

  // Override media-slider styles
  .flannel-media-slider-container {
    top: 0;
    background: $white;
    padding: 0;
    margin-bottom: 0;
    @media all and ( min-width: $screen-md ) {
      top: -150px;
      margin-bottom: -150px;
      padding: 0 40px;
      max-width: 985px;
      box-sizing: border-box;
      right: 0;
      float: right;
    }

    .media-slider-background {
      display: none;
    }

    .media-slider-component {
      margin: 0;
    }
  }
  
  .additional-details-container {
    padding: 30px 0 55px;
    clear: both;
    max-width: 930px;
    margin: 0 auto;
  }
  
  .book-box-background {
    background: $black;
    width: 100%;
    height: 150px;
    margin-top: -150px;
    position: relative;
    z-index: -4;
  }
  
  .flannel-red-book-box {
    max-width: 690px;
    margin: 0 auto;
    clear: both;
  }
  
  .flannel-pagination-container-bkgd {
    padding: 67px 20px;
  }
}
