.flannel-accordion-container {
  li {
    flex-direction: column;
    width: 100%;
    
    &:before {
      content: "" !important;
      display: none !important;
    }
  }
  
  a.uk-accordion-title {
    font-weight: 600;
    font-size: 16px;
    background: $whisper;
    padding: 15px 20px;
    color: $matterhorn;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    @media all and ( min-width: $screen-xs ) {
      font-size: 18px;
    }  
    
    @media all and ( min-width: $screen-sm ) {
      font-size: 20px;
    }
    
    &::before {
      @include fa;
      content: "\f067";
      margin-left: 15px;
      margin-right: 0;
      float: right;
      color: $matterhorn;
      @media all and ( min-width: $screen-sm ) {
        margin-left: 30px;
        margin-right: 10px;
      }
    }
    
    &:active,
    &:hover,
    &:focus {
      background: $black;
      color: $white;
      
      &::before {
        content: "\f067";
        color: $white;
      }
    }
  }
  
  .uk-open a.uk-accordion-title::before {
    content: "\f068";
    
    &:active,
    &:hover,
    &:focus {
      color: $white;
    }
  }
  
  .uk-accordion-content {
    padding: 10px 20px;
    background: $whitesmoke;
    margin: 0;
    
    p {
      font-size: 14px;
      @media all and ( min-width: $screen-xs ) {
        font-size: 16px;
      }  
    }
  }
}
