.three-columns-container {
  padding: 0 30px;
  z-index: 12;
  position: relative;
  background: $black;
  margin-top: -60px;
  margin-bottom: 0 !important; // override uk-container styling
  @media all and ( min-width: $screen-sm ) {
    margin-top: 0;
    padding: 0 42px;
  }
  
  @media all and ( min-width: $screen-md ) {
    background: transparent;
    margin-bottom: 200px !important; // override uk-container styling
  }
  
  .text-container {
    padding: 0 15px;
    @media all and ( min-width: $screen-sm ) {
      padding: 0 30px;
    }
  }
  
  .column-box-shadow {
    box-shadow: -20px 20px 0 rgba( 0, 0, 0, .15 );
    padding-bottom: 40px;
  }
  
  p {
    font-size: 16px;
    line-height: 24px;
    @media all and ( min-width: $screen-sm ) {
      font-size: 20px;
      line-height: 28px;
    }    
  }
  
  figure {
    background: $black;
    position: relative;
    max-width: 360px;
    margin-bottom: 5px;
    @media all and ( min-width: $screen-sm ) {
      margin-bottom: 15px;
    }
      
    
    h2 {
      padding: 0 15px;
      position: absolute; 
      margin: 15px 0;
      bottom: 0;
      color: $white; 
      text-transform: uppercase; 
      line-height: 0.88;
      @media all and ( min-width: $screen-sm ) {
        padding: 0 30px;
      }
      
    }
  }
  
  .button-margin {
    margin: 30px 0 0;
  }
}
