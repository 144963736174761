.flannel-location-page {
  &.page-container .uk-container {
    padding: 0 10px;
    @media all and ( min-width: $screen-sm ) {
      padding: 0 15px;
    }
  }
  
  .content-container {
    padding: 0 10px;
    margin-top: -15px;
    @media all and ( min-width: $screen-sm ) {
      padding: 0 15px;
      margin-top: 0;
    }
  }
  
  .map-background {
    background: $red; 
    background-image: url( $assetImagePath + '/patterns/pattern.png' );
    width: 385px;
    position: absolute;
    z-index: -1;
    right: -170px;
    top: -170px;
    height: 180%;
  }
  
  .store-location-container {
    width: 100%;
    max-width: 1170px;
  }
  
  .location-container {
    margin-top: 60px;
    background: $white;
    padding: 30px 10px 120px;
    margin-bottom: -40px;
    @media all and ( min-width: $screen-sm ) {
      padding: 60px 30px 120px;
    }
    
    .opening-soon-container {      
      .store-name {
        @include open-bold;
        font-size: 20px;
        color: $black;
        margin: 0;
      }
      
      .store-address,
      .store-opening {
        color: $matterhorn;
        font-size: 14px;
        line-height: normal;
        margin: 0;
      }
      
      .store-opening {
        background-color: $silver;
        display: inline-block;
        padding: 1px 4px;
      }
    }
    
    img {
      position: absolute;
      width: 550px;
      right: 50px;
      top: 50%;
    }
  }
  
  &.franchise-section {
    margin-top: -20px;
    margin-bottom: 0;
    position: relative;
    z-index: 2;
    @media all and ( min-width: $screen-sm ) {
      margin-bottom: -100px;
    }
    
    @media all and ( min-width: $screen-lg ) {
      margin-bottom: -140px;
    }
  }
  
  // Overwrite footer-single-cta.scss
  .location-content-container {
    padding: 0 15px !important;
    @media all and ( min-width: $screen-sm ) {
      padding: 90px 0 !important;
    }
  }
}
