.flannel-divider {
  background: rgba( 0, 0, 0, .8 );
  background-repeat: space repeat;
  background-position: center;
  margin: 90px 0 110px;
  width: 100%; 
  position: relative; 
  height: 240px;
  @media all and ( min-width: $screen-md ) {
    margin: 90px 0 150px;
  }
  
  &:after {
    content: "";
    background: url( $assetImagePath + '/patterns/black_pattern.png' );
    opacity: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
  
  .divider-shadow {
    filter: none;
    @media all and ( min-width: $screen-sm ) {
      filter: drop-shadow( -20px 20px 0px rgba( 0, 0, 0, 0.2 ) );    
    }
        
    
    .divider-container {
      max-width: 420px;
      background: $red;
      text-align: center;
      padding: 30px 50px 100px;
      clip-path: polygon( 0 0, 100% 0%, 100% 85%, 0% 100% );
      color: $white;
      margin: -30px auto; 
      position: absolute;
      left: 0;
      right: 0;
      
      h2 {
        color: $white;
      }
      
      p {
        @include open-semi;
        line-height: 1.75;
      }
      
      .flannel-button a {
        background: $black;
        
        &:active,
        &:hover,
        &:focus {
          color: $red !important;
        }
      }
    }
  }
}
