.circle-video-container {
  display: inline-block;
  
  .circle-video-button {
    @include flex-center;
    padding: 0;
    background: transparent;
    margin-right: 7px; 
    @media all and ( min-width: $screen-sm ) {
      margin-right: 30px; 
    }
    
    @media all and ( min-width: $screen-md ) {
      margin-right: 50px;
    }

    &::before {
      @include fa;
      content: "\f04b";
      color: $red;
      z-index: 44;
      font-size: 26px;
      position: absolute;
      font-weight: bold;
      text-shadow: 0 3px 3px $black;
      @media all and ( min-width: $screen-md ) {
        font-size: 40px;
      }
    }
    
    video,
    img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      object-fit: cover;
      @media all and ( min-width: $screen-md ) {
        width: 160px;
        height: 160px;
      }
    }
  }
}
