.flannel-testimonial-quote {
  margin: 0 auto -30px;
  position: relative; 
  z-index: 55;
  width: 50px;
  @media all and ( min-width: $screen-md ) {
    width: 100px;
    margin: 0 auto -50px;
  }
}

.flannel-testimonial-container {
  position: relative;
  z-index: 6;
  height: 520px;
  background: $red;
  clip-path: polygon( 0 0, 100% 0%, 100% 90%, 0% 100% );
  padding: 50px 30px 30px;
  color: $white;
  margin: 0 -15px;
  text-align: left;
  @media all and ( min-width: $screen-sm ) {
    margin: 0;
    text-align: center;
    clip-path: polygon( 0 0, 100% 0%, 100% 70%, 0% 100% );
    height: 600px;
    padding: 75px 20px 30px;
  }
  
  @media all and ( min-width: $screen-md ) {
    padding: 105px 100px 0;
    height: 580px;
  }

  h2 {
    font-size: 40px;
    margin-bottom: 0;
    @media all and ( min-width: $screen-sm ) {
      font-size: 45px;
    }

    @media all and ( min-width: $screen-sm ) {
      font-size: 60px;
    }
  }

  img {
    font-size: 200px;
    color: $black;
    text-align: center;
    position: absolute;
    top: -35px;
    line-height: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 20;
  }

  blockquote {
    @include open-italic;
    font-size: 16px;
    color: $white;
    line-height: 1.45;
    margin: 0 0 30px;
    text-align: left;
    padding: 0;
    @media all and ( min-width: $screen-xs ) {
      line-height: 1.75;
    }

    @media all and ( min-width: $screen-sm ) {
      text-align: center;
      padding: 0 50px;
      font-size: 18px;
    }
    
    @media all and ( min-width: $screen-md ) {
      line-height: 1.75;
      font-size: 20px;
    }
  }

  ul {
    outline: none;

    span {
      @include open-bold;
      display: block;
      text-transform: uppercase;

      &.person {
        color: $black;    
      }
    }
  }

  li {
    &.uk-active {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    
    span {
      @include open-bold;
      font-size: 20px;
    }
  }

  .testimonial-nav-container {
    @include open-con;
    margin: 0;
    font-size: 16px;
    letter-spacing: 2px;
    z-index: 33;
    color: $black;
    @media all and ( min-width: $screen-sm ) {
      font-size: 20px;
    }

    a {
      color: $black;
      
      &:hover, {
        color: $white;
        text-decoration: none;
      }
    }
  }
}
