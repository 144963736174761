.flannel-events-page {
  .content-container {
    width: 100%;
    max-width: 930px;
    margin: 0 auto 30px;
    
    .content-column {
      padding: 20px 20px 80px;
      max-width: $max-width;
      margin: 0 auto;
      @media all and ( min-width: $screen-sm ) {
        padding: 20px 30px 80px;
      }
      
      @media all and ( min-width: $screen-md ) {
        padding: 60px 10px 80px;
        max-width: none;
        width: calc( 60% - 1px );
      }
    }
    
    .video-column {
      @include flex-center;
      
      .plaid-background {
        position: absolute;
        top: 0; 
        right: -200px;
        height: 100%; 
        width: 385px;
        background: $red;
        background-blend-mode: difference;
        background-image: url( $assetImagePath + '/patterns/pattern.png' );
      }
    }
  }
  
  .content-row-container {
    a {
      &:active,
      &:hover,
      &:focus {
        color: $grey;
        text-decoration: none;
      }
    }
    
    .event-info {
      margin: -30px auto 30px;
      max-width: 640px;
      @media all and ( min-width: $screen-sm ) {
        margin-top: -60px;  
      }
      
      @media all and ( min-width: $screen-md ) {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0 50px;
      }
      
      h3 {
        margin: 0;
      }
      
      span {
        @include open-bold;
        color: $red;
        font-size: 20px;
      }
    }
    
    .calendar-body {
      span {
        @include axe;
      }
    }
    
    .uk-container .content-grid .media-column {
      img {
        box-shadow: -20px 20px 0 0 $red;
        max-height: 300px;
        object-fit: cover;
      }
    }
  }
}
