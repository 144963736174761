.flannel-blog-post-header {
  height: 300px;
  @media all and ( min-width: $screen-md ) {
    height: 400px;
  }
    
  .background-banner {
    position: absolute;
    width: 100%;
    height: 300px;
    object-fit: cover;
    @media all and ( min-width: $screen-md ) {
      height: 400px;
    }
  }
  
  a {
    z-index: 8;
    position: relative;
    justify-content: center;
    display: flex;
    
    img {
      margin-top: 30px;
    }
  }
  
  .flanneljaxs-logo {
    position: relative;
    display: flex;
    margin: 0 auto;
    padding: 30px 0 150px;
  }
}

.flannel-blog-post-container {
  max-width: $max-width;
  padding: 0 15px;
  margin-top: 0;
  background: $white;
  position: relative;
  z-index: 12;
  @media all and ( min-width: $screen-md ) {
    max-width: 930px;
    padding: 10px 30px;
    margin-top: -100px;
  }
  
  .flannel-article-header {
    margin-top: 15px;
    
    h1 {
      margin: 15px 0;
    }
    
    a {
      font-size: 14px;
      
      &:active,
      &:hover,
      &:focus {
        color: $grey;
        text-decoration: none;
      }
    }
    
    .featured-image-container {
      clear: both;
      margin: 30px 0;
    }
  }
  
  .meta-container {
    border-top: 2px solid $whitesmoke;
    border-bottom: 2px solid $whitesmoke; 
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    @media all and ( min-width: $screen-sm ) {
      align-items: center;
      flex-direction: row;
      padding: 15px 0;
    }
    
    time {
      @include open-bold;
      font-size: 14px;
      color: $grey;
      margin-bottom: 10px;
      @media all and ( min-width: $screen-md ) {
        font-size: 16px;
        margin-bottom: 0;
      }
      
      i {
        color: $matterhorn;
        font-size: 14px;
        margin-right: 14px;
        @media all and ( min-width: $screen-md ) {
          margin-right: 20px;
          font-size: 20px;
        }
      }
    }
    
    .share-links-container {
      @include open-bold;
      color: $grey;
      font-size: 14px;
      
      a {
        font-size: 14px;
        margin-left: 14px;
        @media all and ( min-width: $screen-md ) {
          margin-left: 20px;
          font-size: 20px;
        }
        
        &[ title = 'Facebook' ] {
          color: $facebook;
        }
        
        &[ title = 'Twitter' ] {
          color: $twitter;
        }   
        
        &[ title = 'Pinterest' ] {
          color: $pinterest;
        }
        
        &[ title = 'LinkedIn' ] {
          color: $linkedin;
        }       
        
        &[ title = 'Email' ] {
          color: $matterhorn;
        }
        
        &:active,
        &:hover,
        &:focus {
          text-decoration: none;
          opacity: .8;
        }
      }
    }
  }
  
  .entry-content {
    a {
      &:active,
      &:hover,
      &:focus {
        color: $grey;
        text-decoration: none;
      }
    }

    ul { 
      list-style-type: none;

      li {
        // counter-increment: numbers;
        font-size: 16px;
        line-height: 1.6;
        color: $matterhorn;
        margin-bottom: 10px;
        @media all and ( min-width: $screen-sm ) {
          font-size: 18px;
        }

        @media all and ( min-width: $screen-md ) {
          font-size: 20px;
        }

        &::before {
          content: "■";
          color: $red;
          width: 47px;
          display: inline-block;
          margin-left: -53px;
          text-align: right;
          padding-right: 15px;
        }
      }
    }
  }
  
  .author-container {
    margin: 50px 0 80px;
    position: relative;
    
    .author-plaid-background {
      margin: -30px; 
      background: $red;
      background-image: url( $assetImagePath + '/patterns/pattern.png' );
      width: 100%;
      height: 60%; 
      padding: 30px;
      position: absolute;
    }
    
    .author-info {
      background: $white;
      position: relative;
      margin: 0;
      padding-top: 30px;
      padding-right: 30px;
        
      h1, h2, h3, h4, h5, h6 {
        @include open-bold;
        font-size: 24px;
        margin-bottom: 10px;
      }
      
      p {
        font-size: 16px;
        line-height: 1.5;
        @media all and ( min-width: $screen-md ) {
          font-size: 20px;
          line-height: 1.75;
        }
      }
    }
  }
}

.blog-pagination-container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto 40px;
  
  .pagination-nav-container {
    border-top: 3px solid $black;
    border-bottom: 3px solid $black;
    padding: 10px 15px;
    max-width: 500px;
    margin: 0 auto;

    
    .pagination-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      color: $black;
      
      .pagination-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 35px;
        
        &.previous-icon {
          margin-right: 20px;
        }
        
        &.next-icon {
          margin-left: 20px;
        }
      }
      
      p {
        @include axe;
        font-size: 26px;
        margin: 0;
        line-height: 1.1;
        @media all and ( min-width: $screen-md ) {
          font-size: 40px;
        }
      }
      
      span {
        @include open-bold;
        font-size: 14px;
        color: $red;
      }
    }
  }
  
  a {
    &:active,
    &:hover,
    &:focus {
      text-decoration: none;
      
      p,
      .pagination-icon {
        color: $red;
      }
    }
  }
}
