header.flannel-local-page-header {
  margin-bottom: 30px;
  position: relative;
  
  .wp-post-image {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .header-logo-container {
    @include flex-center;
    img {
      min-width: 170px;
    }
  }
  
  .local-header-container {
    z-index: 9;
    position: relative;
    max-width: 1080px;
    margin: 0 auto;
    padding: 55px 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media all and ( min-width: $screen-md ) {
      flex-direction: row;  
      padding: 55px;
    }
    
    .flannel-local-title-container {
      @include flex-center;
      flex-direction: column;
      padding: 20px 0;
      @media all and ( min-width: $screen-md ) {
        padding: 0 20px;      
      }
    }
  }

  .sub-header {
    @include open-bold;
    font-size: 24px;
    line-height: 1.46;
    margin: 0;
  }
  
  .local-page-header-humburger-container {
    position: absolute;
    right: 70px;
    top: 30px;
    @media all and ( min-width: $screen-xs ) {
      right: 100px;
      top: 40px;
    }
  }
}

.flannel-local-site .header-contact-container {
  margin: 0 auto;
  @media all and ( min-width: $screen-md ) {
    margin: 50px 0 0;
  }
}

.header-contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  min-width: 240px;
  position: relative;
  z-index: 22;
  @media all and ( min-width: $screen-md ) {
    margin: 0;
  }
  
  .flannel-order-red,
  .flannel-button {
    max-width: 250px; 
    margin: 0 auto;
  }

  span {
    @include open-bold;
    font-size: 21px;
    color: $white;
    margin: 15px 0 0;
    
    a {
      color: $white;
      border-bottom: 4px solid $red;
      padding-bottom: 2px;
      text-decoration: none;
      
      &:active,
      &:hover,
      &:focus {
        color: $white;
        border-bottom: none;
      }
    }
  }
}
