html, body {
  @include open-sans;
  color: $matterhorn;
  overflow-x: hidden;
  position: relative;
}

a {
  @include open-bold;
  color: $red;
  
  &:hover,
  &:active,
  &:focus {
    color: $red;
  }
}

p {
  font-size: 16px;
  line-height: 1.5;
  @media all and ( min-width: $screen-md ) {
    font-size: 20px;
    line-height: 1.75;
  }
}

video {
  @extend .flannel-video-thumbnail;
}

h1, h2, h3 {
  @include axe;
  color: $black;
  
  a {
    @include axe;
    &:active,
    &:hover,
    &:focus {
      color: $grey;
      text-decoration: none;
    }
  }
}

h1 {
  font-size: 45px;
  line-height: 1.05;
  @media all and ( min-width: $screen-md ) {
    font-size: 75px;
  }
}

h2 {
  font-size: 45px;
  line-height: 1.03;
  @media all and ( min-width: $screen-md ) {
    font-size: 60px;
  }
}

h3 {
  font-size: 40px;
  line-height: 1;
  @media all and ( min-width: $screen-md ) {
    font-size: 45px;
  }
}

h4 {
  @include open-bold;
  font-size: 24px;
  line-height: 1.33;
}

h5 {
  @include open-sans;
  font-size: 12px;
}

b, strong {
  @include open-bold;
}

blockquote, 
blockquote p {  
  @include open-bi;
  color: $red;
  font-style: italic;
  margin: 30px 0 -10px;  
  font-size: 25px;
  line-height: 35px;
}

ol {
  list-style: none;
  counter-reset: numbers;
  padding-left: 45px;
}

ol li {
  counter-increment: numbers;
  font-size: 16px;
  line-height: 1.6;
  color: $matterhorn;
  margin-bottom: 10px;
  @media all and ( min-width: $screen-sm ) {
    font-size: 18px;
  }
  
  @media all and ( min-width: $screen-md ) {
    font-size: 20px;
  }

  &::before {
    content: counter(numbers) ". ";
    color: $red;
    width: 38px;
    display: inline-block;
    margin-left: -53px;
    text-align: right;
    padding-right: 15px;
  }
}
