.flannel-default-page {
  @include red-list;
  margin-bottom: 60px;
  
  .uk-container {
    max-width: $max-width;
    margin: 0 auto;
    @media all and ( min-width: $screen-md ) {
      max-width: 930px;
      margin: 60px auto 0;
    }
  }
  
  a {
    color: $red;
    font-weight: bold;
  }
  
  .flannel-video-thumbnail {
    width: 100%;
    max-width: none;
    margin-bottom: 10px;
    @media all and ( min-width: $screen-md ) {
      max-width: 430px;
    }
    
    &.flannel-box-shadow {
      margin-bottom: 40px;
      
      video {
        margin-bottom: 0;
      }
    }
  }
  
  .flannel-float-right {
    margin-left: 0;
    
    @media all and ( min-width: $screen-md ) {
      margin-left: 40px;  
    }
  }
}

.default-page-footer-border {
  clip-path: polygon( 0 75%, 100% 0%, 100% 100%, 0% 100% );
  background: $black;
  padding-top: 60px;
  margin-bottom: -1px;
}
