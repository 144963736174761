@mixin transform( $deg ) {
  -webkit-transform: rotate( $deg );
  -moz-transform: rotate( $deg );
  -o-transform: rotate( $deg );
  transform: rotate( $deg );
}

@mixin transition( $args... ) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin red-list() {
  ul {
    list-style-type: none;
    padding-left: 20px;
    @media all and ( min-width: $screen-sm ) {
      padding-left: 20px;
    }

    li {
      list-style-type: none;
      font-size: 16px;
      line-height: 1.75;
      margin-bottom: 20px;
      @media all and ( min-width: $screen-sm ) {
        font-size: 18px;
      }

      @media all and ( min-width: $screen-md ) {
        font-size: 20px;
      }

      &:before {
        content: '■';
        font-size: 16px;
        color: $red;
        display: inline-block;
        text-indent: -5px;
        margin-top: -4px;
        margin-right: 11px;
        margin-left: -15px;
        @media all and ( min-width: $screen-sm ) {
          font-size: 18px;
        }
        @media all and ( min-width: $screen-md ) {
          font-size: 20px;
          margin-top: 0;
        }
      }
    }
  }
}



/************************
 * Fonts
 ************************/
@font-face {
  font-family: 'AxeHandel';
  src: url( '../fonts/AxeHandel Registered Version.eot' ); /* IE9 Compat Modes */
  src: url( '../fonts/AxeHandel Registered Version.eot?#iefix' ) format( 'embedded-opentype' ), /* IE6-IE8 */
       url( '../fonts/AxeHandel Registered Version.woff' ) format( 'woff' ), /* Modern Browsers */
       url( '../fonts/AxeHandel Registered Version.ttf' ) format( 'truetype' ); /* Safari, Android, iOS */
}

@mixin axe() {
  font-family: 'AxeHandel', sans-serif;
  text-transform: uppercase;
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  text-rendering: optimizeLegibility;
}

@mixin open-bold() {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
}

@mixin open-semi() {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}

@mixin open-italic() {
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  font-weight: 400;
}

@mixin open-sans() {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}

@mixin open-light() {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
}

@mixin open-bi() {
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  font-weight: 700;
}

@mixin open-con() {
  font-family: 'Open Sans Condensed', sans-serif;
  font-style: normal;
  font-weight: 700;
}

@mixin fa() {
  font-family: "Font Awesome 5 Pro";
}
