.instagram-feed-container {
  margin-bottom: -100px;
  padding: 40px 0 0;
}

.instagram-event-container {
  margin-bottom: -40px;
  background: $black;
}

#sb_instagram.sbi_fixed_height {
  overflow: hidden !important;
}

#sb_instagram a, {
  min-height: 200px !important;
  min-width: 200px !important;
  color: transparent;
}

#sb_instagram.sbi_col_10 #sbi_images .sbi_item {
  width: 50%;
}

#sb_instagram #sbi_images {
  display: flex;
  
  div:nth-child(3) {
    display: none;
    @media all and ( min-width: $screen-xs ) {
      display: block;
    }
  }  
  
  div:nth-child(4) {
    display: none;
    @media all and ( min-width: $screen-sm ) {
      display: block;
    }
  }
  
  div:nth-child(5) {
    display: none;
    @media all and ( min-width: 800px ) {
      display: block;
    }
  }
  
  div:nth-child(6) {
    display: none;
    @media all and ( min-width: $screen-md ) {
      display: block;
    }
  }
  
  div:nth-child(7) {
    display: none;
    @media all and ( min-width: $screen-lg ) {
      display: block;
    }
  }
  
  div:nth-child(8) {
    display: none;
    @media all and ( min-width: $screen-xl ) {
      display: block;
    }
  } 
  
  div:nth-child(9) {
    display: none;
    @media all and ( min-width: 1600px ) {
      display: block;
    }
  }
  
  div:nth-child(10) {
    display: none;
    @media all and ( min-width: 1800px ) {
      display: block;
    }
  }
}
