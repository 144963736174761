.flannel-media-slider-container {
  position: relative;
  right: 0;
  display: flex; 
  align-items: center;
  @media all and ( min-width: $screen-lg ) {
    right: -60px;
  }
  
  .uk-slider-items > * {
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    @media all and ( min-width: $screen-xs ) {
      width: auto;
      padding: 0 15px;
    }
  }
  
  .media-slider-background {
    background: $red; 
    background-image: url( $assetImagePath + '/patterns/pattern.png' );
    width: 300px; 
    height: 375px;   
    position: absolute;
    right: -60px;
    display: none;
    @media all and ( min-width: $screen-md ) {
      display: block;
    }
  }
  
  .media-slider-component {
    margin: 0;
    background: $white;
    padding-left: 30px;
    @media all and ( min-width: $screen-md ) {
      margin: 40px 0;
    }
    
    ul {
      display: flex;
      align-items: center;
      padding: 0;
      @media all and ( min-width: $screen-md ) {
        padding: 50px 0 0;
      }
      
      li {
        @include flex-center;
        margin: 0;
        padding: 0;
        
        &:before {
          content: "";        
        }
      }
      
      img,
      video {
        max-height: 210px;
        object-fit: contain;
        max-width: 360px;
      }
      
      img {
        padding-right: 15px;
      }
      
      a.flannel-video-thumbnail {
        margin: 0;
        padding: 0;
        max-width: 360px;
        
        video {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
  
  .media-slider-nav-container {
    width: 100px;
    float: right;
    margin: 3px 0 10px;
    
    a {
      font-size: 20px;
      color: $black;
      
      &:hover {
        color: $red;
        text-decoration: none;
      }
    }
  }
}
