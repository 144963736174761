.juicer-feed * {
  font-size: 13px;
  line-height: 16px;
}

.juicer-feed.modern .j-poster,
.juicer-feed h1.referral,
.juicer-feed .j-paginate {
  display: none;
}
