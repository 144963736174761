.eagr-reviews-layout-list-item {
  margin-bottom: 10px !important;
  
  > div {
    padding: 20px;
    @media all and ( min-width: $screen-md ) {
      padding: 50px;
    }    
  }

  &:nth-child(odd) > div {
    background: $whitesmoke !important;
  }
}
