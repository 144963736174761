.flannel-blog-page,
.flannel-category-page {
  margin-top: 30px;
  @media all and ( min-width: $screen-md ) {
    margin-top: 60px;
  }
  
  .featured-article-container,
  .featured-category-container {
    .uk-first-column {
      padding-left: 0;
      @media all and ( min-width: $screen-md ) {
        padding-left: 30px;
      }
    }
    
    .featured-article-image {
      margin-top: -60px;
      position: relative;
      margin-bottom: -15px;
      text-align: center;
    }
    
    .featured-article {
      background: $white;
      padding: 0;
      margin: 0;
      @media all and ( min-width: $screen-lg ) {
        background: $whitesmoke;
        padding: 50px 50px 50px 100px;
        margin: 0 -80px 20px -80px;
      }
      
      h3 {
        font-size: 36px;
        line-height: 1;
        @media all and ( min-width: $screen-sm ) {
          font-size: 45px;
        }
      }
      
      a {
        text-decoration: none;
        
        &:active,
        &:hover,
        &:focus {
          color: $grey;
          text-decoration: none;
        }
      }
    }
  }
  
  .featured-article-container{
    padding: 60px 0 180px;
    max-width: 690px;
    margin: 0 auto;
    @media all and ( min-width: $screen-md ) {
      max-width: none;
      margin: 0 0 0 -40px;
    }
  }
  
  .featured-category-container {
    padding: 60px 0 0;
  }
  
  .blog-list-angle-background {
    clip-path: polygon( 0 0, 100% 95%, 100% 100%, 0% 100% );
    background: $whitesmoke;
    height: 50px;
    margin-bottom: 0px;
  }

  .blog-list-background {
    background: $whitesmoke;
    position: relative;
    margin-bottom: -50px;
    padding-bottom: 50px;
    
    .uk-container {
      max-width: 1200px;
      padding: 0 15px 0 0;
      margin-left: -20px;
      @media all and ( min-width: $screen-sm ) {
        padding: 0 65px 0 35px;
        margin: 0 auto;
      }
      
      @media all and ( min-width: $screen-xl ) {
        padding: 0 65px;
      }
    }
  }
  
  .blog-container {
    margin-top: -150px;
    
    > div {   
      padding-left: 65px;
      
      &:nth-child(2) {
        @media all and ( min-width: $screen-sm ) {
          padding-top: 50px;
        }        
      }

      &:nth-child(3) {
        @media all and ( min-width: $screen-md ) {
          padding-top: 100px;
        }
      }
    }
    
    * + .uk-grid-margin {
      margin-top: 65px;
    }
    
    .uk-card {
      .uk-card-title {
        @include open-bold;
        font-size: 24px;
        line-height: 1.25;
        margin: 10px 0;
      }
      
      img {
        &:active,
        &:hover,
        &:focus {
          opacity: .7;
        }
      }
      
      h3 {
        &:active,
        &:hover,
        &:focus {
          color: $red;
        }
      }
      
      p {
        font-size: 14px;
        margin: 0;
      }
      
      a {
        text-decoration: none;
        
        &:active,
        &:hover,
        &:focus {
          color: $grey;
          text-decoration: none;
        }
      }
    }
  }
  
  .blog-list-pagination {
    @include open-bold;
    padding: 50px;
    text-align: center;
    
    span {
      background: $red;
      color: $white;
      padding: 2px 10px;      
    }
    
    a {
      margin: 0 15px;
      color: $matterhorn;
      
      &:active,
      &:hover,
      &:focus {
        color: $red;
        text-decoration: none;
      }
    }
  }
}

.max-six-ninety {
  max-width: 690px;
}
