.page-container {
  .uk-container {
    width: 100%;
    max-width: 990px;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;

    .content-container {
      background: $white;
      padding: 10px;
      @media all and ( min-width: $screen-sm ) {
        padding: 30px;
      }
    }
  }
}

.flannel-button a,
.flannel-order-red,
.flannel-order-black {
  @include open-con;
  padding: 15px 10px;
  color: $white !important;
  background: $red;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  width: 200px;
  text-align: center;
  position: relative;
  display: inline-block;
  cursor: pointer;
  z-index: 2;
  @media all and ( min-width: $screen-md ) {
    font-size: 23px;
    width: auto;
    padding: 11px 30px;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: $black !important;
  }
}

.flannel-order-black {
  background: $black;
  
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: $red !important;
  }
}

.background-divider {
  margin-top: -550px;
  z-index: 0; 
  position: relative;
  margin-bottom: -5px;
  height: 500px;
  background: $black; 
  clip-path: polygon( 0 0, 100% 20%, 100% 100%, 0% 100% );
}

.flannel-black-bkgd {
  background: $black;
  
  h1, h2, h3, {
    color: $white;
  }
}

.flannel-red-bkgd {
  background: $red;
}

.flannel-white-bkgd {
  background: $white;
}

.flannel-white-text {
  color: $white;
}

.flannel-black-text {
  color: $black;
}

.flannel-red-text {
  color: $red;
}

.flannel-outline-remove {
  outline: none;
}

.flannel-float-right {
  float: none;
  margin-right: 0 !important;
  @media all and ( min-width: $screen-sm ) {
    float: right;
  }
  
  @media all and ( min-width: $screen-lg ) {
    margin-right: -120px !important; 
  }
}

.flannel-float-left {
  float: none;
  margin-left: 0 !important;
  @media all and ( min-width: $screen-sm ) {
    float: left;
  }
  
  @media all and ( min-width: $screen-lg ) {
    margin-left: -120px !important;
  }
}

.flannel-box-shadow {
  box-shadow: -20px 20px 0 0 rgba( 0, 0, 0, .15 );
  margin: 0 50px 30px;
}

.flannel-plaid-background {
  width: 330px;
  height: 197px;
  background-color: rgba( 206, 31, 46, 1 );
  background-image: url( $assetImagePath + '/patterns/pattern.png' );
  position: absolute;
  top: -30px;
  left: -90px;
  z-index: -2;
}

.left-angle-footer-border {
  clip-path: polygon( 0 0, 100% 65%, 100% 100%, 0% 100% );
  background: $black;
  padding-top: 60px;
  margin-bottom: -2px;
}

.black-gradient-overlay {
  background: linear-gradient( to top, rgba( 0, 0, 0, 0.85 ), rgba( 0, 0, 0, 0 ) );
  width: 100%;
  height: 100%;
  position: absolute;
}

.flannel-background-gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  background: $black;
  background: linear-gradient( 180deg, rgba( 0, 0, 0, 1 ) 0%, rgba( 0, 0, 0, 0.4 ) 40% );
  padding: 0; 
}

.flannel-video-gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient( 180deg, rgba( 0, 0, 0, 1 ) 0%, rgba( 0, 0, 0, 0.1 ) 40% );
  z-index: 30;
}

.flannel-map-container {
  margin-bottom: 60px;
  box-shadow: -20px 20px 0 0 rgba( 0, 0, 0, .15 );
}

.title-shadow {
  text-shadow: -6px -4px 0 rgba( 0, 0, 0, 0.8 );
  margin: 0;
}

.black-nav-button {
  @include transition( opacity .3s ease );
  visibility: hidden;
  opacity: 0;

  a {
    margin: -10px 0;

    span {
      background: $black;
      padding: 10px 20px;
    }
  }
}

.flannel-parent-nav .black-nav-button {
  display: none;
}

.flannel-franchise-front-page,
.flannel-franchise-nav {
  .black-nav-button {
    display: inline-block;
    visibility: visible;
    opacity: 1;
  }
}

// To appear when nav menu is fixed
.uk-sticky-fixed .black-nav-button {
  visibility: visible;
  opacity: 1;
  display: inline-block;
}

.red-nav-button a {
  margin: -10px 0;
  border-bottom: none !important;

  span {
    background: $red;
    padding: 10px 20px;
  }
  
  &:active,
  &:hover,
  &:focus {
    color: $black !important;
  }
}

.flannel-national-base {
  padding-top: 0;
  @media all and ( min-width: $screen-md ) {
    padding-top: 65px;
  }
}

.flannel-max-container {
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
  @media all and ( min-width: $screen-md ) {
    max-width: none;
  }
}

.push-forty {
  margin-top: 20px;
  @media all and ( min-width: $screen-md ) {
    margin-top: 40px;
  }
}

.mobile-book-button {
  margin-top: -40px;
  margin-bottom: 40px !important; // overrides uk-grid setting
}

.pentagon-container {
  .flannel-rating {
    color: $white;
    font-size: 60px;
    margin: 0;
    line-height: 1.03;
  }
  
  .rating-title {
    @include open-semi;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: $white;
    text-transform: none;
    margin: 5px 0;
  }
  
  img {
    display: block;
    margin: 0 auto 2px;
  }

  .flannel-total-rating {
    @include open-semi;
    font-size: 14px;
    text-transform: none;
    line-height: 2;
    color: $grey;
    font-weight: normal;
  }
}

.interactive-map {
  width: 100%;
  height: 400px;
  background-color: $grey;
}  

.chat-app-button {
  @include flex-center;
  background-image: url( $assetImagePath + '/patterns/pattern.png' );
  width: 30px;
  height: 30px;
  position: fixed;
  bottom: -18%;
  right: 5%;
  border-radius: 50%;
  box-shadow: 4px 4px 4px $black;
  padding: 20px;
  z-index: 9999999;
  
  &:active,
  &:hover,
  &:focus {
    text-decoration: none;
    opacity: .7;
  }
  
  i {
    color: $white;
    font-size: 28px;
  }
  
  &.show {
    bottom: 5%;
    transition: all 1s ease;
  }
}

.flannel-slider-drag {
  pointer-events: auto;
  @media all and ( min-width: $screen-md ) {
    pointer-events: none;
  
  }
}

.right-float-helper {
  margin: 0 auto;
  display: block;
  margin-bottom: 40px;
  float: none;
  @media all and ( min-width: $screen-sm ) {
    margin-left: 40px;
    float: right;
  }
}


// Disable scrollspy animation on mobile devices.
.uk-animation-slide-left,
.uk-animation-slide-right {
  -webkit-animation-name: none;
  animation-name: none;
}

.uk-animation-slide-left {
  @media all and ( min-width: $screen-sm ) {
    -webkit-animation-name: uk-fade-left;
    animation-name: uk-fade-left;
  }
}

.uk-animation-slide-right {
  @media all and ( min-width: $screen-sm ) {
    -webkit-animation-name: uk-fade-right;
    animation-name: uk-fade-right;
  }
}
