.footer-cta-container {
  background: $black;
  position: relative;
  margin-bottom: -60px;
  z-index: 2;
  @media all and ( min-width: $screen-md ) {
    margin-bottom: -120px;
  }
  
  .full-bkgd-position {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .pre-box-container {
    margin-top: 0;
    @media all and ( min-width: $screen-md ) {
      margin-top: inherit;
    }
  }
  
  .box-container {
    height: 150px; 
    position: relative;
    padding: 0;
    margin: 0;
    @media all and ( min-width: $screen-sm ) {
      height: 300px;
    }
    
    @media all and ( min-width: $screen-md ) {
      margin: 0 -20px;
    }
    
    img {
      object-fit: cover;
      left: 0;
    }
    
    a {
      @include axe;
      @include flex-center;
      color: $white;
      text-transform: uppercase;  
      font-size: 40px;
      text-shadow: -6px -4px 0 rgba( 0, 0, 0, 0.8 );
      z-index: 9;
      position: relative;
      height: 100%;
      padding: 0 30px;
      line-height: 1;
      @media all and ( min-width: $screen-sm ) {
        font-size: 75px;
      }
      
      &:active,
      &:hover,
      &:focus {
        color: $red;
        text-decoration: none;
      }
    }
    
    .left-gradient {
      background: $black;
      background: linear-gradient( 90deg, rgba( 0, 0, 0, 1 ) 0%, rgba( 0, 0, 0, 0.5 ) 35% );
    }
    
    .right-gradient {
      background: $black;
      background: linear-gradient( 270deg, rgba( 0, 0, 0, 1 ) 0%, rgba( 0, 0, 0, 0.5 ) 35% );
    }
  }
}
