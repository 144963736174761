.flannel-local-site {
  .front-page-header {
    background: $black;
    height: 600px;
    position: relative;
    @media all and ( min-width: $screen-md ) {
      height: 800px;
    }
  }
  
  .flannel-header-grid {
    margin: 0 0 0 -15px;
    width: 100%;
    max-width: 1280px;  
    position: relative;
    @media all and ( min-width: $screen-md ) {
      margin: 0 auto; 
    }
    
    h3 {
      @include open-sans;
      font-size: 20px;
      line-height: 1.4;
      margin: 0;
    }
  }
  
  .flannel-video-container {
    position: absolute;
    top: 0;
    z-index: 0; 
    height: 100%;
    width: 100%;
    
    video {
      min-height: 100%;
      min-width: 100%;
      height: auto;
      width: auto;
      object-fit: cover;
    }
    
    .visual-image-container {
      position: absolute;
      top: 60%;
      left: 0;
      right: 0;
      z-index: 90;
      margin: 0 auto;
    }
  }
}

.pentagon-drop-shadow {
  filter: drop-shadow( -10px 15px 0 rgba( 0, 0, 0, 0.22 ) );
  z-index: 4;

  .pentagon-container {
    @include axe;
    margin: 0 auto -60px;
    max-width: 270px;
    color: $white; 
    position: relative;
    display: block;
    padding: 30px 30px 80px; 
    font-size: 45px;
    line-height: 1;
    clip-path: polygon( 100% 0, 100% 70%, 50% 100%, 0 70%, 0 0 );
    
    &::after {
      content: "";
      background: $black;
      background-image: url( $assetImagePath + '/patterns/pattern.png' );
      background-blend-mode: luminosity;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;   
    }
  }
}
