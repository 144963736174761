.social-links-container {
  margin-top: 20px;
  margin-bottom: -25px;
  
  a {
    color: $white;
    font-size: 20px;
    margin: 0 15px;
    
    &:active,
    &:hover,
    &:focus {
      text-decoration: none;
      opacity: .8;
    }
  }
  
  i.fa-external-link-alt {
    display: none;
  }
}
