.flannel-footer-container img {
  width: 163px;
  margin-top: 35px;
  position: relative;
  z-index: 4;
  @media all and ( min-width: $screen-sm ) {
    width: inherit;
  }
}

.flannel-footer-nav {
  margin-top: 40px;
  font-size: 14px;
  @media all and ( min-width: $screen-sm ) {
    font-size: 16px;
  }
  
  .legal-nav-container, 
  .legal-nav-container a {
    font-size: 14px;
    color: $silver;
    max-width: 930px;
    margin: 0 auto;
  }
  
  // For styling on live site
  .menu-legal-menu-container {
    display: inline-block;
  }
  
  li {
    list-style: none;
    display: inline-block;
    margin-bottom: 20px;
    @media all and ( min-width: $screen-sm ) {
      margin: 0;
    }
  }

  .flannel-legal-nav {
    display: flex;
    flex-direction: column;
    padding: 0;
    @media all and ( min-width: $screen-sm ) {
      display: inline-block;
    }
    
    a {
      color: $silver;
      font-weight: normal;
      margin: 0;
      @media all and ( min-width: $screen-sm ) {
        margin: 0 0 0 20px;
      }
      
      &:active,
      &:hover,
      &:focus {
        opacity: .7;
        text-decoration: none;
      }
    }
  }

  .flannel-extra-nav {
    margin: 0;
    padding: 0 0 15px;
    display: flex;
    flex-direction: column;
    @media all and ( min-width: $screen-sm ) {
      display: inline-block;
    }

    a {  
      @include open-con;
      color: $white;
      text-transform: uppercase;
      font-weight: bold;
      margin: 0 15px; 
      font-size: 20px;
      
      &:active,
      &:hover,
      &:focus {
        color: $red;
        text-decoration: none;
      }
    }
  }
}

.reshift {
  padding-bottom: 20px;
  font-size: 14px;
  
  a {
    &:active,
    &:hover,
    &:focus {
      text-decoration: none;
      color: $grey;
    }
  }
}
