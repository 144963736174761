.hamburger-icon {
  @include transform( 0deg );
  @include transition( .5s ease-in-out );
  position: absolute;
  margin: 15px auto 0;
  cursor: pointer;
  z-index: 999;
  width: 35px;
  height: 30px;

  span {
    @include transform( 0deg );
    @include transition( .5s ease-in-out );
    display: block;
    position: absolute;
    height: 4px;
    border-radius: 3px;
    width: 100%;
    background: $white;
    opacity: 1;
    left: 0;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2) {
      top: 11px;
    }

    &:nth-child(3) {
      top: 22px;
    }
  }

  &.open span {
    &:nth-child(1) {
      @include transform( 135deg );
      top: 8px;
    }

    &:nth-child(2) {
      opacity: 0;
      width: 1px;
      left: 20px;
    }

    &:nth-child(3) {
      @include transform( -135deg );
      top: 8px;
    }
  }
}


// Website's mobile nav menu container
.site-menu-mobile-container {
  @include transition( top .5s, opacity .0s linear .5s );
  background: $black;
  width: 100%;
  z-index: 333;
//  padding: 50px 0 0;
  position: absolute;
  top: -1200px;
  opacity: 0;

  &.expand {
    @include transition( top .5s, opacity .0s linear .0s );
    top: 0;
    opacity: 1;
    left: 0;
    display: block;
    position: fixed;
    overflow-y: scroll;
    height: 100vh;
    
    img {
      padding-top: 50px;
    }
  }

  ul {
    flex-direction: column;
    list-style: none;
    text-align: center;
    padding: 0;
    
    > li.red-nav-button > a {
      background: $red;
      padding: 10px 20px;
    }
    
    > li.black-nav-button {
      opacity: 1;
      visibility: visible;
      
      > a {
        color: $white;
        
        &:active,
        &:hover,
        &:focus {
          color: $black;
        }
        
        span {
          background: $red;
        }
      }
    }

    > li > a {
      @include open-con;
      font-size: 20px;
      display: inline-block;
      color: $white;
      text-transform: uppercase;
      min-height: initial;
      margin-bottom: 50px;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        // opacity: .7;
      }
    }
  }

  .menu-item-has-children ul {
    text-align: center;
    background: $matterhorn;
    padding: 25px 0 0;
    margin: 0 0 25px;

    li {
      padding: 5px 0;
    }

    a {
      color: $white;
      text-transform: uppercase;
      font-size: 16px;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        color: $red;
      }
    }
  }
}

.flannel-local-site .site-menu-mobile-container {
  padding: 0 15px;
  width: 150%;
  margin: 0 -25%;
}


.mobile-hamburger-container {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 479;
  height: 50px;
  width: 50px;
  display: block;
  @media all and ( min-width: $screen-sm ) {
    right: 40px;
    top: 40px;
  }
  @media all and ( min-width: $screen-mdl ) {
    display: none;
  }
}

.non-burger-menu {
  display: none;
  @media all and ( min-width: $screen-mdl ) {
    display: block;
  }
}

.local-front-burger-container {
  position: absolute; 
  right: 80px;
  top: 40px;
  @media all and ( min-width: $screen-sm ) {
    right: 150px;
  }
}
