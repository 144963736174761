.flannel-faq-page {
  padding-bottom: 50px;
  
  &.page-container .uk-container {
    max-width: 1020px;
    padding: 0 10px;
    margin-top: -15px;
    @media all and ( min-width: $screen-sm ) {
      padding: 0 15px;
      margin-top: 0;
    }
  }
  
  .uk-container .content-container {
    @media all and ( min-width: $screen-sm ) {
      padding: 30px;
    }
  }
  
  h2 {
    margin-bottom: 15px;
  }
  
  h3 {
    margin: 40px 0 10px;
  }

  p {
    margin: 0 0 10px;
  }
  
  ul.flannel-accordion-container {
    margin: 0;
  }
}
