.high-score-table {
  color: $white;
  width: 100%;
  
  thead th {
    @include open-bold;
    color: $white;
    text-transform: uppercase;
    font-size: 16px;    
  }
  
  tbody {
    tr {
      border-bottom: 2px solid $matterhorn;
      font-size: 20px;
      line-height: 24px;
      @media all and ( min-width: $screen-md ) {
        font-size: 24px;
        line-height: 35px;
      }
    
      &:nth-child(1) {
        @include open-bold;
        border: 2px solid $red;
        color: $red;
      }
      
      &:last-child {
        border: none;
      }
    }
  }
}

.high-score-cta {
  @include open-con;
  font-size: 19px;
  color: $white;
  margin-top: 10px;
  display: block;
  letter-spacing: 6.5px;
  text-transform: uppercase;
}
