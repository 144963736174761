.flannel-front-page-container {
  header {    
    &.header-parallax-container {
      height: 620px;      
      @media all and ( min-width: $screen-sm ) {
        height: 650px;
      }
      
      @media all and ( min-width: $screen-md ) {
        height: 850px;
      }
      
      @media all and ( min-width: $screen-lg ) {
        height: 1050px;
      }      
    }
    
    .national-parallax-background {
      background-image: url( $assetImagePath + '/front-page/background.jpg' );
      height: 150vh;
      position: fixed;
      width: 100vw;
      top: -200px; 
      background-size: auto; 
      background-position-x: 50%;
      display: none;
      @media all and ( min-width: $screen-lg ) {
        display: block;
      }
    }
    
    .mobile-bkgd-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      z-index: -1;
    }
  
    .parallax-button,
    .parallax-logo {
      margin-top: 35px;
      
      img {
        width: 162px;
        @media all and ( min-width: $screen-sm ) {
          width: auto;
        }
      }
    }
    .thrower-container {
      position: absolute;
      left: 20%; 
      z-index: 0;
      display: none;
      @media all and ( min-width: $screen-lg ) {
        display: block;
      }
    }
    
    .spectator-container {
      bottom: -50%;
      position: absolute;
      width: 140%;
      margin: 0 -33%;
      display: none;
      
      @media all and ( min-width: $screen-lg ) {
        display: block;
      }
    }
    
    .parallax-spectator {
      width: 100%;
      object-fit: cover;
    }
  
    .front-page-header {
      width: 100%;

      h1 {
        max-width: 930px;
        margin: 0 auto;
        padding: 30px;
        line-height: 1;
        font-size: 40px;
        @media all and ( min-width: 340px ) {
          font-size: 45px;
        }
        
        @media all and ( min-width: $screen-md ) {
          font-size: 75px; 
        }
      }
    }
  }

  .content-column {
    background: $white;
    padding: 15px;
    margin: -50px 15px 0;
    @media all and ( min-width: $screen-sm ) {
      margin: -140px 0 0;
      padding: 40px;
    }
    
    @media all and ( min-width: $screen-md ) {
      padding: 70px; 
    }
  }
  
  .flannel-testimonial-container {
    height: 420px;
    @media all and ( min-width: $screen-sm ) {
      height: 600px;
    }
  }
  
  .franchise-row {
    padding: 0;
    margin-top: -10px;
    position: relative;
    z-index: 12;
    display: flex;
    flex-direction: column-reverse;
    
    @media all and ( min-width: $screen-sm ) {
      padding: 0 50px;
    }
    
    @media all and ( min-width: $screen-md ) {
      margin-top: -50px;
      flex-direction: row;
    }
    
    .franchise-image-container {
      padding-right: 0;
      max-width: 450px;
      width: 100%;
      margin-top: 30px;
      @media all and ( min-width: $screen-md ) {
        margin-top: 0;
        padding-right: 50px;
      }

      ul {
        height: 100%;
        min-height: 225px !important;
        @media all and ( min-width: $screen-xs ) {
          min-height: 295px !important;
        }
        
        img {
          width: 100%;
          min-width: 290px;
          max-width: 450px;
          object-fit: cover;        
        }
      }
      
      .franchise-row-nav-container {
        margin-top: 10px;
        text-align: center;
        font-size: 20px;
        @media all and ( min-width: $screen-sm ) {
          text-align: left;
        }

        a:hover {
          text-decoration: none;
        }
      }
    }
    
    .franchise-content-container {
      max-width: 600px;
      padding: 0;
      margin-top: 30px;
      @media all and ( min-width: $screen-md ) {
        margin-top: 0;
      }
    }
  }
  
  .flannel-plaid-obi {
    position: absolute;
    top: 0;
    right: 0;
    width: 265px;
    height: 180%; 
    z-index: 0;
    padding-top: 50px;
    background: $red;
    background-image: url( $assetImagePath + '/patterns/pattern.png' );
  }
  
  .footer-single-cta {
    margin-bottom: 0;
    z-index: 2;
    @media all and ( min-width: $screen-sm ) {
      margin-bottom: -100px;  
    }
  }
}

.additional-information-container {
  max-width: 1140px;
  margin: 0 auto 20px;
  padding: 0 15px;
  @media all and ( min-width: $screen-md ) {
    margin: 0 auto 200px;
  }

  .additional-content-container {
    padding: 0 15px;
    @media all and ( min-width: $screen-md ) {
      padding: 0;    
    }
  }
}

.mobile-bkgd-divider {
  display: block;
  z-index: 0; 
  position: relative;
  height: 100px;
  background: $black; 
  clip-path: polygon( 0 0, 100% 20%, 100% 100%, 0% 100% );
  @media all and ( min-width: $screen-md ) {
    display: none;
  }
}