.flannel-pricing-page {
  margin-top: 20px;
  @media all and ( min-width: $screen-md ) {
    margin-top: 60px;
  }
  
  .content-container {
    max-width: $max-width;
    margin-bottom: 50px;
    @media all and ( min-width: $screen-md ) {
      max-width: 950px;
    }
  }
  
  .pricing-container {
    max-width: 990px; 
    margin: 0 auto;
    
    > div > div {
      padding-left: 18px;
    }
    
    .special-column-container {
      order: -1;
      @media all and ( min-width: $screen-md ) {
        order: 0;
      }
    }
    
    small {
      display: block;
      padding: 0 20px;
      text-align: left;
      @media all and ( min-width: $screen-md ) {
        padding: 0;
      }
    }
  
    .column-container {
      background: $whitesmoke;
      padding-bottom: 10px;
      max-width: 500px;
      margin: 0 auto;

      &.special-column {
        transform: scale(1);
        @media all and ( min-width: $screen-sm ) {
          transform: scale( 1.05 );
          
          .button-container {
            margin-top: 0;
            @media all and ( min-width: $screen-md ) {
              margin-top: 14px !important;
            }
          }
        }
      }
      
      .left-column-header,
      .special-column-header,
      .right-column-header {
        padding: 15px 30px 20px;
        @media all and ( min-width: $screen-sm ) {
          padding: 35px 30px 50px;
        }
      }

      .left-column-header {
        clip-path: polygon( 0 0, 100% 0%, 100% 85%, 0% 100% );
      }

      .special-column-header {
        clip-path: polygon( 100% 0, 100% 85%, 50% 100%, 0 85%, 0 0 );
        padding-bottom: 35px;
        @media all and ( min-width: $screen-sm ) {
          padding: 35px 30px 35px;
        }
        
        @media all and ( min-width: $screen-md ) {
          padding: 35px 30px 40px;
        }
        
        h3 {
          @include open-bold;
        }
      }

      .right-column-header {
        clip-path: polygon( 100% 0, 100% 100%, 0 85%, 0 0 );
      }

      h3 {
        @include open-sans;
        font-size: 24px;
        line-height: 1.16;
        color: $white;
        text-transform: none;
        margin: 0;
      }

      .column-info {
        padding: 0;
        max-width: $max-width;
        margin: 0 auto;

        .price {
          @include axe;
          color: $black;
          font-size: 60px;
          line-height: 50px;
          padding: 20px 0;
          display: inline-block;
        }
        
        small {
          display: inline-block;
          padding: 0;
          margin-left: -2px;
        }

        .button-container {
          margin: 0;
          @media all and ( min-width: $screen-md ) {
            margin: 20px 0 30px;
          }
        }

        dl {
          margin-bottom: 20px;
          border-top: 1px solid $white;
          padding-top: 20px;

          dt,
          dd {
            font-size: 12px;          
            margin: 0;
            padding: 0 10px;
          }

          dt {
            color: $grey;
          }
        }
      }
    }
  }
  
  .small-text {
    color: $white;
    font-size: 12px;
    display: block;
  }
  
  .right-angle-footer-border {
    position: relative;
    width: 100%; 
    margin-top: -80px;
    height: 80px;
    background: $black;
    z-index: -1; 
    clip-path: polygon( 100% 0, 100% 100%, 0 100%, 0 20% );
//    @media all and ( min-width: $screen-sm ) {
//      height: 160px;
//    }
    
    @media all and ( min-width: $screen-md ) {
      margin-top: -160px;
      height: 160px;
    }
    
    @media all and ( min-width: $screen-lg ) {
      margin-top: -250px;
      height: 250px;
    }
  }
  
  .flannel-pagination-container-bkgd {
    padding: 20px;
    margin-top: -20px;
    @media all and ( min-width: $screen-sm ) {
      padding: 20px 30px;
    }
    
    @media all and ( min-width: $screen-md ) {
      padding: 80px 40px 35px;
      margin-top: -60px;
    }
    
    .flannel-pagination-container img {
      display: none;
    }
  }
}
