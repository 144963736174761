.flannel-local-league-page {
  margin-top: 20px;
  @media all and ( min-width: $screen-md ) {
    margin-top: 60px;
  }
  
  .content-container {
    padding-right: 0;
    max-width: $max-width;
    margin: 0 auto;
    @media all and ( min-width: $screen-md ) {
      padding-right: 30px;
    }
  }
  
  .plaid-background {
    width: 300px;  
    height: 100%; 
    background: $red;
    background-image: url( $assetImagePath + '/patterns/pattern.png' );
    z-index: -1;
    position: absolute;  
    right: -150px;
    top: 50px;
  }

  .high-scores-container {
    z-index: 11; 
    background: $black;
    max-width: $max-width;
    margin: 0 auto;
    box-shadow: -20px 20px 0 0 rgba( 0, 0, 0, 0.2 );
    padding: 80px 20px 20px; 
    color: $white;
    
    img {
      position: absolute;
      top: -30px;
      left: 0;
      right: 0;
      padding-left: 40px;
      box-sizing: content-box;
      margin: 0 auto;
    }
    
    span {
      @include open-bold;
      font-size: 14px;
      color: $matterhorn;
    }
  }


  
  // Override media-slider styles
  .flannel-media-slider-container {
    top: 0;
    background: $white;
    padding: 0 15px;
    left: 0;
    margin-bottom: -50px;
    margin: 0 -15px -80px;
    @media all and ( min-width: $screen-md ) {
      margin: 0 0 -150px;
      top: -150px;
      padding: 0 40px;
    }

    .media-slider-background {
      display: none;
    }

    .media-slider-component {
      margin: 0;
    }
  }
  
  
  
  
  .book-box-black-divider {
    background: $black; 
    width: 100%; 
    height: 150px;
    margin-top: -150px;
    position: relative;
    z-index: -4;
  }
  
  .book-box-background {
    background: $black;
    width: 100%;
    height: 150px;
    margin-top: -150px;
    position: relative;
    z-index: -4;
  }
  
  .promo-container {
     padding: 80px 0;
    
    p {
      color: $white;
    }
    
    a {
      @include open-con;
      font-size: 19px;
      text-transform: uppercase;
      line-height: 1.47;
    }
  }
  
  .flannel-red-book-box {
     &::after {
      content: "";
      background-image: url( $assetImagePath + '/axes/axerays_black.svg' );
      background-position: center;
      background-size: auto;
      background-repeat: no-repeat;
      opacity: .1;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;    
    }
  }
  
  .league-form-box {
    max-width: $max-width;
    margin: 0 auto;
    padding: 80px 15px 0;
    @media all and ( min-width: $screen-md ) {
      padding: 80px 0 50px;
      max-width: 930px;
    }
  
    .league-form-container {
      padding: 20px;
      @media all and ( min-width: $screen-md ) {
        padding: 40px;
      }
      
      form .gform_body .ginput_container_consent {
        margin-left: 0;
      }
      
      form .gform_body .validation_message {
        margin-top: 0;
      }
      
      form .gform_footer .button {
        padding: 10px 30px;
        font-size: 23px;
      }
    }
  }
  
  .flannel-pagination-container-bkgd {
    padding: 30px 0 0;
    @media all and ( min-width: $screen-sm ) {
      padding: 30px 30px 0;
    }
    
    @media all and ( min-width: $screen-md ) {
      padding: 20px 40px 35px;
    }
    
    .flannel-pagination-container img {
      display: none;
    }
  }
}
