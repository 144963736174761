.flannel-review-page {
  .uk-container {
    max-width: 1200px;
    margin: 0 auto;
    
    > div {
      padding-left: 0;
      @media all and ( min-width: $screen-md ) {
        padding-left: 30px;
      }
    }
  }
  
  .pentagon-container {
    &::after {
      opacity: .85;
    }
  }
  
  .hours-and-review-container {
    margin-top: 20px;
    @media all and ( min-width: $screen-md ) {
      margin-top: 45px;
    }
  }
  
  .review-content-container {
    width: 100%;
    @media all and ( min-width: $screen-md ) {
      width: 66%;
    }
  }
  
  .content-container {
    padding: 0;
    margin-top: 45px;
  }
  
  .user-review-container {
    margin: 0 auto ;
    padding-bottom: 60px;
    padding-right: 0;
    @media all and ( min-width: $screen-md ) {
      margin: 40px auto 0;
      padding-right: 30px;
    }
  }
}
