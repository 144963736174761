// Colours
$red:        #ce1f2e;
$black:      #000000;
$white:      #ffffff;
$matterhorn: #545454;

$whitesmoke: #f3f3f3;
$silver:     #C6C4C4;
$grey:       #8E8E8E;
$whisper:    #E4E4E4;
$yellow:     #FFCC00;
$green:      #4BB543;

$facebook:   #3B5998;
$twitter:    #00ACED;
$linkedin:   #007BB6;
$pinterest:  #CB2027;


// Screen size
$screen-xxs:  360px;
$screen-xs:   500px;
$screen-sm:   640px;
$screen-md:   960px;
$screen-mdl:  992px;
$screen-lg:  1200px;
$screen-xl:  1480px;


// Misc
$assetImagePath: "../images";
$max-width: $screen-sm;
