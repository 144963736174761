@charset "UTF-8";
/*!
 * Font Awesome Pro 5.9.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../fonts/fa-solid-900.eot");
  src: url("../fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.woff") format("woff"), url("../fonts/fa-solid-900.ttf") format("truetype"), url("../fonts/fa-solid-900.svg#fontawesome") format("svg"); }

.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900; }

/*!
 * Font Awesome Pro 5.9.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../fonts/fa-regular-400.eot");
  src: url("../fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.woff") format("woff"), url("../fonts/fa-regular-400.ttf") format("truetype"), url("../fonts/fa-regular-400.svg#fontawesome") format("svg"); }

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400; }

/*!
 * Font Awesome Pro 5.9.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url("../fonts/fa-light-300.eot");
  src: url("../fonts/fa-light-300.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.woff") format("woff"), url("../fonts/fa-light-300.ttf") format("truetype"), url("../fonts/fa-light-300.svg#fontawesome") format("svg"); }

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300; }

/*!
 * Font Awesome Pro 5.9.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../fonts/fa-brands-400.eot");
  src: url("../fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.woff") format("woff"), url("../fonts/fa-brands-400.ttf") format("truetype"), url("../fonts/fa-brands-400.svg#fontawesome") format("svg"); }

.fab {
  font-family: 'Font Awesome 5 Brands'; }

/*!
 * Font Awesome Pro 5.9.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-lg {
  font-size: 1.3333333333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.fa-xs {
  font-size: .75em; }

.fa-sm {
  font-size: .875em; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em; }

.fa-spin {
  animation: fa-spin 2s infinite linear; }

.fa-pulse {
  animation: fa-spin 1s infinite steps(8); }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1); }

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  filter: none; }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e"; }

.fa-abacus:before {
  content: "\f640"; }

.fa-accessible-icon:before {
  content: "\f368"; }

.fa-accusoft:before {
  content: "\f369"; }

.fa-acorn:before {
  content: "\f6ae"; }

.fa-acquisitions-incorporated:before {
  content: "\f6af"; }

.fa-ad:before {
  content: "\f641"; }

.fa-address-book:before {
  content: "\f2b9"; }

.fa-address-card:before {
  content: "\f2bb"; }

.fa-adjust:before {
  content: "\f042"; }

.fa-adn:before {
  content: "\f170"; }

.fa-adobe:before {
  content: "\f778"; }

.fa-adversal:before {
  content: "\f36a"; }

.fa-affiliatetheme:before {
  content: "\f36b"; }

.fa-air-freshener:before {
  content: "\f5d0"; }

.fa-airbnb:before {
  content: "\f834"; }

.fa-alarm-clock:before {
  content: "\f34e"; }

.fa-alarm-exclamation:before {
  content: "\f843"; }

.fa-alarm-plus:before {
  content: "\f844"; }

.fa-alarm-snooze:before {
  content: "\f845"; }

.fa-algolia:before {
  content: "\f36c"; }

.fa-alicorn:before {
  content: "\f6b0"; }

.fa-align-center:before {
  content: "\f037"; }

.fa-align-justify:before {
  content: "\f039"; }

.fa-align-left:before {
  content: "\f036"; }

.fa-align-right:before {
  content: "\f038"; }

.fa-align-slash:before {
  content: "\f846"; }

.fa-alipay:before {
  content: "\f642"; }

.fa-allergies:before {
  content: "\f461"; }

.fa-amazon:before {
  content: "\f270"; }

.fa-amazon-pay:before {
  content: "\f42c"; }

.fa-ambulance:before {
  content: "\f0f9"; }

.fa-american-sign-language-interpreting:before {
  content: "\f2a3"; }

.fa-amilia:before {
  content: "\f36d"; }

.fa-analytics:before {
  content: "\f643"; }

.fa-anchor:before {
  content: "\f13d"; }

.fa-android:before {
  content: "\f17b"; }

.fa-angel:before {
  content: "\f779"; }

.fa-angellist:before {
  content: "\f209"; }

.fa-angle-double-down:before {
  content: "\f103"; }

.fa-angle-double-left:before {
  content: "\f100"; }

.fa-angle-double-right:before {
  content: "\f101"; }

.fa-angle-double-up:before {
  content: "\f102"; }

.fa-angle-down:before {
  content: "\f107"; }

.fa-angle-left:before {
  content: "\f104"; }

.fa-angle-right:before {
  content: "\f105"; }

.fa-angle-up:before {
  content: "\f106"; }

.fa-angry:before {
  content: "\f556"; }

.fa-angrycreative:before {
  content: "\f36e"; }

.fa-angular:before {
  content: "\f420"; }

.fa-ankh:before {
  content: "\f644"; }

.fa-app-store:before {
  content: "\f36f"; }

.fa-app-store-ios:before {
  content: "\f370"; }

.fa-apper:before {
  content: "\f371"; }

.fa-apple:before {
  content: "\f179"; }

.fa-apple-alt:before {
  content: "\f5d1"; }

.fa-apple-crate:before {
  content: "\f6b1"; }

.fa-apple-pay:before {
  content: "\f415"; }

.fa-archive:before {
  content: "\f187"; }

.fa-archway:before {
  content: "\f557"; }

.fa-arrow-alt-circle-down:before {
  content: "\f358"; }

.fa-arrow-alt-circle-left:before {
  content: "\f359"; }

.fa-arrow-alt-circle-right:before {
  content: "\f35a"; }

.fa-arrow-alt-circle-up:before {
  content: "\f35b"; }

.fa-arrow-alt-down:before {
  content: "\f354"; }

.fa-arrow-alt-from-bottom:before {
  content: "\f346"; }

.fa-arrow-alt-from-left:before {
  content: "\f347"; }

.fa-arrow-alt-from-right:before {
  content: "\f348"; }

.fa-arrow-alt-from-top:before {
  content: "\f349"; }

.fa-arrow-alt-left:before {
  content: "\f355"; }

.fa-arrow-alt-right:before {
  content: "\f356"; }

.fa-arrow-alt-square-down:before {
  content: "\f350"; }

.fa-arrow-alt-square-left:before {
  content: "\f351"; }

.fa-arrow-alt-square-right:before {
  content: "\f352"; }

.fa-arrow-alt-square-up:before {
  content: "\f353"; }

.fa-arrow-alt-to-bottom:before {
  content: "\f34a"; }

.fa-arrow-alt-to-left:before {
  content: "\f34b"; }

.fa-arrow-alt-to-right:before {
  content: "\f34c"; }

.fa-arrow-alt-to-top:before {
  content: "\f34d"; }

.fa-arrow-alt-up:before {
  content: "\f357"; }

.fa-arrow-circle-down:before {
  content: "\f0ab"; }

.fa-arrow-circle-left:before {
  content: "\f0a8"; }

.fa-arrow-circle-right:before {
  content: "\f0a9"; }

.fa-arrow-circle-up:before {
  content: "\f0aa"; }

.fa-arrow-down:before {
  content: "\f063"; }

.fa-arrow-from-bottom:before {
  content: "\f342"; }

.fa-arrow-from-left:before {
  content: "\f343"; }

.fa-arrow-from-right:before {
  content: "\f344"; }

.fa-arrow-from-top:before {
  content: "\f345"; }

.fa-arrow-left:before {
  content: "\f060"; }

.fa-arrow-right:before {
  content: "\f061"; }

.fa-arrow-square-down:before {
  content: "\f339"; }

.fa-arrow-square-left:before {
  content: "\f33a"; }

.fa-arrow-square-right:before {
  content: "\f33b"; }

.fa-arrow-square-up:before {
  content: "\f33c"; }

.fa-arrow-to-bottom:before {
  content: "\f33d"; }

.fa-arrow-to-left:before {
  content: "\f33e"; }

.fa-arrow-to-right:before {
  content: "\f340"; }

.fa-arrow-to-top:before {
  content: "\f341"; }

.fa-arrow-up:before {
  content: "\f062"; }

.fa-arrows:before {
  content: "\f047"; }

.fa-arrows-alt:before {
  content: "\f0b2"; }

.fa-arrows-alt-h:before {
  content: "\f337"; }

.fa-arrows-alt-v:before {
  content: "\f338"; }

.fa-arrows-h:before {
  content: "\f07e"; }

.fa-arrows-v:before {
  content: "\f07d"; }

.fa-artstation:before {
  content: "\f77a"; }

.fa-assistive-listening-systems:before {
  content: "\f2a2"; }

.fa-asterisk:before {
  content: "\f069"; }

.fa-asymmetrik:before {
  content: "\f372"; }

.fa-at:before {
  content: "\f1fa"; }

.fa-atlas:before {
  content: "\f558"; }

.fa-atlassian:before {
  content: "\f77b"; }

.fa-atom:before {
  content: "\f5d2"; }

.fa-atom-alt:before {
  content: "\f5d3"; }

.fa-audible:before {
  content: "\f373"; }

.fa-audio-description:before {
  content: "\f29e"; }

.fa-autoprefixer:before {
  content: "\f41c"; }

.fa-avianex:before {
  content: "\f374"; }

.fa-aviato:before {
  content: "\f421"; }

.fa-award:before {
  content: "\f559"; }

.fa-aws:before {
  content: "\f375"; }

.fa-axe:before {
  content: "\f6b2"; }

.fa-axe-battle:before {
  content: "\f6b3"; }

.fa-baby:before {
  content: "\f77c"; }

.fa-baby-carriage:before {
  content: "\f77d"; }

.fa-backpack:before {
  content: "\f5d4"; }

.fa-backspace:before {
  content: "\f55a"; }

.fa-backward:before {
  content: "\f04a"; }

.fa-bacon:before {
  content: "\f7e5"; }

.fa-badge:before {
  content: "\f335"; }

.fa-badge-check:before {
  content: "\f336"; }

.fa-badge-dollar:before {
  content: "\f645"; }

.fa-badge-percent:before {
  content: "\f646"; }

.fa-badger-honey:before {
  content: "\f6b4"; }

.fa-bags-shopping:before {
  content: "\f847"; }

.fa-balance-scale:before {
  content: "\f24e"; }

.fa-balance-scale-left:before {
  content: "\f515"; }

.fa-balance-scale-right:before {
  content: "\f516"; }

.fa-ball-pile:before {
  content: "\f77e"; }

.fa-ballot:before {
  content: "\f732"; }

.fa-ballot-check:before {
  content: "\f733"; }

.fa-ban:before {
  content: "\f05e"; }

.fa-band-aid:before {
  content: "\f462"; }

.fa-bandcamp:before {
  content: "\f2d5"; }

.fa-barcode:before {
  content: "\f02a"; }

.fa-barcode-alt:before {
  content: "\f463"; }

.fa-barcode-read:before {
  content: "\f464"; }

.fa-barcode-scan:before {
  content: "\f465"; }

.fa-bars:before {
  content: "\f0c9"; }

.fa-baseball:before {
  content: "\f432"; }

.fa-baseball-ball:before {
  content: "\f433"; }

.fa-basketball-ball:before {
  content: "\f434"; }

.fa-basketball-hoop:before {
  content: "\f435"; }

.fa-bat:before {
  content: "\f6b5"; }

.fa-bath:before {
  content: "\f2cd"; }

.fa-battery-bolt:before {
  content: "\f376"; }

.fa-battery-empty:before {
  content: "\f244"; }

.fa-battery-full:before {
  content: "\f240"; }

.fa-battery-half:before {
  content: "\f242"; }

.fa-battery-quarter:before {
  content: "\f243"; }

.fa-battery-slash:before {
  content: "\f377"; }

.fa-battery-three-quarters:before {
  content: "\f241"; }

.fa-battle-net:before {
  content: "\f835"; }

.fa-bed:before {
  content: "\f236"; }

.fa-beer:before {
  content: "\f0fc"; }

.fa-behance:before {
  content: "\f1b4"; }

.fa-behance-square:before {
  content: "\f1b5"; }

.fa-bell:before {
  content: "\f0f3"; }

.fa-bell-exclamation:before {
  content: "\f848"; }

.fa-bell-plus:before {
  content: "\f849"; }

.fa-bell-school:before {
  content: "\f5d5"; }

.fa-bell-school-slash:before {
  content: "\f5d6"; }

.fa-bell-slash:before {
  content: "\f1f6"; }

.fa-bells:before {
  content: "\f77f"; }

.fa-bezier-curve:before {
  content: "\f55b"; }

.fa-bible:before {
  content: "\f647"; }

.fa-bicycle:before {
  content: "\f206"; }

.fa-biking:before {
  content: "\f84a"; }

.fa-biking-mountain:before {
  content: "\f84b"; }

.fa-bimobject:before {
  content: "\f378"; }

.fa-binoculars:before {
  content: "\f1e5"; }

.fa-biohazard:before {
  content: "\f780"; }

.fa-birthday-cake:before {
  content: "\f1fd"; }

.fa-bitbucket:before {
  content: "\f171"; }

.fa-bitcoin:before {
  content: "\f379"; }

.fa-bity:before {
  content: "\f37a"; }

.fa-black-tie:before {
  content: "\f27e"; }

.fa-blackberry:before {
  content: "\f37b"; }

.fa-blanket:before {
  content: "\f498"; }

.fa-blender:before {
  content: "\f517"; }

.fa-blender-phone:before {
  content: "\f6b6"; }

.fa-blind:before {
  content: "\f29d"; }

.fa-blog:before {
  content: "\f781"; }

.fa-blogger:before {
  content: "\f37c"; }

.fa-blogger-b:before {
  content: "\f37d"; }

.fa-bluetooth:before {
  content: "\f293"; }

.fa-bluetooth-b:before {
  content: "\f294"; }

.fa-bold:before {
  content: "\f032"; }

.fa-bolt:before {
  content: "\f0e7"; }

.fa-bomb:before {
  content: "\f1e2"; }

.fa-bone:before {
  content: "\f5d7"; }

.fa-bone-break:before {
  content: "\f5d8"; }

.fa-bong:before {
  content: "\f55c"; }

.fa-book:before {
  content: "\f02d"; }

.fa-book-alt:before {
  content: "\f5d9"; }

.fa-book-dead:before {
  content: "\f6b7"; }

.fa-book-heart:before {
  content: "\f499"; }

.fa-book-medical:before {
  content: "\f7e6"; }

.fa-book-open:before {
  content: "\f518"; }

.fa-book-reader:before {
  content: "\f5da"; }

.fa-book-spells:before {
  content: "\f6b8"; }

.fa-book-user:before {
  content: "\f7e7"; }

.fa-bookmark:before {
  content: "\f02e"; }

.fa-books:before {
  content: "\f5db"; }

.fa-books-medical:before {
  content: "\f7e8"; }

.fa-boot:before {
  content: "\f782"; }

.fa-booth-curtain:before {
  content: "\f734"; }

.fa-bootstrap:before {
  content: "\f836"; }

.fa-border-all:before {
  content: "\f84c"; }

.fa-border-bottom:before {
  content: "\f84d"; }

.fa-border-inner:before {
  content: "\f84e"; }

.fa-border-left:before {
  content: "\f84f"; }

.fa-border-none:before {
  content: "\f850"; }

.fa-border-outer:before {
  content: "\f851"; }

.fa-border-right:before {
  content: "\f852"; }

.fa-border-style:before {
  content: "\f853"; }

.fa-border-style-alt:before {
  content: "\f854"; }

.fa-border-top:before {
  content: "\f855"; }

.fa-bow-arrow:before {
  content: "\f6b9"; }

.fa-bowling-ball:before {
  content: "\f436"; }

.fa-bowling-pins:before {
  content: "\f437"; }

.fa-box:before {
  content: "\f466"; }

.fa-box-alt:before {
  content: "\f49a"; }

.fa-box-ballot:before {
  content: "\f735"; }

.fa-box-check:before {
  content: "\f467"; }

.fa-box-fragile:before {
  content: "\f49b"; }

.fa-box-full:before {
  content: "\f49c"; }

.fa-box-heart:before {
  content: "\f49d"; }

.fa-box-open:before {
  content: "\f49e"; }

.fa-box-up:before {
  content: "\f49f"; }

.fa-box-usd:before {
  content: "\f4a0"; }

.fa-boxes:before {
  content: "\f468"; }

.fa-boxes-alt:before {
  content: "\f4a1"; }

.fa-boxing-glove:before {
  content: "\f438"; }

.fa-brackets:before {
  content: "\f7e9"; }

.fa-brackets-curly:before {
  content: "\f7ea"; }

.fa-braille:before {
  content: "\f2a1"; }

.fa-brain:before {
  content: "\f5dc"; }

.fa-bread-loaf:before {
  content: "\f7eb"; }

.fa-bread-slice:before {
  content: "\f7ec"; }

.fa-briefcase:before {
  content: "\f0b1"; }

.fa-briefcase-medical:before {
  content: "\f469"; }

.fa-bring-forward:before {
  content: "\f856"; }

.fa-bring-front:before {
  content: "\f857"; }

.fa-broadcast-tower:before {
  content: "\f519"; }

.fa-broom:before {
  content: "\f51a"; }

.fa-browser:before {
  content: "\f37e"; }

.fa-brush:before {
  content: "\f55d"; }

.fa-btc:before {
  content: "\f15a"; }

.fa-buffer:before {
  content: "\f837"; }

.fa-bug:before {
  content: "\f188"; }

.fa-building:before {
  content: "\f1ad"; }

.fa-bullhorn:before {
  content: "\f0a1"; }

.fa-bullseye:before {
  content: "\f140"; }

.fa-bullseye-arrow:before {
  content: "\f648"; }

.fa-bullseye-pointer:before {
  content: "\f649"; }

.fa-burger-soda:before {
  content: "\f858"; }

.fa-burn:before {
  content: "\f46a"; }

.fa-buromobelexperte:before {
  content: "\f37f"; }

.fa-burrito:before {
  content: "\f7ed"; }

.fa-bus:before {
  content: "\f207"; }

.fa-bus-alt:before {
  content: "\f55e"; }

.fa-bus-school:before {
  content: "\f5dd"; }

.fa-business-time:before {
  content: "\f64a"; }

.fa-buysellads:before {
  content: "\f20d"; }

.fa-cabinet-filing:before {
  content: "\f64b"; }

.fa-calculator:before {
  content: "\f1ec"; }

.fa-calculator-alt:before {
  content: "\f64c"; }

.fa-calendar:before {
  content: "\f133"; }

.fa-calendar-alt:before {
  content: "\f073"; }

.fa-calendar-check:before {
  content: "\f274"; }

.fa-calendar-day:before {
  content: "\f783"; }

.fa-calendar-edit:before {
  content: "\f333"; }

.fa-calendar-exclamation:before {
  content: "\f334"; }

.fa-calendar-minus:before {
  content: "\f272"; }

.fa-calendar-plus:before {
  content: "\f271"; }

.fa-calendar-star:before {
  content: "\f736"; }

.fa-calendar-times:before {
  content: "\f273"; }

.fa-calendar-week:before {
  content: "\f784"; }

.fa-camera:before {
  content: "\f030"; }

.fa-camera-alt:before {
  content: "\f332"; }

.fa-camera-retro:before {
  content: "\f083"; }

.fa-campfire:before {
  content: "\f6ba"; }

.fa-campground:before {
  content: "\f6bb"; }

.fa-canadian-maple-leaf:before {
  content: "\f785"; }

.fa-candle-holder:before {
  content: "\f6bc"; }

.fa-candy-cane:before {
  content: "\f786"; }

.fa-candy-corn:before {
  content: "\f6bd"; }

.fa-cannabis:before {
  content: "\f55f"; }

.fa-capsules:before {
  content: "\f46b"; }

.fa-car:before {
  content: "\f1b9"; }

.fa-car-alt:before {
  content: "\f5de"; }

.fa-car-battery:before {
  content: "\f5df"; }

.fa-car-building:before {
  content: "\f859"; }

.fa-car-bump:before {
  content: "\f5e0"; }

.fa-car-bus:before {
  content: "\f85a"; }

.fa-car-crash:before {
  content: "\f5e1"; }

.fa-car-garage:before {
  content: "\f5e2"; }

.fa-car-mechanic:before {
  content: "\f5e3"; }

.fa-car-side:before {
  content: "\f5e4"; }

.fa-car-tilt:before {
  content: "\f5e5"; }

.fa-car-wash:before {
  content: "\f5e6"; }

.fa-caret-circle-down:before {
  content: "\f32d"; }

.fa-caret-circle-left:before {
  content: "\f32e"; }

.fa-caret-circle-right:before {
  content: "\f330"; }

.fa-caret-circle-up:before {
  content: "\f331"; }

.fa-caret-down:before {
  content: "\f0d7"; }

.fa-caret-left:before {
  content: "\f0d9"; }

.fa-caret-right:before {
  content: "\f0da"; }

.fa-caret-square-down:before {
  content: "\f150"; }

.fa-caret-square-left:before {
  content: "\f191"; }

.fa-caret-square-right:before {
  content: "\f152"; }

.fa-caret-square-up:before {
  content: "\f151"; }

.fa-caret-up:before {
  content: "\f0d8"; }

.fa-carrot:before {
  content: "\f787"; }

.fa-cars:before {
  content: "\f85b"; }

.fa-cart-arrow-down:before {
  content: "\f218"; }

.fa-cart-plus:before {
  content: "\f217"; }

.fa-cash-register:before {
  content: "\f788"; }

.fa-cat:before {
  content: "\f6be"; }

.fa-cauldron:before {
  content: "\f6bf"; }

.fa-cc-amazon-pay:before {
  content: "\f42d"; }

.fa-cc-amex:before {
  content: "\f1f3"; }

.fa-cc-apple-pay:before {
  content: "\f416"; }

.fa-cc-diners-club:before {
  content: "\f24c"; }

.fa-cc-discover:before {
  content: "\f1f2"; }

.fa-cc-jcb:before {
  content: "\f24b"; }

.fa-cc-mastercard:before {
  content: "\f1f1"; }

.fa-cc-paypal:before {
  content: "\f1f4"; }

.fa-cc-stripe:before {
  content: "\f1f5"; }

.fa-cc-visa:before {
  content: "\f1f0"; }

.fa-centercode:before {
  content: "\f380"; }

.fa-centos:before {
  content: "\f789"; }

.fa-certificate:before {
  content: "\f0a3"; }

.fa-chair:before {
  content: "\f6c0"; }

.fa-chair-office:before {
  content: "\f6c1"; }

.fa-chalkboard:before {
  content: "\f51b"; }

.fa-chalkboard-teacher:before {
  content: "\f51c"; }

.fa-charging-station:before {
  content: "\f5e7"; }

.fa-chart-area:before {
  content: "\f1fe"; }

.fa-chart-bar:before {
  content: "\f080"; }

.fa-chart-line:before {
  content: "\f201"; }

.fa-chart-line-down:before {
  content: "\f64d"; }

.fa-chart-network:before {
  content: "\f78a"; }

.fa-chart-pie:before {
  content: "\f200"; }

.fa-chart-pie-alt:before {
  content: "\f64e"; }

.fa-chart-scatter:before {
  content: "\f7ee"; }

.fa-check:before {
  content: "\f00c"; }

.fa-check-circle:before {
  content: "\f058"; }

.fa-check-double:before {
  content: "\f560"; }

.fa-check-square:before {
  content: "\f14a"; }

.fa-cheese:before {
  content: "\f7ef"; }

.fa-cheese-swiss:before {
  content: "\f7f0"; }

.fa-cheeseburger:before {
  content: "\f7f1"; }

.fa-chess:before {
  content: "\f439"; }

.fa-chess-bishop:before {
  content: "\f43a"; }

.fa-chess-bishop-alt:before {
  content: "\f43b"; }

.fa-chess-board:before {
  content: "\f43c"; }

.fa-chess-clock:before {
  content: "\f43d"; }

.fa-chess-clock-alt:before {
  content: "\f43e"; }

.fa-chess-king:before {
  content: "\f43f"; }

.fa-chess-king-alt:before {
  content: "\f440"; }

.fa-chess-knight:before {
  content: "\f441"; }

.fa-chess-knight-alt:before {
  content: "\f442"; }

.fa-chess-pawn:before {
  content: "\f443"; }

.fa-chess-pawn-alt:before {
  content: "\f444"; }

.fa-chess-queen:before {
  content: "\f445"; }

.fa-chess-queen-alt:before {
  content: "\f446"; }

.fa-chess-rook:before {
  content: "\f447"; }

.fa-chess-rook-alt:before {
  content: "\f448"; }

.fa-chevron-circle-down:before {
  content: "\f13a"; }

.fa-chevron-circle-left:before {
  content: "\f137"; }

.fa-chevron-circle-right:before {
  content: "\f138"; }

.fa-chevron-circle-up:before {
  content: "\f139"; }

.fa-chevron-double-down:before {
  content: "\f322"; }

.fa-chevron-double-left:before {
  content: "\f323"; }

.fa-chevron-double-right:before {
  content: "\f324"; }

.fa-chevron-double-up:before {
  content: "\f325"; }

.fa-chevron-down:before {
  content: "\f078"; }

.fa-chevron-left:before {
  content: "\f053"; }

.fa-chevron-right:before {
  content: "\f054"; }

.fa-chevron-square-down:before {
  content: "\f329"; }

.fa-chevron-square-left:before {
  content: "\f32a"; }

.fa-chevron-square-right:before {
  content: "\f32b"; }

.fa-chevron-square-up:before {
  content: "\f32c"; }

.fa-chevron-up:before {
  content: "\f077"; }

.fa-child:before {
  content: "\f1ae"; }

.fa-chimney:before {
  content: "\f78b"; }

.fa-chrome:before {
  content: "\f268"; }

.fa-chromecast:before {
  content: "\f838"; }

.fa-church:before {
  content: "\f51d"; }

.fa-circle:before {
  content: "\f111"; }

.fa-circle-notch:before {
  content: "\f1ce"; }

.fa-city:before {
  content: "\f64f"; }

.fa-claw-marks:before {
  content: "\f6c2"; }

.fa-clinic-medical:before {
  content: "\f7f2"; }

.fa-clipboard:before {
  content: "\f328"; }

.fa-clipboard-check:before {
  content: "\f46c"; }

.fa-clipboard-list:before {
  content: "\f46d"; }

.fa-clipboard-list-check:before {
  content: "\f737"; }

.fa-clipboard-prescription:before {
  content: "\f5e8"; }

.fa-clipboard-user:before {
  content: "\f7f3"; }

.fa-clock:before {
  content: "\f017"; }

.fa-clone:before {
  content: "\f24d"; }

.fa-closed-captioning:before {
  content: "\f20a"; }

.fa-cloud:before {
  content: "\f0c2"; }

.fa-cloud-download:before {
  content: "\f0ed"; }

.fa-cloud-download-alt:before {
  content: "\f381"; }

.fa-cloud-drizzle:before {
  content: "\f738"; }

.fa-cloud-hail:before {
  content: "\f739"; }

.fa-cloud-hail-mixed:before {
  content: "\f73a"; }

.fa-cloud-meatball:before {
  content: "\f73b"; }

.fa-cloud-moon:before {
  content: "\f6c3"; }

.fa-cloud-moon-rain:before {
  content: "\f73c"; }

.fa-cloud-rain:before {
  content: "\f73d"; }

.fa-cloud-rainbow:before {
  content: "\f73e"; }

.fa-cloud-showers:before {
  content: "\f73f"; }

.fa-cloud-showers-heavy:before {
  content: "\f740"; }

.fa-cloud-sleet:before {
  content: "\f741"; }

.fa-cloud-snow:before {
  content: "\f742"; }

.fa-cloud-sun:before {
  content: "\f6c4"; }

.fa-cloud-sun-rain:before {
  content: "\f743"; }

.fa-cloud-upload:before {
  content: "\f0ee"; }

.fa-cloud-upload-alt:before {
  content: "\f382"; }

.fa-clouds:before {
  content: "\f744"; }

.fa-clouds-moon:before {
  content: "\f745"; }

.fa-clouds-sun:before {
  content: "\f746"; }

.fa-cloudscale:before {
  content: "\f383"; }

.fa-cloudsmith:before {
  content: "\f384"; }

.fa-cloudversify:before {
  content: "\f385"; }

.fa-club:before {
  content: "\f327"; }

.fa-cocktail:before {
  content: "\f561"; }

.fa-code:before {
  content: "\f121"; }

.fa-code-branch:before {
  content: "\f126"; }

.fa-code-commit:before {
  content: "\f386"; }

.fa-code-merge:before {
  content: "\f387"; }

.fa-codepen:before {
  content: "\f1cb"; }

.fa-codiepie:before {
  content: "\f284"; }

.fa-coffee:before {
  content: "\f0f4"; }

.fa-coffee-togo:before {
  content: "\f6c5"; }

.fa-coffin:before {
  content: "\f6c6"; }

.fa-cog:before {
  content: "\f013"; }

.fa-cogs:before {
  content: "\f085"; }

.fa-coin:before {
  content: "\f85c"; }

.fa-coins:before {
  content: "\f51e"; }

.fa-columns:before {
  content: "\f0db"; }

.fa-comment:before {
  content: "\f075"; }

.fa-comment-alt:before {
  content: "\f27a"; }

.fa-comment-alt-check:before {
  content: "\f4a2"; }

.fa-comment-alt-dollar:before {
  content: "\f650"; }

.fa-comment-alt-dots:before {
  content: "\f4a3"; }

.fa-comment-alt-edit:before {
  content: "\f4a4"; }

.fa-comment-alt-exclamation:before {
  content: "\f4a5"; }

.fa-comment-alt-lines:before {
  content: "\f4a6"; }

.fa-comment-alt-medical:before {
  content: "\f7f4"; }

.fa-comment-alt-minus:before {
  content: "\f4a7"; }

.fa-comment-alt-plus:before {
  content: "\f4a8"; }

.fa-comment-alt-slash:before {
  content: "\f4a9"; }

.fa-comment-alt-smile:before {
  content: "\f4aa"; }

.fa-comment-alt-times:before {
  content: "\f4ab"; }

.fa-comment-check:before {
  content: "\f4ac"; }

.fa-comment-dollar:before {
  content: "\f651"; }

.fa-comment-dots:before {
  content: "\f4ad"; }

.fa-comment-edit:before {
  content: "\f4ae"; }

.fa-comment-exclamation:before {
  content: "\f4af"; }

.fa-comment-lines:before {
  content: "\f4b0"; }

.fa-comment-medical:before {
  content: "\f7f5"; }

.fa-comment-minus:before {
  content: "\f4b1"; }

.fa-comment-plus:before {
  content: "\f4b2"; }

.fa-comment-slash:before {
  content: "\f4b3"; }

.fa-comment-smile:before {
  content: "\f4b4"; }

.fa-comment-times:before {
  content: "\f4b5"; }

.fa-comments:before {
  content: "\f086"; }

.fa-comments-alt:before {
  content: "\f4b6"; }

.fa-comments-alt-dollar:before {
  content: "\f652"; }

.fa-comments-dollar:before {
  content: "\f653"; }

.fa-compact-disc:before {
  content: "\f51f"; }

.fa-compass:before {
  content: "\f14e"; }

.fa-compass-slash:before {
  content: "\f5e9"; }

.fa-compress:before {
  content: "\f066"; }

.fa-compress-alt:before {
  content: "\f422"; }

.fa-compress-arrows-alt:before {
  content: "\f78c"; }

.fa-compress-wide:before {
  content: "\f326"; }

.fa-concierge-bell:before {
  content: "\f562"; }

.fa-confluence:before {
  content: "\f78d"; }

.fa-connectdevelop:before {
  content: "\f20e"; }

.fa-construction:before {
  content: "\f85d"; }

.fa-container-storage:before {
  content: "\f4b7"; }

.fa-contao:before {
  content: "\f26d"; }

.fa-conveyor-belt:before {
  content: "\f46e"; }

.fa-conveyor-belt-alt:before {
  content: "\f46f"; }

.fa-cookie:before {
  content: "\f563"; }

.fa-cookie-bite:before {
  content: "\f564"; }

.fa-copy:before {
  content: "\f0c5"; }

.fa-copyright:before {
  content: "\f1f9"; }

.fa-corn:before {
  content: "\f6c7"; }

.fa-couch:before {
  content: "\f4b8"; }

.fa-cow:before {
  content: "\f6c8"; }

.fa-cpanel:before {
  content: "\f388"; }

.fa-creative-commons:before {
  content: "\f25e"; }

.fa-creative-commons-by:before {
  content: "\f4e7"; }

.fa-creative-commons-nc:before {
  content: "\f4e8"; }

.fa-creative-commons-nc-eu:before {
  content: "\f4e9"; }

.fa-creative-commons-nc-jp:before {
  content: "\f4ea"; }

.fa-creative-commons-nd:before {
  content: "\f4eb"; }

.fa-creative-commons-pd:before {
  content: "\f4ec"; }

.fa-creative-commons-pd-alt:before {
  content: "\f4ed"; }

.fa-creative-commons-remix:before {
  content: "\f4ee"; }

.fa-creative-commons-sa:before {
  content: "\f4ef"; }

.fa-creative-commons-sampling:before {
  content: "\f4f0"; }

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1"; }

.fa-creative-commons-share:before {
  content: "\f4f2"; }

.fa-creative-commons-zero:before {
  content: "\f4f3"; }

.fa-credit-card:before {
  content: "\f09d"; }

.fa-credit-card-blank:before {
  content: "\f389"; }

.fa-credit-card-front:before {
  content: "\f38a"; }

.fa-cricket:before {
  content: "\f449"; }

.fa-critical-role:before {
  content: "\f6c9"; }

.fa-croissant:before {
  content: "\f7f6"; }

.fa-crop:before {
  content: "\f125"; }

.fa-crop-alt:before {
  content: "\f565"; }

.fa-cross:before {
  content: "\f654"; }

.fa-crosshairs:before {
  content: "\f05b"; }

.fa-crow:before {
  content: "\f520"; }

.fa-crown:before {
  content: "\f521"; }

.fa-crutch:before {
  content: "\f7f7"; }

.fa-crutches:before {
  content: "\f7f8"; }

.fa-css3:before {
  content: "\f13c"; }

.fa-css3-alt:before {
  content: "\f38b"; }

.fa-cube:before {
  content: "\f1b2"; }

.fa-cubes:before {
  content: "\f1b3"; }

.fa-curling:before {
  content: "\f44a"; }

.fa-cut:before {
  content: "\f0c4"; }

.fa-cuttlefish:before {
  content: "\f38c"; }

.fa-d-and-d:before {
  content: "\f38d"; }

.fa-d-and-d-beyond:before {
  content: "\f6ca"; }

.fa-dagger:before {
  content: "\f6cb"; }

.fa-dashcube:before {
  content: "\f210"; }

.fa-database:before {
  content: "\f1c0"; }

.fa-deaf:before {
  content: "\f2a4"; }

.fa-debug:before {
  content: "\f7f9"; }

.fa-deer:before {
  content: "\f78e"; }

.fa-deer-rudolph:before {
  content: "\f78f"; }

.fa-delicious:before {
  content: "\f1a5"; }

.fa-democrat:before {
  content: "\f747"; }

.fa-deploydog:before {
  content: "\f38e"; }

.fa-deskpro:before {
  content: "\f38f"; }

.fa-desktop:before {
  content: "\f108"; }

.fa-desktop-alt:before {
  content: "\f390"; }

.fa-dev:before {
  content: "\f6cc"; }

.fa-deviantart:before {
  content: "\f1bd"; }

.fa-dewpoint:before {
  content: "\f748"; }

.fa-dharmachakra:before {
  content: "\f655"; }

.fa-dhl:before {
  content: "\f790"; }

.fa-diagnoses:before {
  content: "\f470"; }

.fa-diamond:before {
  content: "\f219"; }

.fa-diaspora:before {
  content: "\f791"; }

.fa-dice:before {
  content: "\f522"; }

.fa-dice-d10:before {
  content: "\f6cd"; }

.fa-dice-d12:before {
  content: "\f6ce"; }

.fa-dice-d20:before {
  content: "\f6cf"; }

.fa-dice-d4:before {
  content: "\f6d0"; }

.fa-dice-d6:before {
  content: "\f6d1"; }

.fa-dice-d8:before {
  content: "\f6d2"; }

.fa-dice-five:before {
  content: "\f523"; }

.fa-dice-four:before {
  content: "\f524"; }

.fa-dice-one:before {
  content: "\f525"; }

.fa-dice-six:before {
  content: "\f526"; }

.fa-dice-three:before {
  content: "\f527"; }

.fa-dice-two:before {
  content: "\f528"; }

.fa-digg:before {
  content: "\f1a6"; }

.fa-digging:before {
  content: "\f85e"; }

.fa-digital-ocean:before {
  content: "\f391"; }

.fa-digital-tachograph:before {
  content: "\f566"; }

.fa-diploma:before {
  content: "\f5ea"; }

.fa-directions:before {
  content: "\f5eb"; }

.fa-discord:before {
  content: "\f392"; }

.fa-discourse:before {
  content: "\f393"; }

.fa-disease:before {
  content: "\f7fa"; }

.fa-divide:before {
  content: "\f529"; }

.fa-dizzy:before {
  content: "\f567"; }

.fa-dna:before {
  content: "\f471"; }

.fa-do-not-enter:before {
  content: "\f5ec"; }

.fa-dochub:before {
  content: "\f394"; }

.fa-docker:before {
  content: "\f395"; }

.fa-dog:before {
  content: "\f6d3"; }

.fa-dog-leashed:before {
  content: "\f6d4"; }

.fa-dollar-sign:before {
  content: "\f155"; }

.fa-dolly:before {
  content: "\f472"; }

.fa-dolly-empty:before {
  content: "\f473"; }

.fa-dolly-flatbed:before {
  content: "\f474"; }

.fa-dolly-flatbed-alt:before {
  content: "\f475"; }

.fa-dolly-flatbed-empty:before {
  content: "\f476"; }

.fa-donate:before {
  content: "\f4b9"; }

.fa-door-closed:before {
  content: "\f52a"; }

.fa-door-open:before {
  content: "\f52b"; }

.fa-dot-circle:before {
  content: "\f192"; }

.fa-dove:before {
  content: "\f4ba"; }

.fa-download:before {
  content: "\f019"; }

.fa-draft2digital:before {
  content: "\f396"; }

.fa-drafting-compass:before {
  content: "\f568"; }

.fa-dragon:before {
  content: "\f6d5"; }

.fa-draw-circle:before {
  content: "\f5ed"; }

.fa-draw-polygon:before {
  content: "\f5ee"; }

.fa-draw-square:before {
  content: "\f5ef"; }

.fa-dreidel:before {
  content: "\f792"; }

.fa-dribbble:before {
  content: "\f17d"; }

.fa-dribbble-square:before {
  content: "\f397"; }

.fa-drone:before {
  content: "\f85f"; }

.fa-drone-alt:before {
  content: "\f860"; }

.fa-dropbox:before {
  content: "\f16b"; }

.fa-drum:before {
  content: "\f569"; }

.fa-drum-steelpan:before {
  content: "\f56a"; }

.fa-drumstick:before {
  content: "\f6d6"; }

.fa-drumstick-bite:before {
  content: "\f6d7"; }

.fa-drupal:before {
  content: "\f1a9"; }

.fa-dryer:before {
  content: "\f861"; }

.fa-dryer-alt:before {
  content: "\f862"; }

.fa-duck:before {
  content: "\f6d8"; }

.fa-dumbbell:before {
  content: "\f44b"; }

.fa-dumpster:before {
  content: "\f793"; }

.fa-dumpster-fire:before {
  content: "\f794"; }

.fa-dungeon:before {
  content: "\f6d9"; }

.fa-dyalog:before {
  content: "\f399"; }

.fa-ear:before {
  content: "\f5f0"; }

.fa-ear-muffs:before {
  content: "\f795"; }

.fa-earlybirds:before {
  content: "\f39a"; }

.fa-ebay:before {
  content: "\f4f4"; }

.fa-eclipse:before {
  content: "\f749"; }

.fa-eclipse-alt:before {
  content: "\f74a"; }

.fa-edge:before {
  content: "\f282"; }

.fa-edit:before {
  content: "\f044"; }

.fa-egg:before {
  content: "\f7fb"; }

.fa-egg-fried:before {
  content: "\f7fc"; }

.fa-eject:before {
  content: "\f052"; }

.fa-elementor:before {
  content: "\f430"; }

.fa-elephant:before {
  content: "\f6da"; }

.fa-ellipsis-h:before {
  content: "\f141"; }

.fa-ellipsis-h-alt:before {
  content: "\f39b"; }

.fa-ellipsis-v:before {
  content: "\f142"; }

.fa-ellipsis-v-alt:before {
  content: "\f39c"; }

.fa-ello:before {
  content: "\f5f1"; }

.fa-ember:before {
  content: "\f423"; }

.fa-empire:before {
  content: "\f1d1"; }

.fa-empty-set:before {
  content: "\f656"; }

.fa-engine-warning:before {
  content: "\f5f2"; }

.fa-envelope:before {
  content: "\f0e0"; }

.fa-envelope-open:before {
  content: "\f2b6"; }

.fa-envelope-open-dollar:before {
  content: "\f657"; }

.fa-envelope-open-text:before {
  content: "\f658"; }

.fa-envelope-square:before {
  content: "\f199"; }

.fa-envira:before {
  content: "\f299"; }

.fa-equals:before {
  content: "\f52c"; }

.fa-eraser:before {
  content: "\f12d"; }

.fa-erlang:before {
  content: "\f39d"; }

.fa-ethereum:before {
  content: "\f42e"; }

.fa-ethernet:before {
  content: "\f796"; }

.fa-etsy:before {
  content: "\f2d7"; }

.fa-euro-sign:before {
  content: "\f153"; }

.fa-evernote:before {
  content: "\f839"; }

.fa-exchange:before {
  content: "\f0ec"; }

.fa-exchange-alt:before {
  content: "\f362"; }

.fa-exclamation:before {
  content: "\f12a"; }

.fa-exclamation-circle:before {
  content: "\f06a"; }

.fa-exclamation-square:before {
  content: "\f321"; }

.fa-exclamation-triangle:before {
  content: "\f071"; }

.fa-expand:before {
  content: "\f065"; }

.fa-expand-alt:before {
  content: "\f424"; }

.fa-expand-arrows:before {
  content: "\f31d"; }

.fa-expand-arrows-alt:before {
  content: "\f31e"; }

.fa-expand-wide:before {
  content: "\f320"; }

.fa-expeditedssl:before {
  content: "\f23e"; }

.fa-external-link:before {
  content: "\f08e"; }

.fa-external-link-alt:before {
  content: "\f35d"; }

.fa-external-link-square:before {
  content: "\f14c"; }

.fa-external-link-square-alt:before {
  content: "\f360"; }

.fa-eye:before {
  content: "\f06e"; }

.fa-eye-dropper:before {
  content: "\f1fb"; }

.fa-eye-evil:before {
  content: "\f6db"; }

.fa-eye-slash:before {
  content: "\f070"; }

.fa-facebook:before {
  content: "\f09a"; }

.fa-facebook-f:before {
  content: "\f39e"; }

.fa-facebook-messenger:before {
  content: "\f39f"; }

.fa-facebook-square:before {
  content: "\f082"; }

.fa-fan:before {
  content: "\f863"; }

.fa-fantasy-flight-games:before {
  content: "\f6dc"; }

.fa-farm:before {
  content: "\f864"; }

.fa-fast-backward:before {
  content: "\f049"; }

.fa-fast-forward:before {
  content: "\f050"; }

.fa-fax:before {
  content: "\f1ac"; }

.fa-feather:before {
  content: "\f52d"; }

.fa-feather-alt:before {
  content: "\f56b"; }

.fa-fedex:before {
  content: "\f797"; }

.fa-fedora:before {
  content: "\f798"; }

.fa-female:before {
  content: "\f182"; }

.fa-field-hockey:before {
  content: "\f44c"; }

.fa-fighter-jet:before {
  content: "\f0fb"; }

.fa-figma:before {
  content: "\f799"; }

.fa-file:before {
  content: "\f15b"; }

.fa-file-alt:before {
  content: "\f15c"; }

.fa-file-archive:before {
  content: "\f1c6"; }

.fa-file-audio:before {
  content: "\f1c7"; }

.fa-file-certificate:before {
  content: "\f5f3"; }

.fa-file-chart-line:before {
  content: "\f659"; }

.fa-file-chart-pie:before {
  content: "\f65a"; }

.fa-file-check:before {
  content: "\f316"; }

.fa-file-code:before {
  content: "\f1c9"; }

.fa-file-contract:before {
  content: "\f56c"; }

.fa-file-csv:before {
  content: "\f6dd"; }

.fa-file-download:before {
  content: "\f56d"; }

.fa-file-edit:before {
  content: "\f31c"; }

.fa-file-excel:before {
  content: "\f1c3"; }

.fa-file-exclamation:before {
  content: "\f31a"; }

.fa-file-export:before {
  content: "\f56e"; }

.fa-file-image:before {
  content: "\f1c5"; }

.fa-file-import:before {
  content: "\f56f"; }

.fa-file-invoice:before {
  content: "\f570"; }

.fa-file-invoice-dollar:before {
  content: "\f571"; }

.fa-file-medical:before {
  content: "\f477"; }

.fa-file-medical-alt:before {
  content: "\f478"; }

.fa-file-minus:before {
  content: "\f318"; }

.fa-file-pdf:before {
  content: "\f1c1"; }

.fa-file-plus:before {
  content: "\f319"; }

.fa-file-powerpoint:before {
  content: "\f1c4"; }

.fa-file-prescription:before {
  content: "\f572"; }

.fa-file-search:before {
  content: "\f865"; }

.fa-file-signature:before {
  content: "\f573"; }

.fa-file-spreadsheet:before {
  content: "\f65b"; }

.fa-file-times:before {
  content: "\f317"; }

.fa-file-upload:before {
  content: "\f574"; }

.fa-file-user:before {
  content: "\f65c"; }

.fa-file-video:before {
  content: "\f1c8"; }

.fa-file-word:before {
  content: "\f1c2"; }

.fa-files-medical:before {
  content: "\f7fd"; }

.fa-fill:before {
  content: "\f575"; }

.fa-fill-drip:before {
  content: "\f576"; }

.fa-film:before {
  content: "\f008"; }

.fa-film-alt:before {
  content: "\f3a0"; }

.fa-filter:before {
  content: "\f0b0"; }

.fa-fingerprint:before {
  content: "\f577"; }

.fa-fire:before {
  content: "\f06d"; }

.fa-fire-alt:before {
  content: "\f7e4"; }

.fa-fire-extinguisher:before {
  content: "\f134"; }

.fa-fire-smoke:before {
  content: "\f74b"; }

.fa-firefox:before {
  content: "\f269"; }

.fa-fireplace:before {
  content: "\f79a"; }

.fa-first-aid:before {
  content: "\f479"; }

.fa-first-order:before {
  content: "\f2b0"; }

.fa-first-order-alt:before {
  content: "\f50a"; }

.fa-firstdraft:before {
  content: "\f3a1"; }

.fa-fish:before {
  content: "\f578"; }

.fa-fish-cooked:before {
  content: "\f7fe"; }

.fa-fist-raised:before {
  content: "\f6de"; }

.fa-flag:before {
  content: "\f024"; }

.fa-flag-alt:before {
  content: "\f74c"; }

.fa-flag-checkered:before {
  content: "\f11e"; }

.fa-flag-usa:before {
  content: "\f74d"; }

.fa-flame:before {
  content: "\f6df"; }

.fa-flask:before {
  content: "\f0c3"; }

.fa-flask-poison:before {
  content: "\f6e0"; }

.fa-flask-potion:before {
  content: "\f6e1"; }

.fa-flickr:before {
  content: "\f16e"; }

.fa-flipboard:before {
  content: "\f44d"; }

.fa-flower:before {
  content: "\f7ff"; }

.fa-flower-daffodil:before {
  content: "\f800"; }

.fa-flower-tulip:before {
  content: "\f801"; }

.fa-flushed:before {
  content: "\f579"; }

.fa-fly:before {
  content: "\f417"; }

.fa-fog:before {
  content: "\f74e"; }

.fa-folder:before {
  content: "\f07b"; }

.fa-folder-minus:before {
  content: "\f65d"; }

.fa-folder-open:before {
  content: "\f07c"; }

.fa-folder-plus:before {
  content: "\f65e"; }

.fa-folder-times:before {
  content: "\f65f"; }

.fa-folder-tree:before {
  content: "\f802"; }

.fa-folders:before {
  content: "\f660"; }

.fa-font:before {
  content: "\f031"; }

.fa-font-awesome:before {
  content: "\f2b4"; }

.fa-font-awesome-alt:before {
  content: "\f35c"; }

.fa-font-awesome-flag:before {
  content: "\f425"; }

.fa-font-awesome-logo-full:before {
  content: "\f4e6"; }

.fa-font-case:before {
  content: "\f866"; }

.fa-fonticons:before {
  content: "\f280"; }

.fa-fonticons-fi:before {
  content: "\f3a2"; }

.fa-football-ball:before {
  content: "\f44e"; }

.fa-football-helmet:before {
  content: "\f44f"; }

.fa-forklift:before {
  content: "\f47a"; }

.fa-fort-awesome:before {
  content: "\f286"; }

.fa-fort-awesome-alt:before {
  content: "\f3a3"; }

.fa-forumbee:before {
  content: "\f211"; }

.fa-forward:before {
  content: "\f04e"; }

.fa-foursquare:before {
  content: "\f180"; }

.fa-fragile:before {
  content: "\f4bb"; }

.fa-free-code-camp:before {
  content: "\f2c5"; }

.fa-freebsd:before {
  content: "\f3a4"; }

.fa-french-fries:before {
  content: "\f803"; }

.fa-frog:before {
  content: "\f52e"; }

.fa-frosty-head:before {
  content: "\f79b"; }

.fa-frown:before {
  content: "\f119"; }

.fa-frown-open:before {
  content: "\f57a"; }

.fa-fulcrum:before {
  content: "\f50b"; }

.fa-function:before {
  content: "\f661"; }

.fa-funnel-dollar:before {
  content: "\f662"; }

.fa-futbol:before {
  content: "\f1e3"; }

.fa-galactic-republic:before {
  content: "\f50c"; }

.fa-galactic-senate:before {
  content: "\f50d"; }

.fa-game-board:before {
  content: "\f867"; }

.fa-game-board-alt:before {
  content: "\f868"; }

.fa-gamepad:before {
  content: "\f11b"; }

.fa-gas-pump:before {
  content: "\f52f"; }

.fa-gas-pump-slash:before {
  content: "\f5f4"; }

.fa-gavel:before {
  content: "\f0e3"; }

.fa-gem:before {
  content: "\f3a5"; }

.fa-genderless:before {
  content: "\f22d"; }

.fa-get-pocket:before {
  content: "\f265"; }

.fa-gg:before {
  content: "\f260"; }

.fa-gg-circle:before {
  content: "\f261"; }

.fa-ghost:before {
  content: "\f6e2"; }

.fa-gift:before {
  content: "\f06b"; }

.fa-gift-card:before {
  content: "\f663"; }

.fa-gifts:before {
  content: "\f79c"; }

.fa-gingerbread-man:before {
  content: "\f79d"; }

.fa-git:before {
  content: "\f1d3"; }

.fa-git-alt:before {
  content: "\f841"; }

.fa-git-square:before {
  content: "\f1d2"; }

.fa-github:before {
  content: "\f09b"; }

.fa-github-alt:before {
  content: "\f113"; }

.fa-github-square:before {
  content: "\f092"; }

.fa-gitkraken:before {
  content: "\f3a6"; }

.fa-gitlab:before {
  content: "\f296"; }

.fa-gitter:before {
  content: "\f426"; }

.fa-glass:before {
  content: "\f804"; }

.fa-glass-champagne:before {
  content: "\f79e"; }

.fa-glass-cheers:before {
  content: "\f79f"; }

.fa-glass-citrus:before {
  content: "\f869"; }

.fa-glass-martini:before {
  content: "\f000"; }

.fa-glass-martini-alt:before {
  content: "\f57b"; }

.fa-glass-whiskey:before {
  content: "\f7a0"; }

.fa-glass-whiskey-rocks:before {
  content: "\f7a1"; }

.fa-glasses:before {
  content: "\f530"; }

.fa-glasses-alt:before {
  content: "\f5f5"; }

.fa-glide:before {
  content: "\f2a5"; }

.fa-glide-g:before {
  content: "\f2a6"; }

.fa-globe:before {
  content: "\f0ac"; }

.fa-globe-africa:before {
  content: "\f57c"; }

.fa-globe-americas:before {
  content: "\f57d"; }

.fa-globe-asia:before {
  content: "\f57e"; }

.fa-globe-europe:before {
  content: "\f7a2"; }

.fa-globe-snow:before {
  content: "\f7a3"; }

.fa-globe-stand:before {
  content: "\f5f6"; }

.fa-gofore:before {
  content: "\f3a7"; }

.fa-golf-ball:before {
  content: "\f450"; }

.fa-golf-club:before {
  content: "\f451"; }

.fa-goodreads:before {
  content: "\f3a8"; }

.fa-goodreads-g:before {
  content: "\f3a9"; }

.fa-google:before {
  content: "\f1a0"; }

.fa-google-drive:before {
  content: "\f3aa"; }

.fa-google-play:before {
  content: "\f3ab"; }

.fa-google-plus:before {
  content: "\f2b3"; }

.fa-google-plus-g:before {
  content: "\f0d5"; }

.fa-google-plus-square:before {
  content: "\f0d4"; }

.fa-google-wallet:before {
  content: "\f1ee"; }

.fa-gopuram:before {
  content: "\f664"; }

.fa-graduation-cap:before {
  content: "\f19d"; }

.fa-gratipay:before {
  content: "\f184"; }

.fa-grav:before {
  content: "\f2d6"; }

.fa-greater-than:before {
  content: "\f531"; }

.fa-greater-than-equal:before {
  content: "\f532"; }

.fa-grimace:before {
  content: "\f57f"; }

.fa-grin:before {
  content: "\f580"; }

.fa-grin-alt:before {
  content: "\f581"; }

.fa-grin-beam:before {
  content: "\f582"; }

.fa-grin-beam-sweat:before {
  content: "\f583"; }

.fa-grin-hearts:before {
  content: "\f584"; }

.fa-grin-squint:before {
  content: "\f585"; }

.fa-grin-squint-tears:before {
  content: "\f586"; }

.fa-grin-stars:before {
  content: "\f587"; }

.fa-grin-tears:before {
  content: "\f588"; }

.fa-grin-tongue:before {
  content: "\f589"; }

.fa-grin-tongue-squint:before {
  content: "\f58a"; }

.fa-grin-tongue-wink:before {
  content: "\f58b"; }

.fa-grin-wink:before {
  content: "\f58c"; }

.fa-grip-horizontal:before {
  content: "\f58d"; }

.fa-grip-lines:before {
  content: "\f7a4"; }

.fa-grip-lines-vertical:before {
  content: "\f7a5"; }

.fa-grip-vertical:before {
  content: "\f58e"; }

.fa-gripfire:before {
  content: "\f3ac"; }

.fa-grunt:before {
  content: "\f3ad"; }

.fa-guitar:before {
  content: "\f7a6"; }

.fa-gulp:before {
  content: "\f3ae"; }

.fa-h-square:before {
  content: "\f0fd"; }

.fa-h1:before {
  content: "\f313"; }

.fa-h2:before {
  content: "\f314"; }

.fa-h3:before {
  content: "\f315"; }

.fa-h4:before {
  content: "\f86a"; }

.fa-hacker-news:before {
  content: "\f1d4"; }

.fa-hacker-news-square:before {
  content: "\f3af"; }

.fa-hackerrank:before {
  content: "\f5f7"; }

.fa-hamburger:before {
  content: "\f805"; }

.fa-hammer:before {
  content: "\f6e3"; }

.fa-hammer-war:before {
  content: "\f6e4"; }

.fa-hamsa:before {
  content: "\f665"; }

.fa-hand-heart:before {
  content: "\f4bc"; }

.fa-hand-holding:before {
  content: "\f4bd"; }

.fa-hand-holding-box:before {
  content: "\f47b"; }

.fa-hand-holding-heart:before {
  content: "\f4be"; }

.fa-hand-holding-magic:before {
  content: "\f6e5"; }

.fa-hand-holding-seedling:before {
  content: "\f4bf"; }

.fa-hand-holding-usd:before {
  content: "\f4c0"; }

.fa-hand-holding-water:before {
  content: "\f4c1"; }

.fa-hand-lizard:before {
  content: "\f258"; }

.fa-hand-middle-finger:before {
  content: "\f806"; }

.fa-hand-paper:before {
  content: "\f256"; }

.fa-hand-peace:before {
  content: "\f25b"; }

.fa-hand-point-down:before {
  content: "\f0a7"; }

.fa-hand-point-left:before {
  content: "\f0a5"; }

.fa-hand-point-right:before {
  content: "\f0a4"; }

.fa-hand-point-up:before {
  content: "\f0a6"; }

.fa-hand-pointer:before {
  content: "\f25a"; }

.fa-hand-receiving:before {
  content: "\f47c"; }

.fa-hand-rock:before {
  content: "\f255"; }

.fa-hand-scissors:before {
  content: "\f257"; }

.fa-hand-spock:before {
  content: "\f259"; }

.fa-hands:before {
  content: "\f4c2"; }

.fa-hands-heart:before {
  content: "\f4c3"; }

.fa-hands-helping:before {
  content: "\f4c4"; }

.fa-hands-usd:before {
  content: "\f4c5"; }

.fa-handshake:before {
  content: "\f2b5"; }

.fa-handshake-alt:before {
  content: "\f4c6"; }

.fa-hanukiah:before {
  content: "\f6e6"; }

.fa-hard-hat:before {
  content: "\f807"; }

.fa-hashtag:before {
  content: "\f292"; }

.fa-hat-chef:before {
  content: "\f86b"; }

.fa-hat-santa:before {
  content: "\f7a7"; }

.fa-hat-winter:before {
  content: "\f7a8"; }

.fa-hat-witch:before {
  content: "\f6e7"; }

.fa-hat-wizard:before {
  content: "\f6e8"; }

.fa-haykal:before {
  content: "\f666"; }

.fa-hdd:before {
  content: "\f0a0"; }

.fa-head-side:before {
  content: "\f6e9"; }

.fa-head-side-brain:before {
  content: "\f808"; }

.fa-head-side-medical:before {
  content: "\f809"; }

.fa-head-vr:before {
  content: "\f6ea"; }

.fa-heading:before {
  content: "\f1dc"; }

.fa-headphones:before {
  content: "\f025"; }

.fa-headphones-alt:before {
  content: "\f58f"; }

.fa-headset:before {
  content: "\f590"; }

.fa-heart:before {
  content: "\f004"; }

.fa-heart-broken:before {
  content: "\f7a9"; }

.fa-heart-circle:before {
  content: "\f4c7"; }

.fa-heart-rate:before {
  content: "\f5f8"; }

.fa-heart-square:before {
  content: "\f4c8"; }

.fa-heartbeat:before {
  content: "\f21e"; }

.fa-helicopter:before {
  content: "\f533"; }

.fa-helmet-battle:before {
  content: "\f6eb"; }

.fa-hexagon:before {
  content: "\f312"; }

.fa-highlighter:before {
  content: "\f591"; }

.fa-hiking:before {
  content: "\f6ec"; }

.fa-hippo:before {
  content: "\f6ed"; }

.fa-hips:before {
  content: "\f452"; }

.fa-hire-a-helper:before {
  content: "\f3b0"; }

.fa-history:before {
  content: "\f1da"; }

.fa-hockey-mask:before {
  content: "\f6ee"; }

.fa-hockey-puck:before {
  content: "\f453"; }

.fa-hockey-sticks:before {
  content: "\f454"; }

.fa-holly-berry:before {
  content: "\f7aa"; }

.fa-home:before {
  content: "\f015"; }

.fa-home-alt:before {
  content: "\f80a"; }

.fa-home-heart:before {
  content: "\f4c9"; }

.fa-home-lg:before {
  content: "\f80b"; }

.fa-home-lg-alt:before {
  content: "\f80c"; }

.fa-hood-cloak:before {
  content: "\f6ef"; }

.fa-hooli:before {
  content: "\f427"; }

.fa-horizontal-rule:before {
  content: "\f86c"; }

.fa-hornbill:before {
  content: "\f592"; }

.fa-horse:before {
  content: "\f6f0"; }

.fa-horse-head:before {
  content: "\f7ab"; }

.fa-hospital:before {
  content: "\f0f8"; }

.fa-hospital-alt:before {
  content: "\f47d"; }

.fa-hospital-symbol:before {
  content: "\f47e"; }

.fa-hospital-user:before {
  content: "\f80d"; }

.fa-hospitals:before {
  content: "\f80e"; }

.fa-hot-tub:before {
  content: "\f593"; }

.fa-hotdog:before {
  content: "\f80f"; }

.fa-hotel:before {
  content: "\f594"; }

.fa-hotjar:before {
  content: "\f3b1"; }

.fa-hourglass:before {
  content: "\f254"; }

.fa-hourglass-end:before {
  content: "\f253"; }

.fa-hourglass-half:before {
  content: "\f252"; }

.fa-hourglass-start:before {
  content: "\f251"; }

.fa-house-damage:before {
  content: "\f6f1"; }

.fa-house-flood:before {
  content: "\f74f"; }

.fa-houzz:before {
  content: "\f27c"; }

.fa-hryvnia:before {
  content: "\f6f2"; }

.fa-html5:before {
  content: "\f13b"; }

.fa-hubspot:before {
  content: "\f3b2"; }

.fa-humidity:before {
  content: "\f750"; }

.fa-hurricane:before {
  content: "\f751"; }

.fa-i-cursor:before {
  content: "\f246"; }

.fa-ice-cream:before {
  content: "\f810"; }

.fa-ice-skate:before {
  content: "\f7ac"; }

.fa-icicles:before {
  content: "\f7ad"; }

.fa-icons:before {
  content: "\f86d"; }

.fa-icons-alt:before {
  content: "\f86e"; }

.fa-id-badge:before {
  content: "\f2c1"; }

.fa-id-card:before {
  content: "\f2c2"; }

.fa-id-card-alt:before {
  content: "\f47f"; }

.fa-igloo:before {
  content: "\f7ae"; }

.fa-image:before {
  content: "\f03e"; }

.fa-images:before {
  content: "\f302"; }

.fa-imdb:before {
  content: "\f2d8"; }

.fa-inbox:before {
  content: "\f01c"; }

.fa-inbox-in:before {
  content: "\f310"; }

.fa-inbox-out:before {
  content: "\f311"; }

.fa-indent:before {
  content: "\f03c"; }

.fa-industry:before {
  content: "\f275"; }

.fa-industry-alt:before {
  content: "\f3b3"; }

.fa-infinity:before {
  content: "\f534"; }

.fa-info:before {
  content: "\f129"; }

.fa-info-circle:before {
  content: "\f05a"; }

.fa-info-square:before {
  content: "\f30f"; }

.fa-inhaler:before {
  content: "\f5f9"; }

.fa-instagram:before {
  content: "\f16d"; }

.fa-integral:before {
  content: "\f667"; }

.fa-intercom:before {
  content: "\f7af"; }

.fa-internet-explorer:before {
  content: "\f26b"; }

.fa-intersection:before {
  content: "\f668"; }

.fa-inventory:before {
  content: "\f480"; }

.fa-invision:before {
  content: "\f7b0"; }

.fa-ioxhost:before {
  content: "\f208"; }

.fa-island-tropical:before {
  content: "\f811"; }

.fa-italic:before {
  content: "\f033"; }

.fa-itch-io:before {
  content: "\f83a"; }

.fa-itunes:before {
  content: "\f3b4"; }

.fa-itunes-note:before {
  content: "\f3b5"; }

.fa-jack-o-lantern:before {
  content: "\f30e"; }

.fa-java:before {
  content: "\f4e4"; }

.fa-jedi:before {
  content: "\f669"; }

.fa-jedi-order:before {
  content: "\f50e"; }

.fa-jenkins:before {
  content: "\f3b6"; }

.fa-jira:before {
  content: "\f7b1"; }

.fa-joget:before {
  content: "\f3b7"; }

.fa-joint:before {
  content: "\f595"; }

.fa-joomla:before {
  content: "\f1aa"; }

.fa-journal-whills:before {
  content: "\f66a"; }

.fa-js:before {
  content: "\f3b8"; }

.fa-js-square:before {
  content: "\f3b9"; }

.fa-jsfiddle:before {
  content: "\f1cc"; }

.fa-kaaba:before {
  content: "\f66b"; }

.fa-kaggle:before {
  content: "\f5fa"; }

.fa-kerning:before {
  content: "\f86f"; }

.fa-key:before {
  content: "\f084"; }

.fa-key-skeleton:before {
  content: "\f6f3"; }

.fa-keybase:before {
  content: "\f4f5"; }

.fa-keyboard:before {
  content: "\f11c"; }

.fa-keycdn:before {
  content: "\f3ba"; }

.fa-keynote:before {
  content: "\f66c"; }

.fa-khanda:before {
  content: "\f66d"; }

.fa-kickstarter:before {
  content: "\f3bb"; }

.fa-kickstarter-k:before {
  content: "\f3bc"; }

.fa-kidneys:before {
  content: "\f5fb"; }

.fa-kiss:before {
  content: "\f596"; }

.fa-kiss-beam:before {
  content: "\f597"; }

.fa-kiss-wink-heart:before {
  content: "\f598"; }

.fa-kite:before {
  content: "\f6f4"; }

.fa-kiwi-bird:before {
  content: "\f535"; }

.fa-knife-kitchen:before {
  content: "\f6f5"; }

.fa-korvue:before {
  content: "\f42f"; }

.fa-lambda:before {
  content: "\f66e"; }

.fa-lamp:before {
  content: "\f4ca"; }

.fa-landmark:before {
  content: "\f66f"; }

.fa-landmark-alt:before {
  content: "\f752"; }

.fa-language:before {
  content: "\f1ab"; }

.fa-laptop:before {
  content: "\f109"; }

.fa-laptop-code:before {
  content: "\f5fc"; }

.fa-laptop-medical:before {
  content: "\f812"; }

.fa-laravel:before {
  content: "\f3bd"; }

.fa-lastfm:before {
  content: "\f202"; }

.fa-lastfm-square:before {
  content: "\f203"; }

.fa-laugh:before {
  content: "\f599"; }

.fa-laugh-beam:before {
  content: "\f59a"; }

.fa-laugh-squint:before {
  content: "\f59b"; }

.fa-laugh-wink:before {
  content: "\f59c"; }

.fa-layer-group:before {
  content: "\f5fd"; }

.fa-layer-minus:before {
  content: "\f5fe"; }

.fa-layer-plus:before {
  content: "\f5ff"; }

.fa-leaf:before {
  content: "\f06c"; }

.fa-leaf-heart:before {
  content: "\f4cb"; }

.fa-leaf-maple:before {
  content: "\f6f6"; }

.fa-leaf-oak:before {
  content: "\f6f7"; }

.fa-leanpub:before {
  content: "\f212"; }

.fa-lemon:before {
  content: "\f094"; }

.fa-less:before {
  content: "\f41d"; }

.fa-less-than:before {
  content: "\f536"; }

.fa-less-than-equal:before {
  content: "\f537"; }

.fa-level-down:before {
  content: "\f149"; }

.fa-level-down-alt:before {
  content: "\f3be"; }

.fa-level-up:before {
  content: "\f148"; }

.fa-level-up-alt:before {
  content: "\f3bf"; }

.fa-life-ring:before {
  content: "\f1cd"; }

.fa-lightbulb:before {
  content: "\f0eb"; }

.fa-lightbulb-dollar:before {
  content: "\f670"; }

.fa-lightbulb-exclamation:before {
  content: "\f671"; }

.fa-lightbulb-on:before {
  content: "\f672"; }

.fa-lightbulb-slash:before {
  content: "\f673"; }

.fa-lights-holiday:before {
  content: "\f7b2"; }

.fa-line:before {
  content: "\f3c0"; }

.fa-line-columns:before {
  content: "\f870"; }

.fa-line-height:before {
  content: "\f871"; }

.fa-link:before {
  content: "\f0c1"; }

.fa-linkedin:before {
  content: "\f08c"; }

.fa-linkedin-in:before {
  content: "\f0e1"; }

.fa-linode:before {
  content: "\f2b8"; }

.fa-linux:before {
  content: "\f17c"; }

.fa-lips:before {
  content: "\f600"; }

.fa-lira-sign:before {
  content: "\f195"; }

.fa-list:before {
  content: "\f03a"; }

.fa-list-alt:before {
  content: "\f022"; }

.fa-list-ol:before {
  content: "\f0cb"; }

.fa-list-ul:before {
  content: "\f0ca"; }

.fa-location:before {
  content: "\f601"; }

.fa-location-arrow:before {
  content: "\f124"; }

.fa-location-circle:before {
  content: "\f602"; }

.fa-location-slash:before {
  content: "\f603"; }

.fa-lock:before {
  content: "\f023"; }

.fa-lock-alt:before {
  content: "\f30d"; }

.fa-lock-open:before {
  content: "\f3c1"; }

.fa-lock-open-alt:before {
  content: "\f3c2"; }

.fa-long-arrow-alt-down:before {
  content: "\f309"; }

.fa-long-arrow-alt-left:before {
  content: "\f30a"; }

.fa-long-arrow-alt-right:before {
  content: "\f30b"; }

.fa-long-arrow-alt-up:before {
  content: "\f30c"; }

.fa-long-arrow-down:before {
  content: "\f175"; }

.fa-long-arrow-left:before {
  content: "\f177"; }

.fa-long-arrow-right:before {
  content: "\f178"; }

.fa-long-arrow-up:before {
  content: "\f176"; }

.fa-loveseat:before {
  content: "\f4cc"; }

.fa-low-vision:before {
  content: "\f2a8"; }

.fa-luchador:before {
  content: "\f455"; }

.fa-luggage-cart:before {
  content: "\f59d"; }

.fa-lungs:before {
  content: "\f604"; }

.fa-lyft:before {
  content: "\f3c3"; }

.fa-mace:before {
  content: "\f6f8"; }

.fa-magento:before {
  content: "\f3c4"; }

.fa-magic:before {
  content: "\f0d0"; }

.fa-magnet:before {
  content: "\f076"; }

.fa-mail-bulk:before {
  content: "\f674"; }

.fa-mailbox:before {
  content: "\f813"; }

.fa-mailchimp:before {
  content: "\f59e"; }

.fa-male:before {
  content: "\f183"; }

.fa-mandalorian:before {
  content: "\f50f"; }

.fa-mandolin:before {
  content: "\f6f9"; }

.fa-map:before {
  content: "\f279"; }

.fa-map-marked:before {
  content: "\f59f"; }

.fa-map-marked-alt:before {
  content: "\f5a0"; }

.fa-map-marker:before {
  content: "\f041"; }

.fa-map-marker-alt:before {
  content: "\f3c5"; }

.fa-map-marker-alt-slash:before {
  content: "\f605"; }

.fa-map-marker-check:before {
  content: "\f606"; }

.fa-map-marker-edit:before {
  content: "\f607"; }

.fa-map-marker-exclamation:before {
  content: "\f608"; }

.fa-map-marker-minus:before {
  content: "\f609"; }

.fa-map-marker-plus:before {
  content: "\f60a"; }

.fa-map-marker-question:before {
  content: "\f60b"; }

.fa-map-marker-slash:before {
  content: "\f60c"; }

.fa-map-marker-smile:before {
  content: "\f60d"; }

.fa-map-marker-times:before {
  content: "\f60e"; }

.fa-map-pin:before {
  content: "\f276"; }

.fa-map-signs:before {
  content: "\f277"; }

.fa-markdown:before {
  content: "\f60f"; }

.fa-marker:before {
  content: "\f5a1"; }

.fa-mars:before {
  content: "\f222"; }

.fa-mars-double:before {
  content: "\f227"; }

.fa-mars-stroke:before {
  content: "\f229"; }

.fa-mars-stroke-h:before {
  content: "\f22b"; }

.fa-mars-stroke-v:before {
  content: "\f22a"; }

.fa-mask:before {
  content: "\f6fa"; }

.fa-mastodon:before {
  content: "\f4f6"; }

.fa-maxcdn:before {
  content: "\f136"; }

.fa-meat:before {
  content: "\f814"; }

.fa-medal:before {
  content: "\f5a2"; }

.fa-medapps:before {
  content: "\f3c6"; }

.fa-medium:before {
  content: "\f23a"; }

.fa-medium-m:before {
  content: "\f3c7"; }

.fa-medkit:before {
  content: "\f0fa"; }

.fa-medrt:before {
  content: "\f3c8"; }

.fa-meetup:before {
  content: "\f2e0"; }

.fa-megaphone:before {
  content: "\f675"; }

.fa-megaport:before {
  content: "\f5a3"; }

.fa-meh:before {
  content: "\f11a"; }

.fa-meh-blank:before {
  content: "\f5a4"; }

.fa-meh-rolling-eyes:before {
  content: "\f5a5"; }

.fa-memory:before {
  content: "\f538"; }

.fa-mendeley:before {
  content: "\f7b3"; }

.fa-menorah:before {
  content: "\f676"; }

.fa-mercury:before {
  content: "\f223"; }

.fa-meteor:before {
  content: "\f753"; }

.fa-microchip:before {
  content: "\f2db"; }

.fa-microphone:before {
  content: "\f130"; }

.fa-microphone-alt:before {
  content: "\f3c9"; }

.fa-microphone-alt-slash:before {
  content: "\f539"; }

.fa-microphone-slash:before {
  content: "\f131"; }

.fa-microscope:before {
  content: "\f610"; }

.fa-microsoft:before {
  content: "\f3ca"; }

.fa-mind-share:before {
  content: "\f677"; }

.fa-minus:before {
  content: "\f068"; }

.fa-minus-circle:before {
  content: "\f056"; }

.fa-minus-hexagon:before {
  content: "\f307"; }

.fa-minus-octagon:before {
  content: "\f308"; }

.fa-minus-square:before {
  content: "\f146"; }

.fa-mistletoe:before {
  content: "\f7b4"; }

.fa-mitten:before {
  content: "\f7b5"; }

.fa-mix:before {
  content: "\f3cb"; }

.fa-mixcloud:before {
  content: "\f289"; }

.fa-mizuni:before {
  content: "\f3cc"; }

.fa-mobile:before {
  content: "\f10b"; }

.fa-mobile-alt:before {
  content: "\f3cd"; }

.fa-mobile-android:before {
  content: "\f3ce"; }

.fa-mobile-android-alt:before {
  content: "\f3cf"; }

.fa-modx:before {
  content: "\f285"; }

.fa-monero:before {
  content: "\f3d0"; }

.fa-money-bill:before {
  content: "\f0d6"; }

.fa-money-bill-alt:before {
  content: "\f3d1"; }

.fa-money-bill-wave:before {
  content: "\f53a"; }

.fa-money-bill-wave-alt:before {
  content: "\f53b"; }

.fa-money-check:before {
  content: "\f53c"; }

.fa-money-check-alt:before {
  content: "\f53d"; }

.fa-money-check-edit:before {
  content: "\f872"; }

.fa-money-check-edit-alt:before {
  content: "\f873"; }

.fa-monitor-heart-rate:before {
  content: "\f611"; }

.fa-monkey:before {
  content: "\f6fb"; }

.fa-monument:before {
  content: "\f5a6"; }

.fa-moon:before {
  content: "\f186"; }

.fa-moon-cloud:before {
  content: "\f754"; }

.fa-moon-stars:before {
  content: "\f755"; }

.fa-mortar-pestle:before {
  content: "\f5a7"; }

.fa-mosque:before {
  content: "\f678"; }

.fa-motorcycle:before {
  content: "\f21c"; }

.fa-mountain:before {
  content: "\f6fc"; }

.fa-mountains:before {
  content: "\f6fd"; }

.fa-mouse-pointer:before {
  content: "\f245"; }

.fa-mug:before {
  content: "\f874"; }

.fa-mug-hot:before {
  content: "\f7b6"; }

.fa-mug-marshmallows:before {
  content: "\f7b7"; }

.fa-mug-tea:before {
  content: "\f875"; }

.fa-music:before {
  content: "\f001"; }

.fa-napster:before {
  content: "\f3d2"; }

.fa-narwhal:before {
  content: "\f6fe"; }

.fa-neos:before {
  content: "\f612"; }

.fa-network-wired:before {
  content: "\f6ff"; }

.fa-neuter:before {
  content: "\f22c"; }

.fa-newspaper:before {
  content: "\f1ea"; }

.fa-nimblr:before {
  content: "\f5a8"; }

.fa-node:before {
  content: "\f419"; }

.fa-node-js:before {
  content: "\f3d3"; }

.fa-not-equal:before {
  content: "\f53e"; }

.fa-notes-medical:before {
  content: "\f481"; }

.fa-npm:before {
  content: "\f3d4"; }

.fa-ns8:before {
  content: "\f3d5"; }

.fa-nutritionix:before {
  content: "\f3d6"; }

.fa-object-group:before {
  content: "\f247"; }

.fa-object-ungroup:before {
  content: "\f248"; }

.fa-octagon:before {
  content: "\f306"; }

.fa-odnoklassniki:before {
  content: "\f263"; }

.fa-odnoklassniki-square:before {
  content: "\f264"; }

.fa-oil-can:before {
  content: "\f613"; }

.fa-oil-temp:before {
  content: "\f614"; }

.fa-old-republic:before {
  content: "\f510"; }

.fa-om:before {
  content: "\f679"; }

.fa-omega:before {
  content: "\f67a"; }

.fa-opencart:before {
  content: "\f23d"; }

.fa-openid:before {
  content: "\f19b"; }

.fa-opera:before {
  content: "\f26a"; }

.fa-optin-monster:before {
  content: "\f23c"; }

.fa-ornament:before {
  content: "\f7b8"; }

.fa-osi:before {
  content: "\f41a"; }

.fa-otter:before {
  content: "\f700"; }

.fa-outdent:before {
  content: "\f03b"; }

.fa-overline:before {
  content: "\f876"; }

.fa-page-break:before {
  content: "\f877"; }

.fa-page4:before {
  content: "\f3d7"; }

.fa-pagelines:before {
  content: "\f18c"; }

.fa-pager:before {
  content: "\f815"; }

.fa-paint-brush:before {
  content: "\f1fc"; }

.fa-paint-brush-alt:before {
  content: "\f5a9"; }

.fa-paint-roller:before {
  content: "\f5aa"; }

.fa-palette:before {
  content: "\f53f"; }

.fa-palfed:before {
  content: "\f3d8"; }

.fa-pallet:before {
  content: "\f482"; }

.fa-pallet-alt:before {
  content: "\f483"; }

.fa-paper-plane:before {
  content: "\f1d8"; }

.fa-paperclip:before {
  content: "\f0c6"; }

.fa-parachute-box:before {
  content: "\f4cd"; }

.fa-paragraph:before {
  content: "\f1dd"; }

.fa-paragraph-rtl:before {
  content: "\f878"; }

.fa-parking:before {
  content: "\f540"; }

.fa-parking-circle:before {
  content: "\f615"; }

.fa-parking-circle-slash:before {
  content: "\f616"; }

.fa-parking-slash:before {
  content: "\f617"; }

.fa-passport:before {
  content: "\f5ab"; }

.fa-pastafarianism:before {
  content: "\f67b"; }

.fa-paste:before {
  content: "\f0ea"; }

.fa-patreon:before {
  content: "\f3d9"; }

.fa-pause:before {
  content: "\f04c"; }

.fa-pause-circle:before {
  content: "\f28b"; }

.fa-paw:before {
  content: "\f1b0"; }

.fa-paw-alt:before {
  content: "\f701"; }

.fa-paw-claws:before {
  content: "\f702"; }

.fa-paypal:before {
  content: "\f1ed"; }

.fa-peace:before {
  content: "\f67c"; }

.fa-pegasus:before {
  content: "\f703"; }

.fa-pen:before {
  content: "\f304"; }

.fa-pen-alt:before {
  content: "\f305"; }

.fa-pen-fancy:before {
  content: "\f5ac"; }

.fa-pen-nib:before {
  content: "\f5ad"; }

.fa-pen-square:before {
  content: "\f14b"; }

.fa-pencil:before {
  content: "\f040"; }

.fa-pencil-alt:before {
  content: "\f303"; }

.fa-pencil-paintbrush:before {
  content: "\f618"; }

.fa-pencil-ruler:before {
  content: "\f5ae"; }

.fa-pennant:before {
  content: "\f456"; }

.fa-penny-arcade:before {
  content: "\f704"; }

.fa-people-carry:before {
  content: "\f4ce"; }

.fa-pepper-hot:before {
  content: "\f816"; }

.fa-percent:before {
  content: "\f295"; }

.fa-percentage:before {
  content: "\f541"; }

.fa-periscope:before {
  content: "\f3da"; }

.fa-person-booth:before {
  content: "\f756"; }

.fa-person-carry:before {
  content: "\f4cf"; }

.fa-person-dolly:before {
  content: "\f4d0"; }

.fa-person-dolly-empty:before {
  content: "\f4d1"; }

.fa-person-sign:before {
  content: "\f757"; }

.fa-phabricator:before {
  content: "\f3db"; }

.fa-phoenix-framework:before {
  content: "\f3dc"; }

.fa-phoenix-squadron:before {
  content: "\f511"; }

.fa-phone:before {
  content: "\f095"; }

.fa-phone-alt:before {
  content: "\f879"; }

.fa-phone-laptop:before {
  content: "\f87a"; }

.fa-phone-office:before {
  content: "\f67d"; }

.fa-phone-plus:before {
  content: "\f4d2"; }

.fa-phone-slash:before {
  content: "\f3dd"; }

.fa-phone-square:before {
  content: "\f098"; }

.fa-phone-square-alt:before {
  content: "\f87b"; }

.fa-phone-volume:before {
  content: "\f2a0"; }

.fa-photo-video:before {
  content: "\f87c"; }

.fa-php:before {
  content: "\f457"; }

.fa-pi:before {
  content: "\f67e"; }

.fa-pie:before {
  content: "\f705"; }

.fa-pied-piper:before {
  content: "\f2ae"; }

.fa-pied-piper-alt:before {
  content: "\f1a8"; }

.fa-pied-piper-hat:before {
  content: "\f4e5"; }

.fa-pied-piper-pp:before {
  content: "\f1a7"; }

.fa-pig:before {
  content: "\f706"; }

.fa-piggy-bank:before {
  content: "\f4d3"; }

.fa-pills:before {
  content: "\f484"; }

.fa-pinterest:before {
  content: "\f0d2"; }

.fa-pinterest-p:before {
  content: "\f231"; }

.fa-pinterest-square:before {
  content: "\f0d3"; }

.fa-pizza:before {
  content: "\f817"; }

.fa-pizza-slice:before {
  content: "\f818"; }

.fa-place-of-worship:before {
  content: "\f67f"; }

.fa-plane:before {
  content: "\f072"; }

.fa-plane-alt:before {
  content: "\f3de"; }

.fa-plane-arrival:before {
  content: "\f5af"; }

.fa-plane-departure:before {
  content: "\f5b0"; }

.fa-play:before {
  content: "\f04b"; }

.fa-play-circle:before {
  content: "\f144"; }

.fa-playstation:before {
  content: "\f3df"; }

.fa-plug:before {
  content: "\f1e6"; }

.fa-plus:before {
  content: "\f067"; }

.fa-plus-circle:before {
  content: "\f055"; }

.fa-plus-hexagon:before {
  content: "\f300"; }

.fa-plus-octagon:before {
  content: "\f301"; }

.fa-plus-square:before {
  content: "\f0fe"; }

.fa-podcast:before {
  content: "\f2ce"; }

.fa-podium:before {
  content: "\f680"; }

.fa-podium-star:before {
  content: "\f758"; }

.fa-poll:before {
  content: "\f681"; }

.fa-poll-h:before {
  content: "\f682"; }

.fa-poll-people:before {
  content: "\f759"; }

.fa-poo:before {
  content: "\f2fe"; }

.fa-poo-storm:before {
  content: "\f75a"; }

.fa-poop:before {
  content: "\f619"; }

.fa-popcorn:before {
  content: "\f819"; }

.fa-portrait:before {
  content: "\f3e0"; }

.fa-pound-sign:before {
  content: "\f154"; }

.fa-power-off:before {
  content: "\f011"; }

.fa-pray:before {
  content: "\f683"; }

.fa-praying-hands:before {
  content: "\f684"; }

.fa-prescription:before {
  content: "\f5b1"; }

.fa-prescription-bottle:before {
  content: "\f485"; }

.fa-prescription-bottle-alt:before {
  content: "\f486"; }

.fa-presentation:before {
  content: "\f685"; }

.fa-print:before {
  content: "\f02f"; }

.fa-print-search:before {
  content: "\f81a"; }

.fa-print-slash:before {
  content: "\f686"; }

.fa-procedures:before {
  content: "\f487"; }

.fa-product-hunt:before {
  content: "\f288"; }

.fa-project-diagram:before {
  content: "\f542"; }

.fa-pumpkin:before {
  content: "\f707"; }

.fa-pushed:before {
  content: "\f3e1"; }

.fa-puzzle-piece:before {
  content: "\f12e"; }

.fa-python:before {
  content: "\f3e2"; }

.fa-qq:before {
  content: "\f1d6"; }

.fa-qrcode:before {
  content: "\f029"; }

.fa-question:before {
  content: "\f128"; }

.fa-question-circle:before {
  content: "\f059"; }

.fa-question-square:before {
  content: "\f2fd"; }

.fa-quidditch:before {
  content: "\f458"; }

.fa-quinscape:before {
  content: "\f459"; }

.fa-quora:before {
  content: "\f2c4"; }

.fa-quote-left:before {
  content: "\f10d"; }

.fa-quote-right:before {
  content: "\f10e"; }

.fa-quran:before {
  content: "\f687"; }

.fa-r-project:before {
  content: "\f4f7"; }

.fa-rabbit:before {
  content: "\f708"; }

.fa-rabbit-fast:before {
  content: "\f709"; }

.fa-racquet:before {
  content: "\f45a"; }

.fa-radiation:before {
  content: "\f7b9"; }

.fa-radiation-alt:before {
  content: "\f7ba"; }

.fa-rainbow:before {
  content: "\f75b"; }

.fa-raindrops:before {
  content: "\f75c"; }

.fa-ram:before {
  content: "\f70a"; }

.fa-ramp-loading:before {
  content: "\f4d4"; }

.fa-random:before {
  content: "\f074"; }

.fa-raspberry-pi:before {
  content: "\f7bb"; }

.fa-ravelry:before {
  content: "\f2d9"; }

.fa-react:before {
  content: "\f41b"; }

.fa-reacteurope:before {
  content: "\f75d"; }

.fa-readme:before {
  content: "\f4d5"; }

.fa-rebel:before {
  content: "\f1d0"; }

.fa-receipt:before {
  content: "\f543"; }

.fa-rectangle-landscape:before {
  content: "\f2fa"; }

.fa-rectangle-portrait:before {
  content: "\f2fb"; }

.fa-rectangle-wide:before {
  content: "\f2fc"; }

.fa-recycle:before {
  content: "\f1b8"; }

.fa-red-river:before {
  content: "\f3e3"; }

.fa-reddit:before {
  content: "\f1a1"; }

.fa-reddit-alien:before {
  content: "\f281"; }

.fa-reddit-square:before {
  content: "\f1a2"; }

.fa-redhat:before {
  content: "\f7bc"; }

.fa-redo:before {
  content: "\f01e"; }

.fa-redo-alt:before {
  content: "\f2f9"; }

.fa-registered:before {
  content: "\f25d"; }

.fa-remove-format:before {
  content: "\f87d"; }

.fa-renren:before {
  content: "\f18b"; }

.fa-repeat:before {
  content: "\f363"; }

.fa-repeat-1:before {
  content: "\f365"; }

.fa-repeat-1-alt:before {
  content: "\f366"; }

.fa-repeat-alt:before {
  content: "\f364"; }

.fa-reply:before {
  content: "\f3e5"; }

.fa-reply-all:before {
  content: "\f122"; }

.fa-replyd:before {
  content: "\f3e6"; }

.fa-republican:before {
  content: "\f75e"; }

.fa-researchgate:before {
  content: "\f4f8"; }

.fa-resolving:before {
  content: "\f3e7"; }

.fa-restroom:before {
  content: "\f7bd"; }

.fa-retweet:before {
  content: "\f079"; }

.fa-retweet-alt:before {
  content: "\f361"; }

.fa-rev:before {
  content: "\f5b2"; }

.fa-ribbon:before {
  content: "\f4d6"; }

.fa-ring:before {
  content: "\f70b"; }

.fa-rings-wedding:before {
  content: "\f81b"; }

.fa-road:before {
  content: "\f018"; }

.fa-robot:before {
  content: "\f544"; }

.fa-rocket:before {
  content: "\f135"; }

.fa-rocketchat:before {
  content: "\f3e8"; }

.fa-rockrms:before {
  content: "\f3e9"; }

.fa-route:before {
  content: "\f4d7"; }

.fa-route-highway:before {
  content: "\f61a"; }

.fa-route-interstate:before {
  content: "\f61b"; }

.fa-rss:before {
  content: "\f09e"; }

.fa-rss-square:before {
  content: "\f143"; }

.fa-ruble-sign:before {
  content: "\f158"; }

.fa-ruler:before {
  content: "\f545"; }

.fa-ruler-combined:before {
  content: "\f546"; }

.fa-ruler-horizontal:before {
  content: "\f547"; }

.fa-ruler-triangle:before {
  content: "\f61c"; }

.fa-ruler-vertical:before {
  content: "\f548"; }

.fa-running:before {
  content: "\f70c"; }

.fa-rupee-sign:before {
  content: "\f156"; }

.fa-rv:before {
  content: "\f7be"; }

.fa-sack:before {
  content: "\f81c"; }

.fa-sack-dollar:before {
  content: "\f81d"; }

.fa-sad-cry:before {
  content: "\f5b3"; }

.fa-sad-tear:before {
  content: "\f5b4"; }

.fa-safari:before {
  content: "\f267"; }

.fa-salad:before {
  content: "\f81e"; }

.fa-salesforce:before {
  content: "\f83b"; }

.fa-sandwich:before {
  content: "\f81f"; }

.fa-sass:before {
  content: "\f41e"; }

.fa-satellite:before {
  content: "\f7bf"; }

.fa-satellite-dish:before {
  content: "\f7c0"; }

.fa-sausage:before {
  content: "\f820"; }

.fa-save:before {
  content: "\f0c7"; }

.fa-scalpel:before {
  content: "\f61d"; }

.fa-scalpel-path:before {
  content: "\f61e"; }

.fa-scanner:before {
  content: "\f488"; }

.fa-scanner-keyboard:before {
  content: "\f489"; }

.fa-scanner-touchscreen:before {
  content: "\f48a"; }

.fa-scarecrow:before {
  content: "\f70d"; }

.fa-scarf:before {
  content: "\f7c1"; }

.fa-schlix:before {
  content: "\f3ea"; }

.fa-school:before {
  content: "\f549"; }

.fa-screwdriver:before {
  content: "\f54a"; }

.fa-scribd:before {
  content: "\f28a"; }

.fa-scroll:before {
  content: "\f70e"; }

.fa-scroll-old:before {
  content: "\f70f"; }

.fa-scrubber:before {
  content: "\f2f8"; }

.fa-scythe:before {
  content: "\f710"; }

.fa-sd-card:before {
  content: "\f7c2"; }

.fa-search:before {
  content: "\f002"; }

.fa-search-dollar:before {
  content: "\f688"; }

.fa-search-location:before {
  content: "\f689"; }

.fa-search-minus:before {
  content: "\f010"; }

.fa-search-plus:before {
  content: "\f00e"; }

.fa-searchengin:before {
  content: "\f3eb"; }

.fa-seedling:before {
  content: "\f4d8"; }

.fa-sellcast:before {
  content: "\f2da"; }

.fa-sellsy:before {
  content: "\f213"; }

.fa-send-back:before {
  content: "\f87e"; }

.fa-send-backward:before {
  content: "\f87f"; }

.fa-server:before {
  content: "\f233"; }

.fa-servicestack:before {
  content: "\f3ec"; }

.fa-shapes:before {
  content: "\f61f"; }

.fa-share:before {
  content: "\f064"; }

.fa-share-all:before {
  content: "\f367"; }

.fa-share-alt:before {
  content: "\f1e0"; }

.fa-share-alt-square:before {
  content: "\f1e1"; }

.fa-share-square:before {
  content: "\f14d"; }

.fa-sheep:before {
  content: "\f711"; }

.fa-shekel-sign:before {
  content: "\f20b"; }

.fa-shield:before {
  content: "\f132"; }

.fa-shield-alt:before {
  content: "\f3ed"; }

.fa-shield-check:before {
  content: "\f2f7"; }

.fa-shield-cross:before {
  content: "\f712"; }

.fa-ship:before {
  content: "\f21a"; }

.fa-shipping-fast:before {
  content: "\f48b"; }

.fa-shipping-timed:before {
  content: "\f48c"; }

.fa-shirtsinbulk:before {
  content: "\f214"; }

.fa-shish-kebab:before {
  content: "\f821"; }

.fa-shoe-prints:before {
  content: "\f54b"; }

.fa-shopping-bag:before {
  content: "\f290"; }

.fa-shopping-basket:before {
  content: "\f291"; }

.fa-shopping-cart:before {
  content: "\f07a"; }

.fa-shopware:before {
  content: "\f5b5"; }

.fa-shovel:before {
  content: "\f713"; }

.fa-shovel-snow:before {
  content: "\f7c3"; }

.fa-shower:before {
  content: "\f2cc"; }

.fa-shredder:before {
  content: "\f68a"; }

.fa-shuttle-van:before {
  content: "\f5b6"; }

.fa-shuttlecock:before {
  content: "\f45b"; }

.fa-sickle:before {
  content: "\f822"; }

.fa-sigma:before {
  content: "\f68b"; }

.fa-sign:before {
  content: "\f4d9"; }

.fa-sign-in:before {
  content: "\f090"; }

.fa-sign-in-alt:before {
  content: "\f2f6"; }

.fa-sign-language:before {
  content: "\f2a7"; }

.fa-sign-out:before {
  content: "\f08b"; }

.fa-sign-out-alt:before {
  content: "\f2f5"; }

.fa-signal:before {
  content: "\f012"; }

.fa-signal-1:before {
  content: "\f68c"; }

.fa-signal-2:before {
  content: "\f68d"; }

.fa-signal-3:before {
  content: "\f68e"; }

.fa-signal-4:before {
  content: "\f68f"; }

.fa-signal-alt:before {
  content: "\f690"; }

.fa-signal-alt-1:before {
  content: "\f691"; }

.fa-signal-alt-2:before {
  content: "\f692"; }

.fa-signal-alt-3:before {
  content: "\f693"; }

.fa-signal-alt-slash:before {
  content: "\f694"; }

.fa-signal-slash:before {
  content: "\f695"; }

.fa-signature:before {
  content: "\f5b7"; }

.fa-sim-card:before {
  content: "\f7c4"; }

.fa-simplybuilt:before {
  content: "\f215"; }

.fa-sistrix:before {
  content: "\f3ee"; }

.fa-sitemap:before {
  content: "\f0e8"; }

.fa-sith:before {
  content: "\f512"; }

.fa-skating:before {
  content: "\f7c5"; }

.fa-skeleton:before {
  content: "\f620"; }

.fa-sketch:before {
  content: "\f7c6"; }

.fa-ski-jump:before {
  content: "\f7c7"; }

.fa-ski-lift:before {
  content: "\f7c8"; }

.fa-skiing:before {
  content: "\f7c9"; }

.fa-skiing-nordic:before {
  content: "\f7ca"; }

.fa-skull:before {
  content: "\f54c"; }

.fa-skull-crossbones:before {
  content: "\f714"; }

.fa-skyatlas:before {
  content: "\f216"; }

.fa-skype:before {
  content: "\f17e"; }

.fa-slack:before {
  content: "\f198"; }

.fa-slack-hash:before {
  content: "\f3ef"; }

.fa-slash:before {
  content: "\f715"; }

.fa-sledding:before {
  content: "\f7cb"; }

.fa-sleigh:before {
  content: "\f7cc"; }

.fa-sliders-h:before {
  content: "\f1de"; }

.fa-sliders-h-square:before {
  content: "\f3f0"; }

.fa-sliders-v:before {
  content: "\f3f1"; }

.fa-sliders-v-square:before {
  content: "\f3f2"; }

.fa-slideshare:before {
  content: "\f1e7"; }

.fa-smile:before {
  content: "\f118"; }

.fa-smile-beam:before {
  content: "\f5b8"; }

.fa-smile-plus:before {
  content: "\f5b9"; }

.fa-smile-wink:before {
  content: "\f4da"; }

.fa-smog:before {
  content: "\f75f"; }

.fa-smoke:before {
  content: "\f760"; }

.fa-smoking:before {
  content: "\f48d"; }

.fa-smoking-ban:before {
  content: "\f54d"; }

.fa-sms:before {
  content: "\f7cd"; }

.fa-snake:before {
  content: "\f716"; }

.fa-snapchat:before {
  content: "\f2ab"; }

.fa-snapchat-ghost:before {
  content: "\f2ac"; }

.fa-snapchat-square:before {
  content: "\f2ad"; }

.fa-snooze:before {
  content: "\f880"; }

.fa-snow-blowing:before {
  content: "\f761"; }

.fa-snowboarding:before {
  content: "\f7ce"; }

.fa-snowflake:before {
  content: "\f2dc"; }

.fa-snowflakes:before {
  content: "\f7cf"; }

.fa-snowman:before {
  content: "\f7d0"; }

.fa-snowmobile:before {
  content: "\f7d1"; }

.fa-snowplow:before {
  content: "\f7d2"; }

.fa-socks:before {
  content: "\f696"; }

.fa-solar-panel:before {
  content: "\f5ba"; }

.fa-sort:before {
  content: "\f0dc"; }

.fa-sort-alpha-down:before {
  content: "\f15d"; }

.fa-sort-alpha-down-alt:before {
  content: "\f881"; }

.fa-sort-alpha-up:before {
  content: "\f15e"; }

.fa-sort-alpha-up-alt:before {
  content: "\f882"; }

.fa-sort-alt:before {
  content: "\f883"; }

.fa-sort-amount-down:before {
  content: "\f160"; }

.fa-sort-amount-down-alt:before {
  content: "\f884"; }

.fa-sort-amount-up:before {
  content: "\f161"; }

.fa-sort-amount-up-alt:before {
  content: "\f885"; }

.fa-sort-down:before {
  content: "\f0dd"; }

.fa-sort-numeric-down:before {
  content: "\f162"; }

.fa-sort-numeric-down-alt:before {
  content: "\f886"; }

.fa-sort-numeric-up:before {
  content: "\f163"; }

.fa-sort-numeric-up-alt:before {
  content: "\f887"; }

.fa-sort-shapes-down:before {
  content: "\f888"; }

.fa-sort-shapes-down-alt:before {
  content: "\f889"; }

.fa-sort-shapes-up:before {
  content: "\f88a"; }

.fa-sort-shapes-up-alt:before {
  content: "\f88b"; }

.fa-sort-size-down:before {
  content: "\f88c"; }

.fa-sort-size-down-alt:before {
  content: "\f88d"; }

.fa-sort-size-up:before {
  content: "\f88e"; }

.fa-sort-size-up-alt:before {
  content: "\f88f"; }

.fa-sort-up:before {
  content: "\f0de"; }

.fa-soundcloud:before {
  content: "\f1be"; }

.fa-soup:before {
  content: "\f823"; }

.fa-sourcetree:before {
  content: "\f7d3"; }

.fa-spa:before {
  content: "\f5bb"; }

.fa-space-shuttle:before {
  content: "\f197"; }

.fa-spade:before {
  content: "\f2f4"; }

.fa-sparkles:before {
  content: "\f890"; }

.fa-speakap:before {
  content: "\f3f3"; }

.fa-speaker-deck:before {
  content: "\f83c"; }

.fa-spell-check:before {
  content: "\f891"; }

.fa-spider:before {
  content: "\f717"; }

.fa-spider-black-widow:before {
  content: "\f718"; }

.fa-spider-web:before {
  content: "\f719"; }

.fa-spinner:before {
  content: "\f110"; }

.fa-spinner-third:before {
  content: "\f3f4"; }

.fa-splotch:before {
  content: "\f5bc"; }

.fa-spotify:before {
  content: "\f1bc"; }

.fa-spray-can:before {
  content: "\f5bd"; }

.fa-square:before {
  content: "\f0c8"; }

.fa-square-full:before {
  content: "\f45c"; }

.fa-square-root:before {
  content: "\f697"; }

.fa-square-root-alt:before {
  content: "\f698"; }

.fa-squarespace:before {
  content: "\f5be"; }

.fa-squirrel:before {
  content: "\f71a"; }

.fa-stack-exchange:before {
  content: "\f18d"; }

.fa-stack-overflow:before {
  content: "\f16c"; }

.fa-stackpath:before {
  content: "\f842"; }

.fa-staff:before {
  content: "\f71b"; }

.fa-stamp:before {
  content: "\f5bf"; }

.fa-star:before {
  content: "\f005"; }

.fa-star-and-crescent:before {
  content: "\f699"; }

.fa-star-christmas:before {
  content: "\f7d4"; }

.fa-star-exclamation:before {
  content: "\f2f3"; }

.fa-star-half:before {
  content: "\f089"; }

.fa-star-half-alt:before {
  content: "\f5c0"; }

.fa-star-of-david:before {
  content: "\f69a"; }

.fa-star-of-life:before {
  content: "\f621"; }

.fa-stars:before {
  content: "\f762"; }

.fa-staylinked:before {
  content: "\f3f5"; }

.fa-steak:before {
  content: "\f824"; }

.fa-steam:before {
  content: "\f1b6"; }

.fa-steam-square:before {
  content: "\f1b7"; }

.fa-steam-symbol:before {
  content: "\f3f6"; }

.fa-steering-wheel:before {
  content: "\f622"; }

.fa-step-backward:before {
  content: "\f048"; }

.fa-step-forward:before {
  content: "\f051"; }

.fa-stethoscope:before {
  content: "\f0f1"; }

.fa-sticker-mule:before {
  content: "\f3f7"; }

.fa-sticky-note:before {
  content: "\f249"; }

.fa-stocking:before {
  content: "\f7d5"; }

.fa-stomach:before {
  content: "\f623"; }

.fa-stop:before {
  content: "\f04d"; }

.fa-stop-circle:before {
  content: "\f28d"; }

.fa-stopwatch:before {
  content: "\f2f2"; }

.fa-store:before {
  content: "\f54e"; }

.fa-store-alt:before {
  content: "\f54f"; }

.fa-strava:before {
  content: "\f428"; }

.fa-stream:before {
  content: "\f550"; }

.fa-street-view:before {
  content: "\f21d"; }

.fa-stretcher:before {
  content: "\f825"; }

.fa-strikethrough:before {
  content: "\f0cc"; }

.fa-stripe:before {
  content: "\f429"; }

.fa-stripe-s:before {
  content: "\f42a"; }

.fa-stroopwafel:before {
  content: "\f551"; }

.fa-studiovinari:before {
  content: "\f3f8"; }

.fa-stumbleupon:before {
  content: "\f1a4"; }

.fa-stumbleupon-circle:before {
  content: "\f1a3"; }

.fa-subscript:before {
  content: "\f12c"; }

.fa-subway:before {
  content: "\f239"; }

.fa-suitcase:before {
  content: "\f0f2"; }

.fa-suitcase-rolling:before {
  content: "\f5c1"; }

.fa-sun:before {
  content: "\f185"; }

.fa-sun-cloud:before {
  content: "\f763"; }

.fa-sun-dust:before {
  content: "\f764"; }

.fa-sun-haze:before {
  content: "\f765"; }

.fa-sunglasses:before {
  content: "\f892"; }

.fa-sunrise:before {
  content: "\f766"; }

.fa-sunset:before {
  content: "\f767"; }

.fa-superpowers:before {
  content: "\f2dd"; }

.fa-superscript:before {
  content: "\f12b"; }

.fa-supple:before {
  content: "\f3f9"; }

.fa-surprise:before {
  content: "\f5c2"; }

.fa-suse:before {
  content: "\f7d6"; }

.fa-swatchbook:before {
  content: "\f5c3"; }

.fa-swimmer:before {
  content: "\f5c4"; }

.fa-swimming-pool:before {
  content: "\f5c5"; }

.fa-sword:before {
  content: "\f71c"; }

.fa-swords:before {
  content: "\f71d"; }

.fa-symfony:before {
  content: "\f83d"; }

.fa-synagogue:before {
  content: "\f69b"; }

.fa-sync:before {
  content: "\f021"; }

.fa-sync-alt:before {
  content: "\f2f1"; }

.fa-syringe:before {
  content: "\f48e"; }

.fa-table:before {
  content: "\f0ce"; }

.fa-table-tennis:before {
  content: "\f45d"; }

.fa-tablet:before {
  content: "\f10a"; }

.fa-tablet-alt:before {
  content: "\f3fa"; }

.fa-tablet-android:before {
  content: "\f3fb"; }

.fa-tablet-android-alt:before {
  content: "\f3fc"; }

.fa-tablet-rugged:before {
  content: "\f48f"; }

.fa-tablets:before {
  content: "\f490"; }

.fa-tachometer:before {
  content: "\f0e4"; }

.fa-tachometer-alt:before {
  content: "\f3fd"; }

.fa-tachometer-alt-average:before {
  content: "\f624"; }

.fa-tachometer-alt-fast:before {
  content: "\f625"; }

.fa-tachometer-alt-fastest:before {
  content: "\f626"; }

.fa-tachometer-alt-slow:before {
  content: "\f627"; }

.fa-tachometer-alt-slowest:before {
  content: "\f628"; }

.fa-tachometer-average:before {
  content: "\f629"; }

.fa-tachometer-fast:before {
  content: "\f62a"; }

.fa-tachometer-fastest:before {
  content: "\f62b"; }

.fa-tachometer-slow:before {
  content: "\f62c"; }

.fa-tachometer-slowest:before {
  content: "\f62d"; }

.fa-taco:before {
  content: "\f826"; }

.fa-tag:before {
  content: "\f02b"; }

.fa-tags:before {
  content: "\f02c"; }

.fa-tally:before {
  content: "\f69c"; }

.fa-tanakh:before {
  content: "\f827"; }

.fa-tape:before {
  content: "\f4db"; }

.fa-tasks:before {
  content: "\f0ae"; }

.fa-tasks-alt:before {
  content: "\f828"; }

.fa-taxi:before {
  content: "\f1ba"; }

.fa-teamspeak:before {
  content: "\f4f9"; }

.fa-teeth:before {
  content: "\f62e"; }

.fa-teeth-open:before {
  content: "\f62f"; }

.fa-telegram:before {
  content: "\f2c6"; }

.fa-telegram-plane:before {
  content: "\f3fe"; }

.fa-temperature-frigid:before {
  content: "\f768"; }

.fa-temperature-high:before {
  content: "\f769"; }

.fa-temperature-hot:before {
  content: "\f76a"; }

.fa-temperature-low:before {
  content: "\f76b"; }

.fa-tencent-weibo:before {
  content: "\f1d5"; }

.fa-tenge:before {
  content: "\f7d7"; }

.fa-tennis-ball:before {
  content: "\f45e"; }

.fa-terminal:before {
  content: "\f120"; }

.fa-text:before {
  content: "\f893"; }

.fa-text-height:before {
  content: "\f034"; }

.fa-text-size:before {
  content: "\f894"; }

.fa-text-width:before {
  content: "\f035"; }

.fa-th:before {
  content: "\f00a"; }

.fa-th-large:before {
  content: "\f009"; }

.fa-th-list:before {
  content: "\f00b"; }

.fa-the-red-yeti:before {
  content: "\f69d"; }

.fa-theater-masks:before {
  content: "\f630"; }

.fa-themeco:before {
  content: "\f5c6"; }

.fa-themeisle:before {
  content: "\f2b2"; }

.fa-thermometer:before {
  content: "\f491"; }

.fa-thermometer-empty:before {
  content: "\f2cb"; }

.fa-thermometer-full:before {
  content: "\f2c7"; }

.fa-thermometer-half:before {
  content: "\f2c9"; }

.fa-thermometer-quarter:before {
  content: "\f2ca"; }

.fa-thermometer-three-quarters:before {
  content: "\f2c8"; }

.fa-theta:before {
  content: "\f69e"; }

.fa-think-peaks:before {
  content: "\f731"; }

.fa-thumbs-down:before {
  content: "\f165"; }

.fa-thumbs-up:before {
  content: "\f164"; }

.fa-thumbtack:before {
  content: "\f08d"; }

.fa-thunderstorm:before {
  content: "\f76c"; }

.fa-thunderstorm-moon:before {
  content: "\f76d"; }

.fa-thunderstorm-sun:before {
  content: "\f76e"; }

.fa-ticket:before {
  content: "\f145"; }

.fa-ticket-alt:before {
  content: "\f3ff"; }

.fa-tilde:before {
  content: "\f69f"; }

.fa-times:before {
  content: "\f00d"; }

.fa-times-circle:before {
  content: "\f057"; }

.fa-times-hexagon:before {
  content: "\f2ee"; }

.fa-times-octagon:before {
  content: "\f2f0"; }

.fa-times-square:before {
  content: "\f2d3"; }

.fa-tint:before {
  content: "\f043"; }

.fa-tint-slash:before {
  content: "\f5c7"; }

.fa-tire:before {
  content: "\f631"; }

.fa-tire-flat:before {
  content: "\f632"; }

.fa-tire-pressure-warning:before {
  content: "\f633"; }

.fa-tire-rugged:before {
  content: "\f634"; }

.fa-tired:before {
  content: "\f5c8"; }

.fa-toggle-off:before {
  content: "\f204"; }

.fa-toggle-on:before {
  content: "\f205"; }

.fa-toilet:before {
  content: "\f7d8"; }

.fa-toilet-paper:before {
  content: "\f71e"; }

.fa-toilet-paper-alt:before {
  content: "\f71f"; }

.fa-tombstone:before {
  content: "\f720"; }

.fa-tombstone-alt:before {
  content: "\f721"; }

.fa-toolbox:before {
  content: "\f552"; }

.fa-tools:before {
  content: "\f7d9"; }

.fa-tooth:before {
  content: "\f5c9"; }

.fa-toothbrush:before {
  content: "\f635"; }

.fa-torah:before {
  content: "\f6a0"; }

.fa-torii-gate:before {
  content: "\f6a1"; }

.fa-tornado:before {
  content: "\f76f"; }

.fa-tractor:before {
  content: "\f722"; }

.fa-trade-federation:before {
  content: "\f513"; }

.fa-trademark:before {
  content: "\f25c"; }

.fa-traffic-cone:before {
  content: "\f636"; }

.fa-traffic-light:before {
  content: "\f637"; }

.fa-traffic-light-go:before {
  content: "\f638"; }

.fa-traffic-light-slow:before {
  content: "\f639"; }

.fa-traffic-light-stop:before {
  content: "\f63a"; }

.fa-train:before {
  content: "\f238"; }

.fa-tram:before {
  content: "\f7da"; }

.fa-transgender:before {
  content: "\f224"; }

.fa-transgender-alt:before {
  content: "\f225"; }

.fa-trash:before {
  content: "\f1f8"; }

.fa-trash-alt:before {
  content: "\f2ed"; }

.fa-trash-restore:before {
  content: "\f829"; }

.fa-trash-restore-alt:before {
  content: "\f82a"; }

.fa-trash-undo:before {
  content: "\f895"; }

.fa-trash-undo-alt:before {
  content: "\f896"; }

.fa-treasure-chest:before {
  content: "\f723"; }

.fa-tree:before {
  content: "\f1bb"; }

.fa-tree-alt:before {
  content: "\f400"; }

.fa-tree-christmas:before {
  content: "\f7db"; }

.fa-tree-decorated:before {
  content: "\f7dc"; }

.fa-tree-large:before {
  content: "\f7dd"; }

.fa-tree-palm:before {
  content: "\f82b"; }

.fa-trees:before {
  content: "\f724"; }

.fa-trello:before {
  content: "\f181"; }

.fa-triangle:before {
  content: "\f2ec"; }

.fa-tripadvisor:before {
  content: "\f262"; }

.fa-trophy:before {
  content: "\f091"; }

.fa-trophy-alt:before {
  content: "\f2eb"; }

.fa-truck:before {
  content: "\f0d1"; }

.fa-truck-container:before {
  content: "\f4dc"; }

.fa-truck-couch:before {
  content: "\f4dd"; }

.fa-truck-loading:before {
  content: "\f4de"; }

.fa-truck-monster:before {
  content: "\f63b"; }

.fa-truck-moving:before {
  content: "\f4df"; }

.fa-truck-pickup:before {
  content: "\f63c"; }

.fa-truck-plow:before {
  content: "\f7de"; }

.fa-truck-ramp:before {
  content: "\f4e0"; }

.fa-tshirt:before {
  content: "\f553"; }

.fa-tty:before {
  content: "\f1e4"; }

.fa-tumblr:before {
  content: "\f173"; }

.fa-tumblr-square:before {
  content: "\f174"; }

.fa-turkey:before {
  content: "\f725"; }

.fa-turtle:before {
  content: "\f726"; }

.fa-tv:before {
  content: "\f26c"; }

.fa-tv-retro:before {
  content: "\f401"; }

.fa-twitch:before {
  content: "\f1e8"; }

.fa-twitter:before {
  content: "\f099"; }

.fa-twitter-square:before {
  content: "\f081"; }

.fa-typo3:before {
  content: "\f42b"; }

.fa-uber:before {
  content: "\f402"; }

.fa-ubuntu:before {
  content: "\f7df"; }

.fa-uikit:before {
  content: "\f403"; }

.fa-umbrella:before {
  content: "\f0e9"; }

.fa-umbrella-beach:before {
  content: "\f5ca"; }

.fa-underline:before {
  content: "\f0cd"; }

.fa-undo:before {
  content: "\f0e2"; }

.fa-undo-alt:before {
  content: "\f2ea"; }

.fa-unicorn:before {
  content: "\f727"; }

.fa-union:before {
  content: "\f6a2"; }

.fa-uniregistry:before {
  content: "\f404"; }

.fa-universal-access:before {
  content: "\f29a"; }

.fa-university:before {
  content: "\f19c"; }

.fa-unlink:before {
  content: "\f127"; }

.fa-unlock:before {
  content: "\f09c"; }

.fa-unlock-alt:before {
  content: "\f13e"; }

.fa-untappd:before {
  content: "\f405"; }

.fa-upload:before {
  content: "\f093"; }

.fa-ups:before {
  content: "\f7e0"; }

.fa-usb:before {
  content: "\f287"; }

.fa-usd-circle:before {
  content: "\f2e8"; }

.fa-usd-square:before {
  content: "\f2e9"; }

.fa-user:before {
  content: "\f007"; }

.fa-user-alt:before {
  content: "\f406"; }

.fa-user-alt-slash:before {
  content: "\f4fa"; }

.fa-user-astronaut:before {
  content: "\f4fb"; }

.fa-user-chart:before {
  content: "\f6a3"; }

.fa-user-check:before {
  content: "\f4fc"; }

.fa-user-circle:before {
  content: "\f2bd"; }

.fa-user-clock:before {
  content: "\f4fd"; }

.fa-user-cog:before {
  content: "\f4fe"; }

.fa-user-crown:before {
  content: "\f6a4"; }

.fa-user-edit:before {
  content: "\f4ff"; }

.fa-user-friends:before {
  content: "\f500"; }

.fa-user-graduate:before {
  content: "\f501"; }

.fa-user-hard-hat:before {
  content: "\f82c"; }

.fa-user-headset:before {
  content: "\f82d"; }

.fa-user-injured:before {
  content: "\f728"; }

.fa-user-lock:before {
  content: "\f502"; }

.fa-user-md:before {
  content: "\f0f0"; }

.fa-user-md-chat:before {
  content: "\f82e"; }

.fa-user-minus:before {
  content: "\f503"; }

.fa-user-ninja:before {
  content: "\f504"; }

.fa-user-nurse:before {
  content: "\f82f"; }

.fa-user-plus:before {
  content: "\f234"; }

.fa-user-secret:before {
  content: "\f21b"; }

.fa-user-shield:before {
  content: "\f505"; }

.fa-user-slash:before {
  content: "\f506"; }

.fa-user-tag:before {
  content: "\f507"; }

.fa-user-tie:before {
  content: "\f508"; }

.fa-user-times:before {
  content: "\f235"; }

.fa-users:before {
  content: "\f0c0"; }

.fa-users-class:before {
  content: "\f63d"; }

.fa-users-cog:before {
  content: "\f509"; }

.fa-users-crown:before {
  content: "\f6a5"; }

.fa-users-medical:before {
  content: "\f830"; }

.fa-usps:before {
  content: "\f7e1"; }

.fa-ussunnah:before {
  content: "\f407"; }

.fa-utensil-fork:before {
  content: "\f2e3"; }

.fa-utensil-knife:before {
  content: "\f2e4"; }

.fa-utensil-spoon:before {
  content: "\f2e5"; }

.fa-utensils:before {
  content: "\f2e7"; }

.fa-utensils-alt:before {
  content: "\f2e6"; }

.fa-vaadin:before {
  content: "\f408"; }

.fa-value-absolute:before {
  content: "\f6a6"; }

.fa-vector-square:before {
  content: "\f5cb"; }

.fa-venus:before {
  content: "\f221"; }

.fa-venus-double:before {
  content: "\f226"; }

.fa-venus-mars:before {
  content: "\f228"; }

.fa-viacoin:before {
  content: "\f237"; }

.fa-viadeo:before {
  content: "\f2a9"; }

.fa-viadeo-square:before {
  content: "\f2aa"; }

.fa-vial:before {
  content: "\f492"; }

.fa-vials:before {
  content: "\f493"; }

.fa-viber:before {
  content: "\f409"; }

.fa-video:before {
  content: "\f03d"; }

.fa-video-plus:before {
  content: "\f4e1"; }

.fa-video-slash:before {
  content: "\f4e2"; }

.fa-vihara:before {
  content: "\f6a7"; }

.fa-vimeo:before {
  content: "\f40a"; }

.fa-vimeo-square:before {
  content: "\f194"; }

.fa-vimeo-v:before {
  content: "\f27d"; }

.fa-vine:before {
  content: "\f1ca"; }

.fa-vk:before {
  content: "\f189"; }

.fa-vnv:before {
  content: "\f40b"; }

.fa-voicemail:before {
  content: "\f897"; }

.fa-volcano:before {
  content: "\f770"; }

.fa-volleyball-ball:before {
  content: "\f45f"; }

.fa-volume:before {
  content: "\f6a8"; }

.fa-volume-down:before {
  content: "\f027"; }

.fa-volume-mute:before {
  content: "\f6a9"; }

.fa-volume-off:before {
  content: "\f026"; }

.fa-volume-slash:before {
  content: "\f2e2"; }

.fa-volume-up:before {
  content: "\f028"; }

.fa-vote-nay:before {
  content: "\f771"; }

.fa-vote-yea:before {
  content: "\f772"; }

.fa-vr-cardboard:before {
  content: "\f729"; }

.fa-vuejs:before {
  content: "\f41f"; }

.fa-walker:before {
  content: "\f831"; }

.fa-walking:before {
  content: "\f554"; }

.fa-wallet:before {
  content: "\f555"; }

.fa-wand:before {
  content: "\f72a"; }

.fa-wand-magic:before {
  content: "\f72b"; }

.fa-warehouse:before {
  content: "\f494"; }

.fa-warehouse-alt:before {
  content: "\f495"; }

.fa-washer:before {
  content: "\f898"; }

.fa-watch:before {
  content: "\f2e1"; }

.fa-watch-fitness:before {
  content: "\f63e"; }

.fa-water:before {
  content: "\f773"; }

.fa-water-lower:before {
  content: "\f774"; }

.fa-water-rise:before {
  content: "\f775"; }

.fa-wave-sine:before {
  content: "\f899"; }

.fa-wave-square:before {
  content: "\f83e"; }

.fa-wave-triangle:before {
  content: "\f89a"; }

.fa-waze:before {
  content: "\f83f"; }

.fa-webcam:before {
  content: "\f832"; }

.fa-webcam-slash:before {
  content: "\f833"; }

.fa-weebly:before {
  content: "\f5cc"; }

.fa-weibo:before {
  content: "\f18a"; }

.fa-weight:before {
  content: "\f496"; }

.fa-weight-hanging:before {
  content: "\f5cd"; }

.fa-weixin:before {
  content: "\f1d7"; }

.fa-whale:before {
  content: "\f72c"; }

.fa-whatsapp:before {
  content: "\f232"; }

.fa-whatsapp-square:before {
  content: "\f40c"; }

.fa-wheat:before {
  content: "\f72d"; }

.fa-wheelchair:before {
  content: "\f193"; }

.fa-whistle:before {
  content: "\f460"; }

.fa-whmcs:before {
  content: "\f40d"; }

.fa-wifi:before {
  content: "\f1eb"; }

.fa-wifi-1:before {
  content: "\f6aa"; }

.fa-wifi-2:before {
  content: "\f6ab"; }

.fa-wifi-slash:before {
  content: "\f6ac"; }

.fa-wikipedia-w:before {
  content: "\f266"; }

.fa-wind:before {
  content: "\f72e"; }

.fa-wind-turbine:before {
  content: "\f89b"; }

.fa-wind-warning:before {
  content: "\f776"; }

.fa-window:before {
  content: "\f40e"; }

.fa-window-alt:before {
  content: "\f40f"; }

.fa-window-close:before {
  content: "\f410"; }

.fa-window-maximize:before {
  content: "\f2d0"; }

.fa-window-minimize:before {
  content: "\f2d1"; }

.fa-window-restore:before {
  content: "\f2d2"; }

.fa-windows:before {
  content: "\f17a"; }

.fa-windsock:before {
  content: "\f777"; }

.fa-wine-bottle:before {
  content: "\f72f"; }

.fa-wine-glass:before {
  content: "\f4e3"; }

.fa-wine-glass-alt:before {
  content: "\f5ce"; }

.fa-wix:before {
  content: "\f5cf"; }

.fa-wizards-of-the-coast:before {
  content: "\f730"; }

.fa-wolf-pack-battalion:before {
  content: "\f514"; }

.fa-won-sign:before {
  content: "\f159"; }

.fa-wordpress:before {
  content: "\f19a"; }

.fa-wordpress-simple:before {
  content: "\f411"; }

.fa-wpbeginner:before {
  content: "\f297"; }

.fa-wpexplorer:before {
  content: "\f2de"; }

.fa-wpforms:before {
  content: "\f298"; }

.fa-wpressr:before {
  content: "\f3e4"; }

.fa-wreath:before {
  content: "\f7e2"; }

.fa-wrench:before {
  content: "\f0ad"; }

.fa-x-ray:before {
  content: "\f497"; }

.fa-xbox:before {
  content: "\f412"; }

.fa-xing:before {
  content: "\f168"; }

.fa-xing-square:before {
  content: "\f169"; }

.fa-y-combinator:before {
  content: "\f23b"; }

.fa-yahoo:before {
  content: "\f19e"; }

.fa-yammer:before {
  content: "\f840"; }

.fa-yandex:before {
  content: "\f413"; }

.fa-yandex-international:before {
  content: "\f414"; }

.fa-yarn:before {
  content: "\f7e3"; }

.fa-yelp:before {
  content: "\f1e9"; }

.fa-yen-sign:before {
  content: "\f157"; }

.fa-yin-yang:before {
  content: "\f6ad"; }

.fa-yoast:before {
  content: "\f2b1"; }

.fa-youtube:before {
  content: "\f167"; }

.fa-youtube-square:before {
  content: "\f431"; }

.fa-zhihu:before {
  content: "\f63f"; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

/* ========================================================================
   Component: Base
 ========================================================================== */
/*
 * 1. Set `font-size` to support `rem` units
 *    Not using `font` property because a leading hyphen (e.g. -apple-system) causes the font to break in IE11 and Edge
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 * 3. Style
 */
html {
  /* 1 */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
  background: #fff;
  color: #666; }

/*
 * Remove the margin in all browsers.
 */
body {
  margin: 0; }

/* Links
 ========================================================================== */
/*
 * Remove the outline on focused links when they are also active or hovered
 */
a:active,
a:hover {
  outline: none; }

/*
 * Style
 */
a,
.uk-link {
  color: #1e87f0;
  text-decoration: none;
  cursor: pointer; }

a:hover,
.uk-link:hover,
.uk-link-toggle:hover .uk-link,
.uk-link-toggle:focus .uk-link {
  color: #0f6ecd;
  text-decoration: underline; }

/* Text-level semantics
 ========================================================================== */
/*
 * 1. Add the correct text decoration in Edge.
 * 2. The shorthand declaration `underline dotted` is not supported in Safari.
 */
abbr[title] {
  /* 1 */
  text-decoration: underline dotted;
  /* 2 */
  -webkit-text-decoration-style: dotted; }

/*
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/*
 * 1. Consolas has a better baseline in running text compared to `Courier`
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Style
 */
:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  /* 1 */
  font-family: Consolas, monaco, monospace;
  /* 2 */
  font-size: 0.875rem;
  /* 3 */
  color: #f0506e;
  white-space: nowrap; }

/*
 * Emphasize
 */
em {
  color: #f0506e; }

/*
 * Insert
 */
ins {
  background: #ffd;
  color: #666;
  text-decoration: none; }

/*
 * Mark
 */
mark {
  background: #ffd;
  color: #666; }

/*
 * Quote
 */
q {
  font-style: italic; }

/*
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/*
 * Prevents `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
 ========================================================================== */
/*
 * Remove the gap between embedded content and the bottom of their containers.
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/*
 * 1. Add responsiveness.
 * 2. Auto-scale the height. Only needed if `height` attribute is present.
 * 3. Corrects responsive `max-width` behavior if padding and border are used.
 * 4. Exclude SVGs for IE11 because they don't preserve their aspect ratio.
 */
canvas,
img,
video {
  /* 1 */
  max-width: 100%;
  /* 2 */
  height: auto;
  /* 3 */
  box-sizing: border-box; }

/* 4 */
@supports (display: block) {
  svg {
    max-width: 100%;
    height: auto;
    box-sizing: border-box; } }

/*
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/*
 * Hide `alt` text for lazy load images
 * Selector for background while loading img[data-src*='.jpg'][src*='data:image'] { background: grey; }
 */
img:not([src]) {
  visibility: hidden; }

/*
 * Iframe
 * Remove border in all browsers
 */
iframe {
  border: 0; }

/* Block elements
 ========================================================================== */
/*
 * Margins
 */
p,
ul,
ol,
dl,
pre,
address,
fieldset,
figure {
  margin: 0 0 20px 0; }

/* Add margin if adjacent element */
* + p,
* + ul,
* + ol,
* + dl,
* + pre,
* + address,
* + fieldset,
* + figure {
  margin-top: 20px; }

/* Headings
 ========================================================================== */
h1, .uk-h1,
h2, .uk-h2,
h3, .uk-h3,
h4, .uk-h4,
h5, .uk-h5,
h6, .uk-h6,
.uk-heading-small,
.uk-heading-medium,
.uk-heading-large,
.uk-heading-xlarge,
.uk-heading-2xlarge {
  margin: 0 0 20px 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  color: #333;
  text-transform: none; }

/* Add margin if adjacent element */
* + h1, * + .uk-h1,
* + h2, * + .uk-h2,
* + h3, * + .uk-h3,
* + h4, * + .uk-h4,
* + h5, * + .uk-h5,
* + h6, * + .uk-h6,
* + .uk-heading-small,
* + .uk-heading-medium,
* + .uk-heading-large,
* + .uk-heading-xlarge,
* + .uk-heading-2xlarge {
  margin-top: 40px; }

/*
 * Sizes
 */
h1, .uk-h1 {
  font-size: 2.23125rem;
  line-height: 1.2; }

h2, .uk-h2 {
  font-size: 1.7rem;
  line-height: 1.3; }

h3, .uk-h3 {
  font-size: 1.5rem;
  line-height: 1.4; }

h4, .uk-h4 {
  font-size: 1.25rem;
  line-height: 1.4; }

h5, .uk-h5 {
  font-size: 16px;
  line-height: 1.4; }

h6, .uk-h6 {
  font-size: 0.875rem;
  line-height: 1.4; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  h1, .uk-h1 {
    font-size: 2.625rem; }
  h2, .uk-h2 {
    font-size: 2rem; } }

/* Lists
 ========================================================================== */
ul,
ol {
  padding-left: 30px; }

/*
 * Reset margin for nested lists
 */
ul > li > ul,
ul > li > ol,
ol > li > ol,
ol > li > ul {
  margin: 0; }

/* Description lists
 ========================================================================== */
dt {
  font-weight: bold; }

dd {
  margin-left: 0; }

/* Horizontal rules
 ========================================================================== */
/*
 * 1. Show the overflow in Chrome, Edge and IE.
 * 2. Add the correct text-align in Edge and IE.
 * 3. Style
 */
hr, .uk-hr {
  /* 1 */
  overflow: visible;
  /* 2 */
  text-align: inherit;
  /* 3 */
  margin: 0 0 20px 0;
  border: 0;
  border-top: 1px solid #e5e5e5; }

/* Add margin if adjacent element */
* + hr,
* + .uk-hr {
  margin-top: 20px; }

/* Address
 ========================================================================== */
address {
  font-style: normal; }

/* Blockquotes
 ========================================================================== */
blockquote {
  margin: 0 0 20px 0;
  font-size: 1.25rem;
  line-height: 1.5;
  font-style: italic; }

/* Add margin if adjacent element */
* + blockquote {
  margin-top: 20px; }

/*
 * Content
 */
blockquote p:last-of-type {
  margin-bottom: 0; }

blockquote footer {
  margin-top: 10px;
  font-size: 0.875rem;
  line-height: 1.5; }

/* Preformatted text
 ========================================================================== */
/*
 * 1. Contain overflow in all browsers.
 */
pre {
  font: 0.875rem / 1.5 Consolas, monaco, monospace;
  color: #666;
  -moz-tab-size: 4;
  tab-size: 4;
  /* 1 */
  overflow: auto; }

pre code {
  font-family: Consolas, monaco, monospace; }

/* Selection pseudo-element
 ========================================================================== */
::selection {
  background: #39f;
  color: #fff;
  text-shadow: none; }

/* HTML5 elements
 ========================================================================== */
/*
 * 1. Add the correct display in Edge, IE 10+, and Firefox.
 * 2. Add the correct display in IE.
 */
details,
main {
  /* 2 */
  display: block; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/*
 * Add the correct display in IE.
 */
template {
  display: none; }

/* Pass media breakpoints to JS
 ========================================================================== */
/*
 * Breakpoints
 */
.uk-breakpoint-s::before {
  content: "640px"; }

.uk-breakpoint-m::before {
  content: "960px"; }

.uk-breakpoint-l::before {
  content: "1200px"; }

.uk-breakpoint-xl::before {
  content: "1600px"; }

:root {
  --uk-breakpoint-s: 640px;
  --uk-breakpoint-m: 960px;
  --uk-breakpoint-l: 1200px;
  --uk-breakpoint-xl: 1600px; }

/* ========================================================================
   Component: Link
 ========================================================================== */
/* Muted
 ========================================================================== */
a.uk-link-muted,
.uk-link-muted a {
  color: #999; }

a.uk-link-muted:hover,
.uk-link-muted a:hover,
.uk-link-toggle:hover .uk-link-muted,
.uk-link-toggle:focus .uk-link-muted {
  color: #666; }

/* Text
 ========================================================================== */
a.uk-link-text,
.uk-link-text a {
  color: inherit; }

a.uk-link-text:hover,
.uk-link-text a:hover,
.uk-link-toggle:hover .uk-link-text,
.uk-link-toggle:focus .uk-link-text {
  color: #999; }

/* Heading
 ========================================================================== */
a.uk-link-heading,
.uk-link-heading a {
  color: inherit; }

a.uk-link-heading:hover,
.uk-link-heading a:hover,
.uk-link-toggle:hover .uk-link-heading,
.uk-link-toggle:focus .uk-link-heading {
  color: #1e87f0;
  text-decoration: none; }

/* Reset
 ========================================================================== */
/*
 * `!important` needed to override inverse component
 */
a.uk-link-reset,
.uk-link-reset a {
  color: inherit !important;
  text-decoration: none !important; }

/* Toggle
 ========================================================================== */
.uk-link-toggle {
  color: inherit !important;
  text-decoration: none !important; }

.uk-link-toggle:focus {
  outline: none; }

/* ========================================================================
   Component: Heading
 ========================================================================== */
.uk-heading-small {
  font-size: 2.6rem;
  line-height: 1.2; }

.uk-heading-medium {
  font-size: 2.8875rem;
  line-height: 1.1; }

.uk-heading-large {
  font-size: 3.4rem;
  line-height: 1.1; }

.uk-heading-xlarge {
  font-size: 4rem;
  line-height: 1; }

.uk-heading-2xlarge {
  font-size: 6rem;
  line-height: 1; }

/* Tablet Landscape and bigger */
@media (min-width: 960px) {
  .uk-heading-small {
    font-size: 3.25rem; }
  .uk-heading-medium {
    font-size: 3.5rem; }
  .uk-heading-large {
    font-size: 4rem; }
  .uk-heading-xlarge {
    font-size: 6rem; }
  .uk-heading-2xlarge {
    font-size: 8rem; } }

/* Laptop and bigger */
@media (min-width: 1200px) {
  .uk-heading-medium {
    font-size: 4rem; }
  .uk-heading-large {
    font-size: 6rem; }
  .uk-heading-xlarge {
    font-size: 8rem; }
  .uk-heading-2xlarge {
    font-size: 11rem; } }

/* Primary
   Deprecated: Use `uk-heading-medium` instead
 ========================================================================== */
/* Tablet landscape and bigger */
/* Desktop and bigger */
/* Hero
   Deprecated: Use `uk-heading-xlarge` instead
 ========================================================================== */
/* Tablet landscape and bigger */
/* Desktop and bigger */
/* Divider
 ========================================================================== */
.uk-heading-divider {
  padding-bottom: calc(5px + 0.1em);
  border-bottom: calc(0.2px + 0.05em) solid #e5e5e5; }

/* Bullet
 ========================================================================== */
.uk-heading-bullet {
  position: relative; }

/*
 * 1. Using `inline-block` to make it work with text alignment
 * 2. Center vertically
 * 3. Style
 */
.uk-heading-bullet::before {
  content: "";
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  top: calc(-0.1 * 1em);
  vertical-align: middle;
  /* 3 */
  height: calc(4px + 0.7em);
  margin-right: calc(5px + 0.2em);
  border-left: calc(5px + 0.1em) solid #e5e5e5; }

/* Line
 ========================================================================== */
/*
 * Clip the child element
 */
.uk-heading-line {
  overflow: hidden; }

/*
 * Extra markup is needed to make it work with text align
 */
.uk-heading-line > * {
  display: inline-block;
  position: relative; }

/*
 * 1. Center vertically
 * 2. Make the element as large as possible. It's clipped by the container.
 * 3. Style
 */
.uk-heading-line > ::before,
.uk-heading-line > ::after {
  content: "";
  /* 1 */
  position: absolute;
  top: calc(50% - (calc(0.2px + 0.05em) / 2));
  /* 2 */
  width: 2000px;
  /* 3 */
  border-bottom: calc(0.2px + 0.05em) solid #e5e5e5; }

.uk-heading-line > ::before {
  right: 100%;
  margin-right: calc(5px + 0.3em); }

.uk-heading-line > ::after {
  left: 100%;
  margin-left: calc(5px + 0.3em); }

/* ========================================================================
   Component: Divider
 ========================================================================== */
/*
 * 1. Reset default `hr`
 * 2. Set margin if a `div` is used for semantical reason
 */
[class*='uk-divider'] {
  /* 1 */
  border: none;
  /* 2 */
  margin-bottom: 20px; }

/* Add margin if adjacent element */
* + [class*='uk-divider'] {
  margin-top: 20px; }

/* Icon
 ========================================================================== */
.uk-divider-icon {
  position: relative;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22%23e5e5e5%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-position: 50% 50%; }

.uk-divider-icon::before,
.uk-divider-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  max-width: calc(50% - (50px / 2));
  border-bottom: 1px solid #e5e5e5; }

.uk-divider-icon::before {
  right: calc(50% + (50px / 2));
  width: 100%; }

.uk-divider-icon::after {
  left: calc(50% + (50px / 2));
  width: 100%; }

/* Small
 ========================================================================== */
/*
 * 1. Fix height because of `inline-block`
 * 2. Using ::after and inline-block to make `text-align` work
 */
/* 1 */
.uk-divider-small {
  line-height: 0; }

/* 2 */
.uk-divider-small::after {
  content: "";
  display: inline-block;
  width: 100px;
  max-width: 100%;
  border-top: 1px solid #e5e5e5;
  vertical-align: top; }

/* Vertical
 ========================================================================== */
.uk-divider-vertical {
  width: 1px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px solid #e5e5e5; }

/* ========================================================================
   Component: List
 ========================================================================== */
.uk-list {
  padding: 0;
  list-style: none; }

/*
 * Micro clearfix
 */
.uk-list > li::before,
.uk-list > li::after {
  content: "";
  display: table; }

.uk-list > li::after {
  clear: both; }

/*
 * Remove margin from the last-child
 */
.uk-list > li > :last-child {
  margin-bottom: 0; }

/*
 * Nested lists
 */
.uk-list ul {
  margin: 0;
  padding-left: 30px;
  list-style: none; }

/*
 * Style
 */
.uk-list > li:nth-child(n+2),
.uk-list > li > ul {
  margin-top: 10px; }

/* Style modifiers
 ========================================================================== */
/*
 * Divider
 */
.uk-list-divider > li:nth-child(n+2) {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #e5e5e5; }

/*
 * Striped
 */
.uk-list-striped > li {
  padding: 10px 10px; }

.uk-list-striped > li:nth-of-type(odd) {
  background: #f8f8f8; }

.uk-list-striped > li:nth-child(n+2) {
  margin-top: 0; }

/*
 * Bullet
 * 1. Reset display `table` which causes issues in combination with multi column layouts.
 */
.uk-list-bullet > li {
  position: relative;
  padding-left: calc(1.5em + 10px); }

.uk-list-bullet > li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5em;
  height: 1.5em;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23666%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  /* 1 */
  display: block; }

/* Size modifier
 ========================================================================== */
.uk-list-large > li:nth-child(n+2),
.uk-list-large > li > ul {
  margin-top: 20px; }

/*
 * Divider
 */
.uk-list-large.uk-list-divider > li:nth-child(n+2) {
  margin-top: 20px;
  padding-top: 20px; }

/*
 * Striped
 */
.uk-list-large.uk-list-striped > li {
  padding: 20px 10px; }

.uk-list-large.uk-list-striped > li:nth-child(n+2) {
  margin-top: 0; }

/* ========================================================================
   Component: Description list
 ========================================================================== */
/*
 * Term
 */
.uk-description-list > dt {
  color: #333; }

.uk-description-list > dt:nth-child(n+2) {
  margin-top: 20px; }

/*
 * Description
 */
/* Style modifier
 ========================================================================== */
/*
 * Line
 */
.uk-description-list-divider > dt:nth-child(n+2) {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e5e5e5; }

/* ========================================================================
   Component: Table
 ========================================================================== */
/*
 * 1. Remove most spacing between table cells.
 * 2. Behave like a block element
 * 3. Style
 */
.uk-table {
  /* 1 */
  border-collapse: collapse;
  border-spacing: 0;
  /* 2 */
  width: 100%;
  /* 3 */
  margin-bottom: 20px; }

/* Add margin if adjacent element */
* + .uk-table {
  margin-top: 20px; }

/* Header cell
 ========================================================================== */
/*
 * 1. Style
 */
.uk-table th {
  padding: 16px 12px;
  text-align: left;
  vertical-align: bottom;
  /* 1 */
  font-size: 16px;
  font-weight: bold;
  color: #666; }

/* Cell
 ========================================================================== */
.uk-table td {
  padding: 16px 12px;
  vertical-align: top; }

/*
 * Remove margin from the last-child
 */
.uk-table td > :last-child {
  margin-bottom: 0; }

/* Footer
 ========================================================================== */
.uk-table tfoot {
  font-size: 0.875rem; }

/* Caption
 ========================================================================== */
.uk-table caption {
  font-size: 0.875rem;
  text-align: left;
  color: #999; }

/* Alignment modifier
 ========================================================================== */
.uk-table-middle,
.uk-table-middle td {
  vertical-align: middle !important; }

/* Style modifiers
 ========================================================================== */
/*
 * Divider
 */
.uk-table-divider > tr:not(:first-child),
.uk-table-divider > :not(:first-child) > tr,
.uk-table-divider > :first-child > tr:not(:first-child) {
  border-top: 1px solid #e5e5e5; }

/*
 * Striped
 */
.uk-table-striped > tr:nth-of-type(odd),
.uk-table-striped tbody tr:nth-of-type(odd) {
  background: #f8f8f8; }

/*
 * Hover
 */
.uk-table-hover > tr:hover,
.uk-table-hover tbody tr:hover {
  background: #ffd; }

/* Active state
 ========================================================================== */
.uk-table > tr.uk-active,
.uk-table tbody tr.uk-active {
  background: #ffd; }

/* Size modifier
 ========================================================================== */
.uk-table-small th,
.uk-table-small td {
  padding: 10px 12px; }

.uk-table-large th,
.uk-table-large td {
  padding: 22px 12px; }

/* Justify modifier
 ========================================================================== */
.uk-table-justify th:first-child,
.uk-table-justify td:first-child {
  padding-left: 0; }

.uk-table-justify th:last-child,
.uk-table-justify td:last-child {
  padding-right: 0; }

/* Cell size modifier
 ========================================================================== */
.uk-table-shrink {
  width: 1px; }

.uk-table-expand {
  min-width: 150px; }

/* Cell link modifier
 ========================================================================== */
/*
 * Does not work with `uk-table-justify` at the moment
 */
.uk-table-link {
  padding: 0 !important; }

.uk-table-link > a {
  display: block;
  padding: 16px 12px; }

.uk-table-small .uk-table-link > a {
  padding: 10px 12px; }

/* Responsive table
 ========================================================================== */
/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-table-responsive,
  .uk-table-responsive tbody,
  .uk-table-responsive th,
  .uk-table-responsive td,
  .uk-table-responsive tr {
    display: block; }
  .uk-table-responsive thead {
    display: none; }
  .uk-table-responsive th,
  .uk-table-responsive td {
    width: auto !important;
    max-width: none !important;
    min-width: 0 !important;
    overflow: visible !important;
    white-space: normal !important; }
  .uk-table-responsive th:not(:first-child):not(.uk-table-link),
  .uk-table-responsive td:not(:first-child):not(.uk-table-link),
  .uk-table-responsive .uk-table-link:not(:first-child) > a {
    padding-top: 5px !important; }
  .uk-table-responsive th:not(:last-child):not(.uk-table-link),
  .uk-table-responsive td:not(:last-child):not(.uk-table-link),
  .uk-table-responsive .uk-table-link:not(:last-child) > a {
    padding-bottom: 5px !important; }
  .uk-table-justify.uk-table-responsive th,
  .uk-table-justify.uk-table-responsive td {
    padding-left: 0;
    padding-right: 0; } }

/* ========================================================================
   Component: Icon
 ========================================================================== */
/*
 * Note: 1. - 7. is required for `button` elements. Needed for Close and Form Icon component.
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Remove border-radius in Chrome.
 * 4. Address `overflow` set to `hidden` in IE.
 * 5. Correct `font` properties and `color` not being inherited for `button`.
 * 6. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 7. Remove default `button` padding and background color
 * 8. Style
 * 9. Fill all SVG elements with the current text color if no `fill` attribute is set
 * 10. Let the container fit the height of the icon
 */
.uk-icon {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  border-radius: 0;
  /* 4 */
  overflow: visible;
  /* 5 */
  font: inherit;
  color: inherit;
  /* 6 */
  text-transform: none;
  /* 7. */
  padding: 0;
  background-color: transparent;
  /* 8 */
  display: inline-block;
  /* 9 */
  fill: currentcolor;
  /* 10 */
  line-height: 0; }

/* Required for `button`. */
button.uk-icon:not(:disabled) {
  cursor: pointer; }

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-icon::-moz-focus-inner {
  border: 0;
  padding: 0; }

/*
 * Set the fill and stroke color of all SVG elements to the current text color
 */
.uk-icon:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
  fill: currentcolor; }

.uk-icon:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
  stroke: currentcolor; }

/*
 * Fix Firefox blurry SVG rendering: https://bugzilla.mozilla.org/show_bug.cgi?id=1046835
 */
.uk-icon > * {
  transform: translate(0, 0); }

/* Image modifier
 ========================================================================== */
/*
 * Display images in icon dimensions
 */
.uk-icon-image {
  width: 20px;
  height: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle; }

/* Style modifiers
 ========================================================================== */
/*
 * Link
 */
.uk-icon-link {
  color: #999; }

.uk-icon-link:hover,
.uk-icon-link:focus {
  color: #666;
  outline: none; }

/* OnClick + Active */
.uk-icon-link:active,
.uk-active > .uk-icon-link {
  color: #595959; }

/*
 * Button
 * 1. Center icon vertically and horizontally
 */
.uk-icon-button {
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 500px;
  background: #f8f8f8;
  color: #999;
  vertical-align: middle;
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center; }

/* Hover + Focus */
.uk-icon-button:hover,
.uk-icon-button:focus {
  background-color: #ebebeb;
  color: #666;
  outline: none; }

/* OnClick + Active */
.uk-icon-button:active,
.uk-active > .uk-icon-button {
  background-color: #dfdfdf;
  color: #666; }

/* ========================================================================
   Component: Form Range
 ========================================================================== */
/*
 * 1. Normalize and defaults
 * 2. Prevent content overflow if a fixed width is used
 * 3. Take the full width
 * 4. Remove default style
 * 5. Remove white background in Chrome
 * 6. Remove padding in IE11
 */
.uk-range {
  /* 1 */
  box-sizing: border-box;
  margin: 0;
  vertical-align: middle;
  /* 2 */
  max-width: 100%;
  /* 3 */
  width: 100%;
  /* 4 */
  -webkit-appearance: none;
  /* 5 */
  background: transparent;
  /* 6 */
  padding: 0; }

/* Focus */
.uk-range:focus {
  outline: none; }

.uk-range::-moz-focus-outer {
  border: none; }

/* IE11 Reset */
.uk-range::-ms-track {
  height: 15px;
  background: transparent;
  border-color: transparent;
  color: transparent; }

/*
 * Improves consistency of cursor style for clickable elements
 */
.uk-range:not(:disabled)::-webkit-slider-thumb {
  cursor: pointer; }

.uk-range:not(:disabled)::-moz-range-thumb {
  cursor: pointer; }

.uk-range:not(:disabled)::-ms-thumb {
  cursor: pointer; }

/* Thumb
 ========================================================================== */
/*
 * 1. Reset
 * 2. Style
 */
/* Webkit */
.uk-range::-webkit-slider-thumb {
  /* 1 */
  -webkit-appearance: none;
  margin-top: -7px;
  /* 2 */
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #666; }

/* Firefox */
.uk-range::-moz-range-thumb {
  /* 1 */
  border: none;
  /* 2 */
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #666; }

/* Edge */
.uk-range::-ms-thumb {
  /* 1 */
  margin-top: 0; }

/* IE11 */
.uk-range::-ms-thumb {
  /* 1 */
  border: none;
  /* 2 */
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #666; }

/* Edge + IE11 */
.uk-range::-ms-tooltip {
  display: none; }

/* Track
 ========================================================================== */
/*
 * 1. Safari doesn't have a focus state. Using active instead.
 */
/* Webkit */
.uk-range::-webkit-slider-runnable-track {
  height: 3px;
  background: #ebebeb; }

.uk-range:focus::-webkit-slider-runnable-track,
.uk-range:active::-webkit-slider-runnable-track {
  background: #d2d2d2; }

/* Firefox */
.uk-range::-moz-range-track {
  height: 3px;
  background: #ebebeb; }

.uk-range:focus::-moz-range-track {
  background: #d2d2d2; }

/* Edge */
.uk-range::-ms-fill-lower,
.uk-range::-ms-fill-upper {
  height: 3px;
  background: #ebebeb; }

.uk-range:focus::-ms-fill-lower,
.uk-range:focus::-ms-fill-upper {
  background: #d2d2d2; }

/* ========================================================================
   Component: Form
 ========================================================================== */
/*
 * 1. Define consistent box sizing.
 *    Default is `content-box` with following exceptions set to `border-box`
 *    `select`, `input[type="checkbox"]` and `input[type="radio"]`
 *    `input[type="search"]` in Chrome, Safari and Opera
 *    `input[type="color"]` in Firefox
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Change font properties to `inherit` in all browsers.
 */
.uk-input,
.uk-select,
.uk-textarea,
.uk-radio,
.uk-checkbox {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit; }

/*
 * Show the overflow in Edge.
 */
.uk-input {
  overflow: visible; }

/*
 * Remove the inheritance of text transform in Firefox.
 */
.uk-select {
  text-transform: none; }

/*
 * 1. Change font properties to `inherit` in all browsers
 * 2. Don't inherit the `font-weight` and use `bold` instead.
 * NOTE: Both declarations don't work in Chrome, Safari and Opera.
 */
.uk-select optgroup {
  /* 1 */
  font: inherit;
  /* 2 */
  font-weight: bold; }

/*
 * Remove the default vertical scrollbar in IE 10+.
 */
.uk-textarea {
  overflow: auto; }

/*
 * Remove the inner padding and cancel buttons in Chrome on OS X and Safari on OS X.
 */
.uk-input[type="search"]::-webkit-search-cancel-button,
.uk-input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/*
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
.uk-input[type="number"]::-webkit-inner-spin-button,
.uk-input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/*
 * Removes placeholder transparency in Firefox.
 */
.uk-input::-moz-placeholder,
.uk-textarea::-moz-placeholder {
  opacity: 1; }

/*
 * Improves consistency of cursor style for clickable elements
 */
.uk-radio:not(:disabled),
.uk-checkbox:not(:disabled) {
  cursor: pointer; }

/*
 * Define consistent border, margin, and padding.
 */
.uk-fieldset {
  border: none;
  margin: 0;
  padding: 0; }

/* Input, select and textarea
 * Allowed: `text`, `password`, `datetime`, `datetime-local`, `date`,  `month`,
            `time`, `week`, `number`, `email`, `url`, `search`, `tel`, `color`
 * Disallowed: `range`, `radio`, `checkbox`, `file`, `submit`, `reset` and `image`
 ========================================================================== */
/*
 * Remove default style in iOS.
 */
.uk-input,
.uk-textarea {
  -webkit-appearance: none; }

/*
 * 1. Prevent content overflow if a fixed width is used
 * 2. Take the full width
 * 3. Reset default
 * 4. Style
 */
.uk-input,
.uk-select,
.uk-textarea {
  /* 1 */
  max-width: 100%;
  /* 2 */
  width: 100%;
  /* 3 */
  border: 0 none;
  /* 4 */
  padding: 0 10px;
  background: #f8f8f8;
  color: #666; }

/*
 * Single-line
 * 1. Allow any element to look like an `input` or `select` element
 * 2. Make sure line-height is not larger than height
 *    Also needed to center the text vertically
 */
.uk-input,
.uk-select:not([multiple]):not([size]) {
  height: 40px;
  vertical-align: middle;
  /* 1 */
  display: inline-block; }

/* 2 */
.uk-input:not(input),
.uk-select:not(select) {
  line-height: 40px; }

/*
 * Multi-line
 */
.uk-select[multiple],
.uk-select[size],
.uk-textarea {
  padding-top: 4px;
  padding-bottom: 4px;
  vertical-align: top; }

/* Focus */
.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus {
  outline: none;
  background-color: #f8f8f8;
  color: #666; }

/* Disabled */
.uk-input:disabled,
.uk-select:disabled,
.uk-textarea:disabled {
  background-color: #f8f8f8;
  color: #999; }

/*
 * Placeholder
 */
.uk-input::-ms-input-placeholder {
  color: #999 !important; }

.uk-input::placeholder {
  color: #999; }

.uk-textarea::-ms-input-placeholder {
  color: #999 !important; }

.uk-textarea::placeholder {
  color: #999; }

/* Style modifier (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Small
 */
.uk-form-small {
  font-size: 0.875rem; }

.uk-form-small:not(textarea):not([multiple]):not([size]) {
  height: 30px;
  padding-left: 8px;
  padding-right: 8px; }

.uk-form-small:not(select):not(input):not(textarea) {
  line-height: 30px; }

/*
 * Large
 */
.uk-form-large {
  font-size: 1.25rem; }

.uk-form-large:not(textarea):not([multiple]):not([size]) {
  height: 55px;
  padding-left: 12px;
  padding-right: 12px; }

.uk-form-large:not(select):not(input):not(textarea) {
  line-height: 55px; }

/* Style modifier (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Error
 */
.uk-form-danger,
.uk-form-danger:focus {
  color: #f0506e; }

/*
 * Success
 */
.uk-form-success,
.uk-form-success:focus {
  color: #32d296; }

/*
 * Blank
 */
.uk-form-blank {
  background: none; }

/* Width modifiers (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Fixed widths
 * Different widths for mini sized `input` and `select` elements
 */
input.uk-form-width-xsmall {
  width: 50px; }

select.uk-form-width-xsmall {
  width: 75px; }

.uk-form-width-small {
  width: 130px; }

.uk-form-width-medium {
  width: 200px; }

.uk-form-width-large {
  width: 500px; }

/* Select
 ========================================================================== */
/*
 * 1. Remove default style. Also works in Firefox
 * 2. Style
 * 3. Remove default style in IE 10/11
 * 4. Set `color` for options in the select dropdown, because the inherited `color` might be too light.
 */
.uk-select:not([multiple]):not([size]) {
  /* 1 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 2 */
  padding-right: 20px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-position: 100% 50%; }

/* 3 */
.uk-select:not([multiple]):not([size])::-ms-expand {
  display: none; }

/* 4 */
.uk-select:not([multiple]):not([size]) option {
  color: #444; }

/*
 * Disabled
 */
.uk-select:not([multiple]):not([size]):disabled {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A"); }

/* Datalist
 ========================================================================== */
/*
 * 1. Remove default style in Chrome
 */
.uk-input[list] {
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position: 100% 50%; }

.uk-input[list]:hover,
.uk-input[list]:focus {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A"); }

/* 1 */
.uk-input[list]::-webkit-calendar-picker-indicator {
  display: none; }

/* Radio and checkbox
 * Note: Does not work in IE11
 ========================================================================== */
/*
 * 1. Style
 * 2. Make box more robust so it clips the child element
 * 3. Vertical alignment
 * 4. Remove default style
 * 5. Fix black background on iOS
 * 6. Center icons
 */
.uk-radio,
.uk-checkbox {
  /* 1 */
  display: inline-block;
  height: 16px;
  width: 16px;
  /* 2 */
  overflow: hidden;
  /* 3 */
  margin-top: -4px;
  vertical-align: middle;
  /* 4 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 5 */
  background-color: #ebebeb;
  /* 6 */
  background-repeat: no-repeat;
  background-position: 50% 50%; }

.uk-radio {
  border-radius: 50%; }

/* Focus */
.uk-radio:focus,
.uk-checkbox:focus {
  outline: none; }

/*
 * Checked
 */
.uk-radio:checked,
.uk-checkbox:checked,
.uk-checkbox:indeterminate {
  background-color: #1e87f0; }

/* Focus */
.uk-radio:checked:focus,
.uk-checkbox:checked:focus,
.uk-checkbox:indeterminate:focus {
  background-color: #0e6ecd; }

/*
 * Icons
 */
.uk-radio:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E"); }

.uk-checkbox:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A"); }

.uk-checkbox:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23fff%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E"); }

/*
 * Disabled
 */
.uk-radio:disabled,
.uk-checkbox:disabled {
  background-color: #f8f8f8; }

.uk-radio:disabled:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23999%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E"); }

.uk-checkbox:disabled:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A"); }

.uk-checkbox:disabled:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23999%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E"); }

/* Legend
 ========================================================================== */
/*
 * Legend
 * 1. Behave like block element
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove padding so people aren't caught out if they zero out fieldsets.
 * 4. Style
 */
.uk-legend {
  /* 1 */
  width: 100%;
  /* 2 */
  color: inherit;
  /* 3 */
  padding: 0;
  /* 4 */
  font-size: 1.5rem;
  line-height: 1.4; }

/* Custom controls
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Behave like most inline-block elements
 */
.uk-form-custom {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle; }

/*
 * 1. Position and resize the form control to always cover its container
 * 2. Required for Firefox for positioning to the left
 * 3. Required for Webkit to make `height` work
 * 4. Hide controle and show cursor
 * 5. Needed for the cursor
 * 6. Clip height caused by 5. Needed for Webkit only
 */
.uk-form-custom select,
.uk-form-custom input[type="file"] {
  /* 1 */
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  /* 2 */
  left: 0;
  /* 3 */
  -webkit-appearance: none;
  /* 4 */
  opacity: 0;
  cursor: pointer; }

.uk-form-custom input[type="file"] {
  /* 5 */
  font-size: 500px;
  /* 6 */
  overflow: hidden; }

/* Label
 ========================================================================== */
/* Layout
 ========================================================================== */
/*
 * Stacked
 */
.uk-form-stacked .uk-form-label {
  display: block;
  margin-bottom: 10px; }

/*
 * Horizontal
 */
/* Tablet portrait and smaller */
@media (max-width: 959px) {
  /* Behave like `uk-form-stacked` */
  .uk-form-horizontal .uk-form-label {
    display: block;
    margin-bottom: 10px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-form-horizontal .uk-form-label {
    width: 200px;
    margin-top: 7px;
    float: left; }
  .uk-form-horizontal .uk-form-controls {
    margin-left: 215px; }
  /* Better vertical alignment if controls are checkboxes and radio buttons with text */
  .uk-form-horizontal .uk-form-controls-text {
    padding-top: 7px; } }

/* Icons
 ========================================================================== */
/*
 * 1. Set position
 * 2. Set width
 * 3. Center icon vertically and horizontally
 * 4. Style
 */
.uk-form-icon {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 40px;
  /* 3 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* 4 */
  color: #999; }

/*
 * Required for `a`.
 */
.uk-form-icon:hover {
  color: #666; }

/*
 * Make `input` element clickable through icon, e.g. if it's a `span`
 */
.uk-form-icon:not(a):not(button):not(input) {
  pointer-events: none; }

/*
 * Input padding
 */
.uk-form-icon:not(.uk-form-icon-flip) ~ .uk-input {
  padding-left: 40px !important; }

/*
 * Position modifier
 */
.uk-form-icon-flip {
  right: 0;
  left: auto; }

.uk-form-icon-flip ~ .uk-input {
  padding-right: 40px !important; }

/* ========================================================================
   Component: Button
 ========================================================================== */
/*
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Address `overflow` set to `hidden` in IE.
 * 4. Correct `font` properties and `color` not being inherited for `button`.
 * 5. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 6. Style
 * 7. `line-height` is used to create a height because it also centers the text vertically for `a` elements.
 *    Better would be to use height and flexbox to center the text vertically but flexbox doesn't work in Firefox on `button` elements.
 * 8. Align text if button has a width
 * 9. Required for `a`.
 */
.uk-button {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  overflow: visible;
  /* 4 */
  font: inherit;
  color: inherit;
  /* 5 */
  text-transform: none;
  /* 6 */
  display: inline-block;
  box-sizing: border-box;
  padding: 0 30px;
  vertical-align: middle;
  font-size: 16px;
  /* 7 */
  line-height: 40px;
  /* 8 */
  text-align: center;
  /* 9 */
  text-decoration: none; }

.uk-button:not(:disabled) {
  cursor: pointer; }

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-button::-moz-focus-inner {
  border: 0;
  padding: 0; }

/* Hover */
.uk-button:hover {
  /* 9 */
  text-decoration: none; }

/* Focus */
.uk-button:focus {
  outline: none; }

/* OnClick + Active */
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-button-default {
  background-color: #f8f8f8;
  color: #333; }

/* Hover + Focus */
.uk-button-default:hover,
.uk-button-default:focus {
  background-color: #ebebeb;
  color: #333; }

/* OnClick + Active */
.uk-button-default:active,
.uk-button-default.uk-active {
  background-color: #dfdfdf;
  color: #333; }

/*
 * Primary
 */
.uk-button-primary {
  background-color: #1e87f0;
  color: #fff; }

/* Hover + Focus */
.uk-button-primary:hover,
.uk-button-primary:focus {
  background-color: #0f7ae5;
  color: #fff; }

/* OnClick + Active */
.uk-button-primary:active,
.uk-button-primary.uk-active {
  background-color: #0e6ecd;
  color: #fff; }

/*
 * Secondary
 */
.uk-button-secondary {
  background-color: #222;
  color: #fff; }

/* Hover + Focus */
.uk-button-secondary:hover,
.uk-button-secondary:focus {
  background-color: #151515;
  color: #fff; }

/* OnClick + Active */
.uk-button-secondary:active,
.uk-button-secondary.uk-active {
  background-color: #090909;
  color: #fff; }

/*
 * Danger
 */
.uk-button-danger {
  background-color: #f0506e;
  color: #fff; }

/* Hover + Focus */
.uk-button-danger:hover,
.uk-button-danger:focus {
  background-color: #ee395b;
  color: #fff; }

/* OnClick + Active */
.uk-button-danger:active,
.uk-button-danger.uk-active {
  background-color: #ec2147;
  color: #fff; }

/*
 * Disabled
 * The same for all style modifiers
 */
.uk-button-default:disabled,
.uk-button-primary:disabled,
.uk-button-secondary:disabled,
.uk-button-danger:disabled {
  background-color: #f8f8f8;
  color: #999; }

/* Size modifiers
 ========================================================================== */
.uk-button-small {
  padding: 0 15px;
  line-height: 30px;
  font-size: 0.875rem; }

.uk-button-large {
  padding: 0 40px;
  line-height: 55px;
  font-size: 1.25rem; }

/* Text modifiers
 ========================================================================== */
/*
 * Text
 * 1. Reset
 * 2. Style
 */
.uk-button-text {
  /* 1 */
  padding: 0;
  line-height: 1.5;
  background: none;
  /* 2 */
  color: #999; }

/* Hover + Focus */
.uk-button-text:hover,
.uk-button-text:focus {
  color: #666; }

/* Disabled */
.uk-button-text:disabled {
  color: #999; }

/*
 * Link
 * 1. Reset
 * 2. Style
 */
.uk-button-link {
  /* 1 */
  padding: 0;
  line-height: 1.5;
  background: none;
  /* 2 */
  color: #1e87f0; }

/* Hover + Focus */
.uk-button-link:hover,
.uk-button-link:focus {
  color: #0f6ecd;
  text-decoration: underline; }

/* Disabled */
.uk-button-link:disabled {
  color: #999;
  text-decoration: none; }

/* Group
 ========================================================================== */
/*
 * 1. Using `flex` instead of `inline-block` to prevent whitespace betweent child elements
 * 2. Behave like button
 * 3. Create position context
 */
.uk-button-group {
  /* 1 */
  display: inline-flex;
  /* 2 */
  vertical-align: middle;
  /* 3 */
  position: relative; }

/* ========================================================================
   Component: Section
 ========================================================================== */
/*
 * 1. Make it work with `100vh` and height in general
 */
.uk-section {
  box-sizing: border-box;
  /* 1 */
  padding-top: 40px;
  padding-bottom: 40px; }

/* Desktop and bigger */
@media (min-width: 960px) {
  .uk-section {
    padding-top: 70px;
    padding-bottom: 70px; } }

/*
 * Micro clearfix
 */
.uk-section::before,
.uk-section::after {
  content: "";
  display: table; }

.uk-section::after {
  clear: both; }

/*
 * Remove margin from the last-child
 */
.uk-section > :last-child {
  margin-bottom: 0; }

/* Size modifiers
 ========================================================================== */
/*
 * XSmall
 */
.uk-section-xsmall {
  padding-top: 20px;
  padding-bottom: 20px; }

/*
 * Small
 */
.uk-section-small {
  padding-top: 40px;
  padding-bottom: 40px; }

/*
 * Large
 */
.uk-section-large {
  padding-top: 70px;
  padding-bottom: 70px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-section-large {
    padding-top: 140px;
    padding-bottom: 140px; } }

/*
 * XLarge
 */
.uk-section-xlarge {
  padding-top: 140px;
  padding-bottom: 140px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-section-xlarge {
    padding-top: 210px;
    padding-bottom: 210px; } }

/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-section-default {
  background: #fff; }

/*
 * Muted
 */
.uk-section-muted {
  background: #f8f8f8; }

/*
 * Primary
 */
.uk-section-primary {
  background: #1e87f0; }

/*
 * Secondary
 */
.uk-section-secondary {
  background: #222; }

/* Overlap modifier
 ========================================================================== */
/*
 * Reserved modifier to make a section overlap another section with an border image
 * Implemented by the theme
 */
/* ========================================================================
   Component: Container
 ========================================================================== */
/*
 * 1. Box sizing has to be `content-box` so the max-width is always the same and
 *    unaffected by the padding on different breakpoints. It's important for the size modifiers.
 */
.uk-container {
  /* 1 */
  box-sizing: content-box;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container {
    padding-left: 30px;
    padding-right: 30px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container {
    padding-left: 40px;
    padding-right: 40px; } }

/*
 * Micro clearfix
 */
.uk-container::before,
.uk-container::after {
  content: "";
  display: table; }

.uk-container::after {
  clear: both; }

/*
 * Remove margin from the last-child
 */
.uk-container > :last-child {
  margin-bottom: 0; }

/*
 * Remove padding from nested containers
 */
.uk-container .uk-container {
  padding-left: 0;
  padding-right: 0; }

/* Size modifier
 ========================================================================== */
.uk-container-xsmall {
  max-width: 750px; }

.uk-container-small {
  max-width: 900px; }

.uk-container-large {
  max-width: 1600px; }

.uk-container-expand {
  max-width: none; }

/* Expand modifier
 ========================================================================== */
/*
 * Expand one side only
 */
.uk-container-expand-left {
  margin-left: 0; }

.uk-container-expand-right {
  margin-right: 0; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-expand-left.uk-container-xsmall,
  .uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 30px); }
  .uk-container-expand-left.uk-container-small,
  .uk-container-expand-right.uk-container-small {
    max-width: calc(50% + (900px / 2) - 30px); } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container-expand-left,
  .uk-container-expand-right {
    max-width: calc(50% + (1200px / 2) - 40px); }
  .uk-container-expand-left.uk-container-xsmall,
  .uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 40px); }
  .uk-container-expand-left.uk-container-small,
  .uk-container-expand-right.uk-container-small {
    max-width: calc(50% + (900px / 2) - 40px); }
  .uk-container-expand-left.uk-container-large,
  .uk-container-expand-right.uk-container-large {
    max-width: calc(50% + (1600px / 2) - 40px); } }

/* Item
 ========================================================================== */
/*
 * Utility classes to reset container padding on the left or right side
 * Note: It has to be negative margin on the item, because it's specific to the item.
 */
.uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
  width: calc(100% + 15px); }

.uk-container-item-padding-remove-left {
  margin-left: -15px; }

.uk-container-item-padding-remove-right {
  margin-right: -15px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-item-padding-remove-left,
  .uk-container-item-padding-remove-right {
    width: calc(100% + 30px); }
  .uk-container-item-padding-remove-left {
    margin-left: -30px; }
  .uk-container-item-padding-remove-right {
    margin-right: -30px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container-item-padding-remove-left,
  .uk-container-item-padding-remove-right {
    width: calc(100% + 40px); }
  .uk-container-item-padding-remove-left {
    margin-left: -40px; }
  .uk-container-item-padding-remove-right {
    margin-right: -40px; } }

/* ========================================================================
   Component: Grid
 ========================================================================== */
/*
 * 1. Allow cells to wrap into the next line
 * 2. Reset list
 */
.uk-grid {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none; }

/*
 * Grid cell
 * Note: Space is allocated solely based on content dimensions, but shrinks: 0 1 auto
 * Reset margin for e.g. paragraphs
 */
.uk-grid > * {
  margin: 0; }

/*
 * Remove margin from the last-child
 */
.uk-grid > * > :last-child {
  margin-bottom: 0; }

/* Gutter
 ========================================================================== */
/*
 * Default
 */
/* Horizontal */
.uk-grid {
  margin-left: -30px; }

.uk-grid > * {
  padding-left: 30px; }

/* Vertical */
.uk-grid + .uk-grid,
.uk-grid > .uk-grid-margin,
* + .uk-grid-margin {
  margin-top: 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid {
    margin-left: -40px; }
  .uk-grid > * {
    padding-left: 40px; }
  /* Vertical */
  .uk-grid + .uk-grid,
  .uk-grid > .uk-grid-margin,
  * + .uk-grid-margin {
    margin-top: 40px; } }

/*
 * Small
 */
/* Horizontal */
.uk-grid-small {
  margin-left: -15px; }

.uk-grid-small > * {
  padding-left: 15px; }

/* Vertical */
.uk-grid + .uk-grid-small,
.uk-grid-small > .uk-grid-margin,
* + .uk-grid-margin-small {
  margin-top: 15px; }

/*
 * Medium
 */
/* Horizontal */
.uk-grid-medium {
  margin-left: -30px; }

.uk-grid-medium > * {
  padding-left: 30px; }

/* Vertical */
.uk-grid + .uk-grid-medium,
.uk-grid-medium > .uk-grid-margin,
* + .uk-grid-margin-medium {
  margin-top: 30px; }

/*
 * Large
 */
/* Horizontal */
.uk-grid-large {
  margin-left: -40px; }

.uk-grid-large > * {
  padding-left: 40px; }

/* Vertical */
.uk-grid + .uk-grid-large,
.uk-grid-large > .uk-grid-margin,
* + .uk-grid-margin-large {
  margin-top: 40px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-large {
    margin-left: -70px; }
  .uk-grid-large > * {
    padding-left: 70px; }
  /* Vertical */
  .uk-grid + .uk-grid-large,
  .uk-grid-large > .uk-grid-margin,
  * + .uk-grid-margin-large {
    margin-top: 70px; } }

/*
 * Collapse
 */
/* Horizontal */
.uk-grid-collapse {
  margin-left: 0; }

.uk-grid-collapse > * {
  padding-left: 0; }

/* Vertical */
.uk-grid + .uk-grid-collapse,
.uk-grid-collapse > .uk-grid-margin {
  margin-top: 0; }

/* Divider
 ========================================================================== */
.uk-grid-divider > * {
  position: relative; }

.uk-grid-divider > :not(.uk-first-column)::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  border-left: 1px solid #e5e5e5; }

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  border-top: 1px solid #e5e5e5; }

/*
 * Default
 */
/* Horizontal */
.uk-grid-divider {
  margin-left: -60px; }

.uk-grid-divider > * {
  padding-left: 60px; }

.uk-grid-divider > :not(.uk-first-column)::before {
  left: 30px; }

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin {
  margin-top: 60px; }

.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
  left: 60px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider {
    margin-left: -80px; }
  .uk-grid-divider > * {
    padding-left: 80px; }
  .uk-grid-divider > :not(.uk-first-column)::before {
    left: 40px; }
  /* Vertical */
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin {
    margin-top: 80px; }
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
    top: -40px;
    left: 80px; } }

/*
 * Small
 */
/* Horizontal */
.uk-grid-divider.uk-grid-small {
  margin-left: -30px; }

.uk-grid-divider.uk-grid-small > * {
  padding-left: 30px; }

.uk-grid-divider.uk-grid-small > :not(.uk-first-column)::before {
  left: 15px; }

/* Vertical */
.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin {
  margin-top: 30px; }

.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin::before {
  top: -15px;
  left: 30px; }

/*
 * Medium
 */
/* Horizontal */
.uk-grid-divider.uk-grid-medium {
  margin-left: -60px; }

.uk-grid-divider.uk-grid-medium > * {
  padding-left: 60px; }

.uk-grid-divider.uk-grid-medium > :not(.uk-first-column)::before {
  left: 30px; }

/* Vertical */
.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin {
  margin-top: 60px; }

.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
  left: 60px; }

/*
 * Large
 */
/* Horizontal */
.uk-grid-divider.uk-grid-large {
  margin-left: -80px; }

.uk-grid-divider.uk-grid-large > * {
  padding-left: 80px; }

.uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before {
  left: 40px; }

/* Vertical */
.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin {
  margin-top: 80px; }

.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
  top: -40px;
  left: 80px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider.uk-grid-large {
    margin-left: -140px; }
  .uk-grid-divider.uk-grid-large > * {
    padding-left: 140px; }
  .uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before {
    left: 70px; }
  /* Vertical */
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin {
    margin-top: 140px; }
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
    top: -70px;
    left: 140px; } }

/* Match child of a grid cell
 ========================================================================== */
/*
 * Behave like a block element
 * 1. Wrap into the next line
 * 2. Take the full width, at least 100%. Only if no class from the Width component is set.
 * 3. Expand width even if larger than 100%, e.g. because of negative margin (Needed for nested grids)
 */
.uk-grid-match > *,
.uk-grid-item-match {
  display: flex;
  /* 1 */
  flex-wrap: wrap; }

.uk-grid-match > * > :not([class*='uk-width']),
.uk-grid-item-match > :not([class*='uk-width']) {
  /* 2 */
  box-sizing: border-box;
  width: 100%;
  /* 3 */
  flex: auto; }

/* ========================================================================
   Component: Tile
 ========================================================================== */
.uk-tile {
  position: relative;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;
  padding-bottom: 40px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-tile {
    padding-left: 30px;
    padding-right: 30px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-tile {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 70px;
    padding-bottom: 70px; } }

/*
 * Micro clearfix
 */
.uk-tile::before,
.uk-tile::after {
  content: "";
  display: table; }

.uk-tile::after {
  clear: both; }

/*
 * Remove margin from the last-child
 */
.uk-tile > :last-child {
  margin-bottom: 0; }

/* Size modifiers
 ========================================================================== */
/*
 * XSmall
 */
.uk-tile-xsmall {
  padding-top: 20px;
  padding-bottom: 20px; }

/*
 * Small
 */
.uk-tile-small {
  padding-top: 40px;
  padding-bottom: 40px; }

/*
 * Large
 */
.uk-tile-large {
  padding-top: 70px;
  padding-bottom: 70px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-tile-large {
    padding-top: 140px;
    padding-bottom: 140px; } }

/*
 * XLarge
 */
.uk-tile-xlarge {
  padding-top: 140px;
  padding-bottom: 140px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-tile-xlarge {
    padding-top: 210px;
    padding-bottom: 210px; } }

/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-tile-default {
  background: #fff; }

/*
 * Muted
 */
.uk-tile-muted {
  background: #f8f8f8; }

/*
 * Primary
 */
.uk-tile-primary {
  background: #1e87f0; }

/*
 * Secondary
 */
.uk-tile-secondary {
  background: #222; }

/* ========================================================================
   Component: Card
 ========================================================================== */
.uk-card {
  position: relative;
  box-sizing: border-box; }

/* Sections
 ========================================================================== */
.uk-card-body {
  padding: 30px 30px; }

.uk-card-header {
  padding: 15px 30px; }

.uk-card-footer {
  padding: 15px 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-card-body {
    padding: 40px 40px; }
  .uk-card-header {
    padding: 20px 40px; }
  .uk-card-footer {
    padding: 20px 40px; } }

/*
 * Micro clearfix
 */
.uk-card-body::before,
.uk-card-body::after,
.uk-card-header::before,
.uk-card-header::after,
.uk-card-footer::before,
.uk-card-footer::after {
  content: "";
  display: table; }

.uk-card-body::after,
.uk-card-header::after,
.uk-card-footer::after {
  clear: both; }

/*
 * Remove margin from the last-child
 */
.uk-card-body > :last-child,
.uk-card-header > :last-child,
.uk-card-footer > :last-child {
  margin-bottom: 0; }

/* Media
 ========================================================================== */
/*
 * Reserved alignment modifier to style the media element, e.g. with `border-radius`
 * Implemented by the theme
 */
/* Title
 ========================================================================== */
.uk-card-title {
  font-size: 1.5rem;
  line-height: 1.4; }

/* Badge
 ========================================================================== */
.uk-card-badge {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 1; }

/*
 * Remove margin from adjacent element
 */
.uk-card-badge:first-child + * {
  margin-top: 0; }

/* Hover modifier
 ========================================================================== */
.uk-card-hover:not(.uk-card-default):not(.uk-card-primary):not(.uk-card-secondary):hover {
  background: #f8f8f8; }

/* Style modifiers
 ========================================================================== */
/*
 * Default
 * Note: Header and Footer are only implemented for the default style
 */
.uk-card-default {
  background: #f8f8f8;
  color: #666; }

.uk-card-default .uk-card-title {
  color: #333; }

.uk-card-default.uk-card-hover:hover {
  background-color: #ebebeb; }

/*
 * Primary
 */
.uk-card-primary {
  background: #1e87f0;
  color: #fff; }

.uk-card-primary .uk-card-title {
  color: #fff; }

.uk-card-primary.uk-card-hover:hover {
  background-color: #0f7ae5; }

/*
 * Secondary
 */
.uk-card-secondary {
  background: #222;
  color: #fff; }

.uk-card-secondary .uk-card-title {
  color: #fff; }

.uk-card-secondary.uk-card-hover:hover {
  background-color: #151515; }

/* Size modifier
 ========================================================================== */
/*
 * Small
 */
.uk-card-small.uk-card-body,
.uk-card-small .uk-card-body {
  padding: 20px 20px; }

.uk-card-small .uk-card-header {
  padding: 13px 20px; }

.uk-card-small .uk-card-footer {
  padding: 13px 20px; }

/*
 * Large
 */
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-card-large.uk-card-body,
  .uk-card-large .uk-card-body {
    padding: 70px 70px; }
  .uk-card-large .uk-card-header {
    padding: 35px 70px; }
  .uk-card-large .uk-card-footer {
    padding: 35px 70px; } }

/* ========================================================================
   Component: Close
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-close {
  color: #999; }

/* Hover + Focus */
.uk-close:hover,
.uk-close:focus {
  color: #666;
  outline: none; }

/* ========================================================================
   Component: Spinner
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
/* SVG
 ========================================================================== */
.uk-spinner > * {
  animation: uk-spinner-rotate 1.4s linear infinite; }

@keyframes uk-spinner-rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(270deg); } }

/*
 * Circle
 */
.uk-spinner > * > * {
  stroke-dasharray: 88px;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: uk-spinner-dash 1.4s ease-in-out infinite;
  stroke-width: 1;
  stroke-linecap: round; }

@keyframes uk-spinner-dash {
  0% {
    stroke-dashoffset: 88px; }
  50% {
    stroke-dashoffset: 22px;
    transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 88px;
    transform: rotate(450deg); } }

/* ========================================================================
   Component: Totop
 ========================================================================== */
/*
 * Addopts `uk-icon`
 */
.uk-totop {
  padding: 5px;
  color: #999; }

/* Hover + Focus */
.uk-totop:hover,
.uk-totop:focus {
  color: #666;
  outline: none; }

/* OnClick */
.uk-totop:active {
  color: #333; }

/* ========================================================================
   Component: Marker
 ========================================================================== */
/*
 * Addopts `uk-icon`
 */
.uk-marker {
  padding: 5px;
  background: #222;
  color: #fff; }

/* Hover + Focus */
.uk-marker:hover,
.uk-marker:focus {
  color: #fff;
  outline: none; }

/* ========================================================================
   Component: Alert
 ========================================================================== */
.uk-alert {
  position: relative;
  margin-bottom: 20px;
  padding: 15px 29px 15px 15px;
  background: #f8f8f8;
  color: #666; }

/* Add margin if adjacent element */
* + .uk-alert {
  margin-top: 20px; }

/*
 * Remove margin from the last-child
 */
.uk-alert > :last-child {
  margin-bottom: 0; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-alert-close {
  position: absolute;
  top: 20px;
  right: 15px; }

/*
 * Remove margin from adjacent element
 */
.uk-alert-close:first-child + * {
  margin-top: 0; }

/*
 * Hover + Focus
 */
/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-alert-primary {
  background: #d8eafc;
  color: #1e87f0; }

/*
 * Success
 */
.uk-alert-success {
  background: #edfbf6;
  color: #32d296; }

/*
 * Warning
 */
.uk-alert-warning {
  background: #fef5ee;
  color: #faa05a; }

/*
 * Danger
 */
.uk-alert-danger {
  background: #fef4f6;
  color: #f0506e; }

/* ========================================================================
   Component: Badge
 ========================================================================== */
/*
 * 1. Style
 * 2. Center child vertically and horizontally
 */
.uk-badge {
  box-sizing: border-box;
  min-width: 22px;
  height: 22px;
  padding: 0 5px;
  border-radius: 500px;
  vertical-align: middle;
  /* 1 */
  background: #1e87f0;
  color: #fff;
  font-size: 0.875rem;
  /* 2 */
  display: inline-flex;
  justify-content: center;
  align-items: center; }

/*
 * Required for `a`
 */
.uk-badge:hover,
.uk-badge:focus {
  color: #fff;
  text-decoration: none;
  outline: none; }

/* ========================================================================
   Component: Label
 ========================================================================== */
.uk-label {
  display: inline-block;
  padding: 0 10px;
  background: #1e87f0;
  line-height: 1.5;
  font-size: 0.875rem;
  color: #fff;
  vertical-align: middle;
  white-space: nowrap; }

/* Color modifiers
 ========================================================================== */
/*
 * Success
 */
.uk-label-success {
  background-color: #32d296;
  color: #fff; }

/*
 * Warning
 */
.uk-label-warning {
  background-color: #faa05a;
  color: #fff; }

/*
 * Danger
 */
.uk-label-danger {
  background-color: #f0506e;
  color: #fff; }

/* ========================================================================
   Component: Overlay
 ========================================================================== */
.uk-overlay {
  padding: 30px 30px; }

/*
 * Remove margin from the last-child
 */
.uk-overlay > :last-child {
  margin-bottom: 0; }

/* Icon
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-overlay-default {
  background: rgba(255, 255, 255, 0.8); }

/*
 * Primary
 */
.uk-overlay-primary {
  background: rgba(34, 34, 34, 0.8); }

/* ========================================================================
   Component: Article
 ========================================================================== */
/*
 * Micro clearfix
 */
.uk-article::before,
.uk-article::after {
  content: "";
  display: table; }

.uk-article::after {
  clear: both; }

/*
 * Remove margin from the last-child
 */
.uk-article > :last-child {
  margin-bottom: 0; }

/* Adjacent sibling
 ========================================================================== */
.uk-article + .uk-article {
  margin-top: 70px; }

/* Title
 ========================================================================== */
.uk-article-title {
  font-size: 2.23125rem;
  line-height: 1.2; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-article-title {
    font-size: 2.625rem; } }

/* Meta
 ========================================================================== */
.uk-article-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999; }

/* ========================================================================
   Component: Comment
 ========================================================================== */
/* Sections
 ========================================================================== */
.uk-comment-body {
  overflow-wrap: break-word;
  word-wrap: break-word; }

.uk-comment-header {
  margin-bottom: 20px; }

/*
 * Micro clearfix
 */
.uk-comment-body::before,
.uk-comment-body::after,
.uk-comment-header::before,
.uk-comment-header::after {
  content: "";
  display: table; }

.uk-comment-body::after,
.uk-comment-header::after {
  clear: both; }

/*
 * Remove margin from the last-child
 */
.uk-comment-body > :last-child,
.uk-comment-header > :last-child {
  margin-bottom: 0; }

/* Title
 ========================================================================== */
.uk-comment-title {
  font-size: 1.25rem;
  line-height: 1.4; }

/* Meta
 ========================================================================== */
.uk-comment-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999; }

/* Avatar
 ========================================================================== */
/* List
 ========================================================================== */
.uk-comment-list {
  padding: 0;
  list-style: none; }

/* Adjacent siblings */
.uk-comment-list > :nth-child(n+2) {
  margin-top: 70px; }

/*
 * Sublists
 * Note: General sibling selector allows reply block between comment and sublist
 */
.uk-comment-list .uk-comment ~ ul {
  margin: 70px 0 0 0;
  padding-left: 30px;
  list-style: none; }

/* Tablet and bigger */
@media (min-width: 960px) {
  .uk-comment-list .uk-comment ~ ul {
    padding-left: 100px; } }

/* Adjacent siblings */
.uk-comment-list .uk-comment ~ ul > :nth-child(n+2) {
  margin-top: 70px; }

/* Style modifier
 ========================================================================== */
/* ========================================================================
   Component: Search
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Reset `form`
 */
.uk-search {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  margin: 0; }

/* Input
 ========================================================================== */
/*
 * Remove the inner padding and cancel buttons in Chrome on OS X and Safari on OS X.
 */
.uk-search-input::-webkit-search-cancel-button,
.uk-search-input::-webkit-search-decoration {
  -webkit-appearance: none; }

/*
 * Removes placeholder transparency in Firefox.
 */
.uk-search-input::-moz-placeholder {
  opacity: 1; }

/*
 * 1. Define consistent box sizing.
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Change font properties to `inherit` in all browsers
 * 5. Show the overflow in Edge.
 * 6. Remove default style in iOS.
 * 7. Vertical alignment
 * 8. Take the full container width
 * 9. Style
 */
.uk-search-input {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
  /* 5 */
  overflow: visible;
  /* 6 */
  -webkit-appearance: none;
  /* 7 */
  vertical-align: middle;
  /* 8 */
  width: 100%;
  /* 9 */
  border: none;
  color: #666; }

.uk-search-input:focus {
  outline: none; }

/* Placeholder */
.uk-search-input:-ms-input-placeholder {
  color: #999 !important; }

.uk-search-input::placeholder {
  color: #999; }

/* Icon (Adopts `uk-icon`)
 ========================================================================== */
/*
 * Remove default focus style
 */
.uk-search-icon:focus {
  outline: none; }

/*
 * Position above input
 * 1. Set position
 * 2. Center icon vertically and horizontally
 * 3. Style
 */
.uk-search .uk-search-icon {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: #999; }

/*
 * Required for `a`.
 */
.uk-search .uk-search-icon:hover {
  color: #999; }

/*
 * Make `input` element clickable through icon, e.g. if it's a `span`
 */
.uk-search .uk-search-icon:not(a):not(button):not(input) {
  pointer-events: none; }

/*
 * Position modifier
 */
.uk-search .uk-search-icon-flip {
  right: 0;
  left: auto; }

/* Default modifier
 ========================================================================== */
.uk-search-default {
  width: 180px; }

/*
 * Input
 */
.uk-search-default .uk-search-input {
  height: 40px;
  padding-left: 6px;
  padding-right: 6px;
  background: #f8f8f8; }

/* Focus */
.uk-search-default .uk-search-input:focus {
  background-color: #f8f8f8; }

/*
 * Icon
 */
.uk-search-default .uk-search-icon {
  width: 40px; }

.uk-search-default .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
  padding-left: 40px; }

.uk-search-default .uk-search-icon-flip ~ .uk-search-input {
  padding-right: 40px; }

/* Navbar modifier
 ========================================================================== */
.uk-search-navbar {
  width: 400px; }

/*
 * Input
 */
.uk-search-navbar .uk-search-input {
  height: 40px;
  background: transparent;
  font-size: 1.5rem; }

/*
 * Icon
 */
.uk-search-navbar .uk-search-icon {
  width: 40px; }

.uk-search-navbar .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
  padding-left: 40px; }

.uk-search-navbar .uk-search-icon-flip ~ .uk-search-input {
  padding-right: 40px; }

/* Large modifier
 ========================================================================== */
.uk-search-large {
  width: 500px; }

/*
 * Input
 */
.uk-search-large .uk-search-input {
  height: 80px;
  background: transparent;
  font-size: 2.625rem; }

/*
 * Icon
 */
.uk-search-large .uk-search-icon {
  width: 80px; }

.uk-search-large .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
  padding-left: 80px; }

.uk-search-large .uk-search-icon-flip ~ .uk-search-input {
  padding-right: 80px; }

/* Toggle
 ========================================================================== */
.uk-search-toggle {
  color: #999; }

/* Hover + Focus */
.uk-search-toggle:hover,
.uk-search-toggle:focus {
  color: #666; }

/* ========================================================================
   Component: Nav
 ========================================================================== */
/*
 * Reset
 * 1. Prepare lists
 * 2. Prepare links
 * 3. Remove default focus style
 */
/* 1 */
.uk-nav,
.uk-nav ul {
  margin: 0;
  padding: 0;
  list-style: none; }

/* 2 */
.uk-nav li > a {
  display: block;
  text-decoration: none; }

/* 3 */
.uk-nav li > a:focus {
  outline: none; }

/*
 * Items
 * Must target `a` elements to exclude other elements (e.g. lists)
 */
.uk-nav > li > a {
  padding: 5px 0; }

/* Sublists
 ========================================================================== */
/*
 * Level 2
 * `ul` needed for higher specificity to override padding
 */
ul.uk-nav-sub {
  padding: 5px 0 5px 15px; }

/*
 * Level 3 and deeper
 */
.uk-nav-sub ul {
  padding-left: 15px; }

/*
 * Items
 */
.uk-nav-sub a {
  padding: 2px 0; }

/* Parent icon modifier
 ========================================================================== */
.uk-nav-parent-icon > .uk-parent > a::after {
  content: "";
  width: 1.5em;
  height: 1.5em;
  float: right;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23666%22%20stroke-width%3D%221.1%22%20points%3D%2210%201%204%207%2010%2013%22%20%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%; }

.uk-nav-parent-icon > .uk-parent.uk-open > a::after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23666%22%20stroke-width%3D%221.1%22%20points%3D%221%204%207%2010%2013%204%22%20%2F%3E%0A%3C%2Fsvg%3E"); }

/* Header
 ========================================================================== */
.uk-nav-header {
  padding: 5px 0;
  text-transform: uppercase;
  font-size: 0.875rem; }

.uk-nav-header:not(:first-child) {
  margin-top: 20px; }

/* Divider
 ========================================================================== */
.uk-nav-divider {
  margin: 5px 0; }

/* Default modifier
 ========================================================================== */
/*
 * Items
 */
.uk-nav-default > li > a {
  color: #999; }

/* Hover + Focus */
.uk-nav-default > li > a:hover,
.uk-nav-default > li > a:focus {
  color: #666; }

/* Active */
.uk-nav-default > li.uk-active > a {
  color: #333; }

/*
 * Header
 */
.uk-nav-default .uk-nav-header {
  color: #333; }

/*
 * Divider
 */
.uk-nav-default .uk-nav-divider {
  border-top: 1px solid #e5e5e5; }

/*
 * Sublists
 */
.uk-nav-default .uk-nav-sub a {
  color: #999; }

.uk-nav-default .uk-nav-sub a:hover,
.uk-nav-default .uk-nav-sub a:focus {
  color: #666; }

.uk-nav-default .uk-nav-sub li.uk-active > a {
  color: #333; }

/* Primary modifier
 ========================================================================== */
/*
 * Items
 */
.uk-nav-primary > li > a {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #999; }

/* Hover + Focus */
.uk-nav-primary > li > a:hover,
.uk-nav-primary > li > a:focus {
  color: #666; }

/* Active */
.uk-nav-primary > li.uk-active > a {
  color: #333; }

/*
 * Header
 */
.uk-nav-primary .uk-nav-header {
  color: #333; }

/*
 * Divider
 */
.uk-nav-primary .uk-nav-divider {
  border-top: 1px solid #e5e5e5; }

/*
 * Sublists
 */
.uk-nav-primary .uk-nav-sub a {
  color: #999; }

.uk-nav-primary .uk-nav-sub a:hover,
.uk-nav-primary .uk-nav-sub a:focus {
  color: #666; }

.uk-nav-primary .uk-nav-sub li.uk-active > a {
  color: #333; }

/* Alignment modifier
 ========================================================================== */
.uk-nav-center {
  text-align: center; }

/* Sublists */
.uk-nav-center .uk-nav-sub,
.uk-nav-center .uk-nav-sub ul {
  padding-left: 0; }

/* Parent icon modifier  */
.uk-nav-center.uk-nav-parent-icon > .uk-parent > a::after {
  position: absolute; }

/* ========================================================================
   Component: Navbar
 ========================================================================== */
/*
 * 1. Create position context to center navbar group
 */
.uk-navbar {
  display: flex;
  /* 1 */
  position: relative; }

/* Container
 ========================================================================== */
.uk-navbar-container:not(.uk-navbar-transparent) {
  background: #f8f8f8; }

/*
 * Remove pseudo elements created by micro clearfix as precaution (if Container component is used)
 */
.uk-navbar-container > ::before,
.uk-navbar-container > ::after {
  display: none !important; }

/* Groups
 ========================================================================== */
/*
 * 1. Align navs and items vertically if they have a different height
 * 2. Note: IE 11 requires an extra `div` which affects the center selector
 */
.uk-navbar-left,
.uk-navbar-right,
.uk-navbar-center,
.uk-navbar-center-left > *,
.uk-navbar-center-right > * {
  display: flex;
  /* 1 */
  align-items: center; }

/*
 * Horizontal alignment
 * 1. Create position context for centered navbar with sub groups (left/right)
 * 2. Fix text wrapping if content is larger than 50% of the container.
 * 3. Needed for dropdowns because a new position context is created
 *    `z-index` must be smaller than off-canvas
 * 4. Align sub groups for centered navbar
 */
.uk-navbar-right {
  margin-left: auto; }

.uk-navbar-center:only-child {
  margin-left: auto;
  margin-right: auto;
  /* 1 */
  position: relative; }

.uk-navbar-center:not(:only-child) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 2 */
  width: max-content;
  box-sizing: border-box;
  /* 3 */
  z-index: 990; }

/* 4 */
.uk-navbar-center-left,
.uk-navbar-center-right {
  position: absolute;
  top: 0; }

.uk-navbar-center-left {
  right: 100%; }

.uk-navbar-center-right {
  left: 100%; }

[class*='uk-navbar-center-'] {
  width: max-content;
  box-sizing: border-box; }

/* Nav
 ========================================================================== */
/*
 * 1. Reset list
 */
.uk-navbar-nav {
  display: flex;
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none; }

/*
 * Allow items to wrap into the next line
 * Only not `absolute` positioned groups
 */
.uk-navbar-left,
.uk-navbar-right,
.uk-navbar-center:only-child {
  flex-wrap: wrap; }

/*
 * Items
 * 1. Center content vertically and horizontally
 * 2. Dimensions
 * 3. Style
 * 4. Required for `a`
 */
.uk-navbar-nav > li > a,
.uk-navbar-item,
.uk-navbar-toggle {
  /* 1 */
  display: flex;
  justify-content: center;
  align-items: center;
  /* 2 */
  box-sizing: border-box;
  min-height: 80px;
  padding: 0 15px;
  /* 3 */
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* 4 */
  text-decoration: none; }

/*
 * Nav items
 */
.uk-navbar-nav > li > a {
  color: #999; }

/*
 * Hover
 * Apply hover style also to focus state and if dropdown is opened
 */
.uk-navbar-nav > li:hover > a,
.uk-navbar-nav > li > a:focus,
.uk-navbar-nav > li > a.uk-open {
  color: #666;
  outline: none; }

/* OnClick */
.uk-navbar-nav > li > a:active {
  color: #333; }

/* Active */
.uk-navbar-nav > li.uk-active > a {
  color: #333; }

/* Item
 ========================================================================== */
.uk-navbar-item {
  color: #666; }

/* Toggle
 ========================================================================== */
.uk-navbar-toggle {
  color: #999; }

.uk-navbar-toggle:hover,
.uk-navbar-toggle:focus,
.uk-navbar-toggle.uk-open {
  color: #666;
  outline: none;
  text-decoration: none; }

/*
 * Icon
 * Adopts `uk-icon`
 */
/* Hover + Focus */
/* Subtitle
 ========================================================================== */
.uk-navbar-subtitle {
  font-size: 0.875rem; }

/* Style modifiers
 ========================================================================== */
/* Dropdown
 ========================================================================== */
/*
 * Adopts `uk-dropdown`
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 * 4. Style
 */
.uk-navbar-dropdown {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  /* 3 */
  box-sizing: border-box;
  width: 200px;
  /* 4 */
  padding: 15px;
  background: #f8f8f8;
  color: #666; }

/* Show */
.uk-navbar-dropdown.uk-open {
  display: block; }

/*
 * Direction / Alignment modifiers
 */
/* Direction */
[class*='uk-navbar-dropdown-top'] {
  margin-top: 0; }

[class*='uk-navbar-dropdown-bottom'] {
  margin-top: 0; }

[class*='uk-navbar-dropdown-left'] {
  margin-left: 0; }

[class*='uk-navbar-dropdown-right'] {
  margin-left: 0; }

/*
 * Grid
 * Adopts `uk-grid`
 */
/* Gutter Horizontal */
.uk-navbar-dropdown-grid {
  margin-left: -30px; }

.uk-navbar-dropdown-grid > * {
  padding-left: 30px; }

/* Gutter Vertical */
.uk-navbar-dropdown-grid > .uk-grid-margin {
  margin-top: 30px; }

/* Stack */
.uk-navbar-dropdown-stack .uk-navbar-dropdown-grid > * {
  width: 100% !important; }

/*
 * Width modifier
 */
.uk-navbar-dropdown-width-2:not(.uk-navbar-dropdown-stack) {
  width: 400px; }

.uk-navbar-dropdown-width-3:not(.uk-navbar-dropdown-stack) {
  width: 600px; }

.uk-navbar-dropdown-width-4:not(.uk-navbar-dropdown-stack) {
  width: 800px; }

.uk-navbar-dropdown-width-5:not(.uk-navbar-dropdown-stack) {
  width: 1000px; }

/*
 * Dropbar modifier
 */
.uk-navbar-dropdown-dropbar {
  margin-top: 0;
  margin-bottom: 0; }

/* Dropdown Nav
 * Adopts `uk-nav`
 ========================================================================== */
/*
 * Items
 */
.uk-navbar-dropdown-nav > li > a {
  color: #999; }

/* Hover + Focus */
.uk-navbar-dropdown-nav > li > a:hover,
.uk-navbar-dropdown-nav > li > a:focus {
  color: #666; }

/* Active */
.uk-navbar-dropdown-nav > li.uk-active > a {
  color: #333; }

/*
 * Header
 */
.uk-navbar-dropdown-nav .uk-nav-header {
  color: #333; }

/*
 * Divider
 */
.uk-navbar-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #e5e5e5; }

/*
 * Sublists
 */
.uk-navbar-dropdown-nav .uk-nav-sub a {
  color: #999; }

.uk-navbar-dropdown-nav .uk-nav-sub a:hover,
.uk-navbar-dropdown-nav .uk-nav-sub a:focus {
  color: #666; }

.uk-navbar-dropdown-nav .uk-nav-sub li.uk-active > a {
  color: #333; }

/* Dropbar
 ========================================================================== */
.uk-navbar-dropbar {
  background: #f8f8f8; }

/*
 * Slide modifier
 */
.uk-navbar-dropbar-slide {
  position: absolute;
  z-index: 980;
  left: 0;
  right: 0; }

/* ========================================================================
   Component: Subnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Gutter
 * 3. Reset list
 */
.uk-subnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin-left: -20px;
  /* 3 */
  padding: 0;
  list-style: none; }

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-subnav > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  position: relative; }

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * Using `:first-child` instead of `a` to support `span` elements for text
 * 1. Prevent gap if child element is `inline-block`, e.g. an icon
 * 2. Style
 */
.uk-subnav > * > :first-child {
  /* 1 */
  display: block;
  /* 2 */
  color: #999; }

/* Hover + Focus */
.uk-subnav > * > a:hover,
.uk-subnav > * > a:focus {
  color: #666;
  text-decoration: none;
  outline: none; }

/* Active */
.uk-subnav > .uk-active > a {
  color: #333; }

/* Divider modifier
 ========================================================================== */
/*
 * 1. Align items and divider vertically
 */
.uk-subnav-divider > * {
  /* 1 */
  display: flex;
  align-items: center; }

/*
 * Divider
 * `nth-child` makes it also work without JS if it's only one row
 */
.uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before {
  content: "";
  height: 1.5em;
  margin-left: 0px;
  margin-right: 20px;
  border-left: 1px solid #e5e5e5; }

/* Pill modifier
 ========================================================================== */
.uk-subnav-pill > * > :first-child {
  padding: 5px 10px;
  background: transparent;
  color: #999; }

/* Hover + Focus */
.uk-subnav-pill > * > a:hover,
.uk-subnav-pill > * > a:focus {
  background-color: #f8f8f8;
  color: #666; }

/* OnClick */
.uk-subnav-pill > * > a:active {
  background-color: #f8f8f8;
  color: #666; }

/* Active */
.uk-subnav-pill > .uk-active > a {
  background-color: #1e87f0;
  color: #fff; }

/* Disabled
 * The same for all style modifiers
 ========================================================================== */
.uk-subnav > .uk-disabled > a {
  color: #999; }

/* ========================================================================
   Component: Breadcrumb
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 */
.uk-breadcrumb {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  padding: 0;
  list-style: none; }

/*
 * Space is allocated solely based on content dimensions: 0 0 auto
 */
.uk-breadcrumb > * {
  flex: none; }

/* Items
 ========================================================================== */
.uk-breadcrumb > * > * {
  display: inline-block;
  font-size: 0.875rem;
  color: #999; }

/* Hover + Focus */
.uk-breadcrumb > * > :hover,
.uk-breadcrumb > * > :focus {
  color: #666;
  text-decoration: none; }

/* Disabled */
/* Active */
.uk-breadcrumb > :last-child > * {
  color: #666; }

/*
 * Divider
 * `nth-child` makes it also work without JS if it's only one row
 */
.uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
  content: "/";
  display: inline-block;
  margin: 0 20px;
  color: #999; }

/* ========================================================================
   Component: Pagination
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Gutter
 * 3. Reset list
 */
.uk-pagination {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin-left: -20px;
  /* 3 */
  padding: 0;
  list-style: none; }

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-pagination > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  position: relative; }

/* Items
 ========================================================================== */
/*
 * 1. Prevent gap if child element is `inline-block`, e.g. an icon
 * 2. Style
 */
.uk-pagination > * > * {
  /* 1 */
  display: block;
  /* 2 */
  color: #999; }

/* Hover + Focus */
.uk-pagination > * > :hover,
.uk-pagination > * > :focus {
  color: #666;
  text-decoration: none; }

/* Active */
.uk-pagination > .uk-active > * {
  color: #666; }

/* Disabled */
.uk-pagination > .uk-disabled > * {
  color: #999; }

/* ========================================================================
   Component: Tab
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Gutter
 * 3. Reset list
 */
.uk-tab {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin-left: -20px;
  /* 3 */
  padding: 0;
  list-style: none; }

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-tab > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  position: relative; }

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * 1. Center text if a width is set
 * 2. Style
 */
.uk-tab > * > a {
  /* 1 */
  display: block;
  text-align: center;
  /* 2 */
  padding: 5px 10px;
  color: #999; }

/* Hover + Focus */
.uk-tab > * > a:hover,
.uk-tab > * > a:focus {
  color: #666;
  text-decoration: none; }

/* Active */
.uk-tab > .uk-active > a {
  color: #333; }

/* Disabled */
.uk-tab > .uk-disabled > a {
  color: #999; }

/* Position modifier
 ========================================================================== */
/*
 * Bottom
 */
/*
 * Left + Right
 * 1. Reset Gutter
 */
.uk-tab-left,
.uk-tab-right {
  flex-direction: column;
  /* 1 */
  margin-left: 0; }

/* 1 */
.uk-tab-left > *,
.uk-tab-right > * {
  padding-left: 0; }

.uk-tab-left > * > a {
  text-align: left; }

.uk-tab-right > * > a {
  text-align: left; }

/* ========================================================================
   Component: Slidenav
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-slidenav {
  padding: 5px 10px;
  color: rgba(102, 102, 102, 0.5); }

/* Hover + Focus */
.uk-slidenav:hover,
.uk-slidenav:focus {
  color: rgba(102, 102, 102, 0.9);
  outline: none; }

/* OnClick */
.uk-slidenav:active {
  color: rgba(102, 102, 102, 0.5); }

/* Icon modifier
 ========================================================================== */
/*
 * Previous
 */
/*
 * Next
 */
/* Size modifier
 ========================================================================== */
.uk-slidenav-large {
  padding: 10px 10px; }

/* Container
 ========================================================================== */
.uk-slidenav-container {
  display: flex; }

/* ========================================================================
   Component: Dotnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-dotnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -12px; }

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 */
.uk-dotnav > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 12px; }

/* Items
 ========================================================================== */
/*
 * Items
 * 1. Hide text if present
 */
.uk-dotnav > * > * {
  display: block;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(102, 102, 102, 0.2);
  /* 1 */
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap; }

/* Hover + Focus */
.uk-dotnav > * > :hover,
.uk-dotnav > * > :focus {
  background-color: rgba(102, 102, 102, 0.6);
  outline: none; }

/* OnClick */
.uk-dotnav > * > :active {
  background-color: rgba(102, 102, 102, 0.2); }

/* Active */
.uk-dotnav > .uk-active > * {
  background-color: rgba(102, 102, 102, 0.6); }

/* Modifier: 'uk-dotnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-dotnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -12px; }

/* 2 */
.uk-dotnav-vertical > * {
  padding-left: 0;
  padding-top: 12px; }

/* ========================================================================
   Component: Thumbnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-thumbnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -15px; }

/*
 * Space is allocated based on content dimensions, but shrinks: 0 1 auto
 * 1. Gutter
 */
.uk-thumbnav > * {
  /* 1 */
  padding-left: 15px; }

/* Items
 ========================================================================== */
/*
 * Items
 */
.uk-thumbnav > * > * {
  display: inline-block; }

/* Hover + Focus */
.uk-thumbnav > * > :hover,
.uk-thumbnav > * > :focus {
  outline: none; }

/* Active */
/* Modifier: 'uk-thumbnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-thumbnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -15px; }

/* 2 */
.uk-thumbnav-vertical > * {
  padding-left: 0;
  padding-top: 15px; }

/* ========================================================================
   Component: Accordion
 ========================================================================== */
.uk-accordion {
  padding: 0;
  list-style: none; }

/* Item
 ========================================================================== */
.uk-accordion > :nth-child(n+2) {
  margin-top: 20px; }

/* Title
 ========================================================================== */
.uk-accordion-title {
  display: block;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #333; }

/* Hover + Focus */
.uk-accordion-title:hover,
.uk-accordion-title:focus {
  color: #666;
  text-decoration: none;
  outline: none; }

/* Content
 ========================================================================== */
.uk-accordion-content {
  margin-top: 20px; }

/*
 * Micro clearfix
 */
.uk-accordion-content::before,
.uk-accordion-content::after {
  content: "";
  display: table; }

.uk-accordion-content::after {
  clear: both; }

/*
 * Remove margin from the last-child
 */
.uk-accordion-content > :last-child {
  margin-bottom: 0; }

/* ========================================================================
   Component: Drop
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 */
.uk-drop {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  /* 3 */
  box-sizing: border-box;
  width: 300px; }

/* Show */
.uk-drop.uk-open {
  display: block; }

/* Direction / Alignment modifiers
 ========================================================================== */
/* Direction */
[class*='uk-drop-top'] {
  margin-top: -20px; }

[class*='uk-drop-bottom'] {
  margin-top: 20px; }

[class*='uk-drop-left'] {
  margin-left: -20px; }

[class*='uk-drop-right'] {
  margin-left: 20px; }

/* Grid modifiers
 ========================================================================== */
.uk-drop-stack .uk-drop-grid > * {
  width: 100% !important; }

/* ========================================================================
   Component: Dropdown
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 * 4. Style
 */
.uk-dropdown {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  /* 3 */
  box-sizing: border-box;
  min-width: 200px;
  /* 4 */
  padding: 15px;
  background: #f8f8f8;
  color: #666; }

/* Show */
.uk-dropdown.uk-open {
  display: block; }

/* Nav
 * Adopts `uk-nav`
 ========================================================================== */
.uk-dropdown-nav {
  white-space: nowrap; }

/*
 * Items
 */
.uk-dropdown-nav > li > a {
  color: #999; }

/* Hover + Focus + Active */
.uk-dropdown-nav > li > a:hover,
.uk-dropdown-nav > li > a:focus,
.uk-dropdown-nav > li.uk-active > a {
  color: #666; }

/*
 * Header
 */
.uk-dropdown-nav .uk-nav-header {
  color: #333; }

/*
 * Divider
 */
.uk-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #e5e5e5; }

/*
 * Sublists
 */
.uk-dropdown-nav .uk-nav-sub a {
  color: #999; }

.uk-dropdown-nav .uk-nav-sub a:hover,
.uk-dropdown-nav .uk-nav-sub a:focus,
.uk-dropdown-nav .uk-nav-sub li.uk-active > a {
  color: #666; }

/* Direction / Alignment modifiers
 ========================================================================== */
/* Direction */
[class*='uk-dropdown-top'] {
  margin-top: -10px; }

[class*='uk-dropdown-bottom'] {
  margin-top: 10px; }

[class*='uk-dropdown-left'] {
  margin-left: -10px; }

[class*='uk-dropdown-right'] {
  margin-left: 10px; }

/* Grid modifiers
 ========================================================================== */
.uk-dropdown-stack .uk-dropdown-grid > * {
  width: 100% !important; }

/* ========================================================================
   Component: Modal
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 */
.uk-modal {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* 4 */
  padding: 15px 15px;
  /* 5 */
  background: rgba(0, 0, 0, 0.6);
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-modal {
    padding: 50px 30px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-modal {
    padding-left: 40px;
    padding-right: 40px; } }

/*
 * Open
 */
.uk-modal.uk-open {
  opacity: 1; }

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-modal-page {
  overflow: hidden; }

/* Dialog
 ========================================================================== */
/*
 * 1. Create position context for spinner and close button
 * 2. Dimensions
 * 3. Fix `max-width: 100%` not working in combination with flex and responsive images in IE11
 *    `!important` needed to overwrite `uk-width-auto`. See `#modal-media-image` in tests
 * 4. Style
 * 5. Slide-in transition
 */
.uk-modal-dialog {
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box;
  margin: 0 auto;
  width: 600px;
  /* 3 */
  max-width: calc(100% - 0.01px) !important;
  /* 4 */
  background: #fff;
  /* 5 */
  opacity: 0;
  transform: translateY(-100px);
  transition: 0.3s linear;
  transition-property: opacity, transform; }

/*
 * Open
 */
.uk-open > .uk-modal-dialog {
  opacity: 1;
  transform: translateY(0); }

/* Size modifier
 ========================================================================== */
/*
 * Container size
 * Take the same size as the Container component
 */
.uk-modal-container .uk-modal-dialog {
  width: 1200px; }

/*
 * Full size
 * 1. Remove padding and background from modal
 * 2. Reset all default declarations from modal dialog
 */
/* 1 */
.uk-modal-full {
  padding: 0;
  background: none; }

/* 2 */
.uk-modal-full .uk-modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
  transform: translateY(0); }

/* Sections
 ========================================================================== */
.uk-modal-body {
  padding: 30px 30px; }

.uk-modal-header {
  padding: 15px 30px;
  background: #f8f8f8; }

.uk-modal-footer {
  padding: 15px 30px;
  background: #f8f8f8; }

/*
 * Micro clearfix
 */
.uk-modal-body::before,
.uk-modal-body::after,
.uk-modal-header::before,
.uk-modal-header::after,
.uk-modal-footer::before,
.uk-modal-footer::after {
  content: "";
  display: table; }

.uk-modal-body::after,
.uk-modal-header::after,
.uk-modal-footer::after {
  clear: both; }

/*
 * Remove margin from the last-child
 */
.uk-modal-body > :last-child,
.uk-modal-header > :last-child,
.uk-modal-footer > :last-child {
  margin-bottom: 0; }

/* Title
 ========================================================================== */
.uk-modal-title {
  font-size: 2rem;
  line-height: 1.3; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
[class*='uk-modal-close-'] {
  position: absolute;
  z-index: 1010;
  top: 10px;
  right: 10px;
  padding: 5px; }

/*
 * Remove margin from adjacent element
 */
[class*='uk-modal-close-']:first-child + * {
  margin-top: 0; }

/*
 * Hover
 */
/*
 * Default
 */
/*
 * Outside
 * 1. Prevent scrollbar on small devices
 */
.uk-modal-close-outside {
  top: 0;
  /* 1 */
  right: -5px;
  transform: translate(0, -100%);
  color: white; }

.uk-modal-close-outside:hover {
  color: #fff; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* 1 */
  .uk-modal-close-outside {
    right: 0;
    transform: translate(100%, -100%); } }

/*
 * Full
 */
/* ========================================================================
   Component: Lightbox
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 */
.uk-lightbox {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 5 */
  background: #000;
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear; }

/*
 * Open
 * 1. Center child
 * 2. Fade-in
 */
.uk-lightbox.uk-open {
  display: block;
  /* 2 */
  opacity: 1; }

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-lightbox-page {
  overflow: hidden; }

/* Item
 ========================================================================== */
/*
 * 1. Center child within the viewport
 * 2. Not visible by default
 * 3. Color needed for spinner icon
 * 4. Optimize animation
 * 5. Responsiveness
 *    Using `vh` for `max-height` to fix image proportions after resize in Safari and Opera
 *    Using `vh` and `vw` to make responsive image work in IE11
 * 6. Suppress outline on focus
 */
.uk-lightbox-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: none;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: rgba(255, 255, 255, 0.7);
  /* 4 */
  will-change: transform, opacity; }

/* 5 */
.uk-lightbox-items > * > * {
  max-width: 100vw;
  max-height: 100vh; }

/* 6 */
.uk-lightbox-items > :focus {
  outline: none; }

.uk-lightbox-items > * > :not(iframe) {
  width: auto;
  height: auto; }

.uk-lightbox-items > .uk-active {
  display: flex; }

/* Toolbar
 ========================================================================== */
.uk-lightbox-toolbar {
  padding: 10px 10px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7); }

.uk-lightbox-toolbar > * {
  color: rgba(255, 255, 255, 0.7); }

/* Toolbar Icon (Close)
 ========================================================================== */
.uk-lightbox-toolbar-icon {
  padding: 5px;
  color: rgba(255, 255, 255, 0.7); }

/*
 * Hover
 */
.uk-lightbox-toolbar-icon:hover {
  color: #fff; }

/* Button (Slidenav)
 ========================================================================== */
/*
 * 1. Center icon vertically and horizontally
 */
.uk-lightbox-button {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center; }

/*
 * Hover
 */
.uk-lightbox-button:hover {
  color: #fff; }

/* Caption
 ========================================================================== */
/* Iframe
 ========================================================================== */
.uk-lightbox-iframe {
  width: 80%;
  height: 80%; }

/* ========================================================================
   Component: Slideshow
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slideshow {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/* Items
 ========================================================================== */
/*
 * 1. Create position and stacking context
 * 2. Reset list
 * 3. Clip child elements
 * 4. Prevent displaying the callout information on iOS.
 */
.uk-slideshow-items {
  /* 1 */
  position: relative;
  z-index: 0;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  overflow: hidden;
  /* 4 */
  -webkit-touch-callout: none; }

/* Item
 ========================================================================== */
/*
 * 1. Position items above each other
 * 2. Take the full width
 * 3. Clip child elements, e.g. for `uk-cover`
 * 4. Optimize animation
 * 5. Disable horizontal panning gestures in IE11 and Edge
 * 6. Suppress outline on focus
 */
.uk-slideshow-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  left: 0;
  /* 2 */
  right: 0;
  bottom: 0;
  /* 3 */
  overflow: hidden;
  /* 4 */
  will-change: transform, opacity;
  /* 5 */
  touch-action: pan-y; }

/* 6 */
.uk-slideshow-items > :focus {
  outline: none; }

/*
 * Hide not active items
 */
.uk-slideshow-items > :not(.uk-active) {
  display: none; }

/* ========================================================================
   Component: Slider
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slider {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/* Container
 ========================================================================== */
/*
 * 1. Clip child elements
 */
.uk-slider-container {
  /* 1 */
  overflow: hidden; }

/* Items
 ========================================================================== */
/*
 * 1. Optimize animation
 * 2. Create a containing block. In Safari it's neither created by `transform` nor `will-change`.
 */
.uk-slider-items {
  /* 1 */
  will-change: transform;
  /* 2 */
  position: relative; }

/*
 * 1. Reset list style without interfering with grid
 * 2. Prevent displaying the callout information on iOS.
 */
.uk-slider-items:not(.uk-grid) {
  display: flex;
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 2 */
  -webkit-touch-callout: none; }

.uk-slider-items.uk-grid {
  flex-wrap: nowrap; }

/* Item
 ========================================================================== */
/*
 * 1. Let items take content dimensions (0 0 auto)
 * 2. Create position context
 * 3. Disable horizontal panning gestures in IE11 and Edge
 * 4. Suppress outline on focus
 */
.uk-slider-items > * {
  /* 1 */
  flex: none;
  /* 2 */
  position: relative;
  /* 3 */
  touch-action: pan-y; }

/* 4 */
.uk-slider-items > :focus {
  outline: none; }

/* ========================================================================
   Component: Sticky
 ========================================================================== */
/*
 * 1. Resolve frame rate issues on devices with lower frame rates by forcing hardware acceleration
 */
.uk-sticky-fixed {
  z-index: 980;
  box-sizing: border-box;
  margin: 0 !important;
  /* 1 */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

/*
 * Faster animations
 */
.uk-sticky[class*='uk-animation-'] {
  animation-duration: 0.2s; }

.uk-sticky.uk-animation-reverse {
  animation-duration: 0.2s; }

/* ========================================================================
   Component: Off-canvas
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 */
.uk-offcanvas {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000; }

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas {
  right: 0;
  left: auto; }

/* Bar
 ========================================================================== */
/*
 * 1. Set position
 * 2. Size and style
 * 3. Allow scrolling
 */
.uk-offcanvas-bar {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: -270px;
  /* 2 */
  box-sizing: border-box;
  width: 270px;
  padding: 20px 20px;
  background: #222;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-offcanvas-bar {
    left: -350px;
    width: 350px;
    padding: 40px 40px; } }

/* Flip modifier */
.uk-offcanvas-flip .uk-offcanvas-bar {
  left: auto;
  right: -270px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-offcanvas-flip .uk-offcanvas-bar {
    right: -350px; } }

/*
 * Open
 */
.uk-open > .uk-offcanvas-bar {
  left: 0; }

.uk-offcanvas-flip .uk-open > .uk-offcanvas-bar {
  left: auto;
  right: 0; }

/*
 * Slide Animation (Used in slide and push mode)
 */
.uk-offcanvas-bar-animation {
  transition: left 0.3s ease-out; }

.uk-offcanvas-flip .uk-offcanvas-bar-animation {
  transition-property: right; }

/*
 * Reveal Animation
 * 1. Set position
 * 2. Clip the bar
 * 3. Animation
 * 4. Reset position
 */
.uk-offcanvas-reveal {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 0;
  overflow: hidden;
  /* 3 */
  transition: width 0.3s ease-out; }

.uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: 0; }

.uk-offcanvas-flip .uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: auto;
  right: 0; }

.uk-open > .uk-offcanvas-reveal {
  width: 270px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-open > .uk-offcanvas-reveal {
    width: 350px; } }

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas-reveal {
  right: 0;
  left: auto; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-offcanvas-close {
  position: absolute;
  z-index: 1000;
  top: 20px;
  right: 20px;
  padding: 5px; }

/* Overlay
 ========================================================================== */
/*
 * Overlay the whole page. Needed for the `::before`
 * 1. Using `100vw` so no modification is needed when off-canvas is flipped
 * 2. Allow for closing with swipe gesture on devices with pointer events.
 */
.uk-offcanvas-overlay {
  /* 1 */
  width: 100vw;
  /* 2 */
  touch-action: none; }

/*
 * 1. Mask the whole page
 * 2. Fade-in transition
 */
.uk-offcanvas-overlay::before {
  /* 1 */
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  /* 2 */
  opacity: 0;
  transition: opacity 0.15s linear; }

.uk-offcanvas-overlay.uk-open::before {
  opacity: 1; }

/* Prevent scrolling
 ========================================================================== */
/*
 * Prevent horizontal scrollbar when the content is slide-out
 * Has to be on the `html` element too to make it work on the `body`
 */
.uk-offcanvas-page,
.uk-offcanvas-container {
  overflow-x: hidden; }

/* Container
 ========================================================================== */
/*
 * Prepare slide-out animation (Used in reveal and push mode)
 * Using `position: left` instead of `transform` because position `fixed` elements like sticky navbars
 * lose their fixed state and behaves like `absolute` within a transformed container
 * 1. Provide a fixed width and prevent shrinking
 */
.uk-offcanvas-container {
  position: relative;
  left: 0;
  transition: left 0.3s ease-out;
  /* 1 */
  box-sizing: border-box;
  width: 100%; }

/*
 * Activate slide-out animation
 */
:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
  left: 270px; }

.uk-offcanvas-flip.uk-offcanvas-container-animation {
  left: -270px; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  :not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
    left: 350px; }
  .uk-offcanvas-flip.uk-offcanvas-container-animation {
    left: -350px; } }

/* ========================================================================
   Component: Switcher
 ========================================================================== */
/*
 * Reset list
 */
.uk-switcher {
  margin: 0;
  padding: 0;
  list-style: none; }

/* Items
 ========================================================================== */
/*
 * Hide not active items
 */
.uk-switcher > :not(.uk-active) {
  display: none; }

/*
 * Remove margin from the last-child
 */
.uk-switcher > * > :last-child {
  margin-bottom: 0; }

/* ========================================================================
   Component: Leader
 ========================================================================== */
.uk-leader {
  overflow: hidden; }

/*
 * 1. Place element in text flow
 * 2. Never break into a new line
 * 3. Get a string back with as many repeating characters to fill the container
 * 4. Prevent wrapping. Overflowing characters will be clipped by the container
 */
.uk-leader-fill::after {
  /* 1 */
  display: inline-block;
  margin-left: 15px;
  /* 2 */
  width: 0;
  /* 3 */
  content: attr(data-fill);
  /* 4 */
  white-space: nowrap; }

/*
 * Hide if media does not match
 */
.uk-leader-fill.uk-leader-hide::after {
  display: none; }

/*
 * Pass fill character to JS
 */
.uk-leader-fill-content::before {
  content: "."; }

:root {
  --uk-leader-fill-content: .; }

/* ========================================================================
   Component: Iconnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-iconnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -10px; }

/*
 * Space is allocated based on content dimensions, but shrinks: 0 1 auto
 * 1. Gutter
 */
.uk-iconnav > * {
  /* 1 */
  padding-left: 10px; }

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * 1. Prevent gap if child element is `inline-block`, e.g. an icon
 * 2. Style
 */
.uk-iconnav > * > a {
  /* 1 */
  display: block;
  /* 2 */
  color: #999; }

/* Hover + Focus */
.uk-iconnav > * > a:hover,
.uk-iconnav > * > a:focus {
  color: #666;
  outline: none; }

/* Active */
.uk-iconnav > .uk-active > a {
  color: #666; }

/* Modifier: 'uk-iconnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-iconnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -10px; }

/* 2 */
.uk-iconnav-vertical > * {
  padding-left: 0;
  padding-top: 10px; }

/* ========================================================================
   Component: Notification
 ========================================================================== */
/*
 * 1. Set position
 * 2. Dimensions
 */
.uk-notification {
  /* 1 */
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1040;
  /* 2 */
  box-sizing: border-box;
  width: 350px; }

/* Position modifiers
========================================================================== */
.uk-notification-top-right,
.uk-notification-bottom-right {
  left: auto;
  right: 10px; }

.uk-notification-top-center,
.uk-notification-bottom-center {
  left: 50%;
  margin-left: -175px; }

.uk-notification-bottom-left,
.uk-notification-bottom-right,
.uk-notification-bottom-center {
  top: auto;
  bottom: 10px; }

/* Responsiveness
========================================================================== */
/* Phones portrait and smaller */
@media (max-width: 639px) {
  .uk-notification {
    left: 10px;
    right: 10px;
    width: auto;
    margin: 0; } }

/* Message
========================================================================== */
.uk-notification-message {
  position: relative;
  padding: 15px;
  background: #f8f8f8;
  color: #666;
  font-size: 1.25rem;
  line-height: 1.4;
  cursor: pointer; }

* + .uk-notification-message {
  margin-top: 10px; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-notification-close {
  display: none;
  position: absolute;
  top: 20px;
  right: 15px; }

.uk-notification-message:hover .uk-notification-close {
  display: block; }

/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-notification-message-primary {
  color: #1e87f0; }

/*
 * Success
 */
.uk-notification-message-success {
  color: #32d296; }

/*
 * Warning
 */
.uk-notification-message-warning {
  color: #faa05a; }

/*
 * Danger
 */
.uk-notification-message-danger {
  color: #f0506e; }

/* ========================================================================
   Component: Tooltip
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Position
 * 3. Dimensions
 * 4. Style
 */
.uk-tooltip {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1030;
  /* 3 */
  box-sizing: border-box;
  max-width: 200px;
  padding: 3px 6px;
  /* 4 */
  background: #666;
  border-radius: 2px;
  color: #fff;
  font-size: 12px; }

/* Show */
.uk-tooltip.uk-active {
  display: block; }

/* Direction / Alignment modifiers
 ========================================================================== */
/* Direction */
[class*='uk-tooltip-top'] {
  margin-top: -10px; }

[class*='uk-tooltip-bottom'] {
  margin-top: 10px; }

[class*='uk-tooltip-left'] {
  margin-left: -10px; }

[class*='uk-tooltip-right'] {
  margin-left: 10px; }

/* ========================================================================
   Component: Placeholder
 ========================================================================== */
.uk-placeholder {
  margin-bottom: 20px;
  padding: 30px 30px;
  background: #f8f8f8; }

/* Add margin if adjacent element */
* + .uk-placeholder {
  margin-top: 20px; }

/*
 * Remove margin from the last-child
 */
.uk-placeholder > :last-child {
  margin-bottom: 0; }

/* ========================================================================
   Component: Progress
 ========================================================================== */
/*
 * 1. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 * 2. Remove default style
 * 3. Behave like a block element
 * 4. Remove borders in Firefox and Edge
 * 5. Set background color for progress container in Firefox, IE11 and Edge
 * 6. Style
 */
.uk-progress {
  /* 1 */
  vertical-align: baseline;
  /* 2 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 3 */
  display: block;
  width: 100%;
  /* 4 */
  border: 0;
  /* 5 */
  background-color: #f8f8f8;
  /* 6 */
  margin-bottom: 20px;
  height: 15px; }

/* Add margin if adjacent element */
* + .uk-progress {
  margin-top: 20px; }

/*
 * Remove animated circles for indeterminate state in IE11 and Edge
 */
.uk-progress:indeterminate {
  color: transparent; }

/*
 * Progress container
 * 2. Remove progress bar for indeterminate state in Firefox
 */
.uk-progress::-webkit-progress-bar {
  background-color: #f8f8f8; }

/* 2 */
.uk-progress:indeterminate::-moz-progress-bar {
  width: 0; }

/*
 * Progress bar
 * 1. Remove right border in IE11 and Edge
 */
.uk-progress::-webkit-progress-value {
  background-color: #1e87f0;
  transition: width 0.6s ease; }

.uk-progress::-moz-progress-bar {
  background-color: #1e87f0; }

.uk-progress::-ms-fill {
  background-color: #1e87f0;
  transition: width 0.6s ease;
  /* 1 */
  border: 0; }

/* ========================================================================
   Component: Sortable
 ========================================================================== */
.uk-sortable {
  position: relative; }

/*
 * Deactivate pointer-events on SVGs in Safari
 */
.uk-sortable svg {
  pointer-events: none; }

/*
 * Remove margin from the last-child
 */
.uk-sortable > :last-child {
  margin-bottom: 0; }

/* Drag
 ========================================================================== */
.uk-sortable-drag {
  position: absolute !important;
  z-index: 1050 !important;
  pointer-events: none; }

/* Placeholder
 ========================================================================== */
.uk-sortable-placeholder {
  opacity: 0;
  pointer-events: none; }

/* Empty modifier
 ========================================================================== */
.uk-sortable-empty {
  min-height: 50px; }

/* Handle
 ========================================================================== */
/* Hover */
.uk-sortable-handle:hover {
  cursor: move; }

/* ========================================================================
   Component: Countdown
 ========================================================================== */
/* Item
 ========================================================================== */
/*
 * 1. Center numbers and separators vertically
 */
.uk-countdown-number,
.uk-countdown-separator {
  /* 1 */
  line-height: 70px; }

/* Number
 ========================================================================== */
.uk-countdown-number {
  font-size: 2rem; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-countdown-number {
    font-size: 4rem; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-countdown-number {
    font-size: 6rem; } }

/* Separator
 ========================================================================== */
.uk-countdown-separator {
  font-size: 1rem; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-countdown-separator {
    font-size: 2rem; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-countdown-separator {
    font-size: 3rem; } }

/* Label
 ========================================================================== */
/* ========================================================================
   Component: Animation
 ========================================================================== */
[class*='uk-animation-'] {
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: both; }

/* Animations
 ========================================================================== */
/*
 * Fade
 */
.uk-animation-fade {
  animation-name: uk-fade;
  animation-duration: 0.8s;
  animation-timing-function: linear; }

/*
 * Scale
 */
.uk-animation-scale-up {
  animation-name: uk-fade-scale-02; }

.uk-animation-scale-down {
  animation-name: uk-fade-scale-18; }

/*
 * Slide
 */
.uk-animation-slide-top {
  animation-name: uk-fade-top; }

.uk-animation-slide-bottom {
  animation-name: uk-fade-bottom; }

.uk-animation-slide-left {
  animation-name: uk-fade-left; }

.uk-animation-slide-right {
  animation-name: uk-fade-right; }

/*
 * Slide Small
 */
.uk-animation-slide-top-small {
  animation-name: uk-fade-top-small; }

.uk-animation-slide-bottom-small {
  animation-name: uk-fade-bottom-small; }

.uk-animation-slide-left-small {
  animation-name: uk-fade-left-small; }

.uk-animation-slide-right-small {
  animation-name: uk-fade-right-small; }

/*
 * Slide Medium
 */
.uk-animation-slide-top-medium {
  animation-name: uk-fade-top-medium; }

.uk-animation-slide-bottom-medium {
  animation-name: uk-fade-bottom-medium; }

.uk-animation-slide-left-medium {
  animation-name: uk-fade-left-medium; }

.uk-animation-slide-right-medium {
  animation-name: uk-fade-right-medium; }

/*
 * Kenburns
 */
.uk-animation-kenburns {
  animation-name: uk-scale-kenburns;
  animation-duration: 15s; }

/*
 * Shake
 */
.uk-animation-shake {
  animation-name: uk-shake; }

/*
 * SVG Stroke
 * The `--uk-animation-stroke` custom property contains the longest path length.
 * Set it manually or use `uk-svg="stroke-animation: true"` to set it automatically.
 * All strokes are animated by the same pace and doesn't end simultaneously.
 * To end simultaneously, `pathLength="1"` could be used, but it's not working in Safari yet.
 */
.uk-animation-stroke {
  animation-name: uk-stroke;
  stroke-dasharray: var(--uk-animation-stroke);
  animation-duration: 2s; }

/* Direction modifier
 ========================================================================== */
.uk-animation-reverse {
  animation-direction: reverse;
  animation-timing-function: ease-in; }

/* Duration modifier
 ========================================================================== */
.uk-animation-fast {
  animation-duration: 0.1s; }

/* Toggle (Hover + Focus)
========================================================================== */
/*
 * The toggle is triggered on touch devices using `:focus` and tabindex
 */
.uk-animation-toggle:not(:hover):not(:focus) [class*='uk-animation-'] {
  animation-name: none; }

/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-animation-toggle {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/*
 * Remove outline for `tabindex`
 */
.uk-animation-toggle:focus {
  outline: none; }

/* Keyframes used by animation classes
 ========================================================================== */
/*
 * Fade
 */
@keyframes uk-fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/*
 * Slide Top
 */
@keyframes uk-fade-top {
  0% {
    opacity: 0;
    transform: translateY(-100%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/*
 * Slide Bottom
 */
@keyframes uk-fade-bottom {
  0% {
    opacity: 0;
    transform: translateY(100%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/*
 * Slide Left
 */
@keyframes uk-fade-left {
  0% {
    opacity: 0;
    transform: translateX(-100%); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/*
 * Slide Right
 */
@keyframes uk-fade-right {
  0% {
    opacity: 0;
    transform: translateX(100%); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/*
 * Slide Top Small
 */
@keyframes uk-fade-top-small {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/*
 * Slide Bottom Small
 */
@keyframes uk-fade-bottom-small {
  0% {
    opacity: 0;
    transform: translateY(10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/*
 * Slide Left Small
 */
@keyframes uk-fade-left-small {
  0% {
    opacity: 0;
    transform: translateX(-10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/*
 * Slide Right Small
 */
@keyframes uk-fade-right-small {
  0% {
    opacity: 0;
    transform: translateX(10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/*
 * Slide Top Medium
 */
@keyframes uk-fade-top-medium {
  0% {
    opacity: 0;
    transform: translateY(-50px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/*
 * Slide Bottom Medium
 */
@keyframes uk-fade-bottom-medium {
  0% {
    opacity: 0;
    transform: translateY(50px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/*
 * Slide Left Medium
 */
@keyframes uk-fade-left-medium {
  0% {
    opacity: 0;
    transform: translateX(-50px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/*
 * Slide Right Medium
 */
@keyframes uk-fade-right-medium {
  0% {
    opacity: 0;
    transform: translateX(50px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/*
 * Scale Up
 */
@keyframes uk-fade-scale-02 {
  0% {
    opacity: 0;
    transform: scale(0.2); }
  100% {
    opacity: 1;
    transform: scale(1); } }

/*
 * Scale Down
 */
@keyframes uk-fade-scale-18 {
  0% {
    opacity: 0;
    transform: scale(1.8); }
  100% {
    opacity: 1;
    transform: scale(1); } }

/*
 * Kenburns
 */
@keyframes uk-scale-kenburns {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.2); } }

/*
 * Shake
 */
@keyframes uk-shake {
  0%, 100% {
    transform: translateX(0); }
  10% {
    transform: translateX(-9px); }
  20% {
    transform: translateX(8px); }
  30% {
    transform: translateX(-7px); }
  40% {
    transform: translateX(6px); }
  50% {
    transform: translateX(-5px); }
  60% {
    transform: translateX(4px); }
  70% {
    transform: translateX(-3px); }
  80% {
    transform: translateX(2px); }
  90% {
    transform: translateX(-1px); } }

/*
 * Stroke
 */
@keyframes uk-stroke {
  0% {
    stroke-dashoffset: var(--uk-animation-stroke); }
  100% {
    stroke-dashoffset: 0; } }

/* ========================================================================
   Component: Width
 ========================================================================== */
/* Equal child widths
 ========================================================================== */
[class*='uk-child-width'] > * {
  box-sizing: border-box;
  width: 100%; }

.uk-child-width-1-2 > * {
  width: 50%; }

.uk-child-width-1-3 > * {
  width: calc(100% * 1 / 3.001); }

.uk-child-width-1-4 > * {
  width: 25%; }

.uk-child-width-1-5 > * {
  width: 20%; }

.uk-child-width-1-6 > * {
  width: calc(100% * 1 / 6.001); }

.uk-child-width-auto > * {
  width: auto; }

/*
 * 1. Reset the `min-width`, which is set to auto by default, because
 *    flex items won't shrink below their minimum intrinsic content size.
 *    Using `1px` instead of `0`, so items still wrap into the next line,
 *    if they have zero width and padding and the predecessor is 100% wide.
 */
.uk-child-width-expand > :not([class*='uk-width']) {
  flex: 1;
  /* 1 */
  min-width: 1px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-child-width-1-1\@s > * {
    width: 100%; }
  .uk-child-width-1-2\@s > * {
    width: 50%; }
  .uk-child-width-1-3\@s > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@s > * {
    width: 25%; }
  .uk-child-width-1-5\@s > * {
    width: 20%; }
  .uk-child-width-1-6\@s > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@s > * {
    width: auto; }
  .uk-child-width-expand\@s > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-child-width-1-1\@m > * {
    width: 100%; }
  .uk-child-width-1-2\@m > * {
    width: 50%; }
  .uk-child-width-1-3\@m > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@m > * {
    width: 25%; }
  .uk-child-width-1-5\@m > * {
    width: 20%; }
  .uk-child-width-1-6\@m > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@m > * {
    width: auto; }
  .uk-child-width-expand\@m > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-child-width-1-1\@l > * {
    width: 100%; }
  .uk-child-width-1-2\@l > * {
    width: 50%; }
  .uk-child-width-1-3\@l > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@l > * {
    width: 25%; }
  .uk-child-width-1-5\@l > * {
    width: 20%; }
  .uk-child-width-1-6\@l > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@l > * {
    width: auto; }
  .uk-child-width-expand\@l > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-child-width-1-1\@xl > * {
    width: 100%; }
  .uk-child-width-1-2\@xl > * {
    width: 50%; }
  .uk-child-width-1-3\@xl > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@xl > * {
    width: 25%; }
  .uk-child-width-1-5\@xl > * {
    width: 20%; }
  .uk-child-width-1-6\@xl > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@xl > * {
    width: auto; }
  .uk-child-width-expand\@xl > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Single Widths
 ========================================================================== */
/*
 * 1. `max-width` is needed for the pixel-based classes
 */
[class*='uk-width'] {
  box-sizing: border-box;
  width: 100%;
  /* 1 */
  max-width: 100%; }

/* Halves */
.uk-width-1-2 {
  width: 50%; }

/* Thirds */
.uk-width-1-3 {
  width: calc(100% * 1 / 3.001); }

.uk-width-2-3 {
  width: calc(100% * 2 / 3.001); }

/* Quarters */
.uk-width-1-4 {
  width: 25%; }

.uk-width-3-4 {
  width: 75%; }

/* Fifths */
.uk-width-1-5 {
  width: 20%; }

.uk-width-2-5 {
  width: 40%; }

.uk-width-3-5 {
  width: 60%; }

.uk-width-4-5 {
  width: 80%; }

/* Sixths */
.uk-width-1-6 {
  width: calc(100% * 1 / 6.001); }

.uk-width-5-6 {
  width: calc(100% * 5 / 6.001); }

/* Pixel */
.uk-width-small {
  width: 150px; }

.uk-width-medium {
  width: 300px; }

.uk-width-large {
  width: 450px; }

.uk-width-xlarge {
  width: 600px; }

.uk-width-xxlarge {
  width: 750px; }

/* Auto */
.uk-width-auto {
  width: auto; }

/* Expand */
.uk-width-expand {
  flex: 1;
  min-width: 1px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  /* Whole */
  .uk-width-1-1\@s {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@s {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@s {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@s {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@s {
    width: 25%; }
  .uk-width-3-4\@s {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@s {
    width: 20%; }
  .uk-width-2-5\@s {
    width: 40%; }
  .uk-width-3-5\@s {
    width: 60%; }
  .uk-width-4-5\@s {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@s {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@s {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@s {
    width: 150px; }
  .uk-width-medium\@s {
    width: 300px; }
  .uk-width-large\@s {
    width: 450px; }
  .uk-width-xlarge\@s {
    width: 600px; }
  .uk-width-xxlarge\@s {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@s {
    width: auto; }
  /* Expand */
  .uk-width-expand\@s {
    flex: 1;
    min-width: 1px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* Whole */
  .uk-width-1-1\@m {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@m {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@m {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@m {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@m {
    width: 25%; }
  .uk-width-3-4\@m {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@m {
    width: 20%; }
  .uk-width-2-5\@m {
    width: 40%; }
  .uk-width-3-5\@m {
    width: 60%; }
  .uk-width-4-5\@m {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@m {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@m {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@m {
    width: 150px; }
  .uk-width-medium\@m {
    width: 300px; }
  .uk-width-large\@m {
    width: 450px; }
  .uk-width-xlarge\@m {
    width: 600px; }
  .uk-width-xxlarge\@m {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@m {
    width: auto; }
  /* Expand */
  .uk-width-expand\@m {
    flex: 1;
    min-width: 1px; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Whole */
  .uk-width-1-1\@l {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@l {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@l {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@l {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@l {
    width: 25%; }
  .uk-width-3-4\@l {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@l {
    width: 20%; }
  .uk-width-2-5\@l {
    width: 40%; }
  .uk-width-3-5\@l {
    width: 60%; }
  .uk-width-4-5\@l {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@l {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@l {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@l {
    width: 150px; }
  .uk-width-medium\@l {
    width: 300px; }
  .uk-width-large\@l {
    width: 450px; }
  .uk-width-xlarge\@l {
    width: 600px; }
  .uk-width-xxlarge\@l {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@l {
    width: auto; }
  /* Expand */
  .uk-width-expand\@l {
    flex: 1;
    min-width: 1px; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  /* Whole */
  .uk-width-1-1\@xl {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@xl {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@xl {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@xl {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@xl {
    width: 25%; }
  .uk-width-3-4\@xl {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@xl {
    width: 20%; }
  .uk-width-2-5\@xl {
    width: 40%; }
  .uk-width-3-5\@xl {
    width: 60%; }
  .uk-width-4-5\@xl {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@xl {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@xl {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@xl {
    width: 150px; }
  .uk-width-medium\@xl {
    width: 300px; }
  .uk-width-large\@xl {
    width: 450px; }
  .uk-width-xlarge\@xl {
    width: 600px; }
  .uk-width-xxlarge\@xl {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@xl {
    width: auto; }
  /* Expand */
  .uk-width-expand\@xl {
    flex: 1;
    min-width: 1px; } }

/* ========================================================================
   Component: Height
 ========================================================================== */
[class*='uk-height'] {
  box-sizing: border-box; }

/*
 * Only works if parent element has a height set
 */
.uk-height-1-1 {
  height: 100%; }

/*
 * Useful to create image teasers
 */
.uk-height-viewport {
  min-height: 100vh; }

/*
 * Pixel
 * Useful for `overflow: auto`
 */
.uk-height-small {
  height: 150px; }

.uk-height-medium {
  height: 300px; }

.uk-height-large {
  height: 450px; }

.uk-height-max-small {
  max-height: 150px; }

.uk-height-max-medium {
  max-height: 300px; }

.uk-height-max-large {
  max-height: 450px; }

/* ========================================================================
   Component: Text
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
.uk-text-lead {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #333; }

.uk-text-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999; }

/* Size modifiers
 ========================================================================== */
.uk-text-small {
  font-size: 0.875rem;
  line-height: 1.5; }

.uk-text-large {
  font-size: 1.5rem;
  line-height: 1.5; }

/* Weight modifier
 ========================================================================== */
.uk-text-bold {
  font-weight: bolder; }

/* Transform modifier
 ========================================================================== */
.uk-text-uppercase {
  text-transform: uppercase !important; }

.uk-text-capitalize {
  text-transform: capitalize !important; }

.uk-text-lowercase {
  text-transform: lowercase !important; }

/* Color modifiers
 ========================================================================== */
.uk-text-muted {
  color: #999 !important; }

.uk-text-emphasis {
  color: #333 !important; }

.uk-text-primary {
  color: #1e87f0 !important; }

.uk-text-secondary {
  color: #222 !important; }

.uk-text-success {
  color: #32d296 !important; }

.uk-text-warning {
  color: #faa05a !important; }

.uk-text-danger {
  color: #f0506e !important; }

/* Background modifier
 ========================================================================== */
/*
 * 1. The background clips to the foreground text. Works in Chrome, Firefox, Safari, Edge and Opera
 *    Default color is set to transparent
 * 2. Container fits the text
 * 3. Fallback color for IE11
 */
.uk-text-background {
  /* 1 */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* 2 */
  display: inline-block;
  /* 3 */
  color: #1e87f0 !important; }

@supports (-webkit-background-clip: text) {
  .uk-text-background {
    background-color: #1e87f0; } }

/* Alignment modifiers
 ========================================================================== */
.uk-text-left {
  text-align: left !important; }

.uk-text-right {
  text-align: right !important; }

.uk-text-center {
  text-align: center !important; }

.uk-text-justify {
  text-align: justify !important; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-text-left\@s {
    text-align: left !important; }
  .uk-text-right\@s {
    text-align: right !important; }
  .uk-text-center\@s {
    text-align: center !important; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-text-left\@m {
    text-align: left !important; }
  .uk-text-right\@m {
    text-align: right !important; }
  .uk-text-center\@m {
    text-align: center !important; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-text-left\@l {
    text-align: left !important; }
  .uk-text-right\@l {
    text-align: right !important; }
  .uk-text-center\@l {
    text-align: center !important; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-text-left\@xl {
    text-align: left !important; }
  .uk-text-right\@xl {
    text-align: right !important; }
  .uk-text-center\@xl {
    text-align: center !important; } }

/*
 * Vertical
 */
.uk-text-top {
  vertical-align: top !important; }

.uk-text-middle {
  vertical-align: middle !important; }

.uk-text-bottom {
  vertical-align: bottom !important; }

.uk-text-baseline {
  vertical-align: baseline !important; }

/* Wrap modifiers
 ========================================================================== */
/*
 * Prevent text from wrapping onto multiple lines
 */
.uk-text-nowrap {
  white-space: nowrap; }

/*
 * 1. Make sure a max-width is set after which truncation can occur
 * 2. Prevent text from wrapping onto multiple lines, and truncate with an ellipsis
 * 3. Fix for table cells
 */
.uk-text-truncate {
  /* 1 */
  max-width: 100%;
  /* 2 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* 2 */
th.uk-text-truncate,
td.uk-text-truncate {
  max-width: 0; }

/*
 * 1. Wrap long words onto the next line and break them if they are too long to fit
 * 2. Legacy `word-wrap` as fallback for `overflow-wrap`
 * 3. Fix `overflow-wrap` which doesn't work with table cells in Chrome, Opera, IE11 and Edge
 *    Must use `break-all` to support IE11 and Edge
 * Note: Not using `hyphens: auto;` because it hyphenates text even if not needed
 */
.uk-text-break {
  /* 1 */
  overflow-wrap: break-word;
  /* 2 */
  word-wrap: break-word; }

/* 3 */
th.uk-text-break,
td.uk-text-break {
  word-break: break-all; }

/* ========================================================================
   Component: Column
 ========================================================================== */
[class*='uk-column-'] {
  column-gap: 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  [class*='uk-column-'] {
    column-gap: 40px; } }

/*
 * Fix image 1px line wrapping into the next column in Chrome
 */
[class*='uk-column-'] img {
  transform: translate3d(0, 0, 0); }

/* Divider
 ========================================================================== */
/*
 * 1. Double the column gap
 */
.uk-column-divider {
  column-rule: 1px solid #e5e5e5;
  /* 1 */
  column-gap: 60px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-column-divider {
    column-gap: 80px; } }

/* Width modifiers
 ========================================================================== */
.uk-column-1-2 {
  column-count: 2; }

.uk-column-1-3 {
  column-count: 3; }

.uk-column-1-4 {
  column-count: 4; }

.uk-column-1-5 {
  column-count: 5; }

.uk-column-1-6 {
  column-count: 6; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-column-1-2\@s {
    column-count: 2; }
  .uk-column-1-3\@s {
    column-count: 3; }
  .uk-column-1-4\@s {
    column-count: 4; }
  .uk-column-1-5\@s {
    column-count: 5; }
  .uk-column-1-6\@s {
    column-count: 6; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-column-1-2\@m {
    column-count: 2; }
  .uk-column-1-3\@m {
    column-count: 3; }
  .uk-column-1-4\@m {
    column-count: 4; }
  .uk-column-1-5\@m {
    column-count: 5; }
  .uk-column-1-6\@m {
    column-count: 6; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-column-1-2\@l {
    column-count: 2; }
  .uk-column-1-3\@l {
    column-count: 3; }
  .uk-column-1-4\@l {
    column-count: 4; }
  .uk-column-1-5\@l {
    column-count: 5; }
  .uk-column-1-6\@l {
    column-count: 6; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-column-1-2\@xl {
    column-count: 2; }
  .uk-column-1-3\@xl {
    column-count: 3; }
  .uk-column-1-4\@xl {
    column-count: 4; }
  .uk-column-1-5\@xl {
    column-count: 5; }
  .uk-column-1-6\@xl {
    column-count: 6; } }

/* Make element span across all columns
 * Does not work in Firefox yet
 ========================================================================== */
.uk-column-span {
  column-span: all; }

/* ========================================================================
   Component: Cover
 ========================================================================== */
/*
 * Works with iframes and embedded content
 * 1. Reset responsiveness for embedded content
 * 2. Center object
 * Note: Percent values on the `top` property only works if this element
 *       is absolute positioned or if the container has a height
 */
.uk-cover {
  /* 1 */
  max-width: none;
  /* 2 */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

iframe.uk-cover {
  pointer-events: none; }

/* Container
 ========================================================================== */
/*
 * 1. Parent container which clips resized object
 * 2. Needed if the child is positioned absolute. See note above
 */
.uk-cover-container {
  /* 1 */
  overflow: hidden;
  /* 2 */
  position: relative; }

/* ========================================================================
   Component: Background
 ========================================================================== */
/* Color
 ========================================================================== */
.uk-background-default {
  background-color: #fff; }

.uk-background-muted {
  background-color: #f8f8f8; }

.uk-background-primary {
  background-color: #1e87f0; }

.uk-background-secondary {
  background-color: #222; }

/* Size
 ========================================================================== */
.uk-background-cover,
.uk-background-contain {
  background-position: 50% 50%;
  background-repeat: no-repeat; }

.uk-background-cover {
  background-size: cover; }

.uk-background-contain {
  background-size: contain; }

/* Position
 ========================================================================== */
.uk-background-top-left {
  background-position: 0 0; }

.uk-background-top-center {
  background-position: 50% 0; }

.uk-background-top-right {
  background-position: 100% 0; }

.uk-background-center-left {
  background-position: 0 50%; }

.uk-background-center-center {
  background-position: 50% 50%; }

.uk-background-center-right {
  background-position: 100% 50%; }

.uk-background-bottom-left {
  background-position: 0 100%; }

.uk-background-bottom-center {
  background-position: 50% 100%; }

.uk-background-bottom-right {
  background-position: 100% 100%; }

/* Repeat
 ========================================================================== */
.uk-background-norepeat {
  background-repeat: no-repeat; }

/* Attachment
 ========================================================================== */
/*
 * 1. Fix bug introduced in Chrome 67: the background image is not visible if any element on the page uses `translate3d`
 */
.uk-background-fixed {
  background-attachment: fixed;
  /* 1 */
  backface-visibility: hidden; }

/*
 * Exclude touch devices because `fixed` doesn't work on iOS and Android
 */
@media (pointer: coarse) {
  .uk-background-fixed {
    background-attachment: scroll; } }

/* Image
 ========================================================================== */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-background-image\@s {
    background-image: none !important; } }

/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-background-image\@m {
    background-image: none !important; } }

/* Tablet landscape and smaller */
@media (max-width: 1199px) {
  .uk-background-image\@l {
    background-image: none !important; } }

/* Desktop and smaller */
@media (max-width: 1599px) {
  .uk-background-image\@xl {
    background-image: none !important; } }

/* Blend modes
 ========================================================================== */
.uk-background-blend-multiply {
  background-blend-mode: multiply; }

.uk-background-blend-screen {
  background-blend-mode: screen; }

.uk-background-blend-overlay {
  background-blend-mode: overlay; }

.uk-background-blend-darken {
  background-blend-mode: darken; }

.uk-background-blend-lighten {
  background-blend-mode: lighten; }

.uk-background-blend-color-dodge {
  background-blend-mode: color-dodge; }

.uk-background-blend-color-burn {
  background-blend-mode: color-burn; }

.uk-background-blend-hard-light {
  background-blend-mode: hard-light; }

.uk-background-blend-soft-light {
  background-blend-mode: soft-light; }

.uk-background-blend-difference {
  background-blend-mode: difference; }

.uk-background-blend-exclusion {
  background-blend-mode: exclusion; }

.uk-background-blend-hue {
  background-blend-mode: hue; }

.uk-background-blend-saturation {
  background-blend-mode: saturation; }

.uk-background-blend-color {
  background-blend-mode: color; }

.uk-background-blend-luminosity {
  background-blend-mode: luminosity; }

/* ========================================================================
   Component: Align
 ========================================================================== */
/*
 * Default
 */
[class*='uk-align'] {
  display: block;
  margin-bottom: 30px; }

* + [class*='uk-align'] {
  margin-top: 30px; }

/*
 * Center
 */
.uk-align-center {
  margin-left: auto;
  margin-right: auto; }

/*
 * Left/Right
 */
.uk-align-left {
  margin-top: 0;
  margin-right: 30px;
  float: left; }

.uk-align-right {
  margin-top: 0;
  margin-left: 30px;
  float: right; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-align-left\@s {
    margin-top: 0;
    margin-right: 30px;
    float: left; }
  .uk-align-right\@s {
    margin-top: 0;
    margin-left: 30px;
    float: right; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-align-left\@m {
    margin-top: 0;
    margin-right: 30px;
    float: left; }
  .uk-align-right\@m {
    margin-top: 0;
    margin-left: 30px;
    float: right; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-align-left\@l {
    margin-top: 0;
    float: left; }
  .uk-align-right\@l {
    margin-top: 0;
    float: right; }
  .uk-align-left,
  .uk-align-left\@s,
  .uk-align-left\@m,
  .uk-align-left\@l {
    margin-right: 40px; }
  .uk-align-right,
  .uk-align-right\@s,
  .uk-align-right\@m,
  .uk-align-right\@l {
    margin-left: 40px; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-align-left\@xl {
    margin-top: 0;
    margin-right: 40px;
    float: left; }
  .uk-align-right\@xl {
    margin-top: 0;
    margin-left: 40px;
    float: right; } }

/* ========================================================================
   Component: SVG
 ========================================================================== */
/*
 * 1. Fill all SVG elements with the current text color if no `fill` attribute is set
 * 2. Set the fill and stroke color of all SVG elements to the current text color
 */
/* 1 */
.uk-svg,
.uk-svg:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
  fill: currentcolor; }

.uk-svg:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
  stroke: currentcolor; }

/*
 * Fix Firefox blurry SVG rendering: https://bugzilla.mozilla.org/show_bug.cgi?id=1046835
 */
.uk-svg {
  transform: translate(0, 0); }

/* ========================================================================
   Component: Utility
 ========================================================================== */
/* Panel
 ========================================================================== */
.uk-panel {
  position: relative;
  box-sizing: border-box; }

/*
 * Micro clearfix
 */
.uk-panel::before,
.uk-panel::after {
  content: "";
  display: table; }

.uk-panel::after {
  clear: both; }

/*
 * Remove margin from the last-child
 */
.uk-panel > :last-child {
  margin-bottom: 0; }

/*
 * Scrollable
 */
.uk-panel-scrollable {
  height: 170px;
  padding: 10px;
  border: 1px solid #e5e5e5;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  resize: both; }

/* Clearfix
 ========================================================================== */
/*
 * 1. `table-cell` is used with `::before` because `table` creates a 1px gap when it becomes a flex item, only in Webkit
 * 2. `table` is used again with `::after` because `clear` only works with block elements.
 * Note: `display: block` with `overflow: hidden` is currently not working in the latest Safari
 */
/* 1 */
.uk-clearfix::before {
  content: "";
  display: table-cell; }

/* 2 */
.uk-clearfix::after {
  content: "";
  display: table;
  clear: both; }

/* Float
 ========================================================================== */
/*
 * 1. Prevent content overflow
 */
.uk-float-left {
  float: left; }

.uk-float-right {
  float: right; }

/* 1 */
[class*='uk-float-'] {
  max-width: 100%; }

/* Overfow
 ========================================================================== */
.uk-overflow-hidden {
  overflow: hidden; }

/*
 * Enable scrollbars if content is clipped
 * Note: Firefox ignores `padding-bottom` for the scrollable overflow https://bugzilla.mozilla.org/show_bug.cgi?id=748518
 */
.uk-overflow-auto {
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.uk-overflow-auto > :last-child {
  margin-bottom: 0; }

/* Resize
 ========================================================================== */
.uk-resize {
  resize: both; }

.uk-resize-vertical {
  resize: vertical; }

/* Display
 ========================================================================== */
.uk-display-block {
  display: block !important; }

.uk-display-inline {
  display: inline !important; }

.uk-display-inline-block {
  display: inline-block !important; }

/* Inline
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Behave like most inline-block elements
 * 5. Force hardware acceleration without creating a new stacking context
 *    to fix 1px glitch when combined with overlays and transitions in Webkit
 * 6. Clip child elements
 */
[class*='uk-inline'] {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle;
  /* 5 */
  -webkit-backface-visibility: hidden; }

.uk-inline-clip {
  /* 6 */
  overflow: hidden; }

/* Responsive objects
 ========================================================================== */
/*
 * Preserve original dimensions
 * Because `img, `video`, `canvas` and  `audio` are already responsive by default, see Base component
 */
.uk-preserve-width,
.uk-preserve-width canvas,
.uk-preserve-width img,
.uk-preserve-width svg,
.uk-preserve-width video {
  max-width: none; }

/*
 * Responsiveness
 * Corrects `max-width` and `max-height` behavior if padding and border are used
 */
.uk-responsive-width,
.uk-responsive-height {
  box-sizing: border-box; }

/*
 * 1. Set a maximum width. `important` needed to override `uk-preserve-width img`
 * 2. Auto scale the height. Only needed if `height` attribute is present
 */
.uk-responsive-width {
  /* 1 */
  max-width: 100% !important;
  /* 2 */
  height: auto; }

/*
 * 1. Set a maximum height. Only works if the parent element has a fixed height
 * 2. Auto scale the width. Only needed if `width` attribute is present
 * 3. Reset max-width, which `img, `video`, `canvas` and  `audio` already have by default
 */
.uk-responsive-height {
  /* 1 */
  max-height: 100%;
  /* 2 */
  width: auto;
  /* 3 */
  max-width: none; }

/* Border
 ========================================================================== */
.uk-border-circle {
  border-radius: 50%; }

.uk-border-pill {
  border-radius: 500px; }

.uk-border-rounded {
  border-radius: 5px; }

/*
 * Fix `overflow: hidden` to be ignored with border-radius and CSS transforms in Webkit
 */
.uk-inline-clip[class*='uk-border-'] {
  -webkit-transform: translateZ(0); }

/* Box-shadow
 ========================================================================== */
.uk-box-shadow-small {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08); }

.uk-box-shadow-medium {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08); }

.uk-box-shadow-large {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16); }

.uk-box-shadow-xlarge {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16); }

/*
 * Hover
 */
[class*='uk-box-shadow-hover'] {
  transition: box-shadow 0.1s ease-in-out; }

.uk-box-shadow-hover-small:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08); }

.uk-box-shadow-hover-medium:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08); }

.uk-box-shadow-hover-large:hover {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16); }

.uk-box-shadow-hover-xlarge:hover {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16); }

/* Box-shadow bottom
 ========================================================================== */
/*
 * 1. Set position.
 * 2. Set style
 * 3. Blur doesn't work on pseudo elements with negative `z-index` in Edge.
 *    Solved by using `before` and add position context to child elements.
 */
@supports (filter: blur(0)) { {
    /* 3 */ }
  .uk-box-shadow-bottom {
    display: inline-block;
    position: relative;
    max-width: 100%;
    vertical-align: middle; }
  .uk-box-shadow-bottom::before {
    content: '';
    /* 1 */
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    /* 2 */
    height: 30px;
    border-radius: 100%;
    background: #444;
    filter: blur(20px); }
  .uk-box-shadow-bottom > * {
    position: relative; } }

/* Drop cap
 ========================================================================== */
/*
 * 1. Firefox doesn't apply `::first-letter` if the first letter is inside child elements
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=214004
 * 2. In Firefox, a floating `::first-letter` doesn't have a line box and there for no `line-height`
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=317933
 * 3. Caused by 1.: Edge creates two nested `::first-letter` containers, one for each selector
 *    This doubles the `font-size` exponential when using the `em` unit.
 */
.uk-dropcap::first-letter,
.uk-dropcap > p:first-of-type::first-letter {
  display: block;
  margin-right: 10px;
  float: left;
  font-size: 4.5em;
  line-height: 1; }

/* 2 */
@-moz-document url-prefix() {
  .uk-dropcap::first-letter,
  .uk-dropcap > p:first-of-type::first-letter {
    margin-top: 1.1%; } }

/* 3 */
@supports (-ms-ime-align: auto) {
  .uk-dropcap > p:first-of-type::first-letter {
    font-size: 1em; } }

/* Logo
 ========================================================================== */
/*
 * 1. Required for `a`
 */
.uk-logo {
  font-size: 1.5rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #666;
  /* 1 */
  text-decoration: none; }

/* Hover + Focus */
.uk-logo:hover,
.uk-logo:focus {
  color: #666;
  outline: none;
  /* 1 */
  text-decoration: none; }

.uk-logo-inverse {
  display: none; }

/* Disabled State
 ========================================================================== */
.uk-disabled {
  pointer-events: none; }

/* Drag State
 ========================================================================== */
/*
 * 1. Needed if moving over elements with have their own cursor on hover, e.g. links or buttons
 * 2. Fix dragging over iframes
 */
.uk-drag,
.uk-drag * {
  cursor: move; }

/* 2 */
.uk-drag iframe {
  pointer-events: none; }

/* Dragover State
 ========================================================================== */
/*
 * Create a box-shadow when dragging a file over the upload area
 */
.uk-dragover {
  box-shadow: 0 0 20px rgba(100, 100, 100, 0.3); }

/* Blend modes
 ========================================================================== */
.uk-blend-multiply {
  mix-blend-mode: multiply; }

.uk-blend-screen {
  mix-blend-mode: screen; }

.uk-blend-overlay {
  mix-blend-mode: overlay; }

.uk-blend-darken {
  mix-blend-mode: darken; }

.uk-blend-lighten {
  mix-blend-mode: lighten; }

.uk-blend-color-dodge {
  mix-blend-mode: color-dodge; }

.uk-blend-color-burn {
  mix-blend-mode: color-burn; }

.uk-blend-hard-light {
  mix-blend-mode: hard-light; }

.uk-blend-soft-light {
  mix-blend-mode: soft-light; }

.uk-blend-difference {
  mix-blend-mode: difference; }

.uk-blend-exclusion {
  mix-blend-mode: exclusion; }

.uk-blend-hue {
  mix-blend-mode: hue; }

.uk-blend-saturation {
  mix-blend-mode: saturation; }

.uk-blend-color {
  mix-blend-mode: color; }

.uk-blend-luminosity {
  mix-blend-mode: luminosity; }

/* Transform
========================================================================== */
.uk-transform-center {
  transform: translate(-50%, -50%); }

/* Transform Origin
========================================================================== */
.uk-transform-origin-top-left {
  transform-origin: 0 0; }

.uk-transform-origin-top-center {
  transform-origin: 50% 0; }

.uk-transform-origin-top-right {
  transform-origin: 100% 0; }

.uk-transform-origin-center-left {
  transform-origin: 0 50%; }

.uk-transform-origin-center-right {
  transform-origin: 100% 50%; }

.uk-transform-origin-bottom-left {
  transform-origin: 0 100%; }

.uk-transform-origin-bottom-center {
  transform-origin: 50% 100%; }

.uk-transform-origin-bottom-right {
  transform-origin: 100% 100%; }

/* ========================================================================
   Component: Flex
 ========================================================================== */
.uk-flex {
  display: flex; }

.uk-flex-inline {
  display: inline-flex; }

/*
 * Remove pseudo elements created by micro clearfix as precaution
 */
.uk-flex::before,
.uk-flex::after,
.uk-flex-inline::before,
.uk-flex-inline::after {
  display: none; }

/* Alignment
 ========================================================================== */
/*
 * Align items along the main axis of the current line of the flex container
 * Row: Horizontal
 */
.uk-flex-left {
  justify-content: flex-start; }

.uk-flex-center {
  justify-content: center; }

.uk-flex-right {
  justify-content: flex-end; }

.uk-flex-between {
  justify-content: space-between; }

.uk-flex-around {
  justify-content: space-around; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-left\@s {
    justify-content: flex-start; }
  .uk-flex-center\@s {
    justify-content: center; }
  .uk-flex-right\@s {
    justify-content: flex-end; }
  .uk-flex-between\@s {
    justify-content: space-between; }
  .uk-flex-around\@s {
    justify-content: space-around; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-left\@m {
    justify-content: flex-start; }
  .uk-flex-center\@m {
    justify-content: center; }
  .uk-flex-right\@m {
    justify-content: flex-end; }
  .uk-flex-between\@m {
    justify-content: space-between; }
  .uk-flex-around\@m {
    justify-content: space-around; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-left\@l {
    justify-content: flex-start; }
  .uk-flex-center\@l {
    justify-content: center; }
  .uk-flex-right\@l {
    justify-content: flex-end; }
  .uk-flex-between\@l {
    justify-content: space-between; }
  .uk-flex-around\@l {
    justify-content: space-around; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-left\@xl {
    justify-content: flex-start; }
  .uk-flex-center\@xl {
    justify-content: center; }
  .uk-flex-right\@xl {
    justify-content: flex-end; }
  .uk-flex-between\@xl {
    justify-content: space-between; }
  .uk-flex-around\@xl {
    justify-content: space-around; } }

/*
 * Align items in the cross axis of the current line of the flex container
 * Row: Vertical
 */
.uk-flex-stretch {
  align-items: stretch; }

.uk-flex-top {
  align-items: flex-start; }

.uk-flex-middle {
  align-items: center; }

.uk-flex-bottom {
  align-items: flex-end; }

/* Direction
 ========================================================================== */
.uk-flex-row {
  flex-direction: row; }

.uk-flex-row-reverse {
  flex-direction: row-reverse; }

.uk-flex-column {
  flex-direction: column; }

.uk-flex-column-reverse {
  flex-direction: column-reverse; }

/* Wrap
 ========================================================================== */
.uk-flex-nowrap {
  flex-wrap: nowrap; }

.uk-flex-wrap {
  flex-wrap: wrap; }

.uk-flex-wrap-reverse {
  flex-wrap: wrap-reverse; }

/*
 * Aligns items within the flex container when there is extra space in the cross-axis
 * Only works if there is more than one line of flex items
 */
.uk-flex-wrap-stretch {
  align-content: stretch; }

.uk-flex-wrap-top {
  align-content: flex-start; }

.uk-flex-wrap-middle {
  align-content: center; }

.uk-flex-wrap-bottom {
  align-content: flex-end; }

.uk-flex-wrap-between {
  align-content: space-between; }

.uk-flex-wrap-around {
  align-content: space-around; }

/* Item ordering
 ========================================================================== */
/*
 * Default is 0
 */
.uk-flex-first {
  order: -1; }

.uk-flex-last {
  order: 99; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-first\@s {
    order: -1; }
  .uk-flex-last\@s {
    order: 99; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-first\@m {
    order: -1; }
  .uk-flex-last\@m {
    order: 99; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-first\@l {
    order: -1; }
  .uk-flex-last\@l {
    order: 99; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-first\@xl {
    order: -1; }
  .uk-flex-last\@xl {
    order: 99; } }

/* Item dimensions
 ========================================================================== */
/*
 * Initial: 0 1 auto
 * Content dimensions, but shrinks
 */
/*
 * No Flex: 0 0 auto
 * Content dimensions
 */
.uk-flex-none {
  flex: none; }

/*
 * Relative Flex: 1 1 auto
 * Space is allocated considering content
 */
.uk-flex-auto {
  flex: auto; }

/*
 * Absolute Flex: 1 1 0%
 * Space is allocated solely based on flex
 */
.uk-flex-1 {
  flex: 1; }

/* ========================================================================
   Component: Margin
 ========================================================================== */
/*
 * Default
 */
.uk-margin {
  margin-bottom: 20px; }

* + .uk-margin {
  margin-top: 20px !important; }

.uk-margin-top {
  margin-top: 20px !important; }

.uk-margin-bottom {
  margin-bottom: 20px !important; }

.uk-margin-left {
  margin-left: 20px !important; }

.uk-margin-right {
  margin-right: 20px !important; }

/* Small
 ========================================================================== */
.uk-margin-small {
  margin-bottom: 10px; }

* + .uk-margin-small {
  margin-top: 10px !important; }

.uk-margin-small-top {
  margin-top: 10px !important; }

.uk-margin-small-bottom {
  margin-bottom: 10px !important; }

.uk-margin-small-left {
  margin-left: 10px !important; }

.uk-margin-small-right {
  margin-right: 10px !important; }

/* Medium
 ========================================================================== */
.uk-margin-medium {
  margin-bottom: 40px; }

* + .uk-margin-medium {
  margin-top: 40px !important; }

.uk-margin-medium-top {
  margin-top: 40px !important; }

.uk-margin-medium-bottom {
  margin-bottom: 40px !important; }

.uk-margin-medium-left {
  margin-left: 40px !important; }

.uk-margin-medium-right {
  margin-right: 40px !important; }

/* Large
 ========================================================================== */
.uk-margin-large {
  margin-bottom: 40px; }

* + .uk-margin-large {
  margin-top: 40px !important; }

.uk-margin-large-top {
  margin-top: 40px !important; }

.uk-margin-large-bottom {
  margin-bottom: 40px !important; }

.uk-margin-large-left {
  margin-left: 40px !important; }

.uk-margin-large-right {
  margin-right: 40px !important; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-large {
    margin-bottom: 70px; }
  * + .uk-margin-large {
    margin-top: 70px !important; }
  .uk-margin-large-top {
    margin-top: 70px !important; }
  .uk-margin-large-bottom {
    margin-bottom: 70px !important; }
  .uk-margin-large-left {
    margin-left: 70px !important; }
  .uk-margin-large-right {
    margin-right: 70px !important; } }

/* XLarge
 ========================================================================== */
.uk-margin-xlarge {
  margin-bottom: 70px; }

* + .uk-margin-xlarge {
  margin-top: 70px !important; }

.uk-margin-xlarge-top {
  margin-top: 70px !important; }

.uk-margin-xlarge-bottom {
  margin-bottom: 70px !important; }

.uk-margin-xlarge-left {
  margin-left: 70px !important; }

.uk-margin-xlarge-right {
  margin-right: 70px !important; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-xlarge {
    margin-bottom: 140px; }
  * + .uk-margin-xlarge {
    margin-top: 140px !important; }
  .uk-margin-xlarge-top {
    margin-top: 140px !important; }
  .uk-margin-xlarge-bottom {
    margin-bottom: 140px !important; }
  .uk-margin-xlarge-left {
    margin-left: 140px !important; }
  .uk-margin-xlarge-right {
    margin-right: 140px !important; } }

/* Auto
 ========================================================================== */
.uk-margin-auto {
  margin-left: auto !important;
  margin-right: auto !important; }

.uk-margin-auto-top {
  margin-top: auto !important; }

.uk-margin-auto-bottom {
  margin-bottom: auto !important; }

.uk-margin-auto-left {
  margin-left: auto !important; }

.uk-margin-auto-right {
  margin-right: auto !important; }

.uk-margin-auto-vertical {
  margin-top: auto !important;
  margin-bottom: auto !important; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-auto\@s {
    margin-left: auto !important;
    margin-right: auto !important; }
  .uk-margin-auto-left\@s {
    margin-left: auto !important; }
  .uk-margin-auto-right\@s {
    margin-right: auto !important; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-margin-auto\@m {
    margin-left: auto !important;
    margin-right: auto !important; }
  .uk-margin-auto-left\@m {
    margin-left: auto !important; }
  .uk-margin-auto-right\@m {
    margin-right: auto !important; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-auto\@l {
    margin-left: auto !important;
    margin-right: auto !important; }
  .uk-margin-auto-left\@l {
    margin-left: auto !important; }
  .uk-margin-auto-right\@l {
    margin-right: auto !important; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-margin-auto\@xl {
    margin-left: auto !important;
    margin-right: auto !important; }
  .uk-margin-auto-left\@xl {
    margin-left: auto !important; }
  .uk-margin-auto-right\@xl {
    margin-right: auto !important; } }

/* Remove
 ========================================================================== */
.uk-margin-remove {
  margin: 0 !important; }

.uk-margin-remove-top {
  margin-top: 0 !important; }

.uk-margin-remove-bottom {
  margin-bottom: 0 !important; }

.uk-margin-remove-left {
  margin-left: 0 !important; }

.uk-margin-remove-right {
  margin-right: 0 !important; }

.uk-margin-remove-vertical {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.uk-margin-remove-adjacent + *,
.uk-margin-remove-first-child > :first-child {
  margin-top: 0 !important; }

.uk-margin-remove-last-child > :last-child {
  margin-bottom: 0 !important; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-remove-left\@s {
    margin-left: 0 !important; }
  .uk-margin-remove-right\@s {
    margin-right: 0 !important; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-margin-remove-left\@m {
    margin-left: 0 !important; }
  .uk-margin-remove-right\@m {
    margin-right: 0 !important; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-remove-left\@l {
    margin-left: 0 !important; }
  .uk-margin-remove-right\@l {
    margin-right: 0 !important; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-margin-remove-left\@xl {
    margin-left: 0 !important; }
  .uk-margin-remove-right\@xl {
    margin-right: 0 !important; } }

/* ========================================================================
   Component: Padding
 ========================================================================== */
.uk-padding {
  padding: 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-padding {
    padding: 40px; } }

/* Small
 ========================================================================== */
.uk-padding-small {
  padding: 15px; }

/* Large
 ========================================================================== */
.uk-padding-large {
  padding: 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-padding-large {
    padding: 70px; } }

/* Remove
 ========================================================================== */
.uk-padding-remove {
  padding: 0 !important; }

.uk-padding-remove-top {
  padding-top: 0 !important; }

.uk-padding-remove-bottom {
  padding-bottom: 0 !important; }

.uk-padding-remove-left {
  padding-left: 0 !important; }

.uk-padding-remove-right {
  padding-right: 0 !important; }

.uk-padding-remove-vertical {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.uk-padding-remove-horizontal {
  padding-left: 0 !important;
  padding-right: 0 !important; }

/* ========================================================================
   Component: Position
 ========================================================================== */
/* Directions
 ========================================================================== */
[class*='uk-position-top'],
[class*='uk-position-bottom'],
[class*='uk-position-left'],
[class*='uk-position-right'],
[class*='uk-position-center'] {
  position: absolute !important; }

/* Edges
 ========================================================================== */
/* Don't use `width: 100%` because it is wrong if the parent has padding. */
.uk-position-top {
  top: 0;
  left: 0;
  right: 0; }

.uk-position-bottom {
  bottom: 0;
  left: 0;
  right: 0; }

.uk-position-left {
  top: 0;
  bottom: 0;
  left: 0; }

.uk-position-right {
  top: 0;
  bottom: 0;
  right: 0; }

/* Corners
 ========================================================================== */
.uk-position-top-left {
  top: 0;
  left: 0; }

.uk-position-top-right {
  top: 0;
  right: 0; }

.uk-position-bottom-left {
  bottom: 0;
  left: 0; }

.uk-position-bottom-right {
  bottom: 0;
  right: 0; }

/*
 * Center
 * 1. Fix text wrapping if content is larger than 50% of the container.
 */
.uk-position-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 1 */
  width: max-content;
  max-width: 100%;
  box-sizing: border-box; }

/* Vertical */
[class*='uk-position-center-left'],
[class*='uk-position-center-right'] {
  top: 50%;
  transform: translateY(-50%); }

.uk-position-center-left {
  left: 0; }

.uk-position-center-right {
  right: 0; }

.uk-position-center-left-out {
  right: 100%;
  width: max-content; }

.uk-position-center-right-out {
  left: 100%;
  width: max-content; }

/* Horizontal */
.uk-position-top-center,
.uk-position-bottom-center {
  left: 50%;
  transform: translateX(-50%);
  /* 1 */
  width: max-content;
  max-width: 100%;
  box-sizing: border-box; }

.uk-position-top-center {
  top: 0; }

.uk-position-bottom-center {
  bottom: 0; }

/* Cover
 ========================================================================== */
.uk-position-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

/* Utility
 ========================================================================== */
.uk-position-relative {
  position: relative !important; }

.uk-position-absolute {
  position: absolute !important; }

.uk-position-fixed {
  position: fixed !important; }

.uk-position-z-index {
  z-index: 1; }

/* Margin modifier
 ========================================================================== */
/*
 * Small
 */
.uk-position-small {
  max-width: calc(100% - (15px * 2));
  margin: 15px; }

.uk-position-small.uk-position-center {
  transform: translate(-50%, -50%) translate(-15px, -15px); }

.uk-position-small[class*='uk-position-center-left'],
.uk-position-small[class*='uk-position-center-right'] {
  transform: translateY(-50%) translateY(-15px); }

.uk-position-small.uk-position-top-center,
.uk-position-small.uk-position-bottom-center {
  transform: translateX(-50%) translateX(-15px); }

/*
 * Medium
 */
.uk-position-medium {
  max-width: calc(100% - (30px * 2));
  margin: 30px; }

.uk-position-medium.uk-position-center {
  transform: translate(-50%, -50%) translate(-30px, -30px); }

.uk-position-medium[class*='uk-position-center-left'],
.uk-position-medium[class*='uk-position-center-right'] {
  transform: translateY(-50%) translateY(-30px); }

.uk-position-medium.uk-position-top-center,
.uk-position-medium.uk-position-bottom-center {
  transform: translateX(-50%) translateX(-30px); }

/*
 * Large
 */
.uk-position-large {
  max-width: calc(100% - (30px * 2));
  margin: 30px; }

.uk-position-large.uk-position-center {
  transform: translate(-50%, -50%) translate(-30px, -30px); }

.uk-position-large[class*='uk-position-center-left'],
.uk-position-large[class*='uk-position-center-right'] {
  transform: translateY(-50%) translateY(-30px); }

.uk-position-large.uk-position-top-center,
.uk-position-large.uk-position-bottom-center {
  transform: translateX(-50%) translateX(-30px); }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-position-large {
    max-width: calc(100% - (50px * 2));
    margin: 50px; }
  .uk-position-large.uk-position-center {
    transform: translate(-50%, -50%) translate(-50px, -50px); }
  .uk-position-large[class*='uk-position-center-left'],
  .uk-position-large[class*='uk-position-center-right'] {
    transform: translateY(-50%) translateY(-50px); }
  .uk-position-large.uk-position-top-center,
  .uk-position-large.uk-position-bottom-center {
    transform: translateX(-50%) translateX(-50px); } }

/* ========================================================================
   Component: Transition
 ========================================================================== */
/* Toggle (Hover + Focus)
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-transition-toggle {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/*
 * Remove outline for `tabindex`
 */
.uk-transition-toggle:focus {
  outline: none; }

/* Transitions
 ========================================================================== */
/*
 * The toggle is triggered on touch devices by two methods:
 * 1. Using `:focus` and tabindex
 * 2. Using `:hover` and a `touchstart` event listener registered on the document
 *    (Doesn't work on Surface touch devices)
 *
 * Note: Transitions don't work with `uk-postion-center-*` classes because they also use `transform`,
 *       therefore it's recommended to use an extra `div` for the transition.
 */
.uk-transition-fade,
[class*='uk-transition-scale'],
[class*='uk-transition-slide'] {
  transition: 0.3s ease-out;
  transition-property: opacity, transform, filter;
  opacity: 0; }

/*
 * Fade
 */
.uk-transition-toggle:hover .uk-transition-fade,
.uk-transition-toggle:focus .uk-transition-fade,
.uk-transition-active.uk-active .uk-transition-fade {
  opacity: 1; }

/*
 * Scale
 */
.uk-transition-scale-up {
  transform: scale(1, 1); }

.uk-transition-scale-down {
  transform: scale(1.1, 1.1); }

/* Show */
.uk-transition-toggle:hover .uk-transition-scale-up,
.uk-transition-toggle:focus .uk-transition-scale-up,
.uk-transition-active.uk-active .uk-transition-scale-up {
  opacity: 1;
  transform: scale(1.1, 1.1); }

.uk-transition-toggle:hover .uk-transition-scale-down,
.uk-transition-toggle:focus .uk-transition-scale-down,
.uk-transition-active.uk-active .uk-transition-scale-down {
  opacity: 1;
  transform: scale(1, 1); }

/*
 * Slide
 */
.uk-transition-slide-top {
  transform: translateY(-100%); }

.uk-transition-slide-bottom {
  transform: translateY(100%); }

.uk-transition-slide-left {
  transform: translateX(-100%); }

.uk-transition-slide-right {
  transform: translateX(100%); }

.uk-transition-slide-top-small {
  transform: translateY(-10px); }

.uk-transition-slide-bottom-small {
  transform: translateY(10px); }

.uk-transition-slide-left-small {
  transform: translateX(-10px); }

.uk-transition-slide-right-small {
  transform: translateX(10px); }

.uk-transition-slide-top-medium {
  transform: translateY(-50px); }

.uk-transition-slide-bottom-medium {
  transform: translateY(50px); }

.uk-transition-slide-left-medium {
  transform: translateX(-50px); }

.uk-transition-slide-right-medium {
  transform: translateX(50px); }

/* Show */
.uk-transition-toggle:hover [class*='uk-transition-slide'],
.uk-transition-toggle:focus [class*='uk-transition-slide'],
.uk-transition-active.uk-active [class*='uk-transition-slide'] {
  opacity: 1;
  transform: translate(0, 0); }

/* Opacity modifier
 ========================================================================== */
.uk-transition-opaque {
  opacity: 1; }

/* Duration modifiers
 ========================================================================== */
.uk-transition-slow {
  transition-duration: 0.7s; }

/* ========================================================================
   Component: Visibility
 ========================================================================== */
/*
 * Hidden
 * `hidden` attribute also set here to make it stronger
 */
[hidden],
.uk-hidden {
  display: none !important; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-hidden\@s {
    display: none !important; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-hidden\@m {
    display: none !important; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-hidden\@l {
    display: none !important; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-hidden\@xl {
    display: none !important; } }

/*
 * Visible
 */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-visible\@s {
    display: none !important; } }

/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-visible\@m {
    display: none !important; } }

/* Tablet landscape and smaller */
@media (max-width: 1199px) {
  .uk-visible\@l {
    display: none !important; } }

/* Desktop and smaller */
@media (max-width: 1599px) {
  .uk-visible\@xl {
    display: none !important; } }

/* Visibility
 ========================================================================== */
.uk-invisible {
  visibility: hidden !important; }

/* Toggle (Hover + Focus)
 ========================================================================== */
/*
 * Hidden
 * 1. The toggle is triggered on touch devices using `:focus` and tabindex
 * 2. The target stays visible if any element within receives focus through keyboard
 *    Doesn't work in Edge, yet.
 * 3. Can't use `display: none` nor `visibility: hidden` because both are not focusable.
 *
 */
/* 1 + 2 */
.uk-visible-toggle:not(:hover):not(:focus) .uk-hidden-hover:not(:focus-within) {
  /* 3 */
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important; }

/*
 * Invisible
 */
/* 1 + 2 */
.uk-visible-toggle:not(:hover):not(:focus) .uk-invisible-hover:not(:focus-within) {
  /* 3 */
  opacity: 0 !important; }

/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-visible-toggle {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/*
 * Remove outline for `tabindex`
 */
.uk-visible-toggle:focus {
  outline: none; }

/* Touch
 ========================================================================== */
/*
 * Hide if primary pointing device has limited accuracy, e.g. a touch screen.
 * Works on mobile browsers: Safari, Chrome and Android browser
 */
@media (pointer: coarse) {
  .uk-hidden-touch {
    display: none !important; } }

/*
 * Hide if primary pointing device is accurate, e.g. mouse.
 * 1. Fallback for IE11 and Firefox, because `pointer` is not supported
 * 2. Reset if supported
 */
/* 1 */
.uk-hidden-notouch {
  display: none !important; }

@media (pointer: coarse) {
  .uk-hidden-notouch {
    display: block !important; } }

/* ========================================================================
   Component: Inverse
 ========================================================================== */
/*
 * Implemented class depends on the general theme color
 * `uk-light` is for light colors on dark backgrounds
 * `uk-dark` is or dark colors on light backgrounds
 */
.uk-light, .uk-section-primary:not(.uk-preserve-color), .uk-section-secondary:not(.uk-preserve-color), .uk-tile-primary:not(.uk-preserve-color), .uk-tile-secondary:not(.uk-preserve-color), .uk-card-primary.uk-card-body, .uk-card-primary > :not([class*='uk-card-media']), .uk-card-secondary.uk-card-body, .uk-card-secondary > :not([class*='uk-card-media']), .uk-overlay-primary, .uk-offcanvas-bar {
  color: rgba(255, 255, 255, 0.7); }
  .uk-light a, .uk-section-primary:not(.uk-preserve-color) a, .uk-section-secondary:not(.uk-preserve-color) a, .uk-tile-primary:not(.uk-preserve-color) a, .uk-tile-secondary:not(.uk-preserve-color) a, .uk-card-primary.uk-card-body a, .uk-card-primary > :not([class*='uk-card-media']) a, .uk-card-secondary.uk-card-body a, .uk-card-secondary > :not([class*='uk-card-media']) a, .uk-overlay-primary a, .uk-offcanvas-bar a,
  .uk-light .uk-link, .uk-section-primary:not(.uk-preserve-color) .uk-link, .uk-section-secondary:not(.uk-preserve-color) .uk-link, .uk-tile-primary:not(.uk-preserve-color) .uk-link, .uk-tile-secondary:not(.uk-preserve-color) .uk-link, .uk-card-primary.uk-card-body .uk-link, .uk-card-primary > :not([class*='uk-card-media']) .uk-link, .uk-card-secondary.uk-card-body .uk-link, .uk-card-secondary > :not([class*='uk-card-media']) .uk-link, .uk-overlay-primary .uk-link, .uk-offcanvas-bar .uk-link {
    color: #fff; }
  .uk-light a:hover, .uk-section-primary:not(.uk-preserve-color) a:hover, .uk-section-secondary:not(.uk-preserve-color) a:hover, .uk-tile-primary:not(.uk-preserve-color) a:hover, .uk-tile-secondary:not(.uk-preserve-color) a:hover, .uk-card-primary.uk-card-body a:hover, .uk-card-primary > :not([class*='uk-card-media']) a:hover, .uk-card-secondary.uk-card-body a:hover, .uk-card-secondary > :not([class*='uk-card-media']) a:hover, .uk-overlay-primary a:hover, .uk-offcanvas-bar a:hover,
  .uk-light .uk-link:hover, .uk-section-primary:not(.uk-preserve-color) .uk-link:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-link:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-link:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-link:hover, .uk-card-primary.uk-card-body .uk-link:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-link:hover, .uk-card-secondary.uk-card-body .uk-link:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-link:hover, .uk-overlay-primary .uk-link:hover, .uk-offcanvas-bar .uk-link:hover {
    color: #fff; }
  .uk-light :not(pre) > code, .uk-section-primary:not(.uk-preserve-color) :not(pre) > code, .uk-section-secondary:not(.uk-preserve-color) :not(pre) > code, .uk-tile-primary:not(.uk-preserve-color) :not(pre) > code, .uk-tile-secondary:not(.uk-preserve-color) :not(pre) > code, .uk-card-primary.uk-card-body :not(pre) > code, .uk-card-primary > :not([class*='uk-card-media']) :not(pre) > code, .uk-card-secondary.uk-card-body :not(pre) > code, .uk-card-secondary > :not([class*='uk-card-media']) :not(pre) > code, .uk-overlay-primary :not(pre) > code, .uk-offcanvas-bar :not(pre) > code,
  .uk-light :not(pre) > kbd, .uk-section-primary:not(.uk-preserve-color) :not(pre) > kbd, .uk-section-secondary:not(.uk-preserve-color) :not(pre) > kbd, .uk-tile-primary:not(.uk-preserve-color) :not(pre) > kbd, .uk-tile-secondary:not(.uk-preserve-color) :not(pre) > kbd, .uk-card-primary.uk-card-body :not(pre) > kbd, .uk-card-primary > :not([class*='uk-card-media']) :not(pre) > kbd, .uk-card-secondary.uk-card-body :not(pre) > kbd, .uk-card-secondary > :not([class*='uk-card-media']) :not(pre) > kbd, .uk-overlay-primary :not(pre) > kbd, .uk-offcanvas-bar :not(pre) > kbd,
  .uk-light :not(pre) > samp, .uk-section-primary:not(.uk-preserve-color) :not(pre) > samp, .uk-section-secondary:not(.uk-preserve-color) :not(pre) > samp, .uk-tile-primary:not(.uk-preserve-color) :not(pre) > samp, .uk-tile-secondary:not(.uk-preserve-color) :not(pre) > samp, .uk-card-primary.uk-card-body :not(pre) > samp, .uk-card-primary > :not([class*='uk-card-media']) :not(pre) > samp, .uk-card-secondary.uk-card-body :not(pre) > samp, .uk-card-secondary > :not([class*='uk-card-media']) :not(pre) > samp, .uk-overlay-primary :not(pre) > samp, .uk-offcanvas-bar :not(pre) > samp {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light em, .uk-section-primary:not(.uk-preserve-color) em, .uk-section-secondary:not(.uk-preserve-color) em, .uk-tile-primary:not(.uk-preserve-color) em, .uk-tile-secondary:not(.uk-preserve-color) em, .uk-card-primary.uk-card-body em, .uk-card-primary > :not([class*='uk-card-media']) em, .uk-card-secondary.uk-card-body em, .uk-card-secondary > :not([class*='uk-card-media']) em, .uk-overlay-primary em, .uk-offcanvas-bar em {
    color: #fff; }
  .uk-light h1, .uk-section-primary:not(.uk-preserve-color) h1, .uk-section-secondary:not(.uk-preserve-color) h1, .uk-tile-primary:not(.uk-preserve-color) h1, .uk-tile-secondary:not(.uk-preserve-color) h1, .uk-card-primary.uk-card-body h1, .uk-card-primary > :not([class*='uk-card-media']) h1, .uk-card-secondary.uk-card-body h1, .uk-card-secondary > :not([class*='uk-card-media']) h1, .uk-overlay-primary h1, .uk-offcanvas-bar h1, .uk-light .uk-h1, .uk-section-primary:not(.uk-preserve-color) .uk-h1, .uk-section-secondary:not(.uk-preserve-color) .uk-h1, .uk-tile-primary:not(.uk-preserve-color) .uk-h1, .uk-tile-secondary:not(.uk-preserve-color) .uk-h1, .uk-card-primary.uk-card-body .uk-h1, .uk-card-primary > :not([class*='uk-card-media']) .uk-h1, .uk-card-secondary.uk-card-body .uk-h1, .uk-card-secondary > :not([class*='uk-card-media']) .uk-h1, .uk-overlay-primary .uk-h1, .uk-offcanvas-bar .uk-h1,
  .uk-light h2, .uk-section-primary:not(.uk-preserve-color) h2, .uk-section-secondary:not(.uk-preserve-color) h2, .uk-tile-primary:not(.uk-preserve-color) h2, .uk-tile-secondary:not(.uk-preserve-color) h2, .uk-card-primary.uk-card-body h2, .uk-card-primary > :not([class*='uk-card-media']) h2, .uk-card-secondary.uk-card-body h2, .uk-card-secondary > :not([class*='uk-card-media']) h2, .uk-overlay-primary h2, .uk-offcanvas-bar h2, .uk-light .uk-h2, .uk-section-primary:not(.uk-preserve-color) .uk-h2, .uk-section-secondary:not(.uk-preserve-color) .uk-h2, .uk-tile-primary:not(.uk-preserve-color) .uk-h2, .uk-tile-secondary:not(.uk-preserve-color) .uk-h2, .uk-card-primary.uk-card-body .uk-h2, .uk-card-primary > :not([class*='uk-card-media']) .uk-h2, .uk-card-secondary.uk-card-body .uk-h2, .uk-card-secondary > :not([class*='uk-card-media']) .uk-h2, .uk-overlay-primary .uk-h2, .uk-offcanvas-bar .uk-h2,
  .uk-light h3, .uk-section-primary:not(.uk-preserve-color) h3, .uk-section-secondary:not(.uk-preserve-color) h3, .uk-tile-primary:not(.uk-preserve-color) h3, .uk-tile-secondary:not(.uk-preserve-color) h3, .uk-card-primary.uk-card-body h3, .uk-card-primary > :not([class*='uk-card-media']) h3, .uk-card-secondary.uk-card-body h3, .uk-card-secondary > :not([class*='uk-card-media']) h3, .uk-overlay-primary h3, .uk-offcanvas-bar h3, .uk-light .uk-h3, .uk-section-primary:not(.uk-preserve-color) .uk-h3, .uk-section-secondary:not(.uk-preserve-color) .uk-h3, .uk-tile-primary:not(.uk-preserve-color) .uk-h3, .uk-tile-secondary:not(.uk-preserve-color) .uk-h3, .uk-card-primary.uk-card-body .uk-h3, .uk-card-primary > :not([class*='uk-card-media']) .uk-h3, .uk-card-secondary.uk-card-body .uk-h3, .uk-card-secondary > :not([class*='uk-card-media']) .uk-h3, .uk-overlay-primary .uk-h3, .uk-offcanvas-bar .uk-h3,
  .uk-light h4, .uk-section-primary:not(.uk-preserve-color) h4, .uk-section-secondary:not(.uk-preserve-color) h4, .uk-tile-primary:not(.uk-preserve-color) h4, .uk-tile-secondary:not(.uk-preserve-color) h4, .uk-card-primary.uk-card-body h4, .uk-card-primary > :not([class*='uk-card-media']) h4, .uk-card-secondary.uk-card-body h4, .uk-card-secondary > :not([class*='uk-card-media']) h4, .uk-overlay-primary h4, .uk-offcanvas-bar h4, .uk-light .uk-h4, .uk-section-primary:not(.uk-preserve-color) .uk-h4, .uk-section-secondary:not(.uk-preserve-color) .uk-h4, .uk-tile-primary:not(.uk-preserve-color) .uk-h4, .uk-tile-secondary:not(.uk-preserve-color) .uk-h4, .uk-card-primary.uk-card-body .uk-h4, .uk-card-primary > :not([class*='uk-card-media']) .uk-h4, .uk-card-secondary.uk-card-body .uk-h4, .uk-card-secondary > :not([class*='uk-card-media']) .uk-h4, .uk-overlay-primary .uk-h4, .uk-offcanvas-bar .uk-h4,
  .uk-light h5, .uk-section-primary:not(.uk-preserve-color) h5, .uk-section-secondary:not(.uk-preserve-color) h5, .uk-tile-primary:not(.uk-preserve-color) h5, .uk-tile-secondary:not(.uk-preserve-color) h5, .uk-card-primary.uk-card-body h5, .uk-card-primary > :not([class*='uk-card-media']) h5, .uk-card-secondary.uk-card-body h5, .uk-card-secondary > :not([class*='uk-card-media']) h5, .uk-overlay-primary h5, .uk-offcanvas-bar h5, .uk-light .uk-h5, .uk-section-primary:not(.uk-preserve-color) .uk-h5, .uk-section-secondary:not(.uk-preserve-color) .uk-h5, .uk-tile-primary:not(.uk-preserve-color) .uk-h5, .uk-tile-secondary:not(.uk-preserve-color) .uk-h5, .uk-card-primary.uk-card-body .uk-h5, .uk-card-primary > :not([class*='uk-card-media']) .uk-h5, .uk-card-secondary.uk-card-body .uk-h5, .uk-card-secondary > :not([class*='uk-card-media']) .uk-h5, .uk-overlay-primary .uk-h5, .uk-offcanvas-bar .uk-h5,
  .uk-light h6, .uk-section-primary:not(.uk-preserve-color) h6, .uk-section-secondary:not(.uk-preserve-color) h6, .uk-tile-primary:not(.uk-preserve-color) h6, .uk-tile-secondary:not(.uk-preserve-color) h6, .uk-card-primary.uk-card-body h6, .uk-card-primary > :not([class*='uk-card-media']) h6, .uk-card-secondary.uk-card-body h6, .uk-card-secondary > :not([class*='uk-card-media']) h6, .uk-overlay-primary h6, .uk-offcanvas-bar h6, .uk-light .uk-h6, .uk-section-primary:not(.uk-preserve-color) .uk-h6, .uk-section-secondary:not(.uk-preserve-color) .uk-h6, .uk-tile-primary:not(.uk-preserve-color) .uk-h6, .uk-tile-secondary:not(.uk-preserve-color) .uk-h6, .uk-card-primary.uk-card-body .uk-h6, .uk-card-primary > :not([class*='uk-card-media']) .uk-h6, .uk-card-secondary.uk-card-body .uk-h6, .uk-card-secondary > :not([class*='uk-card-media']) .uk-h6, .uk-overlay-primary .uk-h6, .uk-offcanvas-bar .uk-h6,
  .uk-light .uk-heading-small, .uk-section-primary:not(.uk-preserve-color) .uk-heading-small, .uk-section-secondary:not(.uk-preserve-color) .uk-heading-small, .uk-tile-primary:not(.uk-preserve-color) .uk-heading-small, .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-small, .uk-card-primary.uk-card-body .uk-heading-small, .uk-card-primary > :not([class*='uk-card-media']) .uk-heading-small, .uk-card-secondary.uk-card-body .uk-heading-small, .uk-card-secondary > :not([class*='uk-card-media']) .uk-heading-small, .uk-overlay-primary .uk-heading-small, .uk-offcanvas-bar .uk-heading-small,
  .uk-light .uk-heading-medium, .uk-section-primary:not(.uk-preserve-color) .uk-heading-medium, .uk-section-secondary:not(.uk-preserve-color) .uk-heading-medium, .uk-tile-primary:not(.uk-preserve-color) .uk-heading-medium, .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-medium, .uk-card-primary.uk-card-body .uk-heading-medium, .uk-card-primary > :not([class*='uk-card-media']) .uk-heading-medium, .uk-card-secondary.uk-card-body .uk-heading-medium, .uk-card-secondary > :not([class*='uk-card-media']) .uk-heading-medium, .uk-overlay-primary .uk-heading-medium, .uk-offcanvas-bar .uk-heading-medium,
  .uk-light .uk-heading-large, .uk-section-primary:not(.uk-preserve-color) .uk-heading-large, .uk-section-secondary:not(.uk-preserve-color) .uk-heading-large, .uk-tile-primary:not(.uk-preserve-color) .uk-heading-large, .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-large, .uk-card-primary.uk-card-body .uk-heading-large, .uk-card-primary > :not([class*='uk-card-media']) .uk-heading-large, .uk-card-secondary.uk-card-body .uk-heading-large, .uk-card-secondary > :not([class*='uk-card-media']) .uk-heading-large, .uk-overlay-primary .uk-heading-large, .uk-offcanvas-bar .uk-heading-large,
  .uk-light .uk-heading-xlarge, .uk-section-primary:not(.uk-preserve-color) .uk-heading-xlarge, .uk-section-secondary:not(.uk-preserve-color) .uk-heading-xlarge, .uk-tile-primary:not(.uk-preserve-color) .uk-heading-xlarge, .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-xlarge, .uk-card-primary.uk-card-body .uk-heading-xlarge, .uk-card-primary > :not([class*='uk-card-media']) .uk-heading-xlarge, .uk-card-secondary.uk-card-body .uk-heading-xlarge, .uk-card-secondary > :not([class*='uk-card-media']) .uk-heading-xlarge, .uk-overlay-primary .uk-heading-xlarge, .uk-offcanvas-bar .uk-heading-xlarge,
  .uk-light .uk-heading-2xlarge, .uk-section-primary:not(.uk-preserve-color) .uk-heading-2xlarge, .uk-section-secondary:not(.uk-preserve-color) .uk-heading-2xlarge, .uk-tile-primary:not(.uk-preserve-color) .uk-heading-2xlarge, .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-2xlarge, .uk-card-primary.uk-card-body .uk-heading-2xlarge, .uk-card-primary > :not([class*='uk-card-media']) .uk-heading-2xlarge, .uk-card-secondary.uk-card-body .uk-heading-2xlarge, .uk-card-secondary > :not([class*='uk-card-media']) .uk-heading-2xlarge, .uk-overlay-primary .uk-heading-2xlarge, .uk-offcanvas-bar .uk-heading-2xlarge {
    color: #fff; }
  .uk-light hr, .uk-section-primary:not(.uk-preserve-color) hr, .uk-section-secondary:not(.uk-preserve-color) hr, .uk-tile-primary:not(.uk-preserve-color) hr, .uk-tile-secondary:not(.uk-preserve-color) hr, .uk-card-primary.uk-card-body hr, .uk-card-primary > :not([class*='uk-card-media']) hr, .uk-card-secondary.uk-card-body hr, .uk-card-secondary > :not([class*='uk-card-media']) hr, .uk-overlay-primary hr, .uk-offcanvas-bar hr, .uk-light .uk-hr, .uk-section-primary:not(.uk-preserve-color) .uk-hr, .uk-section-secondary:not(.uk-preserve-color) .uk-hr, .uk-tile-primary:not(.uk-preserve-color) .uk-hr, .uk-tile-secondary:not(.uk-preserve-color) .uk-hr, .uk-card-primary.uk-card-body .uk-hr, .uk-card-primary > :not([class*='uk-card-media']) .uk-hr, .uk-card-secondary.uk-card-body .uk-hr, .uk-card-secondary > :not([class*='uk-card-media']) .uk-hr, .uk-overlay-primary .uk-hr, .uk-offcanvas-bar .uk-hr {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-link-muted, .uk-section-primary:not(.uk-preserve-color) .uk-link-muted, .uk-section-secondary:not(.uk-preserve-color) .uk-link-muted, .uk-tile-primary:not(.uk-preserve-color) .uk-link-muted, .uk-tile-secondary:not(.uk-preserve-color) .uk-link-muted, .uk-card-primary.uk-card-body .uk-link-muted, .uk-card-primary > :not([class*='uk-card-media']) .uk-link-muted, .uk-card-secondary.uk-card-body .uk-link-muted, .uk-card-secondary > :not([class*='uk-card-media']) .uk-link-muted, .uk-overlay-primary .uk-link-muted, .uk-offcanvas-bar .uk-link-muted,
  .uk-light .uk-link-muted a, .uk-section-primary:not(.uk-preserve-color) .uk-link-muted a, .uk-section-secondary:not(.uk-preserve-color) .uk-link-muted a, .uk-tile-primary:not(.uk-preserve-color) .uk-link-muted a, .uk-tile-secondary:not(.uk-preserve-color) .uk-link-muted a, .uk-card-primary.uk-card-body .uk-link-muted a, .uk-card-primary > :not([class*='uk-card-media']) .uk-link-muted a, .uk-card-secondary.uk-card-body .uk-link-muted a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-link-muted a, .uk-overlay-primary .uk-link-muted a, .uk-offcanvas-bar .uk-link-muted a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-link-muted:hover, .uk-section-primary:not(.uk-preserve-color) .uk-link-muted:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-link-muted:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-link-muted:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-link-muted:hover, .uk-card-primary.uk-card-body .uk-link-muted:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-link-muted:hover, .uk-card-secondary.uk-card-body .uk-link-muted:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-link-muted:hover, .uk-overlay-primary .uk-link-muted:hover, .uk-offcanvas-bar .uk-link-muted:hover,
  .uk-light .uk-link-muted a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-link-muted a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-link-muted a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-link-muted a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-link-muted a:hover, .uk-card-primary.uk-card-body .uk-link-muted a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-link-muted a:hover, .uk-card-secondary.uk-card-body .uk-link-muted a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-link-muted a:hover, .uk-overlay-primary .uk-link-muted a:hover, .uk-offcanvas-bar .uk-link-muted a:hover {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-link-text:hover, .uk-section-primary:not(.uk-preserve-color) .uk-link-text:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-link-text:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-link-text:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-link-text:hover, .uk-card-primary.uk-card-body .uk-link-text:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-link-text:hover, .uk-card-secondary.uk-card-body .uk-link-text:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-link-text:hover, .uk-overlay-primary .uk-link-text:hover, .uk-offcanvas-bar .uk-link-text:hover,
  .uk-light .uk-link-text a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-link-text a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-link-text a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-link-text a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-link-text a:hover, .uk-card-primary.uk-card-body .uk-link-text a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-link-text a:hover, .uk-card-secondary.uk-card-body .uk-link-text a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-link-text a:hover, .uk-overlay-primary .uk-link-text a:hover, .uk-offcanvas-bar .uk-link-text a:hover {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-link-heading:hover, .uk-section-primary:not(.uk-preserve-color) .uk-link-heading:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-link-heading:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-link-heading:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-link-heading:hover, .uk-card-primary.uk-card-body .uk-link-heading:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-link-heading:hover, .uk-card-secondary.uk-card-body .uk-link-heading:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-link-heading:hover, .uk-overlay-primary .uk-link-heading:hover, .uk-offcanvas-bar .uk-link-heading:hover,
  .uk-light .uk-link-heading a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-link-heading a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-link-heading a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-link-heading a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-link-heading a:hover, .uk-card-primary.uk-card-body .uk-link-heading a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-link-heading a:hover, .uk-card-secondary.uk-card-body .uk-link-heading a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-link-heading a:hover, .uk-overlay-primary .uk-link-heading a:hover, .uk-offcanvas-bar .uk-link-heading a:hover {
    color: #fff; }
  .uk-light .uk-heading-divider, .uk-section-primary:not(.uk-preserve-color) .uk-heading-divider, .uk-section-secondary:not(.uk-preserve-color) .uk-heading-divider, .uk-tile-primary:not(.uk-preserve-color) .uk-heading-divider, .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-divider, .uk-card-primary.uk-card-body .uk-heading-divider, .uk-card-primary > :not([class*='uk-card-media']) .uk-heading-divider, .uk-card-secondary.uk-card-body .uk-heading-divider, .uk-card-secondary > :not([class*='uk-card-media']) .uk-heading-divider, .uk-overlay-primary .uk-heading-divider, .uk-offcanvas-bar .uk-heading-divider {
    border-bottom-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-heading-bullet::before, .uk-section-primary:not(.uk-preserve-color) .uk-heading-bullet::before, .uk-section-secondary:not(.uk-preserve-color) .uk-heading-bullet::before, .uk-tile-primary:not(.uk-preserve-color) .uk-heading-bullet::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-bullet::before, .uk-card-primary.uk-card-body .uk-heading-bullet::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-heading-bullet::before, .uk-card-secondary.uk-card-body .uk-heading-bullet::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-heading-bullet::before, .uk-overlay-primary .uk-heading-bullet::before, .uk-offcanvas-bar .uk-heading-bullet::before {
    border-left-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-heading-line > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-heading-line > ::before, .uk-section-secondary:not(.uk-preserve-color) .uk-heading-line > ::before, .uk-tile-primary:not(.uk-preserve-color) .uk-heading-line > ::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-line > ::before, .uk-card-primary.uk-card-body .uk-heading-line > ::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-heading-line > ::before, .uk-card-secondary.uk-card-body .uk-heading-line > ::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-heading-line > ::before, .uk-overlay-primary .uk-heading-line > ::before, .uk-offcanvas-bar .uk-heading-line > ::before,
  .uk-light .uk-heading-line > ::after, .uk-section-primary:not(.uk-preserve-color) .uk-heading-line > ::after, .uk-section-secondary:not(.uk-preserve-color) .uk-heading-line > ::after, .uk-tile-primary:not(.uk-preserve-color) .uk-heading-line > ::after, .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-line > ::after, .uk-card-primary.uk-card-body .uk-heading-line > ::after, .uk-card-primary > :not([class*='uk-card-media']) .uk-heading-line > ::after, .uk-card-secondary.uk-card-body .uk-heading-line > ::after, .uk-card-secondary > :not([class*='uk-card-media']) .uk-heading-line > ::after, .uk-overlay-primary .uk-heading-line > ::after, .uk-offcanvas-bar .uk-heading-line > ::after {
    border-bottom-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-divider-icon, .uk-section-primary:not(.uk-preserve-color) .uk-divider-icon, .uk-section-secondary:not(.uk-preserve-color) .uk-divider-icon, .uk-tile-primary:not(.uk-preserve-color) .uk-divider-icon, .uk-tile-secondary:not(.uk-preserve-color) .uk-divider-icon, .uk-card-primary.uk-card-body .uk-divider-icon, .uk-card-primary > :not([class*='uk-card-media']) .uk-divider-icon, .uk-card-secondary.uk-card-body .uk-divider-icon, .uk-card-secondary > :not([class*='uk-card-media']) .uk-divider-icon, .uk-overlay-primary .uk-divider-icon, .uk-offcanvas-bar .uk-divider-icon {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22rgba(255, 255, 255, 0.2)%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A"); }
  .uk-light .uk-divider-icon::before, .uk-section-primary:not(.uk-preserve-color) .uk-divider-icon::before, .uk-section-secondary:not(.uk-preserve-color) .uk-divider-icon::before, .uk-tile-primary:not(.uk-preserve-color) .uk-divider-icon::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-divider-icon::before, .uk-card-primary.uk-card-body .uk-divider-icon::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-divider-icon::before, .uk-card-secondary.uk-card-body .uk-divider-icon::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-divider-icon::before, .uk-overlay-primary .uk-divider-icon::before, .uk-offcanvas-bar .uk-divider-icon::before,
  .uk-light .uk-divider-icon::after, .uk-section-primary:not(.uk-preserve-color) .uk-divider-icon::after, .uk-section-secondary:not(.uk-preserve-color) .uk-divider-icon::after, .uk-tile-primary:not(.uk-preserve-color) .uk-divider-icon::after, .uk-tile-secondary:not(.uk-preserve-color) .uk-divider-icon::after, .uk-card-primary.uk-card-body .uk-divider-icon::after, .uk-card-primary > :not([class*='uk-card-media']) .uk-divider-icon::after, .uk-card-secondary.uk-card-body .uk-divider-icon::after, .uk-card-secondary > :not([class*='uk-card-media']) .uk-divider-icon::after, .uk-overlay-primary .uk-divider-icon::after, .uk-offcanvas-bar .uk-divider-icon::after {
    border-bottom-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-divider-small::after, .uk-section-primary:not(.uk-preserve-color) .uk-divider-small::after, .uk-section-secondary:not(.uk-preserve-color) .uk-divider-small::after, .uk-tile-primary:not(.uk-preserve-color) .uk-divider-small::after, .uk-tile-secondary:not(.uk-preserve-color) .uk-divider-small::after, .uk-card-primary.uk-card-body .uk-divider-small::after, .uk-card-primary > :not([class*='uk-card-media']) .uk-divider-small::after, .uk-card-secondary.uk-card-body .uk-divider-small::after, .uk-card-secondary > :not([class*='uk-card-media']) .uk-divider-small::after, .uk-overlay-primary .uk-divider-small::after, .uk-offcanvas-bar .uk-divider-small::after {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-divider-vertical, .uk-section-primary:not(.uk-preserve-color) .uk-divider-vertical, .uk-section-secondary:not(.uk-preserve-color) .uk-divider-vertical, .uk-tile-primary:not(.uk-preserve-color) .uk-divider-vertical, .uk-tile-secondary:not(.uk-preserve-color) .uk-divider-vertical, .uk-card-primary.uk-card-body .uk-divider-vertical, .uk-card-primary > :not([class*='uk-card-media']) .uk-divider-vertical, .uk-card-secondary.uk-card-body .uk-divider-vertical, .uk-card-secondary > :not([class*='uk-card-media']) .uk-divider-vertical, .uk-overlay-primary .uk-divider-vertical, .uk-offcanvas-bar .uk-divider-vertical {
    border-left-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-list-divider > li:nth-child(n+2), .uk-section-primary:not(.uk-preserve-color) .uk-list-divider > li:nth-child(n+2), .uk-section-secondary:not(.uk-preserve-color) .uk-list-divider > li:nth-child(n+2), .uk-tile-primary:not(.uk-preserve-color) .uk-list-divider > li:nth-child(n+2), .uk-tile-secondary:not(.uk-preserve-color) .uk-list-divider > li:nth-child(n+2), .uk-card-primary.uk-card-body .uk-list-divider > li:nth-child(n+2), .uk-card-primary > :not([class*='uk-card-media']) .uk-list-divider > li:nth-child(n+2), .uk-card-secondary.uk-card-body .uk-list-divider > li:nth-child(n+2), .uk-card-secondary > :not([class*='uk-card-media']) .uk-list-divider > li:nth-child(n+2), .uk-overlay-primary .uk-list-divider > li:nth-child(n+2), .uk-offcanvas-bar .uk-list-divider > li:nth-child(n+2) {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-list-striped > li:nth-of-type(odd), .uk-section-primary:not(.uk-preserve-color) .uk-list-striped > li:nth-of-type(odd), .uk-section-secondary:not(.uk-preserve-color) .uk-list-striped > li:nth-of-type(odd), .uk-tile-primary:not(.uk-preserve-color) .uk-list-striped > li:nth-of-type(odd), .uk-tile-secondary:not(.uk-preserve-color) .uk-list-striped > li:nth-of-type(odd), .uk-card-primary.uk-card-body .uk-list-striped > li:nth-of-type(odd), .uk-card-primary > :not([class*='uk-card-media']) .uk-list-striped > li:nth-of-type(odd), .uk-card-secondary.uk-card-body .uk-list-striped > li:nth-of-type(odd), .uk-card-secondary > :not([class*='uk-card-media']) .uk-list-striped > li:nth-of-type(odd), .uk-overlay-primary .uk-list-striped > li:nth-of-type(odd), .uk-offcanvas-bar .uk-list-striped > li:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.1); }
  .uk-light .uk-list-bullet > li::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-bullet > li::before, .uk-section-secondary:not(.uk-preserve-color) .uk-list-bullet > li::before, .uk-tile-primary:not(.uk-preserve-color) .uk-list-bullet > li::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-list-bullet > li::before, .uk-card-primary.uk-card-body .uk-list-bullet > li::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-list-bullet > li::before, .uk-card-secondary.uk-card-body .uk-list-bullet > li::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-list-bullet > li::before, .uk-overlay-primary .uk-list-bullet > li::before, .uk-offcanvas-bar .uk-list-bullet > li::before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22rgba(255, 255, 255, 0.7)%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E"); }
  .uk-light .uk-icon-link, .uk-section-primary:not(.uk-preserve-color) .uk-icon-link, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-link, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-link, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link, .uk-card-primary.uk-card-body .uk-icon-link, .uk-card-primary > :not([class*='uk-card-media']) .uk-icon-link, .uk-card-secondary.uk-card-body .uk-icon-link, .uk-card-secondary > :not([class*='uk-card-media']) .uk-icon-link, .uk-overlay-primary .uk-icon-link, .uk-offcanvas-bar .uk-icon-link {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-icon-link:hover, .uk-section-primary:not(.uk-preserve-color) .uk-icon-link:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-link:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-link:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link:hover, .uk-card-primary.uk-card-body .uk-icon-link:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-icon-link:hover, .uk-card-secondary.uk-card-body .uk-icon-link:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-icon-link:hover, .uk-overlay-primary .uk-icon-link:hover, .uk-offcanvas-bar .uk-icon-link:hover,
  .uk-light .uk-icon-link:focus, .uk-section-primary:not(.uk-preserve-color) .uk-icon-link:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-link:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-link:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link:focus, .uk-card-primary.uk-card-body .uk-icon-link:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-icon-link:focus, .uk-card-secondary.uk-card-body .uk-icon-link:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-icon-link:focus, .uk-overlay-primary .uk-icon-link:focus, .uk-offcanvas-bar .uk-icon-link:focus {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-icon-link:active, .uk-section-primary:not(.uk-preserve-color) .uk-icon-link:active, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-link:active, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-link:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link:active, .uk-card-primary.uk-card-body .uk-icon-link:active, .uk-card-primary > :not([class*='uk-card-media']) .uk-icon-link:active, .uk-card-secondary.uk-card-body .uk-icon-link:active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-icon-link:active, .uk-overlay-primary .uk-icon-link:active, .uk-offcanvas-bar .uk-icon-link:active,
  .uk-light .uk-active > .uk-icon-link, .uk-section-primary:not(.uk-preserve-color) .uk-active > .uk-icon-link, .uk-section-secondary:not(.uk-preserve-color) .uk-active > .uk-icon-link, .uk-tile-primary:not(.uk-preserve-color) .uk-active > .uk-icon-link, .uk-tile-secondary:not(.uk-preserve-color) .uk-active > .uk-icon-link, .uk-card-primary.uk-card-body .uk-active > .uk-icon-link, .uk-card-primary > :not([class*='uk-card-media']) .uk-active > .uk-icon-link, .uk-card-secondary.uk-card-body .uk-active > .uk-icon-link, .uk-card-secondary > :not([class*='uk-card-media']) .uk-active > .uk-icon-link, .uk-overlay-primary .uk-active > .uk-icon-link, .uk-offcanvas-bar .uk-active > .uk-icon-link {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-icon-button, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-button, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-button, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button, .uk-card-primary.uk-card-body .uk-icon-button, .uk-card-primary > :not([class*='uk-card-media']) .uk-icon-button, .uk-card-secondary.uk-card-body .uk-icon-button, .uk-card-secondary > :not([class*='uk-card-media']) .uk-icon-button, .uk-overlay-primary .uk-icon-button, .uk-offcanvas-bar .uk-icon-button {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-icon-button:hover, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-button:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-button:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button:hover, .uk-card-primary.uk-card-body .uk-icon-button:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-icon-button:hover, .uk-card-secondary.uk-card-body .uk-icon-button:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-icon-button:hover, .uk-overlay-primary .uk-icon-button:hover, .uk-offcanvas-bar .uk-icon-button:hover,
  .uk-light .uk-icon-button:focus, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-button:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-button:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button:focus, .uk-card-primary.uk-card-body .uk-icon-button:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-icon-button:focus, .uk-card-secondary.uk-card-body .uk-icon-button:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-icon-button:focus, .uk-overlay-primary .uk-icon-button:focus, .uk-offcanvas-bar .uk-icon-button:focus {
    background-color: rgba(242, 242, 242, 0.1);
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-icon-button:active, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button:active, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-button:active, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-button:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button:active, .uk-card-primary.uk-card-body .uk-icon-button:active, .uk-card-primary > :not([class*='uk-card-media']) .uk-icon-button:active, .uk-card-secondary.uk-card-body .uk-icon-button:active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-icon-button:active, .uk-overlay-primary .uk-icon-button:active, .uk-offcanvas-bar .uk-icon-button:active {
    background-color: rgba(230, 230, 230, 0.1);
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-input, .uk-section-primary:not(.uk-preserve-color) .uk-input, .uk-section-secondary:not(.uk-preserve-color) .uk-input, .uk-tile-primary:not(.uk-preserve-color) .uk-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-input, .uk-card-primary.uk-card-body .uk-input, .uk-card-primary > :not([class*='uk-card-media']) .uk-input, .uk-card-secondary.uk-card-body .uk-input, .uk-card-secondary > :not([class*='uk-card-media']) .uk-input, .uk-overlay-primary .uk-input, .uk-offcanvas-bar .uk-input,
  .uk-light .uk-select, .uk-section-primary:not(.uk-preserve-color) .uk-select, .uk-section-secondary:not(.uk-preserve-color) .uk-select, .uk-tile-primary:not(.uk-preserve-color) .uk-select, .uk-tile-secondary:not(.uk-preserve-color) .uk-select, .uk-card-primary.uk-card-body .uk-select, .uk-card-primary > :not([class*='uk-card-media']) .uk-select, .uk-card-secondary.uk-card-body .uk-select, .uk-card-secondary > :not([class*='uk-card-media']) .uk-select, .uk-overlay-primary .uk-select, .uk-offcanvas-bar .uk-select,
  .uk-light .uk-textarea, .uk-section-primary:not(.uk-preserve-color) .uk-textarea, .uk-section-secondary:not(.uk-preserve-color) .uk-textarea, .uk-tile-primary:not(.uk-preserve-color) .uk-textarea, .uk-tile-secondary:not(.uk-preserve-color) .uk-textarea, .uk-card-primary.uk-card-body .uk-textarea, .uk-card-primary > :not([class*='uk-card-media']) .uk-textarea, .uk-card-secondary.uk-card-body .uk-textarea, .uk-card-secondary > :not([class*='uk-card-media']) .uk-textarea, .uk-overlay-primary .uk-textarea, .uk-offcanvas-bar .uk-textarea {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.7);
    background-clip: padding-box; }
    .uk-light .uk-input:focus, .uk-section-primary:not(.uk-preserve-color) .uk-input:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-input:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-input:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-input:focus, .uk-card-primary.uk-card-body .uk-input:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-input:focus, .uk-card-secondary.uk-card-body .uk-input:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-input:focus, .uk-overlay-primary .uk-input:focus, .uk-offcanvas-bar .uk-input:focus,
    .uk-light .uk-select:focus, .uk-section-primary:not(.uk-preserve-color) .uk-select:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-select:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-select:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-select:focus, .uk-card-primary.uk-card-body .uk-select:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-select:focus, .uk-card-secondary.uk-card-body .uk-select:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-select:focus, .uk-overlay-primary .uk-select:focus, .uk-offcanvas-bar .uk-select:focus,
    .uk-light .uk-textarea:focus, .uk-section-primary:not(.uk-preserve-color) .uk-textarea:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-textarea:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-textarea:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-textarea:focus, .uk-card-primary.uk-card-body .uk-textarea:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-textarea:focus, .uk-card-secondary.uk-card-body .uk-textarea:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-textarea:focus, .uk-overlay-primary .uk-textarea:focus, .uk-offcanvas-bar .uk-textarea:focus {
      background-color: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-input::-ms-input-placeholder, .uk-section-primary:not(.uk-preserve-color) .uk-input::-ms-input-placeholder, .uk-section-secondary:not(.uk-preserve-color) .uk-input::-ms-input-placeholder, .uk-tile-primary:not(.uk-preserve-color) .uk-input::-ms-input-placeholder, .uk-tile-secondary:not(.uk-preserve-color) .uk-input::-ms-input-placeholder, .uk-card-primary.uk-card-body .uk-input::-ms-input-placeholder, .uk-card-primary > :not([class*='uk-card-media']) .uk-input::-ms-input-placeholder, .uk-card-secondary.uk-card-body .uk-input::-ms-input-placeholder, .uk-card-secondary > :not([class*='uk-card-media']) .uk-input::-ms-input-placeholder, .uk-overlay-primary .uk-input::-ms-input-placeholder, .uk-offcanvas-bar .uk-input::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5) !important; }
  .uk-light .uk-input::placeholder, .uk-section-primary:not(.uk-preserve-color) .uk-input::placeholder, .uk-section-secondary:not(.uk-preserve-color) .uk-input::placeholder, .uk-tile-primary:not(.uk-preserve-color) .uk-input::placeholder, .uk-tile-secondary:not(.uk-preserve-color) .uk-input::placeholder, .uk-card-primary.uk-card-body .uk-input::placeholder, .uk-card-primary > :not([class*='uk-card-media']) .uk-input::placeholder, .uk-card-secondary.uk-card-body .uk-input::placeholder, .uk-card-secondary > :not([class*='uk-card-media']) .uk-input::placeholder, .uk-overlay-primary .uk-input::placeholder, .uk-offcanvas-bar .uk-input::placeholder {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-textarea::-ms-input-placeholder, .uk-section-primary:not(.uk-preserve-color) .uk-textarea::-ms-input-placeholder, .uk-section-secondary:not(.uk-preserve-color) .uk-textarea::-ms-input-placeholder, .uk-tile-primary:not(.uk-preserve-color) .uk-textarea::-ms-input-placeholder, .uk-tile-secondary:not(.uk-preserve-color) .uk-textarea::-ms-input-placeholder, .uk-card-primary.uk-card-body .uk-textarea::-ms-input-placeholder, .uk-card-primary > :not([class*='uk-card-media']) .uk-textarea::-ms-input-placeholder, .uk-card-secondary.uk-card-body .uk-textarea::-ms-input-placeholder, .uk-card-secondary > :not([class*='uk-card-media']) .uk-textarea::-ms-input-placeholder, .uk-overlay-primary .uk-textarea::-ms-input-placeholder, .uk-offcanvas-bar .uk-textarea::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5) !important; }
  .uk-light .uk-textarea::placeholder, .uk-section-primary:not(.uk-preserve-color) .uk-textarea::placeholder, .uk-section-secondary:not(.uk-preserve-color) .uk-textarea::placeholder, .uk-tile-primary:not(.uk-preserve-color) .uk-textarea::placeholder, .uk-tile-secondary:not(.uk-preserve-color) .uk-textarea::placeholder, .uk-card-primary.uk-card-body .uk-textarea::placeholder, .uk-card-primary > :not([class*='uk-card-media']) .uk-textarea::placeholder, .uk-card-secondary.uk-card-body .uk-textarea::placeholder, .uk-card-secondary > :not([class*='uk-card-media']) .uk-textarea::placeholder, .uk-overlay-primary .uk-textarea::placeholder, .uk-offcanvas-bar .uk-textarea::placeholder {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-select:not([multiple]):not([size]), .uk-section-primary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]), .uk-section-secondary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]), .uk-tile-primary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]), .uk-tile-secondary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]), .uk-card-primary.uk-card-body .uk-select:not([multiple]):not([size]), .uk-card-primary > :not([class*='uk-card-media']) .uk-select:not([multiple]):not([size]), .uk-card-secondary.uk-card-body .uk-select:not([multiple]):not([size]), .uk-card-secondary > :not([class*='uk-card-media']) .uk-select:not([multiple]):not([size]), .uk-overlay-primary .uk-select:not([multiple]):not([size]), .uk-offcanvas-bar .uk-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba(255, 255, 255, 0.7)%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba(255, 255, 255, 0.7)%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A"); }
  .uk-light .uk-input[list]:hover, .uk-section-primary:not(.uk-preserve-color) .uk-input[list]:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-input[list]:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-input[list]:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-input[list]:hover, .uk-card-primary.uk-card-body .uk-input[list]:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-input[list]:hover, .uk-card-secondary.uk-card-body .uk-input[list]:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-input[list]:hover, .uk-overlay-primary .uk-input[list]:hover, .uk-offcanvas-bar .uk-input[list]:hover,
  .uk-light .uk-input[list]:focus, .uk-section-primary:not(.uk-preserve-color) .uk-input[list]:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-input[list]:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-input[list]:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-input[list]:focus, .uk-card-primary.uk-card-body .uk-input[list]:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-input[list]:focus, .uk-card-secondary.uk-card-body .uk-input[list]:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-input[list]:focus, .uk-overlay-primary .uk-input[list]:focus, .uk-offcanvas-bar .uk-input[list]:focus {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba(255, 255, 255, 0.7)%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A"); }
  .uk-light .uk-radio, .uk-section-primary:not(.uk-preserve-color) .uk-radio, .uk-section-secondary:not(.uk-preserve-color) .uk-radio, .uk-tile-primary:not(.uk-preserve-color) .uk-radio, .uk-tile-secondary:not(.uk-preserve-color) .uk-radio, .uk-card-primary.uk-card-body .uk-radio, .uk-card-primary > :not([class*='uk-card-media']) .uk-radio, .uk-card-secondary.uk-card-body .uk-radio, .uk-card-secondary > :not([class*='uk-card-media']) .uk-radio, .uk-overlay-primary .uk-radio, .uk-offcanvas-bar .uk-radio,
  .uk-light .uk-checkbox, .uk-section-primary:not(.uk-preserve-color) .uk-checkbox, .uk-section-secondary:not(.uk-preserve-color) .uk-checkbox, .uk-tile-primary:not(.uk-preserve-color) .uk-checkbox, .uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox, .uk-card-primary.uk-card-body .uk-checkbox, .uk-card-primary > :not([class*='uk-card-media']) .uk-checkbox, .uk-card-secondary.uk-card-body .uk-checkbox, .uk-card-secondary > :not([class*='uk-card-media']) .uk-checkbox, .uk-overlay-primary .uk-checkbox, .uk-offcanvas-bar .uk-checkbox {
    background-color: rgba(242, 242, 242, 0.1); }
  .uk-light .uk-radio:checked, .uk-section-primary:not(.uk-preserve-color) .uk-radio:checked, .uk-section-secondary:not(.uk-preserve-color) .uk-radio:checked, .uk-tile-primary:not(.uk-preserve-color) .uk-radio:checked, .uk-tile-secondary:not(.uk-preserve-color) .uk-radio:checked, .uk-card-primary.uk-card-body .uk-radio:checked, .uk-card-primary > :not([class*='uk-card-media']) .uk-radio:checked, .uk-card-secondary.uk-card-body .uk-radio:checked, .uk-card-secondary > :not([class*='uk-card-media']) .uk-radio:checked, .uk-overlay-primary .uk-radio:checked, .uk-offcanvas-bar .uk-radio:checked,
  .uk-light .uk-checkbox:checked, .uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked, .uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:checked, .uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:checked, .uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:checked, .uk-card-primary.uk-card-body .uk-checkbox:checked, .uk-card-primary > :not([class*='uk-card-media']) .uk-checkbox:checked, .uk-card-secondary.uk-card-body .uk-checkbox:checked, .uk-card-secondary > :not([class*='uk-card-media']) .uk-checkbox:checked, .uk-overlay-primary .uk-checkbox:checked, .uk-offcanvas-bar .uk-checkbox:checked,
  .uk-light .uk-checkbox:indeterminate, .uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate, .uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate, .uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate, .uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate, .uk-card-primary.uk-card-body .uk-checkbox:indeterminate, .uk-card-primary > :not([class*='uk-card-media']) .uk-checkbox:indeterminate, .uk-card-secondary.uk-card-body .uk-checkbox:indeterminate, .uk-card-secondary > :not([class*='uk-card-media']) .uk-checkbox:indeterminate, .uk-overlay-primary .uk-checkbox:indeterminate, .uk-offcanvas-bar .uk-checkbox:indeterminate {
    background-color: #fff; }
  .uk-light .uk-radio:checked:focus, .uk-section-primary:not(.uk-preserve-color) .uk-radio:checked:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-radio:checked:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-radio:checked:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-radio:checked:focus, .uk-card-primary.uk-card-body .uk-radio:checked:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-radio:checked:focus, .uk-card-secondary.uk-card-body .uk-radio:checked:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-radio:checked:focus, .uk-overlay-primary .uk-radio:checked:focus, .uk-offcanvas-bar .uk-radio:checked:focus,
  .uk-light .uk-checkbox:checked:focus, .uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:checked:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:checked:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:checked:focus, .uk-card-primary.uk-card-body .uk-checkbox:checked:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-checkbox:checked:focus, .uk-card-secondary.uk-card-body .uk-checkbox:checked:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-checkbox:checked:focus, .uk-overlay-primary .uk-checkbox:checked:focus, .uk-offcanvas-bar .uk-checkbox:checked:focus,
  .uk-light .uk-checkbox:indeterminate:focus, .uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus, .uk-card-primary.uk-card-body .uk-checkbox:indeterminate:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-checkbox:indeterminate:focus, .uk-card-secondary.uk-card-body .uk-checkbox:indeterminate:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-checkbox:indeterminate:focus, .uk-overlay-primary .uk-checkbox:indeterminate:focus, .uk-offcanvas-bar .uk-checkbox:indeterminate:focus {
    background-color: #e6e6e6; }
  .uk-light .uk-radio:checked, .uk-section-primary:not(.uk-preserve-color) .uk-radio:checked, .uk-section-secondary:not(.uk-preserve-color) .uk-radio:checked, .uk-tile-primary:not(.uk-preserve-color) .uk-radio:checked, .uk-tile-secondary:not(.uk-preserve-color) .uk-radio:checked, .uk-card-primary.uk-card-body .uk-radio:checked, .uk-card-primary > :not([class*='uk-card-media']) .uk-radio:checked, .uk-card-secondary.uk-card-body .uk-radio:checked, .uk-card-secondary > :not([class*='uk-card-media']) .uk-radio:checked, .uk-overlay-primary .uk-radio:checked, .uk-offcanvas-bar .uk-radio:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23666%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E"); }
  .uk-light .uk-checkbox:checked, .uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked, .uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:checked, .uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:checked, .uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:checked, .uk-card-primary.uk-card-body .uk-checkbox:checked, .uk-card-primary > :not([class*='uk-card-media']) .uk-checkbox:checked, .uk-card-secondary.uk-card-body .uk-checkbox:checked, .uk-card-secondary > :not([class*='uk-card-media']) .uk-checkbox:checked, .uk-overlay-primary .uk-checkbox:checked, .uk-offcanvas-bar .uk-checkbox:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A"); }
  .uk-light .uk-checkbox:indeterminate, .uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate, .uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate, .uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate, .uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate, .uk-card-primary.uk-card-body .uk-checkbox:indeterminate, .uk-card-primary > :not([class*='uk-card-media']) .uk-checkbox:indeterminate, .uk-card-secondary.uk-card-body .uk-checkbox:indeterminate, .uk-card-secondary > :not([class*='uk-card-media']) .uk-checkbox:indeterminate, .uk-overlay-primary .uk-checkbox:indeterminate, .uk-offcanvas-bar .uk-checkbox:indeterminate {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E"); }
  .uk-light .uk-form-icon, .uk-section-primary:not(.uk-preserve-color) .uk-form-icon, .uk-section-secondary:not(.uk-preserve-color) .uk-form-icon, .uk-tile-primary:not(.uk-preserve-color) .uk-form-icon, .uk-tile-secondary:not(.uk-preserve-color) .uk-form-icon, .uk-card-primary.uk-card-body .uk-form-icon, .uk-card-primary > :not([class*='uk-card-media']) .uk-form-icon, .uk-card-secondary.uk-card-body .uk-form-icon, .uk-card-secondary > :not([class*='uk-card-media']) .uk-form-icon, .uk-overlay-primary .uk-form-icon, .uk-offcanvas-bar .uk-form-icon {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-form-icon:hover, .uk-section-primary:not(.uk-preserve-color) .uk-form-icon:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-form-icon:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-form-icon:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-form-icon:hover, .uk-card-primary.uk-card-body .uk-form-icon:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-form-icon:hover, .uk-card-secondary.uk-card-body .uk-form-icon:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-form-icon:hover, .uk-overlay-primary .uk-form-icon:hover, .uk-offcanvas-bar .uk-form-icon:hover {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-button-default, .uk-section-primary:not(.uk-preserve-color) .uk-button-default, .uk-section-secondary:not(.uk-preserve-color) .uk-button-default, .uk-tile-primary:not(.uk-preserve-color) .uk-button-default, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-default, .uk-card-primary.uk-card-body .uk-button-default, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-default, .uk-card-secondary.uk-card-body .uk-button-default, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-default, .uk-overlay-primary .uk-button-default, .uk-offcanvas-bar .uk-button-default {
    background-color: #fff;
    color: #666; }
  .uk-light .uk-button-default:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-default:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-default:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-default:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-default:hover, .uk-card-primary.uk-card-body .uk-button-default:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-default:hover, .uk-card-secondary.uk-card-body .uk-button-default:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-default:hover, .uk-overlay-primary .uk-button-default:hover, .uk-offcanvas-bar .uk-button-default:hover,
  .uk-light .uk-button-default:focus, .uk-section-primary:not(.uk-preserve-color) .uk-button-default:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-button-default:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-button-default:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-default:focus, .uk-card-primary.uk-card-body .uk-button-default:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-default:focus, .uk-card-secondary.uk-card-body .uk-button-default:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-default:focus, .uk-overlay-primary .uk-button-default:focus, .uk-offcanvas-bar .uk-button-default:focus {
    background-color: #f2f2f2;
    color: #666; }
  .uk-light .uk-button-default:active, .uk-section-primary:not(.uk-preserve-color) .uk-button-default:active, .uk-section-secondary:not(.uk-preserve-color) .uk-button-default:active, .uk-tile-primary:not(.uk-preserve-color) .uk-button-default:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-default:active, .uk-card-primary.uk-card-body .uk-button-default:active, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-default:active, .uk-card-secondary.uk-card-body .uk-button-default:active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-default:active, .uk-overlay-primary .uk-button-default:active, .uk-offcanvas-bar .uk-button-default:active,
  .uk-light .uk-button-default.uk-active, .uk-section-primary:not(.uk-preserve-color) .uk-button-default.uk-active, .uk-section-secondary:not(.uk-preserve-color) .uk-button-default.uk-active, .uk-tile-primary:not(.uk-preserve-color) .uk-button-default.uk-active, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-default.uk-active, .uk-card-primary.uk-card-body .uk-button-default.uk-active, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-default.uk-active, .uk-card-secondary.uk-card-body .uk-button-default.uk-active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-default.uk-active, .uk-overlay-primary .uk-button-default.uk-active, .uk-offcanvas-bar .uk-button-default.uk-active {
    background-color: #e6e6e6;
    color: #666; }
  .uk-light .uk-button-primary, .uk-section-primary:not(.uk-preserve-color) .uk-button-primary, .uk-section-secondary:not(.uk-preserve-color) .uk-button-primary, .uk-tile-primary:not(.uk-preserve-color) .uk-button-primary, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary, .uk-card-primary.uk-card-body .uk-button-primary, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-primary, .uk-card-secondary.uk-card-body .uk-button-primary, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-primary, .uk-overlay-primary .uk-button-primary, .uk-offcanvas-bar .uk-button-primary {
    background-color: #fff;
    color: #666; }
  .uk-light .uk-button-primary:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-primary:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-primary:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-primary:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary:hover, .uk-card-primary.uk-card-body .uk-button-primary:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-primary:hover, .uk-card-secondary.uk-card-body .uk-button-primary:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-primary:hover, .uk-overlay-primary .uk-button-primary:hover, .uk-offcanvas-bar .uk-button-primary:hover,
  .uk-light .uk-button-primary:focus, .uk-section-primary:not(.uk-preserve-color) .uk-button-primary:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-button-primary:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-button-primary:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary:focus, .uk-card-primary.uk-card-body .uk-button-primary:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-primary:focus, .uk-card-secondary.uk-card-body .uk-button-primary:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-primary:focus, .uk-overlay-primary .uk-button-primary:focus, .uk-offcanvas-bar .uk-button-primary:focus {
    background-color: #f2f2f2;
    color: #666; }
  .uk-light .uk-button-primary:active, .uk-section-primary:not(.uk-preserve-color) .uk-button-primary:active, .uk-section-secondary:not(.uk-preserve-color) .uk-button-primary:active, .uk-tile-primary:not(.uk-preserve-color) .uk-button-primary:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary:active, .uk-card-primary.uk-card-body .uk-button-primary:active, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-primary:active, .uk-card-secondary.uk-card-body .uk-button-primary:active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-primary:active, .uk-overlay-primary .uk-button-primary:active, .uk-offcanvas-bar .uk-button-primary:active,
  .uk-light .uk-button-primary.uk-active, .uk-section-primary:not(.uk-preserve-color) .uk-button-primary.uk-active, .uk-section-secondary:not(.uk-preserve-color) .uk-button-primary.uk-active, .uk-tile-primary:not(.uk-preserve-color) .uk-button-primary.uk-active, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary.uk-active, .uk-card-primary.uk-card-body .uk-button-primary.uk-active, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-primary.uk-active, .uk-card-secondary.uk-card-body .uk-button-primary.uk-active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-primary.uk-active, .uk-overlay-primary .uk-button-primary.uk-active, .uk-offcanvas-bar .uk-button-primary.uk-active {
    background-color: #e6e6e6;
    color: #666; }
  .uk-light .uk-button-secondary, .uk-section-primary:not(.uk-preserve-color) .uk-button-secondary, .uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary, .uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary, .uk-card-primary.uk-card-body .uk-button-secondary, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-secondary, .uk-card-secondary.uk-card-body .uk-button-secondary, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-secondary, .uk-overlay-primary .uk-button-secondary, .uk-offcanvas-bar .uk-button-secondary {
    background-color: #fff;
    color: #666; }
  .uk-light .uk-button-secondary:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-secondary:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary:hover, .uk-card-primary.uk-card-body .uk-button-secondary:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-secondary:hover, .uk-card-secondary.uk-card-body .uk-button-secondary:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-secondary:hover, .uk-overlay-primary .uk-button-secondary:hover, .uk-offcanvas-bar .uk-button-secondary:hover,
  .uk-light .uk-button-secondary:focus, .uk-section-primary:not(.uk-preserve-color) .uk-button-secondary:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary:focus, .uk-card-primary.uk-card-body .uk-button-secondary:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-secondary:focus, .uk-card-secondary.uk-card-body .uk-button-secondary:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-secondary:focus, .uk-overlay-primary .uk-button-secondary:focus, .uk-offcanvas-bar .uk-button-secondary:focus {
    background-color: #f2f2f2;
    color: #666; }
  .uk-light .uk-button-secondary:active, .uk-section-primary:not(.uk-preserve-color) .uk-button-secondary:active, .uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary:active, .uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary:active, .uk-card-primary.uk-card-body .uk-button-secondary:active, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-secondary:active, .uk-card-secondary.uk-card-body .uk-button-secondary:active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-secondary:active, .uk-overlay-primary .uk-button-secondary:active, .uk-offcanvas-bar .uk-button-secondary:active,
  .uk-light .uk-button-secondary.uk-active, .uk-section-primary:not(.uk-preserve-color) .uk-button-secondary.uk-active, .uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary.uk-active, .uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary.uk-active, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary.uk-active, .uk-card-primary.uk-card-body .uk-button-secondary.uk-active, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-secondary.uk-active, .uk-card-secondary.uk-card-body .uk-button-secondary.uk-active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-secondary.uk-active, .uk-overlay-primary .uk-button-secondary.uk-active, .uk-offcanvas-bar .uk-button-secondary.uk-active {
    background-color: #e6e6e6;
    color: #666; }
  .uk-light .uk-button-text, .uk-section-primary:not(.uk-preserve-color) .uk-button-text, .uk-section-secondary:not(.uk-preserve-color) .uk-button-text, .uk-tile-primary:not(.uk-preserve-color) .uk-button-text, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-text, .uk-card-primary.uk-card-body .uk-button-text, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-text, .uk-card-secondary.uk-card-body .uk-button-text, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-text, .uk-overlay-primary .uk-button-text, .uk-offcanvas-bar .uk-button-text {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-button-text:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-text:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-text:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-text:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-text:hover, .uk-card-primary.uk-card-body .uk-button-text:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-text:hover, .uk-card-secondary.uk-card-body .uk-button-text:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-text:hover, .uk-overlay-primary .uk-button-text:hover, .uk-offcanvas-bar .uk-button-text:hover,
  .uk-light .uk-button-text:focus, .uk-section-primary:not(.uk-preserve-color) .uk-button-text:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-button-text:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-button-text:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-text:focus, .uk-card-primary.uk-card-body .uk-button-text:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-text:focus, .uk-card-secondary.uk-card-body .uk-button-text:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-text:focus, .uk-overlay-primary .uk-button-text:focus, .uk-offcanvas-bar .uk-button-text:focus {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-button-text:disabled, .uk-section-primary:not(.uk-preserve-color) .uk-button-text:disabled, .uk-section-secondary:not(.uk-preserve-color) .uk-button-text:disabled, .uk-tile-primary:not(.uk-preserve-color) .uk-button-text:disabled, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-text:disabled, .uk-card-primary.uk-card-body .uk-button-text:disabled, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-text:disabled, .uk-card-secondary.uk-card-body .uk-button-text:disabled, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-text:disabled, .uk-overlay-primary .uk-button-text:disabled, .uk-offcanvas-bar .uk-button-text:disabled {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-button-link, .uk-section-primary:not(.uk-preserve-color) .uk-button-link, .uk-section-secondary:not(.uk-preserve-color) .uk-button-link, .uk-tile-primary:not(.uk-preserve-color) .uk-button-link, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-link, .uk-card-primary.uk-card-body .uk-button-link, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-link, .uk-card-secondary.uk-card-body .uk-button-link, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-link, .uk-overlay-primary .uk-button-link, .uk-offcanvas-bar .uk-button-link {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-button-link:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-link:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-link:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-link:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-link:hover, .uk-card-primary.uk-card-body .uk-button-link:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-link:hover, .uk-card-secondary.uk-card-body .uk-button-link:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-link:hover, .uk-overlay-primary .uk-button-link:hover, .uk-offcanvas-bar .uk-button-link:hover,
  .uk-light .uk-button-link:focus, .uk-section-primary:not(.uk-preserve-color) .uk-button-link:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-button-link:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-button-link:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-link:focus, .uk-card-primary.uk-card-body .uk-button-link:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-button-link:focus, .uk-card-secondary.uk-card-body .uk-button-link:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-button-link:focus, .uk-overlay-primary .uk-button-link:focus, .uk-offcanvas-bar .uk-button-link:focus {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-grid-divider > :not(.uk-first-column)::before, .uk-section-primary:not(.uk-preserve-color) .uk-grid-divider > :not(.uk-first-column)::before, .uk-section-secondary:not(.uk-preserve-color) .uk-grid-divider > :not(.uk-first-column)::before, .uk-tile-primary:not(.uk-preserve-color) .uk-grid-divider > :not(.uk-first-column)::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-grid-divider > :not(.uk-first-column)::before, .uk-card-primary.uk-card-body .uk-grid-divider > :not(.uk-first-column)::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-grid-divider > :not(.uk-first-column)::before, .uk-card-secondary.uk-card-body .uk-grid-divider > :not(.uk-first-column)::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-grid-divider > :not(.uk-first-column)::before, .uk-overlay-primary .uk-grid-divider > :not(.uk-first-column)::before, .uk-offcanvas-bar .uk-grid-divider > :not(.uk-first-column)::before {
    border-left-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-section-primary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-section-secondary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-tile-primary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-card-primary.uk-card-body .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-card-secondary.uk-card-body .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-overlay-primary .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-offcanvas-bar .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-close, .uk-section-primary:not(.uk-preserve-color) .uk-close, .uk-section-secondary:not(.uk-preserve-color) .uk-close, .uk-tile-primary:not(.uk-preserve-color) .uk-close, .uk-tile-secondary:not(.uk-preserve-color) .uk-close, .uk-card-primary.uk-card-body .uk-close, .uk-card-primary > :not([class*='uk-card-media']) .uk-close, .uk-card-secondary.uk-card-body .uk-close, .uk-card-secondary > :not([class*='uk-card-media']) .uk-close, .uk-overlay-primary .uk-close, .uk-offcanvas-bar .uk-close {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-close:hover, .uk-section-primary:not(.uk-preserve-color) .uk-close:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-close:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-close:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-close:hover, .uk-card-primary.uk-card-body .uk-close:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-close:hover, .uk-card-secondary.uk-card-body .uk-close:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-close:hover, .uk-overlay-primary .uk-close:hover, .uk-offcanvas-bar .uk-close:hover,
  .uk-light .uk-close:focus, .uk-section-primary:not(.uk-preserve-color) .uk-close:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-close:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-close:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-close:focus, .uk-card-primary.uk-card-body .uk-close:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-close:focus, .uk-card-secondary.uk-card-body .uk-close:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-close:focus, .uk-overlay-primary .uk-close:focus, .uk-offcanvas-bar .uk-close:focus {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-totop, .uk-section-primary:not(.uk-preserve-color) .uk-totop, .uk-section-secondary:not(.uk-preserve-color) .uk-totop, .uk-tile-primary:not(.uk-preserve-color) .uk-totop, .uk-tile-secondary:not(.uk-preserve-color) .uk-totop, .uk-card-primary.uk-card-body .uk-totop, .uk-card-primary > :not([class*='uk-card-media']) .uk-totop, .uk-card-secondary.uk-card-body .uk-totop, .uk-card-secondary > :not([class*='uk-card-media']) .uk-totop, .uk-overlay-primary .uk-totop, .uk-offcanvas-bar .uk-totop {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-totop:hover, .uk-section-primary:not(.uk-preserve-color) .uk-totop:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-totop:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-totop:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-totop:hover, .uk-card-primary.uk-card-body .uk-totop:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-totop:hover, .uk-card-secondary.uk-card-body .uk-totop:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-totop:hover, .uk-overlay-primary .uk-totop:hover, .uk-offcanvas-bar .uk-totop:hover,
  .uk-light .uk-totop:focus, .uk-section-primary:not(.uk-preserve-color) .uk-totop:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-totop:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-totop:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-totop:focus, .uk-card-primary.uk-card-body .uk-totop:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-totop:focus, .uk-card-secondary.uk-card-body .uk-totop:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-totop:focus, .uk-overlay-primary .uk-totop:focus, .uk-offcanvas-bar .uk-totop:focus {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-totop:active, .uk-section-primary:not(.uk-preserve-color) .uk-totop:active, .uk-section-secondary:not(.uk-preserve-color) .uk-totop:active, .uk-tile-primary:not(.uk-preserve-color) .uk-totop:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-totop:active, .uk-card-primary.uk-card-body .uk-totop:active, .uk-card-primary > :not([class*='uk-card-media']) .uk-totop:active, .uk-card-secondary.uk-card-body .uk-totop:active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-totop:active, .uk-overlay-primary .uk-totop:active, .uk-offcanvas-bar .uk-totop:active {
    color: #fff; }
  .uk-light .uk-badge, .uk-section-primary:not(.uk-preserve-color) .uk-badge, .uk-section-secondary:not(.uk-preserve-color) .uk-badge, .uk-tile-primary:not(.uk-preserve-color) .uk-badge, .uk-tile-secondary:not(.uk-preserve-color) .uk-badge, .uk-card-primary.uk-card-body .uk-badge, .uk-card-primary > :not([class*='uk-card-media']) .uk-badge, .uk-card-secondary.uk-card-body .uk-badge, .uk-card-secondary > :not([class*='uk-card-media']) .uk-badge, .uk-overlay-primary .uk-badge, .uk-offcanvas-bar .uk-badge {
    background-color: #fff;
    color: #666; }
  .uk-light .uk-badge:hover, .uk-section-primary:not(.uk-preserve-color) .uk-badge:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-badge:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-badge:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-badge:hover, .uk-card-primary.uk-card-body .uk-badge:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-badge:hover, .uk-card-secondary.uk-card-body .uk-badge:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-badge:hover, .uk-overlay-primary .uk-badge:hover, .uk-offcanvas-bar .uk-badge:hover,
  .uk-light .uk-badge:focus, .uk-section-primary:not(.uk-preserve-color) .uk-badge:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-badge:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-badge:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-badge:focus, .uk-card-primary.uk-card-body .uk-badge:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-badge:focus, .uk-card-secondary.uk-card-body .uk-badge:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-badge:focus, .uk-overlay-primary .uk-badge:focus, .uk-offcanvas-bar .uk-badge:focus {
    color: #666; }
  .uk-light .uk-label, .uk-section-primary:not(.uk-preserve-color) .uk-label, .uk-section-secondary:not(.uk-preserve-color) .uk-label, .uk-tile-primary:not(.uk-preserve-color) .uk-label, .uk-tile-secondary:not(.uk-preserve-color) .uk-label, .uk-card-primary.uk-card-body .uk-label, .uk-card-primary > :not([class*='uk-card-media']) .uk-label, .uk-card-secondary.uk-card-body .uk-label, .uk-card-secondary > :not([class*='uk-card-media']) .uk-label, .uk-overlay-primary .uk-label, .uk-offcanvas-bar .uk-label {
    background-color: #fff;
    color: #666; }
  .uk-light .uk-article-meta, .uk-section-primary:not(.uk-preserve-color) .uk-article-meta, .uk-section-secondary:not(.uk-preserve-color) .uk-article-meta, .uk-tile-primary:not(.uk-preserve-color) .uk-article-meta, .uk-tile-secondary:not(.uk-preserve-color) .uk-article-meta, .uk-card-primary.uk-card-body .uk-article-meta, .uk-card-primary > :not([class*='uk-card-media']) .uk-article-meta, .uk-card-secondary.uk-card-body .uk-article-meta, .uk-card-secondary > :not([class*='uk-card-media']) .uk-article-meta, .uk-overlay-primary .uk-article-meta, .uk-offcanvas-bar .uk-article-meta {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-input, .uk-section-secondary:not(.uk-preserve-color) .uk-search-input, .uk-tile-primary:not(.uk-preserve-color) .uk-search-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-input, .uk-card-primary.uk-card-body .uk-search-input, .uk-card-primary > :not([class*='uk-card-media']) .uk-search-input, .uk-card-secondary.uk-card-body .uk-search-input, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search-input, .uk-overlay-primary .uk-search-input, .uk-offcanvas-bar .uk-search-input {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-search-input:-ms-input-placeholder, .uk-section-primary:not(.uk-preserve-color) .uk-search-input:-ms-input-placeholder, .uk-section-secondary:not(.uk-preserve-color) .uk-search-input:-ms-input-placeholder, .uk-tile-primary:not(.uk-preserve-color) .uk-search-input:-ms-input-placeholder, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-input:-ms-input-placeholder, .uk-card-primary.uk-card-body .uk-search-input:-ms-input-placeholder, .uk-card-primary > :not([class*='uk-card-media']) .uk-search-input:-ms-input-placeholder, .uk-card-secondary.uk-card-body .uk-search-input:-ms-input-placeholder, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search-input:-ms-input-placeholder, .uk-overlay-primary .uk-search-input:-ms-input-placeholder, .uk-offcanvas-bar .uk-search-input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5) !important; }
  .uk-light .uk-search-input::placeholder, .uk-section-primary:not(.uk-preserve-color) .uk-search-input::placeholder, .uk-section-secondary:not(.uk-preserve-color) .uk-search-input::placeholder, .uk-tile-primary:not(.uk-preserve-color) .uk-search-input::placeholder, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-input::placeholder, .uk-card-primary.uk-card-body .uk-search-input::placeholder, .uk-card-primary > :not([class*='uk-card-media']) .uk-search-input::placeholder, .uk-card-secondary.uk-card-body .uk-search-input::placeholder, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search-input::placeholder, .uk-overlay-primary .uk-search-input::placeholder, .uk-offcanvas-bar .uk-search-input::placeholder {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-search .uk-search-icon, .uk-section-primary:not(.uk-preserve-color) .uk-search .uk-search-icon, .uk-section-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon, .uk-tile-primary:not(.uk-preserve-color) .uk-search .uk-search-icon, .uk-tile-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon, .uk-card-primary.uk-card-body .uk-search .uk-search-icon, .uk-card-primary > :not([class*='uk-card-media']) .uk-search .uk-search-icon, .uk-card-secondary.uk-card-body .uk-search .uk-search-icon, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search .uk-search-icon, .uk-overlay-primary .uk-search .uk-search-icon, .uk-offcanvas-bar .uk-search .uk-search-icon {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-search .uk-search-icon:hover, .uk-section-primary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover, .uk-card-primary.uk-card-body .uk-search .uk-search-icon:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-search .uk-search-icon:hover, .uk-card-secondary.uk-card-body .uk-search .uk-search-icon:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search .uk-search-icon:hover, .uk-overlay-primary .uk-search .uk-search-icon:hover, .uk-offcanvas-bar .uk-search .uk-search-icon:hover {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-search-default .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input, .uk-section-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input, .uk-tile-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input, .uk-card-primary.uk-card-body .uk-search-default .uk-search-input, .uk-card-primary > :not([class*='uk-card-media']) .uk-search-default .uk-search-input, .uk-card-secondary.uk-card-body .uk-search-default .uk-search-input, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search-default .uk-search-input, .uk-overlay-primary .uk-search-default .uk-search-input, .uk-offcanvas-bar .uk-search-default .uk-search-input {
    background-color: rgba(255, 255, 255, 0.1); }
  .uk-light .uk-search-default .uk-search-input:focus, .uk-section-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus, .uk-card-primary.uk-card-body .uk-search-default .uk-search-input:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-search-default .uk-search-input:focus, .uk-card-secondary.uk-card-body .uk-search-default .uk-search-input:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search-default .uk-search-input:focus, .uk-overlay-primary .uk-search-default .uk-search-input:focus, .uk-offcanvas-bar .uk-search-default .uk-search-input:focus {
    background-color: rgba(255, 255, 255, 0.1); }
  .uk-light .uk-search-navbar .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input, .uk-section-secondary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input, .uk-tile-primary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input, .uk-card-primary.uk-card-body .uk-search-navbar .uk-search-input, .uk-card-primary > :not([class*='uk-card-media']) .uk-search-navbar .uk-search-input, .uk-card-secondary.uk-card-body .uk-search-navbar .uk-search-input, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search-navbar .uk-search-input, .uk-overlay-primary .uk-search-navbar .uk-search-input, .uk-offcanvas-bar .uk-search-navbar .uk-search-input {
    background-color: transparent; }
  .uk-light .uk-search-large .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-large .uk-search-input, .uk-section-secondary:not(.uk-preserve-color) .uk-search-large .uk-search-input, .uk-tile-primary:not(.uk-preserve-color) .uk-search-large .uk-search-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-large .uk-search-input, .uk-card-primary.uk-card-body .uk-search-large .uk-search-input, .uk-card-primary > :not([class*='uk-card-media']) .uk-search-large .uk-search-input, .uk-card-secondary.uk-card-body .uk-search-large .uk-search-input, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search-large .uk-search-input, .uk-overlay-primary .uk-search-large .uk-search-input, .uk-offcanvas-bar .uk-search-large .uk-search-input {
    background-color: transparent; }
  .uk-light .uk-search-toggle, .uk-section-primary:not(.uk-preserve-color) .uk-search-toggle, .uk-section-secondary:not(.uk-preserve-color) .uk-search-toggle, .uk-tile-primary:not(.uk-preserve-color) .uk-search-toggle, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-toggle, .uk-card-primary.uk-card-body .uk-search-toggle, .uk-card-primary > :not([class*='uk-card-media']) .uk-search-toggle, .uk-card-secondary.uk-card-body .uk-search-toggle, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search-toggle, .uk-overlay-primary .uk-search-toggle, .uk-offcanvas-bar .uk-search-toggle {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-search-toggle:hover, .uk-section-primary:not(.uk-preserve-color) .uk-search-toggle:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-search-toggle:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-search-toggle:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-toggle:hover, .uk-card-primary.uk-card-body .uk-search-toggle:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-search-toggle:hover, .uk-card-secondary.uk-card-body .uk-search-toggle:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search-toggle:hover, .uk-overlay-primary .uk-search-toggle:hover, .uk-offcanvas-bar .uk-search-toggle:hover,
  .uk-light .uk-search-toggle:focus, .uk-section-primary:not(.uk-preserve-color) .uk-search-toggle:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-search-toggle:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-search-toggle:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-toggle:focus, .uk-card-primary.uk-card-body .uk-search-toggle:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-search-toggle:focus, .uk-card-secondary.uk-card-body .uk-search-toggle:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-search-toggle:focus, .uk-overlay-primary .uk-search-toggle:focus, .uk-offcanvas-bar .uk-search-toggle:focus {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-nav-parent-icon > .uk-parent > a::after, .uk-section-primary:not(.uk-preserve-color) .uk-nav-parent-icon > .uk-parent > a::after, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-parent-icon > .uk-parent > a::after, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-parent-icon > .uk-parent > a::after, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-parent-icon > .uk-parent > a::after, .uk-card-primary.uk-card-body .uk-nav-parent-icon > .uk-parent > a::after, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-parent-icon > .uk-parent > a::after, .uk-card-secondary.uk-card-body .uk-nav-parent-icon > .uk-parent > a::after, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-parent-icon > .uk-parent > a::after, .uk-overlay-primary .uk-nav-parent-icon > .uk-parent > a::after, .uk-offcanvas-bar .uk-nav-parent-icon > .uk-parent > a::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22rgba(255, 255, 255, 0.7)%22%20stroke-width%3D%221.1%22%20points%3D%2210%201%204%207%2010%2013%22%20%2F%3E%0A%3C%2Fsvg%3E"); }
  .uk-light .uk-nav-parent-icon > .uk-parent.uk-open > a::after, .uk-section-primary:not(.uk-preserve-color) .uk-nav-parent-icon > .uk-parent.uk-open > a::after, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-parent-icon > .uk-parent.uk-open > a::after, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-parent-icon > .uk-parent.uk-open > a::after, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-parent-icon > .uk-parent.uk-open > a::after, .uk-card-primary.uk-card-body .uk-nav-parent-icon > .uk-parent.uk-open > a::after, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-parent-icon > .uk-parent.uk-open > a::after, .uk-card-secondary.uk-card-body .uk-nav-parent-icon > .uk-parent.uk-open > a::after, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-parent-icon > .uk-parent.uk-open > a::after, .uk-overlay-primary .uk-nav-parent-icon > .uk-parent.uk-open > a::after, .uk-offcanvas-bar .uk-nav-parent-icon > .uk-parent.uk-open > a::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22rgba(255, 255, 255, 0.7)%22%20stroke-width%3D%221.1%22%20points%3D%221%204%207%2010%2013%204%22%20%2F%3E%0A%3C%2Fsvg%3E"); }
  .uk-light .uk-nav-default > li > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default > li > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default > li > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default > li > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default > li > a, .uk-card-primary.uk-card-body .uk-nav-default > li > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-default > li > a, .uk-card-secondary.uk-card-body .uk-nav-default > li > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-default > li > a, .uk-overlay-primary .uk-nav-default > li > a, .uk-offcanvas-bar .uk-nav-default > li > a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-nav-default > li > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default > li > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default > li > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default > li > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default > li > a:hover, .uk-card-primary.uk-card-body .uk-nav-default > li > a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-default > li > a:hover, .uk-card-secondary.uk-card-body .uk-nav-default > li > a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-default > li > a:hover, .uk-overlay-primary .uk-nav-default > li > a:hover, .uk-offcanvas-bar .uk-nav-default > li > a:hover,
  .uk-light .uk-nav-default > li > a:focus, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default > li > a:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default > li > a:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default > li > a:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default > li > a:focus, .uk-card-primary.uk-card-body .uk-nav-default > li > a:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-default > li > a:focus, .uk-card-secondary.uk-card-body .uk-nav-default > li > a:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-default > li > a:focus, .uk-overlay-primary .uk-nav-default > li > a:focus, .uk-offcanvas-bar .uk-nav-default > li > a:focus {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-nav-default > li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default > li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default > li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default > li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default > li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-default > li.uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-default > li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-default > li.uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-default > li.uk-active > a, .uk-overlay-primary .uk-nav-default > li.uk-active > a, .uk-offcanvas-bar .uk-nav-default > li.uk-active > a {
    color: #fff; }
  .uk-light .uk-nav-default .uk-nav-header, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-header, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-header, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-header, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-header, .uk-overlay-primary .uk-nav-default .uk-nav-header, .uk-offcanvas-bar .uk-nav-default .uk-nav-header {
    color: #fff; }
  .uk-light .uk-nav-default .uk-nav-divider, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-divider, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-divider, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-divider, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-divider, .uk-overlay-primary .uk-nav-default .uk-nav-divider, .uk-offcanvas-bar .uk-nav-default .uk-nav-divider {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-nav-default .uk-nav-sub a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub a, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub a, .uk-overlay-primary .uk-nav-default .uk-nav-sub a, .uk-offcanvas-bar .uk-nav-default .uk-nav-sub a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-nav-default .uk-nav-sub a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub a:hover, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub a:hover, .uk-overlay-primary .uk-nav-default .uk-nav-sub a:hover, .uk-offcanvas-bar .uk-nav-default .uk-nav-sub a:hover,
  .uk-light .uk-nav-default .uk-nav-sub a:focus, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:focus, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub a:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub a:focus, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub a:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub a:focus, .uk-overlay-primary .uk-nav-default .uk-nav-sub a:focus, .uk-offcanvas-bar .uk-nav-default .uk-nav-sub a:focus {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-overlay-primary .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-offcanvas-bar .uk-nav-default .uk-nav-sub li.uk-active > a {
    color: #fff; }
  .uk-light .uk-nav-primary > li > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary > li > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary > li > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary > li > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary > li > a, .uk-card-primary.uk-card-body .uk-nav-primary > li > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-primary > li > a, .uk-card-secondary.uk-card-body .uk-nav-primary > li > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-primary > li > a, .uk-overlay-primary .uk-nav-primary > li > a, .uk-offcanvas-bar .uk-nav-primary > li > a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-nav-primary > li > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary > li > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary > li > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary > li > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary > li > a:hover, .uk-card-primary.uk-card-body .uk-nav-primary > li > a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-primary > li > a:hover, .uk-card-secondary.uk-card-body .uk-nav-primary > li > a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-primary > li > a:hover, .uk-overlay-primary .uk-nav-primary > li > a:hover, .uk-offcanvas-bar .uk-nav-primary > li > a:hover,
  .uk-light .uk-nav-primary > li > a:focus, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary > li > a:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary > li > a:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary > li > a:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary > li > a:focus, .uk-card-primary.uk-card-body .uk-nav-primary > li > a:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-primary > li > a:focus, .uk-card-secondary.uk-card-body .uk-nav-primary > li > a:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-primary > li > a:focus, .uk-overlay-primary .uk-nav-primary > li > a:focus, .uk-offcanvas-bar .uk-nav-primary > li > a:focus {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-nav-primary > li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary > li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary > li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary > li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary > li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-primary > li.uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-primary > li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-primary > li.uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-primary > li.uk-active > a, .uk-overlay-primary .uk-nav-primary > li.uk-active > a, .uk-offcanvas-bar .uk-nav-primary > li.uk-active > a {
    color: #fff; }
  .uk-light .uk-nav-primary .uk-nav-header, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-header, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-header, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-header, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-header, .uk-overlay-primary .uk-nav-primary .uk-nav-header, .uk-offcanvas-bar .uk-nav-primary .uk-nav-header {
    color: #fff; }
  .uk-light .uk-nav-primary .uk-nav-divider, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-divider, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-divider, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-divider, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-divider, .uk-overlay-primary .uk-nav-primary .uk-nav-divider, .uk-offcanvas-bar .uk-nav-primary .uk-nav-divider {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-nav-primary .uk-nav-sub a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub a, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub a, .uk-overlay-primary .uk-nav-primary .uk-nav-sub a, .uk-offcanvas-bar .uk-nav-primary .uk-nav-sub a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-nav-primary .uk-nav-sub a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub a:hover, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub a:hover, .uk-overlay-primary .uk-nav-primary .uk-nav-sub a:hover, .uk-offcanvas-bar .uk-nav-primary .uk-nav-sub a:hover,
  .uk-light .uk-nav-primary .uk-nav-sub a:focus, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:focus, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub a:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub a:focus, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub a:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub a:focus, .uk-overlay-primary .uk-nav-primary .uk-nav-sub a:focus, .uk-offcanvas-bar .uk-nav-primary .uk-nav-sub a:focus {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-overlay-primary .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-offcanvas-bar .uk-nav-primary .uk-nav-sub li.uk-active > a {
    color: #fff; }
  .uk-light .uk-navbar-nav > li > a, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a, .uk-card-primary.uk-card-body .uk-navbar-nav > li > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-nav > li > a, .uk-card-secondary.uk-card-body .uk-navbar-nav > li > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-nav > li > a, .uk-overlay-primary .uk-navbar-nav > li > a, .uk-offcanvas-bar .uk-navbar-nav > li > a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-navbar-nav > li:hover > a, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li:hover > a, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li:hover > a, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li:hover > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li:hover > a, .uk-card-primary.uk-card-body .uk-navbar-nav > li:hover > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-nav > li:hover > a, .uk-card-secondary.uk-card-body .uk-navbar-nav > li:hover > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-nav > li:hover > a, .uk-overlay-primary .uk-navbar-nav > li:hover > a, .uk-offcanvas-bar .uk-navbar-nav > li:hover > a,
  .uk-light .uk-navbar-nav > li > a:focus, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a:focus, .uk-card-primary.uk-card-body .uk-navbar-nav > li > a:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-nav > li > a:focus, .uk-card-secondary.uk-card-body .uk-navbar-nav > li > a:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-nav > li > a:focus, .uk-overlay-primary .uk-navbar-nav > li > a:focus, .uk-offcanvas-bar .uk-navbar-nav > li > a:focus,
  .uk-light .uk-navbar-nav > li > a.uk-open, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a.uk-open, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a.uk-open, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a.uk-open, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a.uk-open, .uk-card-primary.uk-card-body .uk-navbar-nav > li > a.uk-open, .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-nav > li > a.uk-open, .uk-card-secondary.uk-card-body .uk-navbar-nav > li > a.uk-open, .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-nav > li > a.uk-open, .uk-overlay-primary .uk-navbar-nav > li > a.uk-open, .uk-offcanvas-bar .uk-navbar-nav > li > a.uk-open {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-navbar-nav > li > a:active, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a:active, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a:active, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a:active, .uk-card-primary.uk-card-body .uk-navbar-nav > li > a:active, .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-nav > li > a:active, .uk-card-secondary.uk-card-body .uk-navbar-nav > li > a:active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-nav > li > a:active, .uk-overlay-primary .uk-navbar-nav > li > a:active, .uk-offcanvas-bar .uk-navbar-nav > li > a:active {
    color: #fff; }
  .uk-light .uk-navbar-nav > li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li.uk-active > a, .uk-card-primary.uk-card-body .uk-navbar-nav > li.uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-nav > li.uk-active > a, .uk-card-secondary.uk-card-body .uk-navbar-nav > li.uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-nav > li.uk-active > a, .uk-overlay-primary .uk-navbar-nav > li.uk-active > a, .uk-offcanvas-bar .uk-navbar-nav > li.uk-active > a {
    color: #fff; }
  .uk-light .uk-navbar-item, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-item, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-item, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-item, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-item, .uk-card-primary.uk-card-body .uk-navbar-item, .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-item, .uk-card-secondary.uk-card-body .uk-navbar-item, .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-item, .uk-overlay-primary .uk-navbar-item, .uk-offcanvas-bar .uk-navbar-item {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-navbar-toggle, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle, .uk-card-primary.uk-card-body .uk-navbar-toggle, .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-toggle, .uk-card-secondary.uk-card-body .uk-navbar-toggle, .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-toggle, .uk-overlay-primary .uk-navbar-toggle, .uk-offcanvas-bar .uk-navbar-toggle {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-navbar-toggle:hover, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle:hover, .uk-card-primary.uk-card-body .uk-navbar-toggle:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-toggle:hover, .uk-card-secondary.uk-card-body .uk-navbar-toggle:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-toggle:hover, .uk-overlay-primary .uk-navbar-toggle:hover, .uk-offcanvas-bar .uk-navbar-toggle:hover,
  .uk-light .uk-navbar-toggle:focus, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle:focus, .uk-card-primary.uk-card-body .uk-navbar-toggle:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-toggle:focus, .uk-card-secondary.uk-card-body .uk-navbar-toggle:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-toggle:focus, .uk-overlay-primary .uk-navbar-toggle:focus, .uk-offcanvas-bar .uk-navbar-toggle:focus,
  .uk-light .uk-navbar-toggle.uk-open, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle.uk-open, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle.uk-open, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle.uk-open, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle.uk-open, .uk-card-primary.uk-card-body .uk-navbar-toggle.uk-open, .uk-card-primary > :not([class*='uk-card-media']) .uk-navbar-toggle.uk-open, .uk-card-secondary.uk-card-body .uk-navbar-toggle.uk-open, .uk-card-secondary > :not([class*='uk-card-media']) .uk-navbar-toggle.uk-open, .uk-overlay-primary .uk-navbar-toggle.uk-open, .uk-offcanvas-bar .uk-navbar-toggle.uk-open {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-subnav > * > :first-child, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > * > :first-child, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav > * > :first-child, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav > * > :first-child, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav > * > :first-child, .uk-card-primary.uk-card-body .uk-subnav > * > :first-child, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav > * > :first-child, .uk-card-secondary.uk-card-body .uk-subnav > * > :first-child, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav > * > :first-child, .uk-overlay-primary .uk-subnav > * > :first-child, .uk-offcanvas-bar .uk-subnav > * > :first-child {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-subnav > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > * > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav > * > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav > * > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav > * > a:hover, .uk-card-primary.uk-card-body .uk-subnav > * > a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav > * > a:hover, .uk-card-secondary.uk-card-body .uk-subnav > * > a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav > * > a:hover, .uk-overlay-primary .uk-subnav > * > a:hover, .uk-offcanvas-bar .uk-subnav > * > a:hover,
  .uk-light .uk-subnav > * > a:focus, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > * > a:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav > * > a:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav > * > a:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav > * > a:focus, .uk-card-primary.uk-card-body .uk-subnav > * > a:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav > * > a:focus, .uk-card-secondary.uk-card-body .uk-subnav > * > a:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav > * > a:focus, .uk-overlay-primary .uk-subnav > * > a:focus, .uk-offcanvas-bar .uk-subnav > * > a:focus {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-subnav > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > .uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav > .uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav > .uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav > .uk-active > a, .uk-card-primary.uk-card-body .uk-subnav > .uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav > .uk-active > a, .uk-card-secondary.uk-card-body .uk-subnav > .uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav > .uk-active > a, .uk-overlay-primary .uk-subnav > .uk-active > a, .uk-offcanvas-bar .uk-subnav > .uk-active > a {
    color: #fff; }
  .uk-light .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary.uk-card-body .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-secondary.uk-card-body .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-overlay-primary .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-offcanvas-bar .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before {
    border-left-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-subnav-pill > * > :first-child, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > * > :first-child, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > :first-child, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill > * > :first-child, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > :first-child, .uk-card-primary.uk-card-body .uk-subnav-pill > * > :first-child, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav-pill > * > :first-child, .uk-card-secondary.uk-card-body .uk-subnav-pill > * > :first-child, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav-pill > * > :first-child, .uk-overlay-primary .uk-subnav-pill > * > :first-child, .uk-offcanvas-bar .uk-subnav-pill > * > :first-child {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-subnav-pill > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > a:hover, .uk-card-primary.uk-card-body .uk-subnav-pill > * > a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav-pill > * > a:hover, .uk-card-secondary.uk-card-body .uk-subnav-pill > * > a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav-pill > * > a:hover, .uk-overlay-primary .uk-subnav-pill > * > a:hover, .uk-offcanvas-bar .uk-subnav-pill > * > a:hover,
  .uk-light .uk-subnav-pill > * > a:focus, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > a:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > a:focus, .uk-card-primary.uk-card-body .uk-subnav-pill > * > a:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav-pill > * > a:focus, .uk-card-secondary.uk-card-body .uk-subnav-pill > * > a:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav-pill > * > a:focus, .uk-overlay-primary .uk-subnav-pill > * > a:focus, .uk-offcanvas-bar .uk-subnav-pill > * > a:focus {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-subnav-pill > * > a:active, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:active, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > a:active, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > a:active, .uk-card-primary.uk-card-body .uk-subnav-pill > * > a:active, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav-pill > * > a:active, .uk-card-secondary.uk-card-body .uk-subnav-pill > * > a:active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav-pill > * > a:active, .uk-overlay-primary .uk-subnav-pill > * > a:active, .uk-offcanvas-bar .uk-subnav-pill > * > a:active {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-subnav-pill > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > .uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill > .uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill > .uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill > .uk-active > a, .uk-card-primary.uk-card-body .uk-subnav-pill > .uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav-pill > .uk-active > a, .uk-card-secondary.uk-card-body .uk-subnav-pill > .uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav-pill > .uk-active > a, .uk-overlay-primary .uk-subnav-pill > .uk-active > a, .uk-offcanvas-bar .uk-subnav-pill > .uk-active > a {
    background-color: #fff;
    color: #666; }
  .uk-light .uk-subnav > .uk-disabled > a, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > .uk-disabled > a, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav > .uk-disabled > a, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav > .uk-disabled > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav > .uk-disabled > a, .uk-card-primary.uk-card-body .uk-subnav > .uk-disabled > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-subnav > .uk-disabled > a, .uk-card-secondary.uk-card-body .uk-subnav > .uk-disabled > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-subnav > .uk-disabled > a, .uk-overlay-primary .uk-subnav > .uk-disabled > a, .uk-offcanvas-bar .uk-subnav > .uk-disabled > a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-breadcrumb > * > *, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > * > *, .uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb > * > *, .uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb > * > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb > * > *, .uk-card-primary.uk-card-body .uk-breadcrumb > * > *, .uk-card-primary > :not([class*='uk-card-media']) .uk-breadcrumb > * > *, .uk-card-secondary.uk-card-body .uk-breadcrumb > * > *, .uk-card-secondary > :not([class*='uk-card-media']) .uk-breadcrumb > * > *, .uk-overlay-primary .uk-breadcrumb > * > *, .uk-offcanvas-bar .uk-breadcrumb > * > * {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-breadcrumb > * > :hover, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > * > :hover, .uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb > * > :hover, .uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb > * > :hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb > * > :hover, .uk-card-primary.uk-card-body .uk-breadcrumb > * > :hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-breadcrumb > * > :hover, .uk-card-secondary.uk-card-body .uk-breadcrumb > * > :hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-breadcrumb > * > :hover, .uk-overlay-primary .uk-breadcrumb > * > :hover, .uk-offcanvas-bar .uk-breadcrumb > * > :hover,
  .uk-light .uk-breadcrumb > * > :focus, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > * > :focus, .uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb > * > :focus, .uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb > * > :focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb > * > :focus, .uk-card-primary.uk-card-body .uk-breadcrumb > * > :focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-breadcrumb > * > :focus, .uk-card-secondary.uk-card-body .uk-breadcrumb > * > :focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-breadcrumb > * > :focus, .uk-overlay-primary .uk-breadcrumb > * > :focus, .uk-offcanvas-bar .uk-breadcrumb > * > :focus {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-breadcrumb > :last-child > *, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > :last-child > *, .uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb > :last-child > *, .uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb > :last-child > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb > :last-child > *, .uk-card-primary.uk-card-body .uk-breadcrumb > :last-child > *, .uk-card-primary > :not([class*='uk-card-media']) .uk-breadcrumb > :last-child > *, .uk-card-secondary.uk-card-body .uk-breadcrumb > :last-child > *, .uk-card-secondary > :not([class*='uk-card-media']) .uk-breadcrumb > :last-child > *, .uk-overlay-primary .uk-breadcrumb > :last-child > *, .uk-offcanvas-bar .uk-breadcrumb > :last-child > * {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary.uk-card-body .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary > :not([class*='uk-card-media']) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-secondary.uk-card-body .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-secondary > :not([class*='uk-card-media']) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-overlay-primary .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-offcanvas-bar .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-pagination > * > *, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > * > *, .uk-section-secondary:not(.uk-preserve-color) .uk-pagination > * > *, .uk-tile-primary:not(.uk-preserve-color) .uk-pagination > * > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-pagination > * > *, .uk-card-primary.uk-card-body .uk-pagination > * > *, .uk-card-primary > :not([class*='uk-card-media']) .uk-pagination > * > *, .uk-card-secondary.uk-card-body .uk-pagination > * > *, .uk-card-secondary > :not([class*='uk-card-media']) .uk-pagination > * > *, .uk-overlay-primary .uk-pagination > * > *, .uk-offcanvas-bar .uk-pagination > * > * {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-pagination > * > :hover, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > * > :hover, .uk-section-secondary:not(.uk-preserve-color) .uk-pagination > * > :hover, .uk-tile-primary:not(.uk-preserve-color) .uk-pagination > * > :hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-pagination > * > :hover, .uk-card-primary.uk-card-body .uk-pagination > * > :hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-pagination > * > :hover, .uk-card-secondary.uk-card-body .uk-pagination > * > :hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-pagination > * > :hover, .uk-overlay-primary .uk-pagination > * > :hover, .uk-offcanvas-bar .uk-pagination > * > :hover,
  .uk-light .uk-pagination > * > :focus, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > * > :focus, .uk-section-secondary:not(.uk-preserve-color) .uk-pagination > * > :focus, .uk-tile-primary:not(.uk-preserve-color) .uk-pagination > * > :focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-pagination > * > :focus, .uk-card-primary.uk-card-body .uk-pagination > * > :focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-pagination > * > :focus, .uk-card-secondary.uk-card-body .uk-pagination > * > :focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-pagination > * > :focus, .uk-overlay-primary .uk-pagination > * > :focus, .uk-offcanvas-bar .uk-pagination > * > :focus {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-pagination > .uk-active > *, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > .uk-active > *, .uk-section-secondary:not(.uk-preserve-color) .uk-pagination > .uk-active > *, .uk-tile-primary:not(.uk-preserve-color) .uk-pagination > .uk-active > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-pagination > .uk-active > *, .uk-card-primary.uk-card-body .uk-pagination > .uk-active > *, .uk-card-primary > :not([class*='uk-card-media']) .uk-pagination > .uk-active > *, .uk-card-secondary.uk-card-body .uk-pagination > .uk-active > *, .uk-card-secondary > :not([class*='uk-card-media']) .uk-pagination > .uk-active > *, .uk-overlay-primary .uk-pagination > .uk-active > *, .uk-offcanvas-bar .uk-pagination > .uk-active > * {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-pagination > .uk-disabled > *, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > .uk-disabled > *, .uk-section-secondary:not(.uk-preserve-color) .uk-pagination > .uk-disabled > *, .uk-tile-primary:not(.uk-preserve-color) .uk-pagination > .uk-disabled > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-pagination > .uk-disabled > *, .uk-card-primary.uk-card-body .uk-pagination > .uk-disabled > *, .uk-card-primary > :not([class*='uk-card-media']) .uk-pagination > .uk-disabled > *, .uk-card-secondary.uk-card-body .uk-pagination > .uk-disabled > *, .uk-card-secondary > :not([class*='uk-card-media']) .uk-pagination > .uk-disabled > *, .uk-overlay-primary .uk-pagination > .uk-disabled > *, .uk-offcanvas-bar .uk-pagination > .uk-disabled > * {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-tab > * > a, .uk-section-primary:not(.uk-preserve-color) .uk-tab > * > a, .uk-section-secondary:not(.uk-preserve-color) .uk-tab > * > a, .uk-tile-primary:not(.uk-preserve-color) .uk-tab > * > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-tab > * > a, .uk-card-primary.uk-card-body .uk-tab > * > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-tab > * > a, .uk-card-secondary.uk-card-body .uk-tab > * > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-tab > * > a, .uk-overlay-primary .uk-tab > * > a, .uk-offcanvas-bar .uk-tab > * > a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-tab > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-tab > * > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-tab > * > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-tab > * > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-tab > * > a:hover, .uk-card-primary.uk-card-body .uk-tab > * > a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-tab > * > a:hover, .uk-card-secondary.uk-card-body .uk-tab > * > a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-tab > * > a:hover, .uk-overlay-primary .uk-tab > * > a:hover, .uk-offcanvas-bar .uk-tab > * > a:hover,
  .uk-light .uk-tab > * > a:focus, .uk-section-primary:not(.uk-preserve-color) .uk-tab > * > a:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-tab > * > a:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-tab > * > a:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-tab > * > a:focus, .uk-card-primary.uk-card-body .uk-tab > * > a:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-tab > * > a:focus, .uk-card-secondary.uk-card-body .uk-tab > * > a:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-tab > * > a:focus, .uk-overlay-primary .uk-tab > * > a:focus, .uk-offcanvas-bar .uk-tab > * > a:focus {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-tab > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-tab > .uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-tab > .uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-tab > .uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-tab > .uk-active > a, .uk-card-primary.uk-card-body .uk-tab > .uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-tab > .uk-active > a, .uk-card-secondary.uk-card-body .uk-tab > .uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-tab > .uk-active > a, .uk-overlay-primary .uk-tab > .uk-active > a, .uk-offcanvas-bar .uk-tab > .uk-active > a {
    color: #fff; }
  .uk-light .uk-tab > .uk-disabled > a, .uk-section-primary:not(.uk-preserve-color) .uk-tab > .uk-disabled > a, .uk-section-secondary:not(.uk-preserve-color) .uk-tab > .uk-disabled > a, .uk-tile-primary:not(.uk-preserve-color) .uk-tab > .uk-disabled > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-tab > .uk-disabled > a, .uk-card-primary.uk-card-body .uk-tab > .uk-disabled > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-tab > .uk-disabled > a, .uk-card-secondary.uk-card-body .uk-tab > .uk-disabled > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-tab > .uk-disabled > a, .uk-overlay-primary .uk-tab > .uk-disabled > a, .uk-offcanvas-bar .uk-tab > .uk-disabled > a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-slidenav, .uk-section-primary:not(.uk-preserve-color) .uk-slidenav, .uk-section-secondary:not(.uk-preserve-color) .uk-slidenav, .uk-tile-primary:not(.uk-preserve-color) .uk-slidenav, .uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav, .uk-card-primary.uk-card-body .uk-slidenav, .uk-card-primary > :not([class*='uk-card-media']) .uk-slidenav, .uk-card-secondary.uk-card-body .uk-slidenav, .uk-card-secondary > :not([class*='uk-card-media']) .uk-slidenav, .uk-overlay-primary .uk-slidenav, .uk-offcanvas-bar .uk-slidenav {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-slidenav:hover, .uk-section-primary:not(.uk-preserve-color) .uk-slidenav:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-slidenav:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-slidenav:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav:hover, .uk-card-primary.uk-card-body .uk-slidenav:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-slidenav:hover, .uk-card-secondary.uk-card-body .uk-slidenav:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-slidenav:hover, .uk-overlay-primary .uk-slidenav:hover, .uk-offcanvas-bar .uk-slidenav:hover,
  .uk-light .uk-slidenav:focus, .uk-section-primary:not(.uk-preserve-color) .uk-slidenav:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-slidenav:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-slidenav:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav:focus, .uk-card-primary.uk-card-body .uk-slidenav:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-slidenav:focus, .uk-card-secondary.uk-card-body .uk-slidenav:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-slidenav:focus, .uk-overlay-primary .uk-slidenav:focus, .uk-offcanvas-bar .uk-slidenav:focus {
    color: rgba(255, 255, 255, 0.95); }
  .uk-light .uk-slidenav:active, .uk-section-primary:not(.uk-preserve-color) .uk-slidenav:active, .uk-section-secondary:not(.uk-preserve-color) .uk-slidenav:active, .uk-tile-primary:not(.uk-preserve-color) .uk-slidenav:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav:active, .uk-card-primary.uk-card-body .uk-slidenav:active, .uk-card-primary > :not([class*='uk-card-media']) .uk-slidenav:active, .uk-card-secondary.uk-card-body .uk-slidenav:active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-slidenav:active, .uk-overlay-primary .uk-slidenav:active, .uk-offcanvas-bar .uk-slidenav:active {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-dotnav > * > *, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > * > *, .uk-section-secondary:not(.uk-preserve-color) .uk-dotnav > * > *, .uk-tile-primary:not(.uk-preserve-color) .uk-dotnav > * > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav > * > *, .uk-card-primary.uk-card-body .uk-dotnav > * > *, .uk-card-primary > :not([class*='uk-card-media']) .uk-dotnav > * > *, .uk-card-secondary.uk-card-body .uk-dotnav > * > *, .uk-card-secondary > :not([class*='uk-card-media']) .uk-dotnav > * > *, .uk-overlay-primary .uk-dotnav > * > *, .uk-offcanvas-bar .uk-dotnav > * > * {
    background-color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-dotnav > * > :hover, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > * > :hover, .uk-section-secondary:not(.uk-preserve-color) .uk-dotnav > * > :hover, .uk-tile-primary:not(.uk-preserve-color) .uk-dotnav > * > :hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav > * > :hover, .uk-card-primary.uk-card-body .uk-dotnav > * > :hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-dotnav > * > :hover, .uk-card-secondary.uk-card-body .uk-dotnav > * > :hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-dotnav > * > :hover, .uk-overlay-primary .uk-dotnav > * > :hover, .uk-offcanvas-bar .uk-dotnav > * > :hover,
  .uk-light .uk-dotnav > * > :focus, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > * > :focus, .uk-section-secondary:not(.uk-preserve-color) .uk-dotnav > * > :focus, .uk-tile-primary:not(.uk-preserve-color) .uk-dotnav > * > :focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav > * > :focus, .uk-card-primary.uk-card-body .uk-dotnav > * > :focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-dotnav > * > :focus, .uk-card-secondary.uk-card-body .uk-dotnav > * > :focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-dotnav > * > :focus, .uk-overlay-primary .uk-dotnav > * > :focus, .uk-offcanvas-bar .uk-dotnav > * > :focus {
    background-color: rgba(255, 255, 255, 0.9); }
  .uk-light .uk-dotnav > * > :active, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > * > :active, .uk-section-secondary:not(.uk-preserve-color) .uk-dotnav > * > :active, .uk-tile-primary:not(.uk-preserve-color) .uk-dotnav > * > :active, .uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav > * > :active, .uk-card-primary.uk-card-body .uk-dotnav > * > :active, .uk-card-primary > :not([class*='uk-card-media']) .uk-dotnav > * > :active, .uk-card-secondary.uk-card-body .uk-dotnav > * > :active, .uk-card-secondary > :not([class*='uk-card-media']) .uk-dotnav > * > :active, .uk-overlay-primary .uk-dotnav > * > :active, .uk-offcanvas-bar .uk-dotnav > * > :active {
    background-color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-dotnav > .uk-active > *, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > .uk-active > *, .uk-section-secondary:not(.uk-preserve-color) .uk-dotnav > .uk-active > *, .uk-tile-primary:not(.uk-preserve-color) .uk-dotnav > .uk-active > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav > .uk-active > *, .uk-card-primary.uk-card-body .uk-dotnav > .uk-active > *, .uk-card-primary > :not([class*='uk-card-media']) .uk-dotnav > .uk-active > *, .uk-card-secondary.uk-card-body .uk-dotnav > .uk-active > *, .uk-card-secondary > :not([class*='uk-card-media']) .uk-dotnav > .uk-active > *, .uk-overlay-primary .uk-dotnav > .uk-active > *, .uk-offcanvas-bar .uk-dotnav > .uk-active > * {
    background-color: rgba(255, 255, 255, 0.9); }
  .uk-light .uk-accordion-title, .uk-section-primary:not(.uk-preserve-color) .uk-accordion-title, .uk-section-secondary:not(.uk-preserve-color) .uk-accordion-title, .uk-tile-primary:not(.uk-preserve-color) .uk-accordion-title, .uk-tile-secondary:not(.uk-preserve-color) .uk-accordion-title, .uk-card-primary.uk-card-body .uk-accordion-title, .uk-card-primary > :not([class*='uk-card-media']) .uk-accordion-title, .uk-card-secondary.uk-card-body .uk-accordion-title, .uk-card-secondary > :not([class*='uk-card-media']) .uk-accordion-title, .uk-overlay-primary .uk-accordion-title, .uk-offcanvas-bar .uk-accordion-title {
    color: #fff; }
  .uk-light .uk-accordion-title:hover, .uk-section-primary:not(.uk-preserve-color) .uk-accordion-title:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-accordion-title:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-accordion-title:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-accordion-title:hover, .uk-card-primary.uk-card-body .uk-accordion-title:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-accordion-title:hover, .uk-card-secondary.uk-card-body .uk-accordion-title:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-accordion-title:hover, .uk-overlay-primary .uk-accordion-title:hover, .uk-offcanvas-bar .uk-accordion-title:hover,
  .uk-light .uk-accordion-title:focus, .uk-section-primary:not(.uk-preserve-color) .uk-accordion-title:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-accordion-title:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-accordion-title:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-accordion-title:focus, .uk-card-primary.uk-card-body .uk-accordion-title:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-accordion-title:focus, .uk-card-secondary.uk-card-body .uk-accordion-title:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-accordion-title:focus, .uk-overlay-primary .uk-accordion-title:focus, .uk-offcanvas-bar .uk-accordion-title:focus {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-iconnav > * > a, .uk-section-primary:not(.uk-preserve-color) .uk-iconnav > * > a, .uk-section-secondary:not(.uk-preserve-color) .uk-iconnav > * > a, .uk-tile-primary:not(.uk-preserve-color) .uk-iconnav > * > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav > * > a, .uk-card-primary.uk-card-body .uk-iconnav > * > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-iconnav > * > a, .uk-card-secondary.uk-card-body .uk-iconnav > * > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-iconnav > * > a, .uk-overlay-primary .uk-iconnav > * > a, .uk-offcanvas-bar .uk-iconnav > * > a {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-iconnav > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-iconnav > * > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-iconnav > * > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-iconnav > * > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav > * > a:hover, .uk-card-primary.uk-card-body .uk-iconnav > * > a:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-iconnav > * > a:hover, .uk-card-secondary.uk-card-body .uk-iconnav > * > a:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-iconnav > * > a:hover, .uk-overlay-primary .uk-iconnav > * > a:hover, .uk-offcanvas-bar .uk-iconnav > * > a:hover,
  .uk-light .uk-iconnav > * > a:focus, .uk-section-primary:not(.uk-preserve-color) .uk-iconnav > * > a:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-iconnav > * > a:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-iconnav > * > a:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav > * > a:focus, .uk-card-primary.uk-card-body .uk-iconnav > * > a:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-iconnav > * > a:focus, .uk-card-secondary.uk-card-body .uk-iconnav > * > a:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-iconnav > * > a:focus, .uk-overlay-primary .uk-iconnav > * > a:focus, .uk-offcanvas-bar .uk-iconnav > * > a:focus {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-iconnav > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-iconnav > .uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-iconnav > .uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-iconnav > .uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav > .uk-active > a, .uk-card-primary.uk-card-body .uk-iconnav > .uk-active > a, .uk-card-primary > :not([class*='uk-card-media']) .uk-iconnav > .uk-active > a, .uk-card-secondary.uk-card-body .uk-iconnav > .uk-active > a, .uk-card-secondary > :not([class*='uk-card-media']) .uk-iconnav > .uk-active > a, .uk-overlay-primary .uk-iconnav > .uk-active > a, .uk-offcanvas-bar .uk-iconnav > .uk-active > a {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-text-lead, .uk-section-primary:not(.uk-preserve-color) .uk-text-lead, .uk-section-secondary:not(.uk-preserve-color) .uk-text-lead, .uk-tile-primary:not(.uk-preserve-color) .uk-text-lead, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-lead, .uk-card-primary.uk-card-body .uk-text-lead, .uk-card-primary > :not([class*='uk-card-media']) .uk-text-lead, .uk-card-secondary.uk-card-body .uk-text-lead, .uk-card-secondary > :not([class*='uk-card-media']) .uk-text-lead, .uk-overlay-primary .uk-text-lead, .uk-offcanvas-bar .uk-text-lead {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-text-meta, .uk-section-primary:not(.uk-preserve-color) .uk-text-meta, .uk-section-secondary:not(.uk-preserve-color) .uk-text-meta, .uk-tile-primary:not(.uk-preserve-color) .uk-text-meta, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-meta, .uk-card-primary.uk-card-body .uk-text-meta, .uk-card-primary > :not([class*='uk-card-media']) .uk-text-meta, .uk-card-secondary.uk-card-body .uk-text-meta, .uk-card-secondary > :not([class*='uk-card-media']) .uk-text-meta, .uk-overlay-primary .uk-text-meta, .uk-offcanvas-bar .uk-text-meta {
    color: rgba(255, 255, 255, 0.5); }
  .uk-light .uk-text-muted, .uk-section-primary:not(.uk-preserve-color) .uk-text-muted, .uk-section-secondary:not(.uk-preserve-color) .uk-text-muted, .uk-tile-primary:not(.uk-preserve-color) .uk-text-muted, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-muted, .uk-card-primary.uk-card-body .uk-text-muted, .uk-card-primary > :not([class*='uk-card-media']) .uk-text-muted, .uk-card-secondary.uk-card-body .uk-text-muted, .uk-card-secondary > :not([class*='uk-card-media']) .uk-text-muted, .uk-overlay-primary .uk-text-muted, .uk-offcanvas-bar .uk-text-muted {
    color: rgba(255, 255, 255, 0.5) !important; }
  .uk-light .uk-text-emphasis, .uk-section-primary:not(.uk-preserve-color) .uk-text-emphasis, .uk-section-secondary:not(.uk-preserve-color) .uk-text-emphasis, .uk-tile-primary:not(.uk-preserve-color) .uk-text-emphasis, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-emphasis, .uk-card-primary.uk-card-body .uk-text-emphasis, .uk-card-primary > :not([class*='uk-card-media']) .uk-text-emphasis, .uk-card-secondary.uk-card-body .uk-text-emphasis, .uk-card-secondary > :not([class*='uk-card-media']) .uk-text-emphasis, .uk-overlay-primary .uk-text-emphasis, .uk-offcanvas-bar .uk-text-emphasis {
    color: #fff !important; }
  .uk-light .uk-text-primary, .uk-section-primary:not(.uk-preserve-color) .uk-text-primary, .uk-section-secondary:not(.uk-preserve-color) .uk-text-primary, .uk-tile-primary:not(.uk-preserve-color) .uk-text-primary, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-primary, .uk-card-primary.uk-card-body .uk-text-primary, .uk-card-primary > :not([class*='uk-card-media']) .uk-text-primary, .uk-card-secondary.uk-card-body .uk-text-primary, .uk-card-secondary > :not([class*='uk-card-media']) .uk-text-primary, .uk-overlay-primary .uk-text-primary, .uk-offcanvas-bar .uk-text-primary {
    color: #fff !important; }
  .uk-light .uk-text-secondary, .uk-section-primary:not(.uk-preserve-color) .uk-text-secondary, .uk-section-secondary:not(.uk-preserve-color) .uk-text-secondary, .uk-tile-primary:not(.uk-preserve-color) .uk-text-secondary, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-secondary, .uk-card-primary.uk-card-body .uk-text-secondary, .uk-card-primary > :not([class*='uk-card-media']) .uk-text-secondary, .uk-card-secondary.uk-card-body .uk-text-secondary, .uk-card-secondary > :not([class*='uk-card-media']) .uk-text-secondary, .uk-overlay-primary .uk-text-secondary, .uk-offcanvas-bar .uk-text-secondary {
    color: #fff !important; }
  .uk-light .uk-column-divider, .uk-section-primary:not(.uk-preserve-color) .uk-column-divider, .uk-section-secondary:not(.uk-preserve-color) .uk-column-divider, .uk-tile-primary:not(.uk-preserve-color) .uk-column-divider, .uk-tile-secondary:not(.uk-preserve-color) .uk-column-divider, .uk-card-primary.uk-card-body .uk-column-divider, .uk-card-primary > :not([class*='uk-card-media']) .uk-column-divider, .uk-card-secondary.uk-card-body .uk-column-divider, .uk-card-secondary > :not([class*='uk-card-media']) .uk-column-divider, .uk-overlay-primary .uk-column-divider, .uk-offcanvas-bar .uk-column-divider {
    column-rule-color: rgba(255, 255, 255, 0.2); }
  .uk-light .uk-logo, .uk-section-primary:not(.uk-preserve-color) .uk-logo, .uk-section-secondary:not(.uk-preserve-color) .uk-logo, .uk-tile-primary:not(.uk-preserve-color) .uk-logo, .uk-tile-secondary:not(.uk-preserve-color) .uk-logo, .uk-card-primary.uk-card-body .uk-logo, .uk-card-primary > :not([class*='uk-card-media']) .uk-logo, .uk-card-secondary.uk-card-body .uk-logo, .uk-card-secondary > :not([class*='uk-card-media']) .uk-logo, .uk-overlay-primary .uk-logo, .uk-offcanvas-bar .uk-logo {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-logo:hover, .uk-section-primary:not(.uk-preserve-color) .uk-logo:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-logo:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-logo:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-logo:hover, .uk-card-primary.uk-card-body .uk-logo:hover, .uk-card-primary > :not([class*='uk-card-media']) .uk-logo:hover, .uk-card-secondary.uk-card-body .uk-logo:hover, .uk-card-secondary > :not([class*='uk-card-media']) .uk-logo:hover, .uk-overlay-primary .uk-logo:hover, .uk-offcanvas-bar .uk-logo:hover,
  .uk-light .uk-logo:focus, .uk-section-primary:not(.uk-preserve-color) .uk-logo:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-logo:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-logo:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-logo:focus, .uk-card-primary.uk-card-body .uk-logo:focus, .uk-card-primary > :not([class*='uk-card-media']) .uk-logo:focus, .uk-card-secondary.uk-card-body .uk-logo:focus, .uk-card-secondary > :not([class*='uk-card-media']) .uk-logo:focus, .uk-overlay-primary .uk-logo:focus, .uk-offcanvas-bar .uk-logo:focus {
    color: rgba(255, 255, 255, 0.7); }
  .uk-light .uk-logo > :not(.uk-logo-inverse):not(:only-of-type), .uk-section-primary:not(.uk-preserve-color) .uk-logo > :not(.uk-logo-inverse):not(:only-of-type), .uk-section-secondary:not(.uk-preserve-color) .uk-logo > :not(.uk-logo-inverse):not(:only-of-type), .uk-tile-primary:not(.uk-preserve-color) .uk-logo > :not(.uk-logo-inverse):not(:only-of-type), .uk-tile-secondary:not(.uk-preserve-color) .uk-logo > :not(.uk-logo-inverse):not(:only-of-type), .uk-card-primary.uk-card-body .uk-logo > :not(.uk-logo-inverse):not(:only-of-type), .uk-card-primary > :not([class*='uk-card-media']) .uk-logo > :not(.uk-logo-inverse):not(:only-of-type), .uk-card-secondary.uk-card-body .uk-logo > :not(.uk-logo-inverse):not(:only-of-type), .uk-card-secondary > :not([class*='uk-card-media']) .uk-logo > :not(.uk-logo-inverse):not(:only-of-type), .uk-overlay-primary .uk-logo > :not(.uk-logo-inverse):not(:only-of-type), .uk-offcanvas-bar .uk-logo > :not(.uk-logo-inverse):not(:only-of-type) {
    display: none; }
  .uk-light .uk-logo-inverse, .uk-section-primary:not(.uk-preserve-color) .uk-logo-inverse, .uk-section-secondary:not(.uk-preserve-color) .uk-logo-inverse, .uk-tile-primary:not(.uk-preserve-color) .uk-logo-inverse, .uk-tile-secondary:not(.uk-preserve-color) .uk-logo-inverse, .uk-card-primary.uk-card-body .uk-logo-inverse, .uk-card-primary > :not([class*='uk-card-media']) .uk-logo-inverse, .uk-card-secondary.uk-card-body .uk-logo-inverse, .uk-card-secondary > :not([class*='uk-card-media']) .uk-logo-inverse, .uk-overlay-primary .uk-logo-inverse, .uk-offcanvas-bar .uk-logo-inverse {
    display: inline; }

/* ========================================================================
   Component: Print
 ========================================================================== */
@media print {
  *,
  *::before,
  *::after {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

/************************
 * Fonts
 ************************/
@font-face {
  font-family: 'AxeHandel';
  src: url("../fonts/AxeHandel Registered Version.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/AxeHandel Registered Version.eot?#iefix") format("embedded-opentype"), url("../fonts/AxeHandel Registered Version.woff") format("woff"), url("../fonts/AxeHandel Registered Version.ttf") format("truetype");
  /* Safari, Android, iOS */ }

html, body {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #545454;
  overflow-x: hidden;
  position: relative; }

a {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #ce1f2e; }
  a:hover, a:active, a:focus {
    color: #ce1f2e; }

p {
  font-size: 16px;
  line-height: 1.5; }
  @media all and (min-width: 960px) {
    p {
      font-size: 20px;
      line-height: 1.75; } }

h1, h2, h3 {
  font-family: 'AxeHandel', sans-serif;
  text-transform: uppercase;
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  text-rendering: optimizeLegibility;
  color: #000000; }
  h1 a, h2 a, h3 a {
    font-family: 'AxeHandel', sans-serif;
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    text-rendering: optimizeLegibility; }
    h1 a:active, h1 a:hover, h1 a:focus, h2 a:active, h2 a:hover, h2 a:focus, h3 a:active, h3 a:hover, h3 a:focus {
      color: #8E8E8E;
      text-decoration: none; }

h1 {
  font-size: 45px;
  line-height: 1.05; }
  @media all and (min-width: 960px) {
    h1 {
      font-size: 75px; } }

h2 {
  font-size: 45px;
  line-height: 1.03; }
  @media all and (min-width: 960px) {
    h2 {
      font-size: 60px; } }

h3 {
  font-size: 40px;
  line-height: 1; }
  @media all and (min-width: 960px) {
    h3 {
      font-size: 45px; } }

h4 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.33; }

h5 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px; }

b, strong {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700; }

blockquote,
blockquote p {
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  font-weight: 700;
  color: #ce1f2e;
  font-style: italic;
  margin: 30px 0 -10px;
  font-size: 25px;
  line-height: 35px; }

ol {
  list-style: none;
  counter-reset: numbers;
  padding-left: 45px; }

ol li {
  counter-increment: numbers;
  font-size: 16px;
  line-height: 1.6;
  color: #545454;
  margin-bottom: 10px; }
  @media all and (min-width: 640px) {
    ol li {
      font-size: 18px; } }
  @media all and (min-width: 960px) {
    ol li {
      font-size: 20px; } }
  ol li::before {
    content: counter(numbers) ". ";
    color: #ce1f2e;
    width: 38px;
    display: inline-block;
    margin-left: -53px;
    text-align: right;
    padding-right: 15px; }

.page-container .uk-container {
  width: 100%;
  max-width: 990px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px; }
  .page-container .uk-container .content-container {
    background: #ffffff;
    padding: 10px; }
    @media all and (min-width: 640px) {
      .page-container .uk-container .content-container {
        padding: 30px; } }

.flannel-button a,
.flannel-order-red,
.flannel-order-black {
  font-family: 'Open Sans Condensed', sans-serif;
  font-style: normal;
  font-weight: 700;
  padding: 15px 10px;
  color: #ffffff !important;
  background: #ce1f2e;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  width: 200px;
  text-align: center;
  position: relative;
  display: inline-block;
  cursor: pointer;
  z-index: 2; }
  @media all and (min-width: 960px) {
    .flannel-button a,
    .flannel-order-red,
    .flannel-order-black {
      font-size: 23px;
      width: auto;
      padding: 11px 30px; } }
  .flannel-button a:hover, .flannel-button a:active, .flannel-button a:focus,
  .flannel-order-red:hover,
  .flannel-order-red:active,
  .flannel-order-red:focus,
  .flannel-order-black:hover,
  .flannel-order-black:active,
  .flannel-order-black:focus {
    text-decoration: none;
    color: #000000 !important; }

.flannel-order-black {
  background: #000000; }
  .flannel-order-black:hover, .flannel-order-black:active, .flannel-order-black:focus {
    text-decoration: none;
    color: #ce1f2e !important; }

.background-divider {
  margin-top: -550px;
  z-index: 0;
  position: relative;
  margin-bottom: -5px;
  height: 500px;
  background: #000000;
  clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 100%); }

.flannel-black-bkgd {
  background: #000000; }
  .flannel-black-bkgd h1, .flannel-black-bkgd h2, .flannel-black-bkgd h3 {
    color: #ffffff; }

.flannel-red-bkgd {
  background: #ce1f2e; }

.flannel-white-bkgd {
  background: #ffffff; }

.flannel-white-text {
  color: #ffffff; }

.flannel-black-text {
  color: #000000; }

.flannel-red-text {
  color: #ce1f2e; }

.flannel-outline-remove {
  outline: none; }

.flannel-float-right {
  float: none;
  margin-right: 0 !important; }
  @media all and (min-width: 640px) {
    .flannel-float-right {
      float: right; } }
  @media all and (min-width: 1200px) {
    .flannel-float-right {
      margin-right: -120px !important; } }

.flannel-float-left {
  float: none;
  margin-left: 0 !important; }
  @media all and (min-width: 640px) {
    .flannel-float-left {
      float: left; } }
  @media all and (min-width: 1200px) {
    .flannel-float-left {
      margin-left: -120px !important; } }

.flannel-box-shadow {
  box-shadow: -20px 20px 0 0 rgba(0, 0, 0, 0.15);
  margin: 0 50px 30px; }

.flannel-plaid-background {
  width: 330px;
  height: 197px;
  background-color: #ce1f2e;
  background-image: url("../images/patterns/pattern.png");
  position: absolute;
  top: -30px;
  left: -90px;
  z-index: -2; }

.left-angle-footer-border {
  clip-path: polygon(0 0, 100% 65%, 100% 100%, 0% 100%);
  background: #000000;
  padding-top: 60px;
  margin-bottom: -2px; }

.black-gradient-overlay {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent);
  width: 100%;
  height: 100%;
  position: absolute; }

.flannel-background-gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #000000;
  background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0.4) 40%);
  padding: 0; }

.flannel-video-gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0.1) 40%);
  z-index: 30; }

.flannel-map-container {
  margin-bottom: 60px;
  box-shadow: -20px 20px 0 0 rgba(0, 0, 0, 0.15); }

.title-shadow {
  text-shadow: -6px -4px 0 rgba(0, 0, 0, 0.8);
  margin: 0; }

.black-nav-button {
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  visibility: hidden;
  opacity: 0; }
  .black-nav-button a {
    margin: -10px 0; }
    .black-nav-button a span {
      background: #000000;
      padding: 10px 20px; }

.flannel-parent-nav .black-nav-button {
  display: none; }

.flannel-franchise-front-page .black-nav-button,
.flannel-franchise-nav .black-nav-button {
  display: inline-block;
  visibility: visible;
  opacity: 1; }

.uk-sticky-fixed .black-nav-button {
  visibility: visible;
  opacity: 1;
  display: inline-block; }

.red-nav-button a {
  margin: -10px 0;
  border-bottom: none !important; }
  .red-nav-button a span {
    background: #ce1f2e;
    padding: 10px 20px; }
  .red-nav-button a:active, .red-nav-button a:hover, .red-nav-button a:focus {
    color: #000000 !important; }

.flannel-national-base {
  padding-top: 0; }
  @media all and (min-width: 960px) {
    .flannel-national-base {
      padding-top: 65px; } }

.flannel-max-container {
  width: 100%;
  max-width: 640px;
  margin: 0 auto; }
  @media all and (min-width: 960px) {
    .flannel-max-container {
      max-width: none; } }

.push-forty {
  margin-top: 20px; }
  @media all and (min-width: 960px) {
    .push-forty {
      margin-top: 40px; } }

.mobile-book-button {
  margin-top: -40px;
  margin-bottom: 40px !important; }

.pentagon-container .flannel-rating {
  color: #ffffff;
  font-size: 60px;
  margin: 0;
  line-height: 1.03; }

.pentagon-container .rating-title {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: #ffffff;
  text-transform: none;
  margin: 5px 0; }

.pentagon-container img {
  display: block;
  margin: 0 auto 2px; }

.pentagon-container .flannel-total-rating {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-transform: none;
  line-height: 2;
  color: #8E8E8E;
  font-weight: normal; }

.interactive-map {
  width: 100%;
  height: 400px;
  background-color: #8E8E8E; }

.chat-app-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../images/patterns/pattern.png");
  width: 30px;
  height: 30px;
  position: fixed;
  bottom: -18%;
  right: 5%;
  border-radius: 50%;
  box-shadow: 4px 4px 4px #000000;
  padding: 20px;
  z-index: 9999999; }
  .chat-app-button:active, .chat-app-button:hover, .chat-app-button:focus {
    text-decoration: none;
    opacity: .7; }
  .chat-app-button i {
    color: #ffffff;
    font-size: 28px; }
  .chat-app-button.show {
    bottom: 5%;
    transition: all 1s ease; }

.flannel-slider-drag {
  pointer-events: auto; }
  @media all and (min-width: 960px) {
    .flannel-slider-drag {
      pointer-events: none; } }

.right-float-helper {
  margin: 0 auto;
  display: block;
  margin-bottom: 40px;
  float: none; }
  @media all and (min-width: 640px) {
    .right-float-helper {
      margin-left: 40px;
      float: right; } }

.uk-animation-slide-left,
.uk-animation-slide-right {
  -webkit-animation-name: none;
  animation-name: none; }

@media all and (min-width: 640px) {
  .uk-animation-slide-left {
    -webkit-animation-name: uk-fade-left;
    animation-name: uk-fade-left; } }

@media all and (min-width: 640px) {
  .uk-animation-slide-right {
    -webkit-animation-name: uk-fade-right;
    animation-name: uk-fade-right; } }

.flannel-accordion-container li {
  flex-direction: column;
  width: 100%; }
  .flannel-accordion-container li:before {
    content: "" !important;
    display: none !important; }

.flannel-accordion-container a.uk-accordion-title {
  font-weight: 600;
  font-size: 16px;
  background: #E4E4E4;
  padding: 15px 20px;
  color: #545454;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between; }
  @media all and (min-width: 500px) {
    .flannel-accordion-container a.uk-accordion-title {
      font-size: 18px; } }
  @media all and (min-width: 640px) {
    .flannel-accordion-container a.uk-accordion-title {
      font-size: 20px; } }
  .flannel-accordion-container a.uk-accordion-title::before {
    font-family: "Font Awesome 5 Pro";
    content: "\f067";
    margin-left: 15px;
    margin-right: 0;
    float: right;
    color: #545454; }
    @media all and (min-width: 640px) {
      .flannel-accordion-container a.uk-accordion-title::before {
        margin-left: 30px;
        margin-right: 10px; } }
  .flannel-accordion-container a.uk-accordion-title:active, .flannel-accordion-container a.uk-accordion-title:hover, .flannel-accordion-container a.uk-accordion-title:focus {
    background: #000000;
    color: #ffffff; }
    .flannel-accordion-container a.uk-accordion-title:active::before, .flannel-accordion-container a.uk-accordion-title:hover::before, .flannel-accordion-container a.uk-accordion-title:focus::before {
      content: "\f067";
      color: #ffffff; }

.flannel-accordion-container .uk-open a.uk-accordion-title::before {
  content: "\f068"; }
  .flannel-accordion-container .uk-open a.uk-accordion-title::before:active, .flannel-accordion-container .uk-open a.uk-accordion-title::before:hover, .flannel-accordion-container .uk-open a.uk-accordion-title::before:focus {
    color: #ffffff; }

.flannel-accordion-container .uk-accordion-content {
  padding: 10px 20px;
  background: #f3f3f3;
  margin: 0; }
  .flannel-accordion-container .uk-accordion-content p {
    font-size: 14px; }
    @media all and (min-width: 500px) {
      .flannel-accordion-container .uk-accordion-content p {
        font-size: 16px; } }

.flannel-blockquote {
  width: 80%;
  background: #ce1f2e;
  padding: 30px;
  margin: 0 30px 30px;
  position: relative;
  overflow: hidden; }
  @media all and (min-width: 640px) {
    .flannel-blockquote {
      width: 50%;
      padding: 50px; } }
  .flannel-blockquote:after {
    content: "";
    background-image: url("../images/axes/axes_black.svg");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    right: -28px;
    opacity: .2;
    width: 100%;
    height: 113%; }
  .flannel-blockquote blockquote {
    font-weight: bold;
    font-style: italic;
    line-height: 1.46;
    font-size: 16px;
    color: #ffffff;
    margin: 0;
    position: relative;
    z-index: 2; }
    @media all and (min-width: 960px) {
      .flannel-blockquote blockquote {
        font-size: 24px; } }

.flannel-black-book-box,
.flannel-red-book-box {
  text-align: center;
  color: #ffffff; }
  .flannel-black-book-box h2, .flannel-black-book-box h3, .flannel-black-book-box h4,
  .flannel-red-book-box h2,
  .flannel-red-book-box h3,
  .flannel-red-book-box h4 {
    margin: 0;
    padding: 0; }

.flannel-black-book-box {
  background: #000000;
  padding: 30px 30px 100px;
  position: relative;
  margin: 40px auto 0;
  max-width: 640px;
  clip-path: polygon(0 0, 100% 0%, 100% 80%, 0% 100%); }
  .flannel-black-book-box::after {
    content: "";
    background-image: url("../images/axes/axerays_white.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: .1;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0; }
  .flannel-black-book-box p {
    margin: 0 0 30px; }

.flannel-red-book-box {
  background: #ce1f2e;
  padding: 40px;
  margin: 40px auto 0; }
  .flannel-red-book-box p {
    margin: 0 0 30px; }
  .flannel-red-book-box .flannel-button a {
    background: #000000; }
    .flannel-red-book-box .flannel-button a:active, .flannel-red-book-box .flannel-button a:hover, .flannel-red-book-box .flannel-button a:focus {
      color: #ce1f2e !important; }

.hamburger-icon {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  position: absolute;
  margin: 15px auto 0;
  cursor: pointer;
  z-index: 999;
  width: 35px;
  height: 30px; }
  .hamburger-icon span {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    display: block;
    position: absolute;
    height: 4px;
    border-radius: 3px;
    width: 100%;
    background: #ffffff;
    opacity: 1;
    left: 0; }
    .hamburger-icon span:nth-child(1) {
      top: 0px; }
    .hamburger-icon span:nth-child(2) {
      top: 11px; }
    .hamburger-icon span:nth-child(3) {
      top: 22px; }
  .hamburger-icon.open span:nth-child(1) {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    top: 8px; }
  .hamburger-icon.open span:nth-child(2) {
    opacity: 0;
    width: 1px;
    left: 20px; }
  .hamburger-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
    top: 8px; }

.site-menu-mobile-container {
  -webkit-transition: top 0.5s, opacity 0s linear 0.5s;
  -moz-transition: top 0.5s, opacity 0s linear 0.5s;
  -o-transition: top 0.5s, opacity 0s linear 0.5s;
  transition: top 0.5s, opacity 0s linear 0.5s;
  background: #000000;
  width: 100%;
  z-index: 333;
  position: absolute;
  top: -1200px;
  opacity: 0; }
  .site-menu-mobile-container.expand {
    -webkit-transition: top 0.5s, opacity 0s linear 0s;
    -moz-transition: top 0.5s, opacity 0s linear 0s;
    -o-transition: top 0.5s, opacity 0s linear 0s;
    transition: top 0.5s, opacity 0s linear 0s;
    top: 0;
    opacity: 1;
    left: 0;
    display: block;
    position: fixed;
    overflow-y: scroll;
    height: 100vh; }
    .site-menu-mobile-container.expand img {
      padding-top: 50px; }
  .site-menu-mobile-container ul {
    flex-direction: column;
    list-style: none;
    text-align: center;
    padding: 0; }
    .site-menu-mobile-container ul > li.red-nav-button > a {
      background: #ce1f2e;
      padding: 10px 20px; }
    .site-menu-mobile-container ul > li.black-nav-button {
      opacity: 1;
      visibility: visible; }
      .site-menu-mobile-container ul > li.black-nav-button > a {
        color: #ffffff; }
        .site-menu-mobile-container ul > li.black-nav-button > a:active, .site-menu-mobile-container ul > li.black-nav-button > a:hover, .site-menu-mobile-container ul > li.black-nav-button > a:focus {
          color: #000000; }
        .site-menu-mobile-container ul > li.black-nav-button > a span {
          background: #ce1f2e; }
    .site-menu-mobile-container ul > li > a {
      font-family: 'Open Sans Condensed', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      display: inline-block;
      color: #ffffff;
      text-transform: uppercase;
      min-height: initial;
      margin-bottom: 50px; }
      .site-menu-mobile-container ul > li > a:hover, .site-menu-mobile-container ul > li > a:focus, .site-menu-mobile-container ul > li > a:active {
        text-decoration: none; }
  .site-menu-mobile-container .menu-item-has-children ul {
    text-align: center;
    background: #545454;
    padding: 25px 0 0;
    margin: 0 0 25px; }
    .site-menu-mobile-container .menu-item-has-children ul li {
      padding: 5px 0; }
    .site-menu-mobile-container .menu-item-has-children ul a {
      color: #ffffff;
      text-transform: uppercase;
      font-size: 16px; }
      .site-menu-mobile-container .menu-item-has-children ul a:hover, .site-menu-mobile-container .menu-item-has-children ul a:focus, .site-menu-mobile-container .menu-item-has-children ul a:active {
        text-decoration: none;
        color: #ce1f2e; }

.flannel-local-site .site-menu-mobile-container {
  padding: 0 15px;
  width: 150%;
  margin: 0 -25%; }

.mobile-hamburger-container {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 479;
  height: 50px;
  width: 50px;
  display: block; }
  @media all and (min-width: 640px) {
    .mobile-hamburger-container {
      right: 40px;
      top: 40px; } }
  @media all and (min-width: 992px) {
    .mobile-hamburger-container {
      display: none; } }

.non-burger-menu {
  display: none; }
  @media all and (min-width: 992px) {
    .non-burger-menu {
      display: block; } }

.local-front-burger-container {
  position: absolute;
  right: 80px;
  top: 40px; }
  @media all and (min-width: 640px) {
    .local-front-burger-container {
      right: 150px; } }

.circle-video-container {
  display: inline-block; }
  .circle-video-container .circle-video-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: transparent;
    margin-right: 7px; }
    @media all and (min-width: 640px) {
      .circle-video-container .circle-video-button {
        margin-right: 30px; } }
    @media all and (min-width: 960px) {
      .circle-video-container .circle-video-button {
        margin-right: 50px; } }
    .circle-video-container .circle-video-button::before {
      font-family: "Font Awesome 5 Pro";
      content: "\f04b";
      color: #ce1f2e;
      z-index: 44;
      font-size: 26px;
      position: absolute;
      font-weight: bold;
      text-shadow: 0 3px 3px #000000; }
      @media all and (min-width: 960px) {
        .circle-video-container .circle-video-button::before {
          font-size: 40px; } }
    .circle-video-container .circle-video-button video,
    .circle-video-container .circle-video-button img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      object-fit: cover; }
      @media all and (min-width: 960px) {
        .circle-video-container .circle-video-button video,
        .circle-video-container .circle-video-button img {
          width: 160px;
          height: 160px; } }

.flannel-divider {
  background: rgba(0, 0, 0, 0.8);
  background-repeat: space repeat;
  background-position: center;
  margin: 90px 0 110px;
  width: 100%;
  position: relative;
  height: 240px; }
  @media all and (min-width: 960px) {
    .flannel-divider {
      margin: 90px 0 150px; } }
  .flannel-divider:after {
    content: "";
    background: url("../images/patterns/black_pattern.png");
    opacity: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1; }
  .flannel-divider .divider-shadow {
    filter: none; }
    @media all and (min-width: 640px) {
      .flannel-divider .divider-shadow {
        filter: drop-shadow(-20px 20px 0px rgba(0, 0, 0, 0.2)); } }
    .flannel-divider .divider-shadow .divider-container {
      max-width: 420px;
      background: #ce1f2e;
      text-align: center;
      padding: 30px 50px 100px;
      clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%);
      color: #ffffff;
      margin: -30px auto;
      position: absolute;
      left: 0;
      right: 0; }
      .flannel-divider .divider-shadow .divider-container h2 {
        color: #ffffff; }
      .flannel-divider .divider-shadow .divider-container p {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 1.75; }
      .flannel-divider .divider-shadow .divider-container .flannel-button a {
        background: #000000; }
        .flannel-divider .divider-shadow .divider-container .flannel-button a:active, .flannel-divider .divider-shadow .divider-container .flannel-button a:hover, .flannel-divider .divider-shadow .divider-container .flannel-button a:focus {
          color: #ce1f2e !important; }

.flannel-media-slider-container {
  position: relative;
  right: 0;
  display: flex;
  align-items: center; }
  @media all and (min-width: 1200px) {
    .flannel-media-slider-container {
      right: -60px; } }
  .flannel-media-slider-container .uk-slider-items > * {
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden; }
    @media all and (min-width: 500px) {
      .flannel-media-slider-container .uk-slider-items > * {
        width: auto;
        padding: 0 15px; } }
  .flannel-media-slider-container .media-slider-background {
    background: #ce1f2e;
    background-image: url("../images/patterns/pattern.png");
    width: 300px;
    height: 375px;
    position: absolute;
    right: -60px;
    display: none; }
    @media all and (min-width: 960px) {
      .flannel-media-slider-container .media-slider-background {
        display: block; } }
  .flannel-media-slider-container .media-slider-component {
    margin: 0;
    background: #ffffff;
    padding-left: 30px; }
    @media all and (min-width: 960px) {
      .flannel-media-slider-container .media-slider-component {
        margin: 40px 0; } }
    .flannel-media-slider-container .media-slider-component ul {
      display: flex;
      align-items: center;
      padding: 0; }
      @media all and (min-width: 960px) {
        .flannel-media-slider-container .media-slider-component ul {
          padding: 50px 0 0; } }
      .flannel-media-slider-container .media-slider-component ul li {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0; }
        .flannel-media-slider-container .media-slider-component ul li:before {
          content: ""; }
      .flannel-media-slider-container .media-slider-component ul img,
      .flannel-media-slider-container .media-slider-component ul video {
        max-height: 210px;
        object-fit: contain;
        max-width: 360px; }
      .flannel-media-slider-container .media-slider-component ul img {
        padding-right: 15px; }
      .flannel-media-slider-container .media-slider-component ul a.flannel-video-thumbnail {
        margin: 0;
        padding: 0;
        max-width: 360px; }
        .flannel-media-slider-container .media-slider-component ul a.flannel-video-thumbnail video {
          margin: 0;
          padding: 0; }
  .flannel-media-slider-container .media-slider-nav-container {
    width: 100px;
    float: right;
    margin: 3px 0 10px; }
    .flannel-media-slider-container .media-slider-nav-container a {
      font-size: 20px;
      color: #000000; }
      .flannel-media-slider-container .media-slider-nav-container a:hover {
        color: #ce1f2e;
        text-decoration: none; }

.flannel-promo-box {
  width: 100%;
  box-shadow: -20px 20px 0 0 rgba(0, 0, 0, 0.15);
  background-color: #f3f3f3;
  margin: 0 35px 30px;
  max-width: 320px; }
  @media all and (min-width: 640px) {
    .flannel-promo-box {
      width: 70%;
      max-width: none;
      margin: 0 50px 30px; } }
  @media all and (min-width: 960px) {
    .flannel-promo-box {
      width: 60%; } }
  .flannel-promo-box .promo-image {
    margin: 0;
    padding: 0; }
    .flannel-promo-box .promo-image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .flannel-promo-box .promo-column {
    padding: 0 30px 30px; }
    @media all and (min-width: 640px) {
      .flannel-promo-box .promo-column {
        padding: 30px; } }
    .flannel-promo-box .promo-column .promo-text {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 30px;
      color: #000000;
      font-size: 18px;
      line-height: normal; }
      @media all and (min-width: 960px) {
        .flannel-promo-box .promo-column .promo-text {
          font-size: 24px; } }

.flannel-red-promo-box {
  background: #ce1f2e;
  padding: 30px;
  color: #ffffff;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  overflow: hidden; }
  @media all and (min-width: 1480px) {
    .flannel-red-promo-box {
      margin-left: -120px;
      margin-right: 120px; } }
  .flannel-red-promo-box:after {
    content: "";
    background-image: url("../images/axes/axes_black.svg");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    bottom: -75px;
    right: -25px;
    opacity: .2;
    width: 100%;
    height: 100%; }
  .flannel-red-promo-box .flannel-button a {
    background: #000000; }
    .flannel-red-promo-box .flannel-button a:active, .flannel-red-promo-box .flannel-button a:hover, .flannel-red-promo-box .flannel-button a:focus {
      color: #ce1f2e !important; }

.social-links-container {
  margin-top: 20px;
  margin-bottom: -25px; }
  .social-links-container a {
    color: #ffffff;
    font-size: 20px;
    margin: 0 15px; }
    .social-links-container a:active, .social-links-container a:hover, .social-links-container a:focus {
      text-decoration: none;
      opacity: .8; }
  .social-links-container i.fa-external-link-alt {
    display: none; }

.flannel-video-thumbnail, video {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: relative;
  width: 100%;
  max-width: 640px;
  margin: 0 auto; }
  .flannel-video-thumbnail::before, video::before {
    font-family: "Font Awesome 5 Pro";
    content: "\f04b";
    position: absolute;
    color: #ce1f2e;
    font-size: 40px;
    z-index: 12;
    font-weight: bold;
    text-shadow: 0 3px 3px #000000; }

.flannel-footer-container img {
  width: 163px;
  margin-top: 35px;
  position: relative;
  z-index: 4; }
  @media all and (min-width: 640px) {
    .flannel-footer-container img {
      width: inherit; } }

.flannel-footer-nav {
  margin-top: 40px;
  font-size: 14px; }
  @media all and (min-width: 640px) {
    .flannel-footer-nav {
      font-size: 16px; } }
  .flannel-footer-nav .legal-nav-container,
  .flannel-footer-nav .legal-nav-container a {
    font-size: 14px;
    color: #C6C4C4;
    max-width: 930px;
    margin: 0 auto; }
  .flannel-footer-nav .menu-legal-menu-container {
    display: inline-block; }
  .flannel-footer-nav li {
    list-style: none;
    display: inline-block;
    margin-bottom: 20px; }
    @media all and (min-width: 640px) {
      .flannel-footer-nav li {
        margin: 0; } }
  .flannel-footer-nav .flannel-legal-nav {
    display: flex;
    flex-direction: column;
    padding: 0; }
    @media all and (min-width: 640px) {
      .flannel-footer-nav .flannel-legal-nav {
        display: inline-block; } }
    .flannel-footer-nav .flannel-legal-nav a {
      color: #C6C4C4;
      font-weight: normal;
      margin: 0; }
      @media all and (min-width: 640px) {
        .flannel-footer-nav .flannel-legal-nav a {
          margin: 0 0 0 20px; } }
      .flannel-footer-nav .flannel-legal-nav a:active, .flannel-footer-nav .flannel-legal-nav a:hover, .flannel-footer-nav .flannel-legal-nav a:focus {
        opacity: .7;
        text-decoration: none; }
  .flannel-footer-nav .flannel-extra-nav {
    margin: 0;
    padding: 0 0 15px;
    display: flex;
    flex-direction: column; }
    @media all and (min-width: 640px) {
      .flannel-footer-nav .flannel-extra-nav {
        display: inline-block; } }
    .flannel-footer-nav .flannel-extra-nav a {
      font-family: 'Open Sans Condensed', sans-serif;
      font-style: normal;
      font-weight: 700;
      color: #ffffff;
      text-transform: uppercase;
      font-weight: bold;
      margin: 0 15px;
      font-size: 20px; }
      .flannel-footer-nav .flannel-extra-nav a:active, .flannel-footer-nav .flannel-extra-nav a:hover, .flannel-footer-nav .flannel-extra-nav a:focus {
        color: #ce1f2e;
        text-decoration: none; }

.reshift {
  padding-bottom: 20px;
  font-size: 14px; }
  .reshift a:active, .reshift a:hover, .reshift a:focus {
    text-decoration: none;
    color: #8E8E8E; }

header.flannel-page-header {
  margin-bottom: 30px;
  position: relative;
  box-shadow: 0px 6px rgba(0, 0, 0, 0.2); }
  header.flannel-page-header img {
    width: 163px;
    margin-bottom: 50px; }
    @media all and (min-width: 640px) {
      header.flannel-page-header img {
        width: auto; } }
  header.flannel-page-header .wp-post-image {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  header.flannel-page-header .title-container {
    z-index: 9;
    padding: 30px;
    position: relative; }

.flannel-parent-nav {
  display: flex;
  justify-content: center;
  margin: 0;
  position: relative;
  width: 100%;
  z-index: 222; }
  .flannel-parent-nav ul {
    display: flex;
    margin: 0;
    align-items: center; }
  .flannel-parent-nav li {
    list-style-type: none;
    padding: 0;
    margin: 0 25px; }
    .flannel-parent-nav li .nav-image-container:after {
      content: '';
      display: block;
      height: 100%;
      background-size: cover;
      background-position: center; }
    .flannel-parent-nav li a {
      font-family: 'Open Sans Condensed', sans-serif;
      font-style: normal;
      font-weight: 700;
      color: #ffffff;
      font-size: 20px;
      font-stretch: condensed;
      text-transform: uppercase;
      font-weight: bold;
      min-height: 0;
      padding: 15px 0;
      border-bottom: 5px solid transparent; }
      .flannel-parent-nav li a:active, .flannel-parent-nav li a:hover, .flannel-parent-nav li a:focus {
        color: #ce1f2e; }
        .flannel-parent-nav li a:active::after,
        .flannel-parent-nav li a:active i, .flannel-parent-nav li a:hover::after,
        .flannel-parent-nav li a:hover i, .flannel-parent-nav li a:focus::after,
        .flannel-parent-nav li a:focus i {
          color: #ce1f2e; }
    .flannel-parent-nav li i {
      margin-left: 5px;
      color: #ffffff;
      font-size: 18px;
      margin-left: 6px;
      font-weight: 500; }
    .flannel-parent-nav li .nav-children {
      flex-direction: column;
      align-items: flex-start;
      padding: 22px 0; }
    .flannel-parent-nav li .uk-navbar-dropdown {
      margin-top: 0;
      box-shadow: -20px -20px 0 0 rgba(0, 0, 0, 0.15);
      width: 500px;
      z-index: -2;
      min-height: 200px;
      padding: 0; }
      .flannel-parent-nav li .uk-navbar-dropdown.uk-open {
        display: flex;
        flex-direction: row-reverse; }
      .flannel-parent-nav li .uk-navbar-dropdown .uk-drop-bottom {
        margin-top: 0; }
      .flannel-parent-nav li .uk-navbar-dropdown a,
      .flannel-parent-nav li .uk-navbar-dropdown i {
        color: #000000 !important;
        font-size: 16px;
        border: none !important;
        padding: 0;
        margin: 10px 0; }
        .flannel-parent-nav li .uk-navbar-dropdown a:active, .flannel-parent-nav li .uk-navbar-dropdown a:hover, .flannel-parent-nav li .uk-navbar-dropdown a:focus,
        .flannel-parent-nav li .uk-navbar-dropdown i:active,
        .flannel-parent-nav li .uk-navbar-dropdown i:hover,
        .flannel-parent-nav li .uk-navbar-dropdown i:focus {
          color: #545454 !important; }
      .flannel-parent-nav li .uk-navbar-dropdown i {
        font-size: 12px !important; }
  .flannel-parent-nav .uk-active > a,
  .flannel-parent-nav .current-page-parent > a {
    color: #ffffff !important; }
    .flannel-parent-nav .uk-active > a:active, .flannel-parent-nav .uk-active > a:hover, .flannel-parent-nav .uk-active > a:focus,
    .flannel-parent-nav .current-page-parent > a:active,
    .flannel-parent-nav .current-page-parent > a:hover,
    .flannel-parent-nav .current-page-parent > a:focus {
      color: #545454; }
  .flannel-parent-nav .flannel-black-bkgd .uk-active > a,
  .flannel-parent-nav .flannel-black-bkgd .current-page-parent > a,
  .flannel-parent-nav .flannel-black-bkgd .current-menu-parent > a {
    border-bottom: 5px solid #ce1f2e; }
  .flannel-parent-nav .flannel-black-bkgd .current-menu-parent .uk-active > a {
    color: #ce1f2e !important; }
  .flannel-parent-nav .flannel-black-bkgd a:active,
  .flannel-parent-nav .flannel-black-bkgd a:hover,
  .flannel-parent-nav .flannel-black-bkgd a:focus,
  .flannel-parent-nav .flannel-black-bkgd a.uk-open {
    color: #ce1f2e !important; }
    .flannel-parent-nav .flannel-black-bkgd a:active i,
    .flannel-parent-nav .flannel-black-bkgd a:hover i,
    .flannel-parent-nav .flannel-black-bkgd a:focus i,
    .flannel-parent-nav .flannel-black-bkgd a.uk-open i {
      color: #ce1f2e !important; }
  .flannel-parent-nav .flannel-black-bkgd .uk-navbar-dropdown a:active, .flannel-parent-nav .flannel-black-bkgd .uk-navbar-dropdown a:hover, .flannel-parent-nav .flannel-black-bkgd .uk-navbar-dropdown a:focus {
    color: #ce1f2e !important; }
  .flannel-parent-nav .flannel-black-bkgd .red-nav-button a:active, .flannel-parent-nav .flannel-black-bkgd .red-nav-button a:hover, .flannel-parent-nav .flannel-black-bkgd .red-nav-button a:focus {
    color: #000000 !important; }
  .flannel-parent-nav .flannel-red-bkgd .uk-active > a,
  .flannel-parent-nav .flannel-red-bkgd .current-page-parent > a,
  .flannel-parent-nav .flannel-red-bkgd .active.uk-parent a,
  .flannel-parent-nav .flannel-red-bkgd .current-menu-parent > a {
    border-bottom: 5px solid #000000; }
  .flannel-parent-nav .flannel-red-bkgd .current-menu-parent .current_page_item > a {
    color: #ce1f2e !important; }
  .flannel-parent-nav .flannel-red-bkgd li a:active,
  .flannel-parent-nav .flannel-red-bkgd li a:hover,
  .flannel-parent-nav .flannel-red-bkgd li a:focus,
  .flannel-parent-nav .flannel-red-bkgd li a.uk-open {
    color: #000000 !important;
    opacity: 1; }
    .flannel-parent-nav .flannel-red-bkgd li a:active i,
    .flannel-parent-nav .flannel-red-bkgd li a:hover i,
    .flannel-parent-nav .flannel-red-bkgd li a:focus i,
    .flannel-parent-nav .flannel-red-bkgd li a.uk-open i {
      color: #000000; }
  .flannel-parent-nav .flannel-red-bkgd li.black-nav-button a {
    padding: 0; }
    .flannel-parent-nav .flannel-red-bkgd li.black-nav-button a:active, .flannel-parent-nav .flannel-red-bkgd li.black-nav-button a:hover, .flannel-parent-nav .flannel-red-bkgd li.black-nav-button a:focus {
      color: #ce1f2e !important; }
  .flannel-parent-nav .flannel-red-bkgd li .uk-navbar-dropdown a:active, .flannel-parent-nav .flannel-red-bkgd li .uk-navbar-dropdown a:hover, .flannel-parent-nav .flannel-red-bkgd li .uk-navbar-dropdown a:focus {
    color: #ce1f2e !important; }

nav.flannel-red-nav > div {
  width: 100%; }

.mobile-header-nav {
  margin-top: 50px; }
  .mobile-header-nav i {
    margin-left: 5px;
    color: #ffffff;
    font-size: 18px;
    margin-left: 6px;
    font-weight: 500; }
  .mobile-header-nav a:active, .mobile-header-nav a:hover, .mobile-header-nav a:focus {
    color: #ce1f2e; }
    .mobile-header-nav a:active i, .mobile-header-nav a:hover i, .mobile-header-nav a:focus i {
      color: #ce1f2e; }
  .mobile-header-nav .red-nav-button span {
    color: #ffffff; }
  .mobile-header-nav .red-nav-button:active span, .mobile-header-nav .red-nav-button:hover span, .mobile-header-nav .red-nav-button:focus span {
    color: #000000; }

.page-404-container .content-container {
  max-width: 640px;
  margin: 0 auto;
  padding: 20px; }
  @media all and (min-width: 640px) {
    .page-404-container .content-container {
      padding: 50px 100px 50px 50px; } }
  .page-404-container .content-container h2 {
    color: #ce1f2e;
    margin: 0; }
  .page-404-container .content-container h3 {
    margin: 0 0 15px; }
  .page-404-container .content-container p {
    margin-bottom: 30px; }
  .page-404-container .content-container a {
    color: #ce1f2e; }
    .page-404-container .content-container a::after {
      font-family: "Font Awesome 5 Pro";
      content: "\f30b";
      margin-left: 10px; }
    .page-404-container .content-container a:active, .page-404-container .content-container a:hover, .page-404-container .content-container a:focus {
      color: #8E8E8E;
      text-decoration: none; }

.page-404-container .broken-column {
  background-image: url("../images/patterns/pattern.png");
  margin-right: -200px;
  padding: 50px;
  min-height: 500px;
  position: relative; }
  .page-404-container .broken-column img {
    position: absolute;
    left: -100px; }

.flannel-blog-page,
.flannel-category-page {
  margin-top: 30px; }
  @media all and (min-width: 960px) {
    .flannel-blog-page,
    .flannel-category-page {
      margin-top: 60px; } }
  .flannel-blog-page .featured-article-container .uk-first-column,
  .flannel-blog-page .featured-category-container .uk-first-column,
  .flannel-category-page .featured-article-container .uk-first-column,
  .flannel-category-page .featured-category-container .uk-first-column {
    padding-left: 0; }
    @media all and (min-width: 960px) {
      .flannel-blog-page .featured-article-container .uk-first-column,
      .flannel-blog-page .featured-category-container .uk-first-column,
      .flannel-category-page .featured-article-container .uk-first-column,
      .flannel-category-page .featured-category-container .uk-first-column {
        padding-left: 30px; } }
  .flannel-blog-page .featured-article-container .featured-article-image,
  .flannel-blog-page .featured-category-container .featured-article-image,
  .flannel-category-page .featured-article-container .featured-article-image,
  .flannel-category-page .featured-category-container .featured-article-image {
    margin-top: -60px;
    position: relative;
    margin-bottom: -15px;
    text-align: center; }
  .flannel-blog-page .featured-article-container .featured-article,
  .flannel-blog-page .featured-category-container .featured-article,
  .flannel-category-page .featured-article-container .featured-article,
  .flannel-category-page .featured-category-container .featured-article {
    background: #ffffff;
    padding: 0;
    margin: 0; }
    @media all and (min-width: 1200px) {
      .flannel-blog-page .featured-article-container .featured-article,
      .flannel-blog-page .featured-category-container .featured-article,
      .flannel-category-page .featured-article-container .featured-article,
      .flannel-category-page .featured-category-container .featured-article {
        background: #f3f3f3;
        padding: 50px 50px 50px 100px;
        margin: 0 -80px 20px -80px; } }
    .flannel-blog-page .featured-article-container .featured-article h3,
    .flannel-blog-page .featured-category-container .featured-article h3,
    .flannel-category-page .featured-article-container .featured-article h3,
    .flannel-category-page .featured-category-container .featured-article h3 {
      font-size: 36px;
      line-height: 1; }
      @media all and (min-width: 640px) {
        .flannel-blog-page .featured-article-container .featured-article h3,
        .flannel-blog-page .featured-category-container .featured-article h3,
        .flannel-category-page .featured-article-container .featured-article h3,
        .flannel-category-page .featured-category-container .featured-article h3 {
          font-size: 45px; } }
    .flannel-blog-page .featured-article-container .featured-article a,
    .flannel-blog-page .featured-category-container .featured-article a,
    .flannel-category-page .featured-article-container .featured-article a,
    .flannel-category-page .featured-category-container .featured-article a {
      text-decoration: none; }
      .flannel-blog-page .featured-article-container .featured-article a:active, .flannel-blog-page .featured-article-container .featured-article a:hover, .flannel-blog-page .featured-article-container .featured-article a:focus,
      .flannel-blog-page .featured-category-container .featured-article a:active,
      .flannel-blog-page .featured-category-container .featured-article a:hover,
      .flannel-blog-page .featured-category-container .featured-article a:focus,
      .flannel-category-page .featured-article-container .featured-article a:active,
      .flannel-category-page .featured-article-container .featured-article a:hover,
      .flannel-category-page .featured-article-container .featured-article a:focus,
      .flannel-category-page .featured-category-container .featured-article a:active,
      .flannel-category-page .featured-category-container .featured-article a:hover,
      .flannel-category-page .featured-category-container .featured-article a:focus {
        color: #8E8E8E;
        text-decoration: none; }
  .flannel-blog-page .featured-article-container,
  .flannel-category-page .featured-article-container {
    padding: 60px 0 180px;
    max-width: 690px;
    margin: 0 auto; }
    @media all and (min-width: 960px) {
      .flannel-blog-page .featured-article-container,
      .flannel-category-page .featured-article-container {
        max-width: none;
        margin: 0 0 0 -40px; } }
  .flannel-blog-page .featured-category-container,
  .flannel-category-page .featured-category-container {
    padding: 60px 0 0; }
  .flannel-blog-page .blog-list-angle-background,
  .flannel-category-page .blog-list-angle-background {
    clip-path: polygon(0 0, 100% 95%, 100% 100%, 0% 100%);
    background: #f3f3f3;
    height: 50px;
    margin-bottom: 0px; }
  .flannel-blog-page .blog-list-background,
  .flannel-category-page .blog-list-background {
    background: #f3f3f3;
    position: relative;
    margin-bottom: -50px;
    padding-bottom: 50px; }
    .flannel-blog-page .blog-list-background .uk-container,
    .flannel-category-page .blog-list-background .uk-container {
      max-width: 1200px;
      padding: 0 15px 0 0;
      margin-left: -20px; }
      @media all and (min-width: 640px) {
        .flannel-blog-page .blog-list-background .uk-container,
        .flannel-category-page .blog-list-background .uk-container {
          padding: 0 65px 0 35px;
          margin: 0 auto; } }
      @media all and (min-width: 1480px) {
        .flannel-blog-page .blog-list-background .uk-container,
        .flannel-category-page .blog-list-background .uk-container {
          padding: 0 65px; } }
  .flannel-blog-page .blog-container,
  .flannel-category-page .blog-container {
    margin-top: -150px; }
    .flannel-blog-page .blog-container > div,
    .flannel-category-page .blog-container > div {
      padding-left: 65px; }
      @media all and (min-width: 640px) {
        .flannel-blog-page .blog-container > div:nth-child(2),
        .flannel-category-page .blog-container > div:nth-child(2) {
          padding-top: 50px; } }
      @media all and (min-width: 960px) {
        .flannel-blog-page .blog-container > div:nth-child(3),
        .flannel-category-page .blog-container > div:nth-child(3) {
          padding-top: 100px; } }
    .flannel-blog-page .blog-container * + .uk-grid-margin,
    .flannel-category-page .blog-container * + .uk-grid-margin {
      margin-top: 65px; }
    .flannel-blog-page .blog-container .uk-card .uk-card-title,
    .flannel-category-page .blog-container .uk-card .uk-card-title {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 1.25;
      margin: 10px 0; }
    .flannel-blog-page .blog-container .uk-card img:active, .flannel-blog-page .blog-container .uk-card img:hover, .flannel-blog-page .blog-container .uk-card img:focus,
    .flannel-category-page .blog-container .uk-card img:active,
    .flannel-category-page .blog-container .uk-card img:hover,
    .flannel-category-page .blog-container .uk-card img:focus {
      opacity: .7; }
    .flannel-blog-page .blog-container .uk-card h3:active, .flannel-blog-page .blog-container .uk-card h3:hover, .flannel-blog-page .blog-container .uk-card h3:focus,
    .flannel-category-page .blog-container .uk-card h3:active,
    .flannel-category-page .blog-container .uk-card h3:hover,
    .flannel-category-page .blog-container .uk-card h3:focus {
      color: #ce1f2e; }
    .flannel-blog-page .blog-container .uk-card p,
    .flannel-category-page .blog-container .uk-card p {
      font-size: 14px;
      margin: 0; }
    .flannel-blog-page .blog-container .uk-card a,
    .flannel-category-page .blog-container .uk-card a {
      text-decoration: none; }
      .flannel-blog-page .blog-container .uk-card a:active, .flannel-blog-page .blog-container .uk-card a:hover, .flannel-blog-page .blog-container .uk-card a:focus,
      .flannel-category-page .blog-container .uk-card a:active,
      .flannel-category-page .blog-container .uk-card a:hover,
      .flannel-category-page .blog-container .uk-card a:focus {
        color: #8E8E8E;
        text-decoration: none; }
  .flannel-blog-page .blog-list-pagination,
  .flannel-category-page .blog-list-pagination {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    padding: 50px;
    text-align: center; }
    .flannel-blog-page .blog-list-pagination span,
    .flannel-category-page .blog-list-pagination span {
      background: #ce1f2e;
      color: #ffffff;
      padding: 2px 10px; }
    .flannel-blog-page .blog-list-pagination a,
    .flannel-category-page .blog-list-pagination a {
      margin: 0 15px;
      color: #545454; }
      .flannel-blog-page .blog-list-pagination a:active, .flannel-blog-page .blog-list-pagination a:hover, .flannel-blog-page .blog-list-pagination a:focus,
      .flannel-category-page .blog-list-pagination a:active,
      .flannel-category-page .blog-list-pagination a:hover,
      .flannel-category-page .blog-list-pagination a:focus {
        color: #ce1f2e;
        text-decoration: none; }

.max-six-ninety {
  max-width: 690px; }

.flannel-blog-post-header {
  height: 300px; }
  @media all and (min-width: 960px) {
    .flannel-blog-post-header {
      height: 400px; } }
  .flannel-blog-post-header .background-banner {
    position: absolute;
    width: 100%;
    height: 300px;
    object-fit: cover; }
    @media all and (min-width: 960px) {
      .flannel-blog-post-header .background-banner {
        height: 400px; } }
  .flannel-blog-post-header a {
    z-index: 8;
    position: relative;
    justify-content: center;
    display: flex; }
    .flannel-blog-post-header a img {
      margin-top: 30px; }
  .flannel-blog-post-header .flanneljaxs-logo {
    position: relative;
    display: flex;
    margin: 0 auto;
    padding: 30px 0 150px; }

.flannel-blog-post-container {
  max-width: 640px;
  padding: 0 15px;
  margin-top: 0;
  background: #ffffff;
  position: relative;
  z-index: 12; }
  @media all and (min-width: 960px) {
    .flannel-blog-post-container {
      max-width: 930px;
      padding: 10px 30px;
      margin-top: -100px; } }
  .flannel-blog-post-container .flannel-article-header {
    margin-top: 15px; }
    .flannel-blog-post-container .flannel-article-header h1 {
      margin: 15px 0; }
    .flannel-blog-post-container .flannel-article-header a {
      font-size: 14px; }
      .flannel-blog-post-container .flannel-article-header a:active, .flannel-blog-post-container .flannel-article-header a:hover, .flannel-blog-post-container .flannel-article-header a:focus {
        color: #8E8E8E;
        text-decoration: none; }
    .flannel-blog-post-container .flannel-article-header .featured-image-container {
      clear: both;
      margin: 30px 0; }
  .flannel-blog-post-container .meta-container {
    border-top: 2px solid #f3f3f3;
    border-bottom: 2px solid #f3f3f3;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column; }
    @media all and (min-width: 640px) {
      .flannel-blog-post-container .meta-container {
        align-items: center;
        flex-direction: row;
        padding: 15px 0; } }
    .flannel-blog-post-container .meta-container time {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #8E8E8E;
      margin-bottom: 10px; }
      @media all and (min-width: 960px) {
        .flannel-blog-post-container .meta-container time {
          font-size: 16px;
          margin-bottom: 0; } }
      .flannel-blog-post-container .meta-container time i {
        color: #545454;
        font-size: 14px;
        margin-right: 14px; }
        @media all and (min-width: 960px) {
          .flannel-blog-post-container .meta-container time i {
            margin-right: 20px;
            font-size: 20px; } }
    .flannel-blog-post-container .meta-container .share-links-container {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      color: #8E8E8E;
      font-size: 14px; }
      .flannel-blog-post-container .meta-container .share-links-container a {
        font-size: 14px;
        margin-left: 14px; }
        @media all and (min-width: 960px) {
          .flannel-blog-post-container .meta-container .share-links-container a {
            margin-left: 20px;
            font-size: 20px; } }
        .flannel-blog-post-container .meta-container .share-links-container a[title='Facebook'] {
          color: #3B5998; }
        .flannel-blog-post-container .meta-container .share-links-container a[title='Twitter'] {
          color: #00ACED; }
        .flannel-blog-post-container .meta-container .share-links-container a[title='Pinterest'] {
          color: #CB2027; }
        .flannel-blog-post-container .meta-container .share-links-container a[title='LinkedIn'] {
          color: #007BB6; }
        .flannel-blog-post-container .meta-container .share-links-container a[title='Email'] {
          color: #545454; }
        .flannel-blog-post-container .meta-container .share-links-container a:active, .flannel-blog-post-container .meta-container .share-links-container a:hover, .flannel-blog-post-container .meta-container .share-links-container a:focus {
          text-decoration: none;
          opacity: .8; }
  .flannel-blog-post-container .entry-content a:active, .flannel-blog-post-container .entry-content a:hover, .flannel-blog-post-container .entry-content a:focus {
    color: #8E8E8E;
    text-decoration: none; }
  .flannel-blog-post-container .entry-content ul {
    list-style-type: none; }
    .flannel-blog-post-container .entry-content ul li {
      font-size: 16px;
      line-height: 1.6;
      color: #545454;
      margin-bottom: 10px; }
      @media all and (min-width: 640px) {
        .flannel-blog-post-container .entry-content ul li {
          font-size: 18px; } }
      @media all and (min-width: 960px) {
        .flannel-blog-post-container .entry-content ul li {
          font-size: 20px; } }
      .flannel-blog-post-container .entry-content ul li::before {
        content: "■";
        color: #ce1f2e;
        width: 47px;
        display: inline-block;
        margin-left: -53px;
        text-align: right;
        padding-right: 15px; }
  .flannel-blog-post-container .author-container {
    margin: 50px 0 80px;
    position: relative; }
    .flannel-blog-post-container .author-container .author-plaid-background {
      margin: -30px;
      background: #ce1f2e;
      background-image: url("../images/patterns/pattern.png");
      width: 100%;
      height: 60%;
      padding: 30px;
      position: absolute; }
    .flannel-blog-post-container .author-container .author-info {
      background: #ffffff;
      position: relative;
      margin: 0;
      padding-top: 30px;
      padding-right: 30px; }
      .flannel-blog-post-container .author-container .author-info h1, .flannel-blog-post-container .author-container .author-info h2, .flannel-blog-post-container .author-container .author-info h3, .flannel-blog-post-container .author-container .author-info h4, .flannel-blog-post-container .author-container .author-info h5, .flannel-blog-post-container .author-container .author-info h6 {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 10px; }
      .flannel-blog-post-container .author-container .author-info p {
        font-size: 16px;
        line-height: 1.5; }
        @media all and (min-width: 960px) {
          .flannel-blog-post-container .author-container .author-info p {
            font-size: 20px;
            line-height: 1.75; } }

.blog-pagination-container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto 40px; }
  .blog-pagination-container .pagination-nav-container {
    border-top: 3px solid #000000;
    border-bottom: 3px solid #000000;
    padding: 10px 15px;
    max-width: 500px;
    margin: 0 auto; }
    .blog-pagination-container .pagination-nav-container .pagination-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      color: #000000; }
      .blog-pagination-container .pagination-nav-container .pagination-nav .pagination-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 35px; }
        .blog-pagination-container .pagination-nav-container .pagination-nav .pagination-icon.previous-icon {
          margin-right: 20px; }
        .blog-pagination-container .pagination-nav-container .pagination-nav .pagination-icon.next-icon {
          margin-left: 20px; }
      .blog-pagination-container .pagination-nav-container .pagination-nav p {
        font-family: 'AxeHandel', sans-serif;
        text-transform: uppercase;
        font-style: normal;
        font-weight: normal;
        font-display: swap;
        text-rendering: optimizeLegibility;
        font-size: 26px;
        margin: 0;
        line-height: 1.1; }
        @media all and (min-width: 960px) {
          .blog-pagination-container .pagination-nav-container .pagination-nav p {
            font-size: 40px; } }
      .blog-pagination-container .pagination-nav-container .pagination-nav span {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #ce1f2e; }
  .blog-pagination-container a:active, .blog-pagination-container a:hover, .blog-pagination-container a:focus {
    text-decoration: none; }
    .blog-pagination-container a:active p,
    .blog-pagination-container a:active .pagination-icon, .blog-pagination-container a:hover p,
    .blog-pagination-container a:hover .pagination-icon, .blog-pagination-container a:focus p,
    .blog-pagination-container a:focus .pagination-icon {
      color: #ce1f2e; }

.flannel-contact-page .uk-container {
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 0; }
  @media all and (min-width: 960px) {
    .flannel-contact-page .uk-container {
      padding-top: 30px; } }

.flannel-contact-page .content-container {
  min-height: auto;
  max-width: 640px;
  margin: 0 auto;
  padding-bottom: 40px; }
  @media all and (min-width: 960px) {
    .flannel-contact-page .content-container {
      min-height: 650px;
      max-width: none; } }
  .flannel-contact-page .content-container h2 {
    margin-bottom: 10px; }
  .flannel-contact-page .content-container h4 {
    text-align: center;
    margin-bottom: 40px; }
    @media all and (min-width: 500px) {
      .flannel-contact-page .content-container h4 {
        text-align: left; } }
  .flannel-contact-page .content-container p {
    margin: 10px 0; }
  .flannel-contact-page .content-container a:active, .flannel-contact-page .content-container a:hover, .flannel-contact-page .content-container a:focus {
    color: #8E8E8E;
    text-decoration: none; }

.flannel-contact-page .icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid #545454;
  margin-right: 20px;
  min-width: 40px;
  height: 40px;
  margin: 0 auto 10px;
  align-self: start; }
  @media all and (min-width: 500px) {
    .flannel-contact-page .icon-container {
      min-width: 50px;
      height: 50px;
      margin: 0 15px 0 0; } }
  .flannel-contact-page .icon-container i {
    font-size: 1em; }
    @media all and (min-width: 500px) {
      .flannel-contact-page .icon-container i {
        font-size: 1.33em; } }

.flannel-contact-page .info-container {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  flex-direction: column; }
  @media all and (min-width: 500px) {
    .flannel-contact-page .info-container {
      flex-direction: row; } }
  .flannel-contact-page .info-container .address-container {
    text-align: center; }
    @media all and (min-width: 500px) {
      .flannel-contact-page .info-container .address-container {
        text-align: left; } }
  .flannel-contact-page .info-container span {
    display: block; }
  .flannel-contact-page .info-container a {
    font-size: 14px;
    line-height: 28px; }

.flannel-contact-page .contact-container {
  position: relative;
  padding: 0 0 50px;
  max-width: 450px;
  float: none;
  margin: 0 auto; }
  @media all and (min-width: 960px) {
    .flannel-contact-page .contact-container {
      margin: 0;
      padding: 0;
      float: right; } }
  .flannel-contact-page .contact-container .form-container {
    padding: 15px;
    background: #ffffff;
    z-index: 11;
    position: relative;
    overflow: hidden;
    min-height: 470px;
    box-shadow: -20px 20px 0 0 rgba(0, 0, 0, 0.15); }
    @media all and (min-width: 960px) {
      .flannel-contact-page .contact-container .form-container {
        padding: 0 40px 40px; } }
    .flannel-contact-page .contact-container .form-container:after {
      content: "";
      background-image: url("../images/axes/axes_black.svg");
      background-position: bottom center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      bottom: -30px;
      right: 0;
      left: 0;
      margin: 0 auto;
      opacity: .1;
      width: 80%;
      height: 300px; }
    .flannel-contact-page .contact-container .form-container .gform_body div.ginput_container {
      width: auto !important; }
    .flannel-contact-page .contact-container .form-container div.gform_wrapper.gform_validation_error {
      padding-top: 30px;
      margin-top: 0; }
      .flannel-contact-page .contact-container .form-container div.gform_wrapper.gform_validation_error li.gfield.gfield_error {
        background: transparent; }
      .flannel-contact-page .contact-container .form-container div.gform_wrapper.gform_validation_error div.validation_error {
        margin-bottom: 0; }
    .flannel-contact-page .contact-container .form-container .ginput_container_consent {
      margin: 30px 0 0 25px; }
      .flannel-contact-page .contact-container .form-container .ginput_container_consent input {
        margin-left: -25px !important; }
    .flannel-contact-page .contact-container .form-container .gform_footer input[type="submit"] {
      font-size: 23px !important; }
  .flannel-contact-page .contact-container .form-background {
    background: rgba(81, 12, 18, 0.83);
    background-image: url("../images/patterns/pattern.png");
    background-blend-mode: difference;
    position: absolute;
    right: -55px;
    top: -66px;
    width: 100%;
    z-index: -4;
    height: 145%; }

.minus-eighty {
  margin-top: -80px; }

.flannel-default-page {
  margin-bottom: 60px; }
  .flannel-default-page ul {
    list-style-type: none;
    padding-left: 20px; }
    @media all and (min-width: 640px) {
      .flannel-default-page ul {
        padding-left: 20px; } }
    .flannel-default-page ul li {
      list-style-type: none;
      font-size: 16px;
      line-height: 1.75;
      margin-bottom: 20px; }
      @media all and (min-width: 640px) {
        .flannel-default-page ul li {
          font-size: 18px; } }
      @media all and (min-width: 960px) {
        .flannel-default-page ul li {
          font-size: 20px; } }
      .flannel-default-page ul li:before {
        content: '■';
        font-size: 16px;
        color: #ce1f2e;
        display: inline-block;
        text-indent: -5px;
        margin-top: -4px;
        margin-right: 11px;
        margin-left: -15px; }
        @media all and (min-width: 640px) {
          .flannel-default-page ul li:before {
            font-size: 18px; } }
        @media all and (min-width: 960px) {
          .flannel-default-page ul li:before {
            font-size: 20px;
            margin-top: 0; } }
  .flannel-default-page .uk-container {
    max-width: 640px;
    margin: 0 auto; }
    @media all and (min-width: 960px) {
      .flannel-default-page .uk-container {
        max-width: 930px;
        margin: 60px auto 0; } }
  .flannel-default-page a {
    color: #ce1f2e;
    font-weight: bold; }
  .flannel-default-page .flannel-video-thumbnail, .flannel-default-page video {
    width: 100%;
    max-width: none;
    margin-bottom: 10px; }
    @media all and (min-width: 960px) {
      .flannel-default-page .flannel-video-thumbnail, .flannel-default-page video {
        max-width: 430px; } }
    .flannel-default-page .flannel-video-thumbnail.flannel-box-shadow, .flannel-default-page video.flannel-box-shadow {
      margin-bottom: 40px; }
      .flannel-default-page .flannel-video-thumbnail.flannel-box-shadow video, .flannel-default-page video.flannel-box-shadow video {
        margin-bottom: 0; }
  .flannel-default-page .flannel-float-right {
    margin-left: 0; }
    @media all and (min-width: 960px) {
      .flannel-default-page .flannel-float-right {
        margin-left: 40px; } }

.default-page-footer-border {
  clip-path: polygon(0 75%, 100% 0%, 100% 100%, 0% 100%);
  background: #000000;
  padding-top: 60px;
  margin-bottom: -1px; }

.flannel-detail-page .uk-container {
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 0;
  padding: 0 20px; }
  @media all and (min-width: 640px) {
    .flannel-detail-page .uk-container {
      padding: 0 30px; } }
  @media all and (min-width: 960px) {
    .flannel-detail-page .uk-container {
      padding-top: 30px; } }
  .flannel-detail-page .uk-container .content-container {
    max-width: 640px;
    margin: 0 auto 30px;
    padding-bottom: 60px;
    padding-right: 10px; }
    @media all and (min-width: 960px) {
      .flannel-detail-page .uk-container .content-container {
        margin: 0 auto; } }
  .flannel-detail-page .uk-container .promo-column {
    max-width: 490px;
    margin: 0 auto; }
    .flannel-detail-page .uk-container .promo-column .flannel-detail-video {
      margin-bottom: 60px; }
    .flannel-detail-page .uk-container .promo-column .promo-background {
      background: #ce1f2e;
      background-image: url("../images/patterns/pattern.png");
      position: absolute;
      right: -40px;
      top: -60px;
      width: 100%;
      height: 120%;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 100%); }

.flannel-detail-page .flannel-information-container {
  margin: 0 -20px; }
  @media all and (min-width: 960px) {
    .flannel-detail-page .flannel-information-container {
      margin: 0; } }
  .flannel-detail-page .flannel-information-container .content-column {
    padding: 30px 20px;
    margin: 50px auto 0; }
    @media all and (min-width: 960px) {
      .flannel-detail-page .flannel-information-container .content-column {
        padding: 80px 40px 151px 80px; } }

.flannel-detail-page .flannel-media-slider-container {
  top: 0;
  background: #ffffff;
  padding: 0;
  margin-bottom: 0; }
  @media all and (min-width: 960px) {
    .flannel-detail-page .flannel-media-slider-container {
      top: -150px;
      margin-bottom: -150px;
      padding: 0 40px;
      max-width: 985px;
      box-sizing: border-box;
      right: 0;
      float: right; } }
  .flannel-detail-page .flannel-media-slider-container .media-slider-background {
    display: none; }
  .flannel-detail-page .flannel-media-slider-container .media-slider-component {
    margin: 0; }

.flannel-detail-page .additional-details-container {
  padding: 30px 0 55px;
  clear: both;
  max-width: 930px;
  margin: 0 auto; }

.flannel-detail-page .book-box-background {
  background: #000000;
  width: 100%;
  height: 150px;
  margin-top: -150px;
  position: relative;
  z-index: -4; }

.flannel-detail-page .flannel-red-book-box {
  max-width: 690px;
  margin: 0 auto;
  clear: both; }

.flannel-detail-page .flannel-pagination-container-bkgd {
  padding: 67px 20px; }

.flannel-faq-page {
  padding-bottom: 50px; }
  .flannel-faq-page.page-container .uk-container {
    max-width: 1020px;
    padding: 0 10px;
    margin-top: -15px; }
    @media all and (min-width: 640px) {
      .flannel-faq-page.page-container .uk-container {
        padding: 0 15px;
        margin-top: 0; } }
  @media all and (min-width: 640px) {
    .flannel-faq-page .uk-container .content-container {
      padding: 30px; } }
  .flannel-faq-page h2 {
    margin-bottom: 15px; }
  .flannel-faq-page h3 {
    margin: 40px 0 10px; }
  .flannel-faq-page p {
    margin: 0 0 10px; }
  .flannel-faq-page ul.flannel-accordion-container {
    margin: 0; }

.flannel-front-page-container header.header-parallax-container {
  height: 620px; }
  @media all and (min-width: 640px) {
    .flannel-front-page-container header.header-parallax-container {
      height: 650px; } }
  @media all and (min-width: 960px) {
    .flannel-front-page-container header.header-parallax-container {
      height: 850px; } }
  @media all and (min-width: 1200px) {
    .flannel-front-page-container header.header-parallax-container {
      height: 1050px; } }

.flannel-front-page-container header .national-parallax-background {
  background-image: url("../images/front-page/background.jpg");
  height: 150vh;
  position: fixed;
  width: 100vw;
  top: -200px;
  background-size: auto;
  background-position-x: 50%;
  display: none; }
  @media all and (min-width: 1200px) {
    .flannel-front-page-container header .national-parallax-background {
      display: block; } }

.flannel-front-page-container header .mobile-bkgd-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1; }

.flannel-front-page-container header .parallax-button,
.flannel-front-page-container header .parallax-logo {
  margin-top: 35px; }
  .flannel-front-page-container header .parallax-button img,
  .flannel-front-page-container header .parallax-logo img {
    width: 162px; }
    @media all and (min-width: 640px) {
      .flannel-front-page-container header .parallax-button img,
      .flannel-front-page-container header .parallax-logo img {
        width: auto; } }

.flannel-front-page-container header .thrower-container {
  position: absolute;
  left: 20%;
  z-index: 0;
  display: none; }
  @media all and (min-width: 1200px) {
    .flannel-front-page-container header .thrower-container {
      display: block; } }

.flannel-front-page-container header .spectator-container {
  bottom: -50%;
  position: absolute;
  width: 140%;
  margin: 0 -33%;
  display: none; }
  @media all and (min-width: 1200px) {
    .flannel-front-page-container header .spectator-container {
      display: block; } }

.flannel-front-page-container header .parallax-spectator {
  width: 100%;
  object-fit: cover; }

.flannel-front-page-container header .front-page-header {
  width: 100%; }
  .flannel-front-page-container header .front-page-header h1 {
    max-width: 930px;
    margin: 0 auto;
    padding: 30px;
    line-height: 1;
    font-size: 40px; }
    @media all and (min-width: 340px) {
      .flannel-front-page-container header .front-page-header h1 {
        font-size: 45px; } }
    @media all and (min-width: 960px) {
      .flannel-front-page-container header .front-page-header h1 {
        font-size: 75px; } }

.flannel-front-page-container .content-column {
  background: #ffffff;
  padding: 15px;
  margin: -50px 15px 0; }
  @media all and (min-width: 640px) {
    .flannel-front-page-container .content-column {
      margin: -140px 0 0;
      padding: 40px; } }
  @media all and (min-width: 960px) {
    .flannel-front-page-container .content-column {
      padding: 70px; } }

.flannel-front-page-container .flannel-testimonial-container {
  height: 420px; }
  @media all and (min-width: 640px) {
    .flannel-front-page-container .flannel-testimonial-container {
      height: 600px; } }

.flannel-front-page-container .franchise-row {
  padding: 0;
  margin-top: -10px;
  position: relative;
  z-index: 12;
  display: flex;
  flex-direction: column-reverse; }
  @media all and (min-width: 640px) {
    .flannel-front-page-container .franchise-row {
      padding: 0 50px; } }
  @media all and (min-width: 960px) {
    .flannel-front-page-container .franchise-row {
      margin-top: -50px;
      flex-direction: row; } }
  .flannel-front-page-container .franchise-row .franchise-image-container {
    padding-right: 0;
    max-width: 450px;
    width: 100%;
    margin-top: 30px; }
    @media all and (min-width: 960px) {
      .flannel-front-page-container .franchise-row .franchise-image-container {
        margin-top: 0;
        padding-right: 50px; } }
    .flannel-front-page-container .franchise-row .franchise-image-container ul {
      height: 100%;
      min-height: 225px !important; }
      @media all and (min-width: 500px) {
        .flannel-front-page-container .franchise-row .franchise-image-container ul {
          min-height: 295px !important; } }
      .flannel-front-page-container .franchise-row .franchise-image-container ul img {
        width: 100%;
        min-width: 290px;
        max-width: 450px;
        object-fit: cover; }
    .flannel-front-page-container .franchise-row .franchise-image-container .franchise-row-nav-container {
      margin-top: 10px;
      text-align: center;
      font-size: 20px; }
      @media all and (min-width: 640px) {
        .flannel-front-page-container .franchise-row .franchise-image-container .franchise-row-nav-container {
          text-align: left; } }
      .flannel-front-page-container .franchise-row .franchise-image-container .franchise-row-nav-container a:hover {
        text-decoration: none; }
  .flannel-front-page-container .franchise-row .franchise-content-container {
    max-width: 600px;
    padding: 0;
    margin-top: 30px; }
    @media all and (min-width: 960px) {
      .flannel-front-page-container .franchise-row .franchise-content-container {
        margin-top: 0; } }

.flannel-front-page-container .flannel-plaid-obi {
  position: absolute;
  top: 0;
  right: 0;
  width: 265px;
  height: 180%;
  z-index: 0;
  padding-top: 50px;
  background: #ce1f2e;
  background-image: url("../images/patterns/pattern.png"); }

.flannel-front-page-container .footer-single-cta {
  margin-bottom: 0;
  z-index: 2; }
  @media all and (min-width: 640px) {
    .flannel-front-page-container .footer-single-cta {
      margin-bottom: -100px; } }

.additional-information-container {
  max-width: 1140px;
  margin: 0 auto 20px;
  padding: 0 15px; }
  @media all and (min-width: 960px) {
    .additional-information-container {
      margin: 0 auto 200px; } }
  .additional-information-container .additional-content-container {
    padding: 0 15px; }
    @media all and (min-width: 960px) {
      .additional-information-container .additional-content-container {
        padding: 0; } }

.mobile-bkgd-divider {
  display: block;
  z-index: 0;
  position: relative;
  height: 100px;
  background: #000000;
  clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 100%); }
  @media all and (min-width: 960px) {
    .mobile-bkgd-divider {
      display: none; } }

.flannel-index-page .uk-container {
  width: 100%;
  max-width: 1020px;
  padding: 0 10px;
  margin-top: -15px; }
  @media all and (min-width: 640px) {
    .flannel-index-page .uk-container {
      padding: 0 15px;
      margin-top: 0; } }
  .flannel-index-page .uk-container .content-container {
    max-width: 640px;
    margin: 0 auto 50px; }
    @media all and (min-width: 960px) {
      .flannel-index-page .uk-container .content-container {
        max-width: none; } }
    .flannel-index-page .uk-container .content-container.extra-fifteen {
      margin-top: 15px; }

.flannel-index-page .content-row-container {
  padding: 0 15px; }
  @media all and (min-width: 640px) {
    .flannel-index-page .content-row-container {
      padding: 0 30px; } }
  @media all and (min-width: 960px) {
    .flannel-index-page .content-row-container {
      padding: 0 30px 40px; } }
  .flannel-index-page .content-row-container a:active, .flannel-index-page .content-row-container a:hover, .flannel-index-page .content-row-container a:focus {
    color: #8E8E8E;
    text-decoration: none; }

.minus-sixty {
  margin-top: -60px; }

.flannel-location-page.page-container .uk-container {
  padding: 0 10px; }
  @media all and (min-width: 640px) {
    .flannel-location-page.page-container .uk-container {
      padding: 0 15px; } }

.flannel-location-page .content-container {
  padding: 0 10px;
  margin-top: -15px; }
  @media all and (min-width: 640px) {
    .flannel-location-page .content-container {
      padding: 0 15px;
      margin-top: 0; } }

.flannel-location-page .map-background {
  background: #ce1f2e;
  background-image: url("../images/patterns/pattern.png");
  width: 385px;
  position: absolute;
  z-index: -1;
  right: -170px;
  top: -170px;
  height: 180%; }

.flannel-location-page .store-location-container {
  width: 100%;
  max-width: 1170px; }

.flannel-location-page .location-container {
  margin-top: 60px;
  background: #ffffff;
  padding: 30px 10px 120px;
  margin-bottom: -40px; }
  @media all and (min-width: 640px) {
    .flannel-location-page .location-container {
      padding: 60px 30px 120px; } }
  .flannel-location-page .location-container .opening-soon-container .store-name {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
    margin: 0; }
  .flannel-location-page .location-container .opening-soon-container .store-address,
  .flannel-location-page .location-container .opening-soon-container .store-opening {
    color: #545454;
    font-size: 14px;
    line-height: normal;
    margin: 0; }
  .flannel-location-page .location-container .opening-soon-container .store-opening {
    background-color: #C6C4C4;
    display: inline-block;
    padding: 1px 4px; }
  .flannel-location-page .location-container img {
    position: absolute;
    width: 550px;
    right: 50px;
    top: 50%; }

.flannel-location-page.franchise-section {
  margin-top: -20px;
  margin-bottom: 0;
  position: relative;
  z-index: 2; }
  @media all and (min-width: 640px) {
    .flannel-location-page.franchise-section {
      margin-bottom: -100px; } }
  @media all and (min-width: 1200px) {
    .flannel-location-page.franchise-section {
      margin-bottom: -140px; } }

.flannel-location-page .location-content-container {
  padding: 0 15px !important; }
  @media all and (min-width: 640px) {
    .flannel-location-page .location-content-container {
      padding: 90px 0 !important; } }

.content-row-container {
  background: #f3f3f3;
  padding: 0 30px;
  margin: 0 auto; }
  @media all and (min-width: 640px) {
    .content-row-container {
      padding: 0 30px 40px; } }
  @media all and (min-width: 960px) {
    .content-row-container {
      max-width: none; } }
  .content-row-container:nth-child(2n) {
    background: #ffffff; }
    .content-row-container:nth-child(2n) .uk-container .content-grid {
      flex-direction: column-reverse !important;
      right: 0;
      left: 0; }
      @media all and (min-width: 960px) {
        .content-row-container:nth-child(2n) .uk-container .content-grid {
          flex-direction: row-reverse !important; } }
      @media all and (min-width: 1200px) {
        .content-row-container:nth-child(2n) .uk-container .content-grid {
          left: -50px; } }
      .content-row-container:nth-child(2n) .uk-container .content-grid .content-column {
        padding: 0;
        max-width: 640px;
        margin: 0 auto; }
        @media all and (min-width: 960px) {
          .content-row-container:nth-child(2n) .uk-container .content-grid .content-column {
            padding: 20px 0 0 100px; } }
  .content-row-container .uk-container {
    padding: 50px 0;
    width: 100%;
    max-width: 1020px;
    position: relative; }
    .content-row-container .uk-container .content-grid {
      display: flex;
      position: relative;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      flex-direction: column-reverse; }
      @media all and (min-width: 960px) {
        .content-row-container .uk-container .content-grid {
          flex-direction: row; } }
      @media all and (min-width: 1200px) {
        .content-row-container .uk-container .content-grid {
          right: -50px; } }
      .content-row-container .uk-container .content-grid a:after {
        font-family: "Font Awesome 5 Pro";
        content: "\f30b";
        margin-left: 10px; }
      .content-row-container .uk-container .content-grid .flannel-button a:after,
      .content-row-container .uk-container .content-grid a.flannel-video-thumbnail:after {
        content: "";
        display: none; }
      .content-row-container .uk-container .content-grid .content-column,
      .content-row-container .uk-container .content-grid .media-column {
        width: 100%; }
        @media all and (min-width: 960px) {
          .content-row-container .uk-container .content-grid .content-column,
          .content-row-container .uk-container .content-grid .media-column {
            width: 50%; } }
      .content-row-container .uk-container .content-grid .content-column {
        position: relative;
        max-width: 640px;
        margin: 0 auto;
        top: -20px;
        padding: 0; }
        @media all and (min-width: 960px) {
          .content-row-container .uk-container .content-grid .content-column {
            padding: 20px 100px 0 0; } }
      .content-row-container .uk-container .content-grid .media-column {
        position: relative;
        top: -70px;
        margin: 0 auto;
        text-align: center;
        max-width: 490px;
        min-height: 40px; }
        @media all and (min-width: 640px) {
          .content-row-container .uk-container .content-grid .media-column {
            top: -100px; } }
        .content-row-container .uk-container .content-grid .media-column img {
          width: 100%;
          object-fit: contain; }
        .content-row-container .uk-container .content-grid .media-column .calendar-container {
          position: absolute;
          top: -20px;
          right: 10px;
          width: 90px;
          text-align: center; }
          @media all and (min-width: 960px) {
            .content-row-container .uk-container .content-grid .media-column .calendar-container {
              width: 130px;
              right: 20px; } }
          .content-row-container .uk-container .content-grid .media-column .calendar-container .calendar-header {
            font-family: 'Open Sans Condensed', sans-serif;
            font-style: normal;
            font-weight: 700;
            background: #ce1f2e;
            color: #ffffff;
            font-size: 18px;
            padding: 7px 8px; }
            @media all and (min-width: 960px) {
              .content-row-container .uk-container .content-grid .media-column .calendar-container .calendar-header {
                font-size: 23px;
                padding: 7px 10px; } }
          .content-row-container .uk-container .content-grid .media-column .calendar-container .calendar-body {
            background: #ffffff;
            color: #000000;
            font-size: 45px;
            line-height: 1; }
            @media all and (min-width: 960px) {
              .content-row-container .uk-container .content-grid .media-column .calendar-container .calendar-body {
                font-size: 75px; } }
        .content-row-container .uk-container .content-grid .media-column .content-media {
          box-shadow: -20px 20px 0 0 #ce1f2e; }

.footer-cta-container {
  background: #000000;
  position: relative;
  margin-bottom: -60px;
  z-index: 2; }
  @media all and (min-width: 960px) {
    .footer-cta-container {
      margin-bottom: -120px; } }
  .footer-cta-container .full-bkgd-position {
    width: 100%;
    height: 100%;
    position: absolute; }
  .footer-cta-container .pre-box-container {
    margin-top: 0; }
    @media all and (min-width: 960px) {
      .footer-cta-container .pre-box-container {
        margin-top: inherit; } }
  .footer-cta-container .box-container {
    height: 150px;
    position: relative;
    padding: 0;
    margin: 0; }
    @media all and (min-width: 640px) {
      .footer-cta-container .box-container {
        height: 300px; } }
    @media all and (min-width: 960px) {
      .footer-cta-container .box-container {
        margin: 0 -20px; } }
    .footer-cta-container .box-container img {
      object-fit: cover;
      left: 0; }
    .footer-cta-container .box-container a {
      font-family: 'AxeHandel', sans-serif;
      text-transform: uppercase;
      font-style: normal;
      font-weight: normal;
      font-display: swap;
      text-rendering: optimizeLegibility;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 40px;
      text-shadow: -6px -4px 0 rgba(0, 0, 0, 0.8);
      z-index: 9;
      position: relative;
      height: 100%;
      padding: 0 30px;
      line-height: 1; }
      @media all and (min-width: 640px) {
        .footer-cta-container .box-container a {
          font-size: 75px; } }
      .footer-cta-container .box-container a:active, .footer-cta-container .box-container a:hover, .footer-cta-container .box-container a:focus {
        color: #ce1f2e;
        text-decoration: none; }
    .footer-cta-container .box-container .left-gradient {
      background: #000000;
      background: linear-gradient(90deg, black 0%, rgba(0, 0, 0, 0.5) 35%); }
    .footer-cta-container .box-container .right-gradient {
      background: #000000;
      background: linear-gradient(270deg, black 0%, rgba(0, 0, 0, 0.5) 35%); }

.footer-single-cta {
  padding: 0;
  margin: 50px 0; }
  @media all and (min-width: 640px) {
    .footer-single-cta {
      padding: 0 40px 0 80px;
      margin: inherit; } }
  @media all and (min-width: 960px) {
    .footer-single-cta {
      padding: 0 120px; } }
  .footer-single-cta .background-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    overflow: hidden; }
    .footer-single-cta .background-image-container .radial-gradient {
      background: radial-gradient(circle, rgba(255, 255, 255, 0) 0%, black 100%);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 3; }
    .footer-single-cta .background-image-container .top-gradiant {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 40%, black 100%);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2; }
    .footer-single-cta .background-image-container .bottom-gradient {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 40%, black 100%);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1; }
    .footer-single-cta .background-image-container img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: relative;
      top: 0px; }
  .footer-single-cta h1, .footer-single-cta h2, .footer-single-cta h3, .footer-single-cta h4 {
    font-size: 32px; }
    @media all and (min-width: 640px) {
      .footer-single-cta h1, .footer-single-cta h2, .footer-single-cta h3, .footer-single-cta h4 {
        font-size: 45px; } }
  .footer-single-cta .location-content-container {
    color: #ffffff;
    z-index: 99;
    padding: 50px 0 0; }
    @media all and (min-width: 640px) {
      .footer-single-cta .location-content-container {
        padding: 90px 0; } }

.flannel-information-container {
  margin-left: 0;
  margin-right: 0;
  position: relative; }
  @media all and (min-width: 1480px) {
    .flannel-information-container {
      margin-left: -175px;
      margin-right: -30px; } }
  .flannel-information-container .image-column {
    display: none;
    position: relative; }
    @media all and (min-width: 960px) {
      .flannel-information-container .image-column {
        display: block; } }
  .flannel-information-container .content-column {
    background: #f3f3f3;
    margin-top: 0;
    padding: 30px 15px;
    display: flex;
    font-size: 20px;
    flex-direction: column;
    justify-content: center; }
    @media all and (min-width: 640px) {
      .flannel-information-container .content-column {
        padding: 40px 40px 50px;
        margin: 50px auto 0;
        max-width: 640px; } }
    @media all and (min-width: 960px) {
      .flannel-information-container .content-column {
        padding: 80px 40px 151px 80px;
        max-width: 900px;
        margin-left: -45px; } }
    .flannel-information-container .content-column h2, .flannel-information-container .content-column h3 {
      margin-bottom: 10px; }
    .flannel-information-container .content-column ul {
      padding-left: 35px;
      margin-top: 0; }
      .flannel-information-container .content-column ul li {
        list-style-type: none;
        position: relative;
        margin-bottom: 10px; }
        .flannel-information-container .content-column ul li::before {
          content: '•';
          position: absolute;
          left: -25px;
          font-size: 18px;
          line-height: 24px; }

.flannel-pagination-container-bkgd {
  background: #000000;
  padding: 67px 0 117px;
  z-index: 0;
  position: relative; }
  .flannel-pagination-container-bkgd .flannel-pagination-container {
    max-width: 1200px;
    margin: 0 auto; }
    .flannel-pagination-container-bkgd .flannel-pagination-container .pagination-nav {
      display: inline-flex;
      flex-direction: row;
      color: #ffffff;
      align-items: center; }
      .flannel-pagination-container-bkgd .flannel-pagination-container .pagination-nav i {
        font-size: 35px;
        margin: 0 15px; }
      .flannel-pagination-container-bkgd .flannel-pagination-container .pagination-nav span {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 2;
        text-transform: uppercase; }
      .flannel-pagination-container-bkgd .flannel-pagination-container .pagination-nav p {
        font-family: 'AxeHandel', sans-serif;
        text-transform: uppercase;
        font-style: normal;
        font-weight: normal;
        font-display: swap;
        text-rendering: optimizeLegibility;
        font-size: 45px;
        line-height: 1;
        margin: 0; }
      .flannel-pagination-container-bkgd .flannel-pagination-container .pagination-nav:active, .flannel-pagination-container-bkgd .flannel-pagination-container .pagination-nav:hover, .flannel-pagination-container-bkgd .flannel-pagination-container .pagination-nav:focus {
        text-decoration: none; }
        .flannel-pagination-container-bkgd .flannel-pagination-container .pagination-nav:active p, .flannel-pagination-container-bkgd .flannel-pagination-container .pagination-nav:hover p, .flannel-pagination-container-bkgd .flannel-pagination-container .pagination-nav:focus p {
          color: #ce1f2e; }
    .flannel-pagination-container-bkgd .flannel-pagination-container img {
      opacity: .1; }

.flannel-testimonial-quote {
  margin: 0 auto -30px;
  position: relative;
  z-index: 55;
  width: 50px; }
  @media all and (min-width: 960px) {
    .flannel-testimonial-quote {
      width: 100px;
      margin: 0 auto -50px; } }

.flannel-testimonial-container {
  position: relative;
  z-index: 6;
  height: 520px;
  background: #ce1f2e;
  clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
  padding: 50px 30px 30px;
  color: #ffffff;
  margin: 0 -15px;
  text-align: left; }
  @media all and (min-width: 640px) {
    .flannel-testimonial-container {
      margin: 0;
      text-align: center;
      clip-path: polygon(0 0, 100% 0%, 100% 70%, 0% 100%);
      height: 600px;
      padding: 75px 20px 30px; } }
  @media all and (min-width: 960px) {
    .flannel-testimonial-container {
      padding: 105px 100px 0;
      height: 580px; } }
  .flannel-testimonial-container h2 {
    font-size: 40px;
    margin-bottom: 0; }
    @media all and (min-width: 640px) {
      .flannel-testimonial-container h2 {
        font-size: 45px; } }
    @media all and (min-width: 640px) {
      .flannel-testimonial-container h2 {
        font-size: 60px; } }
  .flannel-testimonial-container img {
    font-size: 200px;
    color: #000000;
    text-align: center;
    position: absolute;
    top: -35px;
    line-height: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 20; }
  .flannel-testimonial-container blockquote {
    font-family: 'Open Sans', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    line-height: 1.45;
    margin: 0 0 30px;
    text-align: left;
    padding: 0; }
    @media all and (min-width: 500px) {
      .flannel-testimonial-container blockquote {
        line-height: 1.75; } }
    @media all and (min-width: 640px) {
      .flannel-testimonial-container blockquote {
        text-align: center;
        padding: 0 50px;
        font-size: 18px; } }
    @media all and (min-width: 960px) {
      .flannel-testimonial-container blockquote {
        line-height: 1.75;
        font-size: 20px; } }
  .flannel-testimonial-container ul {
    outline: none; }
    .flannel-testimonial-container ul span {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      display: block;
      text-transform: uppercase; }
      .flannel-testimonial-container ul span.person {
        color: #000000; }
  .flannel-testimonial-container li.uk-active {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .flannel-testimonial-container li span {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px; }
  .flannel-testimonial-container .testimonial-nav-container {
    font-family: 'Open Sans Condensed', sans-serif;
    font-style: normal;
    font-weight: 700;
    margin: 0;
    font-size: 16px;
    letter-spacing: 2px;
    z-index: 33;
    color: #000000; }
    @media all and (min-width: 640px) {
      .flannel-testimonial-container .testimonial-nav-container {
        font-size: 20px; } }
    .flannel-testimonial-container .testimonial-nav-container a {
      color: #000000; }
      .flannel-testimonial-container .testimonial-nav-container a:hover {
        color: #ffffff;
        text-decoration: none; }

.three-columns-container {
  padding: 0 30px;
  z-index: 12;
  position: relative;
  background: #000000;
  margin-top: -60px;
  margin-bottom: 0 !important; }
  @media all and (min-width: 640px) {
    .three-columns-container {
      margin-top: 0;
      padding: 0 42px; } }
  @media all and (min-width: 960px) {
    .three-columns-container {
      background: transparent;
      margin-bottom: 200px !important; } }
  .three-columns-container .text-container {
    padding: 0 15px; }
    @media all and (min-width: 640px) {
      .three-columns-container .text-container {
        padding: 0 30px; } }
  .three-columns-container .column-box-shadow {
    box-shadow: -20px 20px 0 rgba(0, 0, 0, 0.15);
    padding-bottom: 40px; }
  .three-columns-container p {
    font-size: 16px;
    line-height: 24px; }
    @media all and (min-width: 640px) {
      .three-columns-container p {
        font-size: 20px;
        line-height: 28px; } }
  .three-columns-container figure {
    background: #000000;
    position: relative;
    max-width: 360px;
    margin-bottom: 5px; }
    @media all and (min-width: 640px) {
      .three-columns-container figure {
        margin-bottom: 15px; } }
    .three-columns-container figure h2 {
      padding: 0 15px;
      position: absolute;
      margin: 15px 0;
      bottom: 0;
      color: #ffffff;
      text-transform: uppercase;
      line-height: 0.88; }
      @media all and (min-width: 640px) {
        .three-columns-container figure h2 {
          padding: 0 30px; } }
  .three-columns-container .button-margin {
    margin: 30px 0 0; }

.eagr-reviews-layout-list-item {
  margin-bottom: 10px !important; }
  .eagr-reviews-layout-list-item > div {
    padding: 20px; }
    @media all and (min-width: 960px) {
      .eagr-reviews-layout-list-item > div {
        padding: 50px; } }
  .eagr-reviews-layout-list-item:nth-child(odd) > div {
    background: #f3f3f3 !important; }

form .gform_body div.ginput_container {
  width: 100% !important; }
  form .gform_body div.ginput_container span {
    padding-right: 0; }

form .gform_body li {
  position: relative;
  z-index: 2; }

form .gform_body input {
  border: none;
  border-bottom: 2px solid #C6C4C4;
  border-radius: 0;
  outline: none;
  background: transparent;
  margin: 0 !important; }
  form .gform_body input:active, form .gform_body input:focus {
    border-bottom: 2px solid #000000; }

form .gform_body textarea {
  border: 2px solid #C6C4C4;
  outline: none; }
  form .gform_body textarea:active, form .gform_body textarea:focus {
    border: 2px solid #000000; }

form .gform_body label {
  color: #000000; }

form .gform_body .gfield_required {
  color: #ce1f2e; }

form .gform_body .ginput_container_consent {
  margin-left: 25px; }
  form .gform_body .ginput_container_consent input[type="checkbox"] {
    -webkit-appearance: none;
    margin-left: -25px;
    height: 20px;
    width: 20px;
    position: relative;
    bottom: -4px;
    border: 2px solid #000000;
    border-radius: 0;
    background: #ffffff; }
    form .gform_body .ginput_container_consent input[type="checkbox"]:checked {
      background-image: url("../images/misc/check.svg");
      background-position: center;
      background-repeat: no-repeat; }
  form .gform_body .ginput_container_consent label {
    color: #000000 !important;
    font-size: 16px !important; }
  form .gform_body .ginput_container_consent a:active, form .gform_body .ginput_container_consent a:hover, form .gform_body .ginput_container_consent a:focus {
    color: #8E8E8E;
    text-decoration: none; }
  form .gform_body .ginput_container_consent i {
    display: none; }

form .gform_body .validation_message {
  color: #ce1f2e;
  margin-top: -12px; }

form .gform_body .ginput_container span:not(.ginput_price) {
  margin-bottom: 0;
  display: inline; }

form .gform_body li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
  border: none;
  border-bottom: 2px solid #ce1f2e; }

form .gform_body li.gfield_error textarea {
  border: 2px solid #ce1f2e; }

form .gform_body .form-background {
  background: rgba(81, 12, 18, 0.83);
  background-image: url("../images/patterns/pattern.png");
  position: absolute;
  right: -30px;
  top: -30px;
  width: 100%;
  height: 100%; }

.gform_confirmation_message {
  font-family: 'AxeHandel', sans-serif;
  text-transform: uppercase;
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  text-rendering: optimizeLegibility;
  padding: 50px 0;
  font-size: 40px;
  color: #000000;
  height: 300px;
  line-height: 1;
  text-align: center; }

form .gform_footer {
  text-align: center; }
  form .gform_footer .button {
    font-family: 'Open Sans Condensed', sans-serif;
    font-style: normal;
    font-weight: 700;
    background: #ce1f2e;
    color: #ffffff;
    padding: 20px 60px;
    text-align: center;
    border: none;
    text-transform: uppercase;
    position: relative;
    z-index: 22; }
    form .gform_footer .button:active, form .gform_footer .button:hover, form .gform_footer .button:focus {
      border: none;
      color: #000000;
      cursor: pointer; }
  form .gform_footer input[type="submit"] {
    appearance: none;
    border-radius: 0; }

/**
 * Local Footer Subscription Form
 */
form.flannel-subscription-container .gform_wrapper {
  margin: 0; }

form.flannel-subscription-container div.validation_error {
  display: none; }

form.flannel-subscription-container .gform_footer {
  position: relative;
  top: 0;
  right: 0;
  text-align: left;
  width: 100%; }
  @media all and (min-width: 960px) {
    form.flannel-subscription-container .gform_footer {
      position: absolute;
      text-align: right;
      width: 25%; } }
  form.flannel-subscription-container .gform_footer .button {
    padding: 10px 20px;
    font-size: 20px !important; }

.gform_wrapper form li.gfield.gfield_error {
  margin-bottom: 0 !important;
  border-top: none;
  border-bottom: none;
  padding-bottom: 0;
  padding-top: 0; }

.gform_wrapper .screen-reader-text {
  display: none; }

.gform_wrapper form .ginput_container_phone .instruction {
  margin-top: 0; }

.gform_wrapper .special-label > label {
  position: relative;
  top: 35px;
  z-index: -1;
  transition: all .35s; }

.gform_wrapper .special-label.has-focus-or-value > label {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #8E8E8E;
  font-size: 12px;
  top: 10px;
  transition: all .35s; }

.gform_wrapper li.gfield select {
  appearance: none;
  max-width: 100%;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #C6C4C4;
  padding: 5px 20px 5px 4px;
  background-color: transparent;
  outline: none;
  border-radius: 0;
  background-image: url("../images/misc/chevron-down.svg");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 14px; }

.gform_wrapper .message-label label {
  margin-top: 40px; }

.instagram-feed-container {
  margin-bottom: -100px;
  padding: 40px 0 0; }

.instagram-event-container {
  margin-bottom: -40px;
  background: #000000; }

#sb_instagram.sbi_fixed_height {
  overflow: hidden !important; }

#sb_instagram a {
  min-height: 200px !important;
  min-width: 200px !important;
  color: transparent; }

#sb_instagram.sbi_col_10 #sbi_images .sbi_item {
  width: 50%; }

#sb_instagram #sbi_images {
  display: flex; }
  #sb_instagram #sbi_images div:nth-child(3) {
    display: none; }
    @media all and (min-width: 500px) {
      #sb_instagram #sbi_images div:nth-child(3) {
        display: block; } }
  #sb_instagram #sbi_images div:nth-child(4) {
    display: none; }
    @media all and (min-width: 640px) {
      #sb_instagram #sbi_images div:nth-child(4) {
        display: block; } }
  #sb_instagram #sbi_images div:nth-child(5) {
    display: none; }
    @media all and (min-width: 800px) {
      #sb_instagram #sbi_images div:nth-child(5) {
        display: block; } }
  #sb_instagram #sbi_images div:nth-child(6) {
    display: none; }
    @media all and (min-width: 960px) {
      #sb_instagram #sbi_images div:nth-child(6) {
        display: block; } }
  #sb_instagram #sbi_images div:nth-child(7) {
    display: none; }
    @media all and (min-width: 1200px) {
      #sb_instagram #sbi_images div:nth-child(7) {
        display: block; } }
  #sb_instagram #sbi_images div:nth-child(8) {
    display: none; }
    @media all and (min-width: 1480px) {
      #sb_instagram #sbi_images div:nth-child(8) {
        display: block; } }
  #sb_instagram #sbi_images div:nth-child(9) {
    display: none; }
    @media all and (min-width: 1600px) {
      #sb_instagram #sbi_images div:nth-child(9) {
        display: block; } }
  #sb_instagram #sbi_images div:nth-child(10) {
    display: none; }
    @media all and (min-width: 1800px) {
      #sb_instagram #sbi_images div:nth-child(10) {
        display: block; } }

.juicer-feed * {
  font-size: 13px;
  line-height: 16px; }

.juicer-feed.modern .j-poster,
.juicer-feed h1.referral,
.juicer-feed .j-paginate {
  display: none; }

.flannel-local-footer {
  background: #000000;
  padding-top: 40px; }
  .flannel-local-footer .background-image-container {
    position: absolute;
    top: 40px;
    margin: 0 auto;
    clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 100%);
    background: black;
    background: linear-gradient(90deg, black 0%, rgba(0, 0, 0, 0.4) 25%, rgba(0, 0, 0, 0.4) 75%, black 100%);
    max-width: 1200px;
    margin-right: 15px; }
    @media all and (min-width: 640px) {
      .flannel-local-footer .background-image-container {
        margin-right: 30px; } }
    @media all and (min-width: 960px) {
      .flannel-local-footer .background-image-container {
        margin-right: 40px; } }
    .flannel-local-footer .background-image-container img {
      width: 100%;
      height: 100%;
      min-height: 640px;
      object-fit: cover;
      padding-right: 0px; }
      @media all and (min-width: 640px) {
        .flannel-local-footer .background-image-container img {
          padding-right: 0px; } }
      @media all and (min-width: 1200px) {
        .flannel-local-footer .background-image-container img {
          padding-right: 0; } }
    .flannel-local-footer .background-image-container .background-shadow {
      width: 100%;
      height: 100%;
      position: absolute;
      background: black;
      background: linear-gradient(90deg, black 0%, rgba(0, 0, 0, 0.8) 25%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.8) 75%, black 100%);
      top: 0; }
  .flannel-local-footer .footer-content-container {
    color: #ffffff;
    position: relative;
    top: 0;
    left: 0;
    right: 0; }
    .flannel-local-footer .footer-content-container .local-info-container {
      margin-bottom: 30px;
      padding-right: 30px; }
    .flannel-local-footer .footer-content-container .logo-container {
      position: relative;
      margin-bottom: 30px; }
      .flannel-local-footer .footer-content-container .logo-container .logo img {
        display: block;
        width: 170px;
        margin: 0 auto 35px; }
    .flannel-local-footer .footer-content-container p {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 1.4; }
  .flannel-local-footer .footer-form-container {
    background: #ffffff;
    padding: 15px 20px;
    overflow: hidden;
    position: relative;
    text-align: left;
    max-width: 640px;
    margin: 0 auto;
    height: 100%; }
    @media all and (min-width: 960px) {
      .flannel-local-footer .footer-form-container {
        padding: 40px;
        max-width: none; } }
    @media all and (min-width: 1200px) {
      .flannel-local-footer .footer-form-container {
        padding: 40px 100px; } }
    .flannel-local-footer .footer-form-container::after {
      content: "";
      background-image: url("../images/patterns/pattern2.png");
      background-repeat: repeat;
      opacity: .1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      z-index: 0; }
    .flannel-local-footer .footer-form-container.uk-grid > * {
      padding: 0 !important; }
      @media all and (min-width: 960px) {
        .flannel-local-footer .footer-form-container.uk-grid > * {
          padding: 0 0 0 30px; } }
    .flannel-local-footer .footer-form-container .footer-form-ax {
      position: absolute;
      top: -36px;
      right: -36px;
      width: 132px;
      z-index: -2; }
    .flannel-local-footer .footer-form-container .form-content-container h2 {
      font-size: 45px;
      line-height: 1;
      margin: 0; }
    .flannel-local-footer .footer-form-container .form-content-container p {
      font-size: 14px;
      margin: 0; }
    .flannel-local-footer .footer-form-container .subscription-form-container {
      margin: 20px 0 0;
      width: 100%; }
      @media all and (min-width: 960px) {
        .flannel-local-footer .footer-form-container .subscription-form-container {
          width: calc( 60% - 1px);
          margin: -15px 0 0; } }
      .flannel-local-footer .footer-form-container .subscription-form-container .gform_wrapper {
        margin: 0; }
        .flannel-local-footer .footer-form-container .subscription-form-container .gform_wrapper .top_label input[type="text"] {
          width: 100%; }
          @media all and (min-width: 640px) {
            .flannel-local-footer .footer-form-container .subscription-form-container .gform_wrapper .top_label input[type="text"] {
              width: 65%; } }
        .flannel-local-footer .footer-form-container .subscription-form-container .gform_wrapper ul li.gfield {
          margin-top: 0; }
      .flannel-local-footer .footer-form-container .subscription-form-container form .gform_body .ginput_container_consent {
        margin: 20px 0 0; }
        @media all and (min-width: 960px) {
          .flannel-local-footer .footer-form-container .subscription-form-container form .gform_body .ginput_container_consent {
            margin: 0; } }
        .flannel-local-footer .footer-form-container .subscription-form-container form .gform_body .ginput_container_consent label {
          font-size: 14px !important; }
      .flannel-local-footer .footer-form-container .subscription-form-container .flannel-subscription-container .gform_footer {
        margin: 20px 0 0;
        padding: 0; }
        @media all and (min-width: 960px) {
          .flannel-local-footer .footer-form-container .subscription-form-container .flannel-subscription-container .gform_footer {
            margin: 0; } }
      .flannel-local-footer .footer-form-container .subscription-form-container .gform_confirmation_message {
        font-family: 'AxeHandel', sans-serif;
        text-transform: uppercase;
        font-style: normal;
        font-weight: normal;
        font-display: swap;
        text-rendering: optimizeLegibility;
        font-size: 32px;
        color: #000000;
        line-height: 1;
        height: auto;
        padding: 20px;
        text-align: center;
        margin-top: 0; }
        @media all and (min-width: 960px) {
          .flannel-local-footer .footer-form-container .subscription-form-container .gform_confirmation_message {
            padding: 0 0 0 80px;
            margin-top: 15px; } }
  .flannel-local-footer .info-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 3px solid #ffffff;
    margin: 0 auto;
    width: 50px;
    height: 50px; }

header.flannel-local-page-header {
  margin-bottom: 30px;
  position: relative; }
  header.flannel-local-page-header .wp-post-image {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  header.flannel-local-page-header .header-logo-container {
    display: flex;
    align-items: center;
    justify-content: center; }
    header.flannel-local-page-header .header-logo-container img {
      min-width: 170px; }
  header.flannel-local-page-header .local-header-container {
    z-index: 9;
    position: relative;
    max-width: 1080px;
    margin: 0 auto;
    padding: 55px 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
    @media all and (min-width: 960px) {
      header.flannel-local-page-header .local-header-container {
        flex-direction: row;
        padding: 55px; } }
    header.flannel-local-page-header .local-header-container .flannel-local-title-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 20px 0; }
      @media all and (min-width: 960px) {
        header.flannel-local-page-header .local-header-container .flannel-local-title-container {
          padding: 0 20px; } }
  header.flannel-local-page-header .sub-header {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.46;
    margin: 0; }
  header.flannel-local-page-header .local-page-header-humburger-container {
    position: absolute;
    right: 70px;
    top: 30px; }
    @media all and (min-width: 500px) {
      header.flannel-local-page-header .local-page-header-humburger-container {
        right: 100px;
        top: 40px; } }

.flannel-local-site .header-contact-container {
  margin: 0 auto; }
  @media all and (min-width: 960px) {
    .flannel-local-site .header-contact-container {
      margin: 50px 0 0; } }

.header-contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  min-width: 240px;
  position: relative;
  z-index: 22; }
  @media all and (min-width: 960px) {
    .header-contact-container {
      margin: 0; } }
  .header-contact-container .flannel-order-red,
  .header-contact-container .flannel-button {
    max-width: 250px;
    margin: 0 auto; }
  .header-contact-container span {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    color: #ffffff;
    margin: 15px 0 0; }
    .header-contact-container span a {
      color: #ffffff;
      border-bottom: 4px solid #ce1f2e;
      padding-bottom: 2px;
      text-decoration: none; }
      .header-contact-container span a:active, .header-contact-container span a:hover, .header-contact-container span a:focus {
        color: #ffffff;
        border-bottom: none; }

.flannel-local-site .front-page-header {
  background: #000000;
  height: 600px;
  position: relative; }
  @media all and (min-width: 960px) {
    .flannel-local-site .front-page-header {
      height: 800px; } }

.flannel-local-site .flannel-header-grid {
  margin: 0 0 0 -15px;
  width: 100%;
  max-width: 1280px;
  position: relative; }
  @media all and (min-width: 960px) {
    .flannel-local-site .flannel-header-grid {
      margin: 0 auto; } }
  .flannel-local-site .flannel-header-grid h3 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.4;
    margin: 0; }

.flannel-local-site .flannel-video-container {
  position: absolute;
  top: 0;
  z-index: 0;
  height: 100%;
  width: 100%; }
  .flannel-local-site .flannel-video-container video {
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    object-fit: cover; }
  .flannel-local-site .flannel-video-container .visual-image-container {
    position: absolute;
    top: 60%;
    left: 0;
    right: 0;
    z-index: 90;
    margin: 0 auto; }

.pentagon-drop-shadow {
  filter: drop-shadow(-10px 15px 0 rgba(0, 0, 0, 0.22));
  z-index: 4; }
  .pentagon-drop-shadow .pentagon-container {
    font-family: 'AxeHandel', sans-serif;
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    text-rendering: optimizeLegibility;
    margin: 0 auto -60px;
    max-width: 270px;
    color: #ffffff;
    position: relative;
    display: block;
    padding: 30px 30px 80px;
    font-size: 45px;
    line-height: 1;
    clip-path: polygon(100% 0, 100% 70%, 50% 100%, 0 70%, 0 0); }
    .pentagon-drop-shadow .pentagon-container::after {
      content: "";
      background: #000000;
      background-image: url("../images/patterns/pattern.png");
      background-blend-mode: luminosity;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1; }

.flannel-local-contact-page .uk-container {
  max-width: 1050px;
  margin: 0 auto; }
  .flannel-local-contact-page .uk-container .content-container {
    margin-top: 20px;
    margin-bottom: -200px;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto; }
    @media all and (min-width: 960px) {
      .flannel-local-contact-page .uk-container .content-container {
        margin-top: 60px;
        margin-bottom: 80px; } }

.flannel-local-contact-page .icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid #545454;
  margin-right: 20px;
  min-width: 40px;
  height: 40px;
  align-self: start;
  margin: 0 auto 10px; }
  @media all and (min-width: 500px) {
    .flannel-local-contact-page .icon-container {
      min-width: 50px;
      height: 50px;
      margin: 0 15px 0 0; } }
  .flannel-local-contact-page .icon-container i {
    font-size: 1em; }
    @media all and (min-width: 500px) {
      .flannel-local-contact-page .icon-container i {
        font-size: 1.33em; } }

.flannel-local-contact-page .info-container {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 1.1;
  flex-direction: column; }
  @media all and (min-width: 500px) {
    .flannel-local-contact-page .info-container {
      flex-direction: row; } }
  .flannel-local-contact-page .info-container .address-container {
    text-align: center; }
    @media all and (min-width: 500px) {
      .flannel-local-contact-page .info-container .address-container {
        text-align: left; } }
  .flannel-local-contact-page .info-container span {
    display: block; }
  .flannel-local-contact-page .info-container a {
    line-height: 28px; }
    .flannel-local-contact-page .info-container a:active, .flannel-local-contact-page .info-container a:hover, .flannel-local-contact-page .info-container a:focus {
      color: #8E8E8E;
      text-decoration: none; }

.flannel-local-contact-page .store-hours-container .icon-container {
  float: left; }

.flannel-local-contact-page .store-hours-container table.uk-table {
  margin: 0;
  display: inline; }
  .flannel-local-contact-page .store-hours-container table.uk-table td {
    padding: 0 24px 0 0;
    line-height: 1.75;
    vertical-align: top; }

.flannel-local-contact-page .contact-container {
  position: relative;
  padding: 120px 0 0;
  margin-bottom: 0; }
  @media all and (min-width: 960px) {
    .flannel-local-contact-page .contact-container {
      margin-bottom: 200px; } }
  .flannel-local-contact-page .contact-container .form-container {
    padding: 20px;
    background: #ffffff;
    z-index: 11;
    max-width: 480px;
    margin: 0 auto;
    position: relative;
    box-shadow: -20px 20px 0 0 rgba(0, 0, 0, 0.15); }
    @media all and (min-width: 960px) {
      .flannel-local-contact-page .contact-container .form-container {
        padding: 40px; } }
    .flannel-local-contact-page .contact-container .form-container:after {
      content: "";
      background-image: url("../images/axes/axes_black.svg");
      background-position: bottom center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      bottom: -30px;
      right: 0;
      left: 0;
      margin: 0 auto;
      opacity: .1;
      width: 80%;
      height: 300px; }
    .flannel-local-contact-page .contact-container .form-container form .gform_body .ginput_container_consent {
      margin-left: 30px; }
      .flannel-local-contact-page .contact-container .form-container form .gform_body .ginput_container_consent input[type="checkbox"] {
        margin-left: -25px !important; }
  .flannel-local-contact-page .contact-container .form-background {
    background: rgba(81, 12, 18, 0.83);
    background-image: url("../images/patterns/pattern.png");
    background-blend-mode: difference;
    position: absolute;
    right: -30px;
    top: -30px;
    width: 70%;
    height: 185%;
    z-index: -1; }
    @media all and (min-width: 960px) {
      .flannel-local-contact-page .contact-container .form-background {
        width: 100%; } }

.flannel-local-contact-page .flannel-map-container {
  z-index: 32;
  position: relative;
  margin-bottom: 150px;
  width: 100%;
  max-width: 500px; }

.flannel-local-contact-page .contact-footer-border {
  position: relative;
  width: 100%;
  margin-top: -120px;
  margin-bottom: 20px;
  height: 150px;
  background: #000000;
  z-index: 0;
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 100%); }
  @media all and (min-width: 960px) {
    .flannel-local-contact-page .contact-footer-border {
      margin-top: -300px;
      margin-bottom: 60px; } }

.flannel-local-contact-page .flannel-pagination-container-bkgd {
  padding: 20px;
  margin-top: -20px; }
  @media all and (min-width: 640px) {
    .flannel-local-contact-page .flannel-pagination-container-bkgd {
      padding: 20px 30px; } }
  @media all and (min-width: 960px) {
    .flannel-local-contact-page .flannel-pagination-container-bkgd {
      padding: 80px 40px 35px;
      margin-top: -60px; } }
  .flannel-local-contact-page .flannel-pagination-container-bkgd .flannel-pagination-container img {
    display: none; }

.flannel-local-detail-page {
  margin-top: 20px; }
  @media all and (min-width: 960px) {
    .flannel-local-detail-page {
      margin-top: 60px; } }
  .flannel-local-detail-page .uk-container {
    max-width: 1050px;
    margin: 0 auto; }
    .flannel-local-detail-page .uk-container .content-container {
      padding-right: 0;
      padding-bottom: 40px;
      max-width: 640px;
      margin: 0 auto; }
      @media all and (min-width: 960px) {
        .flannel-local-detail-page .uk-container .content-container {
          max-width: none; } }
      @media all and (min-width: 960px) {
        .flannel-local-detail-page .uk-container .content-container {
          padding-right: 30px; } }
    .flannel-local-detail-page .uk-container .booking-container {
      position: relative;
      padding: 0;
      margin: 0 -15px; }
      @media all and (min-width: 960px) {
        .flannel-local-detail-page .uk-container .booking-container {
          margin: 0; } }
      .flannel-local-detail-page .uk-container .booking-container .pricing-container {
        z-index: 11;
        position: relative;
        background: #000000;
        padding: 40px;
        margin: 0 0 -40px;
        color: #ffffff; }
        @media all and (min-width: 960px) {
          .flannel-local-detail-page .uk-container .booking-container .pricing-container {
            margin: 0 20px -40px; } }
        .flannel-local-detail-page .uk-container .booking-container .pricing-container dl {
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          color: #545454; }
        .flannel-local-detail-page .uk-container .booking-container .pricing-container dt {
          color: #8E8E8E; }
        .flannel-local-detail-page .uk-container .booking-container .pricing-container dd {
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          color: #ffffff; }
        .flannel-local-detail-page .uk-container .booking-container .pricing-container a {
          font-family: 'Open Sans Condensed', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 19px;
          line-height: 1.47; }
          .flannel-local-detail-page .uk-container .booking-container .pricing-container a:active, .flannel-local-detail-page .uk-container .booking-container .pricing-container a:hover, .flannel-local-detail-page .uk-container .booking-container .pricing-container a:focus {
            color: #ffffff;
            text-decoration: none; }
      .flannel-local-detail-page .uk-container .booking-container .book-box-container {
        z-index: 11;
        position: relative; }
        .flannel-local-detail-page .uk-container .booking-container .book-box-container .flannel-red-book-box {
          clip-path: polygon(0 0, 100% 0%, 100% 80%, 0% 100%);
          padding-bottom: 80px; }
  .flannel-local-detail-page .flannel-media-slider-container {
    top: 0;
    background: #ffffff;
    padding: 0;
    margin-bottom: -150px; }
    @media all and (min-width: 960px) {
      .flannel-local-detail-page .flannel-media-slider-container {
        padding: 0 40px;
        top: -150px; } }
    .flannel-local-detail-page .flannel-media-slider-container .media-slider-background {
      display: none; }
    .flannel-local-detail-page .flannel-media-slider-container .media-slider-component {
      margin: 0; }
  .flannel-local-detail-page .additional-content-container {
    padding-top: 140px;
    max-width: 640px;
    margin: 0 auto; }
    @media all and (min-width: 960px) {
      .flannel-local-detail-page .additional-content-container {
        padding-top: 0;
        max-width: none; } }
  .flannel-local-detail-page .book-box-black-divider {
    background: #000000;
    width: 100%;
    height: 150px;
    margin-top: -150px;
    position: relative;
    z-index: -4; }
  .flannel-local-detail-page .book-box-background {
    background: #000000;
    width: 100%;
    height: 150px;
    margin-top: -150px;
    position: relative;
    z-index: -4; }
  .flannel-local-detail-page .promo-container {
    padding: 60px 15px 0;
    max-width: 640px;
    margin: 0 auto; }
    @media all and (min-width: 960px) {
      .flannel-local-detail-page .promo-container {
        padding: 80px 0 0;
        max-width: 950px; } }
    .flannel-local-detail-page .promo-container p {
      color: #ffffff; }
    .flannel-local-detail-page .promo-container a {
      font-family: 'Open Sans Condensed', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 19px;
      text-transform: uppercase;
      line-height: 1.47; }
      .flannel-local-detail-page .promo-container a:after {
        font-family: "Font Awesome 5 Pro";
        content: "\f30b";
        margin-left: 10px; }
      .flannel-local-detail-page .promo-container a:active, .flannel-local-detail-page .promo-container a:hover, .flannel-local-detail-page .promo-container a:focus {
        color: #ffffff;
        text-decoration: none; }
  .flannel-local-detail-page .flannel-red-book-box::after {
    content: "";
    background-image: url("../images/axes/axerays_black.svg");
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    opacity: .1;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0; }
  .flannel-local-detail-page .flannel-pagination-container-bkgd {
    padding: 0; }
    @media all and (min-width: 640px) {
      .flannel-local-detail-page .flannel-pagination-container-bkgd {
        padding: 50px 30px 0; } }
    @media all and (min-width: 960px) {
      .flannel-local-detail-page .flannel-pagination-container-bkgd {
        padding: 80px 40px 35px; } }
    .flannel-local-detail-page .flannel-pagination-container-bkgd .flannel-pagination-container img {
      display: none; }

.flannel-events-page .content-container {
  width: 100%;
  max-width: 930px;
  margin: 0 auto 30px; }
  .flannel-events-page .content-container .content-column {
    padding: 20px 20px 80px;
    max-width: 640px;
    margin: 0 auto; }
    @media all and (min-width: 640px) {
      .flannel-events-page .content-container .content-column {
        padding: 20px 30px 80px; } }
    @media all and (min-width: 960px) {
      .flannel-events-page .content-container .content-column {
        padding: 60px 10px 80px;
        max-width: none;
        width: calc( 60% - 1px); } }
  .flannel-events-page .content-container .video-column {
    display: flex;
    align-items: center;
    justify-content: center; }
    .flannel-events-page .content-container .video-column .plaid-background {
      position: absolute;
      top: 0;
      right: -200px;
      height: 100%;
      width: 385px;
      background: #ce1f2e;
      background-blend-mode: difference;
      background-image: url("../images/patterns/pattern.png"); }

.flannel-events-page .content-row-container a:active, .flannel-events-page .content-row-container a:hover, .flannel-events-page .content-row-container a:focus {
  color: #8E8E8E;
  text-decoration: none; }

.flannel-events-page .content-row-container .event-info {
  margin: -30px auto 30px;
  max-width: 640px; }
  @media all and (min-width: 640px) {
    .flannel-events-page .content-row-container .event-info {
      margin-top: -60px; } }
  @media all and (min-width: 960px) {
    .flannel-events-page .content-row-container .event-info {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0 50px; } }
  .flannel-events-page .content-row-container .event-info h3 {
    margin: 0; }
  .flannel-events-page .content-row-container .event-info span {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #ce1f2e;
    font-size: 20px; }

.flannel-events-page .content-row-container .calendar-body span {
  font-family: 'AxeHandel', sans-serif;
  text-transform: uppercase;
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  text-rendering: optimizeLegibility; }

.flannel-events-page .content-row-container .uk-container .content-grid .media-column img {
  box-shadow: -20px 20px 0 0 #ce1f2e;
  max-height: 300px;
  object-fit: cover; }

.flannel-local-front-page header .flannel-header-grid {
  padding-top: 35px; }

.flannel-local-front-page header .flannel-video-thumbnail, .flannel-local-front-page header video {
  max-width: none; }

.flannel-local-front-page header .logo-container {
  margin-top: 15px;
  padding: 0 0 0 30px; }
  @media all and (min-width: 960px) {
    .flannel-local-front-page header .logo-container {
      padding: 0; } }
  .flannel-local-front-page header .logo-container img {
    width: 162px;
    margin-top: 30px; }
    @media all and (min-width: 640px) {
      .flannel-local-front-page header .logo-container img {
        width: 187px;
        margin-top: 0; } }
    .flannel-local-front-page header .logo-container img.visual-image {
      width: auto;
      margin: 0 auto; }

.flannel-local-front-page header .mobile-bkgd-img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.flannel-local-front-page header .title-container {
  margin: 30px 15px 0;
  padding: 0 0 0 30px; }
  @media all and (min-width: 960px) {
    .flannel-local-front-page header .title-container {
      margin: 30px 0 0;
      padding: 0; } }

.flannel-local-front-page .uk-container {
  padding: 0; }
  @media all and (min-width: 960px) {
    .flannel-local-front-page .uk-container {
      padding: inherit; } }

.flannel-local-front-page .hours-and-review-container {
  max-width: 270px;
  margin: 30px auto 0; }
  .flannel-local-front-page .hours-and-review-container .pentagon-container {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    padding: 10px 30px 40px;
    margin: 0 0 -60px; }
    .flannel-local-front-page .hours-and-review-container .pentagon-container::after {
      opacity: .85; }
    .flannel-local-front-page .hours-and-review-container .pentagon-container .hours {
      font-size: 16px;
      color: #C6C4C4;
      text-transform: none;
      line-height: 28px;
      font-weight: 600; }
  .flannel-local-front-page .hours-and-review-container img {
    width: auto;
    margin: 0 auto 5px; }
  .flannel-local-front-page .hours-and-review-container .flannel-total-rating {
    text-decoration: underline; }
    .flannel-local-front-page .hours-and-review-container .flannel-total-rating:active, .flannel-local-front-page .hours-and-review-container .flannel-total-rating:hover, .flannel-local-front-page .hours-and-review-container .flannel-total-rating:focus {
      text-decoration: none; }

.flannel-local-front-page .flannel-content-header {
  padding: 40px 0 0 40px; }

.flannel-local-front-page .local-main-container {
  margin-top: 30px;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto; }
  @media all and (min-width: 960px) {
    .flannel-local-front-page .local-main-container {
      margin-bottom: 0;
      margin-top: 40px;
      max-width: 1080px; } }
  .flannel-local-front-page .local-main-container .interactive-map {
    height: 367px; }
  .flannel-local-front-page .local-main-container .content-column {
    padding: 0 30px 40px 0;
    background: #ffffff;
    min-height: 400px; }
    @media all and (min-width: 960px) {
      .flannel-local-front-page .local-main-container .content-column {
        padding: 0 40px 40px 0; } }
  .flannel-local-front-page .local-main-container .opening-content-column {
    top: -50px;
    display: flex;
    width: 80%;
    z-index: 1;
    right: 0;
    flex-direction: column;
    align-items: center; }
    @media all and (min-width: 960px) {
      .flannel-local-front-page .local-main-container .opening-content-column {
        margin: -40px 60px 0 0; } }
    .flannel-local-front-page .local-main-container .opening-content-column .flannel-plaid-obi {
      width: 300px;
      background: #ce1f2e;
      padding: 140px 20px;
      margin-top: -45px;
      background-image: url("../images/patterns/pattern.png"); }
  .flannel-local-front-page .local-main-container .three-columns-container {
    background: transparent;
    margin-top: -150px; }

.flannel-local-front-page .flannel-local-testimonial-container {
  margin-top: -40px; }
  .flannel-local-front-page .flannel-local-testimonial-container .flannel-testimonial-container {
    height: 760px;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%);
    margin-bottom: -250px; }

.flannel-local-front-page .three-columns-container {
  margin-top: 0; }
  @media all and (min-width: 640px) {
    .flannel-local-front-page .three-columns-container {
      margin-top: 60px; } }
  @media all and (min-width: 960px) {
    .flannel-local-front-page .three-columns-container {
      margin-top: 0; } }
  .flannel-local-front-page .three-columns-container .uk-first-column {
    margin-top: -50px;
    margin-bottom: 50px; }
    @media all and (min-width: 960px) {
      .flannel-local-front-page .three-columns-container .uk-first-column {
        margin-top: 0;
        margin-bottom: 0; } }

.flannel-local-front-page .opening-soon-background-divider {
  clip-path: polygon(0 0, 100% 100%, 100% 100%, 0% 100%);
  background: #000000;
  padding-top: 90px;
  margin-bottom: -1px; }

.flannel-local-front-page .flannel-widget-container {
  max-width: 640px;
  margin: 0 auto;
  padding-right: 30px; }
  @media all and (min-width: 960px) {
    .flannel-local-front-page .flannel-widget-container {
      max-width: none; } }
  .flannel-local-front-page .flannel-widget-container .calendar-container .calendar-header-container {
    background: #ce1f2e;
    background-image: url("../images/patterns/pattern.png");
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .flannel-local-front-page .flannel-widget-container .calendar-container .calendar-header-container h3 {
      text-shadow: -6px -4px 0 rgba(0, 0, 0, 0.8);
      font-size: 40px;
      line-height: 1; }
      @media all and (min-width: 960px) {
        .flannel-local-front-page .flannel-widget-container .calendar-container .calendar-header-container h3 {
          font-size: 60px; } }
  .flannel-local-front-page .flannel-widget-container .calendar-container .calendar-events-container {
    padding: 20px 20px 1px;
    background: #ffffff; }
    @media all and (min-width: 960px) {
      .flannel-local-front-page .flannel-widget-container .calendar-container .calendar-events-container {
        padding: 35px 35px 1px; } }
    .flannel-local-front-page .flannel-widget-container .calendar-container .calendar-events-container li {
      list-style: none;
      margin: 0 0 20px;
      display: flex;
      align-items: center; }
      @media all and (min-width: 960px) {
        .flannel-local-front-page .flannel-widget-container .calendar-container .calendar-events-container li {
          margin: 0 0 40px; } }
      .flannel-local-front-page .flannel-widget-container .calendar-container .calendar-events-container li .date-container {
        font-family: 'AxeHandel', sans-serif;
        text-transform: uppercase;
        font-style: normal;
        font-weight: normal;
        font-display: swap;
        text-rendering: optimizeLegibility;
        border-radius: 6px;
        display: inline-block;
        padding: 10px;
        position: relative;
        margin-right: 20px;
        text-align: center;
        min-width: 42px;
        font-size: 22px;
        line-height: 0.97;
        color: #000000; }
        @media all and (min-width: 960px) {
          .flannel-local-front-page .flannel-widget-container .calendar-container .calendar-events-container li .date-container {
            font-size: 26.4px;
            min-width: 50px;
            margin-right: 25px; } }
        .flannel-local-front-page .flannel-widget-container .calendar-container .calendar-events-container li .date-container img {
          position: absolute;
          top: -6px;
          left: 1px; }
        .flannel-local-front-page .flannel-widget-container .calendar-container .calendar-events-container li .date-container span {
          display: block; }
      .flannel-local-front-page .flannel-widget-container .calendar-container .calendar-events-container li a {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        margin: 0;
        text-transform: uppercase;
        font-size: 16px;
        color: #000000; }
        @media all and (min-width: 960px) {
          .flannel-local-front-page .flannel-widget-container .calendar-container .calendar-events-container li a {
            font-size: 20px; } }
        .flannel-local-front-page .flannel-widget-container .calendar-container .calendar-events-container li a:active, .flannel-local-front-page .flannel-widget-container .calendar-container .calendar-events-container li a:hover, .flannel-local-front-page .flannel-widget-container .calendar-container .calendar-events-container li a:focus {
          color: #ce1f2e;
          text-decoration: none; }
  .flannel-local-front-page .flannel-widget-container .promo-image-container {
    position: relative;
    max-height: 400px;
    margin: 0 -30px;
    overflow: hidden; }
    @media all and (min-width: 960px) {
      .flannel-local-front-page .flannel-widget-container .promo-image-container {
        margin: 65px 0 0; } }
    .flannel-local-front-page .flannel-widget-container .promo-image-container:active, .flannel-local-front-page .flannel-widget-container .promo-image-container:hover, .flannel-local-front-page .flannel-widget-container .promo-image-container:focus {
      opacity: .8; }
    .flannel-local-front-page .flannel-widget-container .promo-image-container .promo-content-container {
      position: absolute;
      bottom: 0;
      z-index: 12;
      padding: 20px; }
      @media all and (min-width: 960px) {
        .flannel-local-front-page .flannel-widget-container .promo-image-container .promo-content-container {
          padding: 30px; } }
      .flannel-local-front-page .flannel-widget-container .promo-image-container .promo-content-container h2 {
        font-size: 40px;
        line-height: 1;
        margin-bottom: 5px; }
        @media all and (min-width: 640px) {
          .flannel-local-front-page .flannel-widget-container .promo-image-container .promo-content-container h2 {
            font-size: 45px; } }
        @media all and (min-width: 960px) {
          .flannel-local-front-page .flannel-widget-container .promo-image-container .promo-content-container h2 {
            font-size: 60px;
            line-height: 53px;
            margin-bottom: 20px; } }
      .flannel-local-front-page .flannel-widget-container .promo-image-container .promo-content-container p {
        font-family: 'Open Sans Condensed', sans-serif;
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 19px;
        line-height: 1.47;
        letter-spacing: 6.5px;
        color: #ffffff; }
    .flannel-local-front-page .flannel-widget-container .promo-image-container .image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      overflow: hidden; }
  .flannel-local-front-page .flannel-widget-container .promo-text-container {
    background: #ce1f2e;
    background-image: url("../images/patterns/pattern.png");
    margin: 0 -30px; }
    @media all and (min-width: 960px) {
      .flannel-local-front-page .flannel-widget-container .promo-text-container {
        margin: 40px 0 0; } }
    .flannel-local-front-page .flannel-widget-container .promo-text-container ul {
      margin: 0 20px 20px; }
      @media all and (min-width: 960px) {
        .flannel-local-front-page .flannel-widget-container .promo-text-container ul {
          margin: 20px; } }
    .flannel-local-front-page .flannel-widget-container .promo-text-container li {
      background: #ffffff;
      margin: 20px 0 0;
      padding: 20px;
      color: #000000; }
    .flannel-local-front-page .flannel-widget-container .promo-text-container h2, .flannel-local-front-page .flannel-widget-container .promo-text-container h3, .flannel-local-front-page .flannel-widget-container .promo-text-container h4 {
      color: #000000;
      font-size: 28px; }
      @media all and (min-width: 640px) {
        .flannel-local-front-page .flannel-widget-container .promo-text-container h2, .flannel-local-front-page .flannel-widget-container .promo-text-container h3, .flannel-local-front-page .flannel-widget-container .promo-text-container h4 {
          font-size: 40px; } }
    .flannel-local-front-page .flannel-widget-container .promo-text-container p {
      font-size: 14px;
      line-height: 22px;
      color: #545454; }
      @media all and (min-width: 640px) {
        .flannel-local-front-page .flannel-widget-container .promo-text-container p {
          font-size: 20px;
          line-height: 28px; } }
    .flannel-local-front-page .flannel-widget-container .promo-text-container a {
      font-family: 'Open Sans Condensed', sans-serif;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
      color: #000000;
      font-size: 13px;
      letter-spacing: 6px;
      line-height: 1; }
      @media all and (min-width: 640px) {
        .flannel-local-front-page .flannel-widget-container .promo-text-container a {
          font-size: 19px; } }
      .flannel-local-front-page .flannel-widget-container .promo-text-container a:after {
        font-family: "Font Awesome 5 Pro";
        content: "\f30b";
        margin-left: 10px; }
      .flannel-local-front-page .flannel-widget-container .promo-text-container a:active, .flannel-local-front-page .flannel-widget-container .promo-text-container a:hover, .flannel-local-front-page .flannel-widget-container .promo-text-container a:focus {
        text-decoration: none;
        color: #ce1f2e; }
    .flannel-local-front-page .flannel-widget-container .promo-text-container .promo-text-nav-container {
      font-family: 'Open Sans Condensed', sans-serif;
      font-style: normal;
      font-weight: 700;
      text-align: center;
      background: #ffffff;
      margin: 10px 20px 0;
      padding: 20px 0 10px;
      color: #545454;
      letter-spacing: 4px; }
      .flannel-local-front-page .flannel-widget-container .promo-text-container .promo-text-nav-container a {
        color: #545454;
        font-size: 20px;
        line-height: 1.4;
        margin: 0 4px;
        letter-spacing: 7px; }
        .flannel-local-front-page .flannel-widget-container .promo-text-container .promo-text-nav-container a:after {
          content: "";
          display: none; }
        .flannel-local-front-page .flannel-widget-container .promo-text-container .promo-text-nav-container a:active, .flannel-local-front-page .flannel-widget-container .promo-text-container .promo-text-nav-container a:hover, .flannel-local-front-page .flannel-widget-container .promo-text-container .promo-text-nav-container a:focus {
          text-decoration: none;
          color: #ce1f2e; }
  .flannel-local-front-page .flannel-widget-container .wall-of-fame-container {
    margin-top: 40px; }
    .flannel-local-front-page .flannel-widget-container .wall-of-fame-container h2 {
      padding: 0 30px; }
    .flannel-local-front-page .flannel-widget-container .wall-of-fame-container span {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      position: absolute;
      left: 0;
      right: 0;
      top: 25%;
      text-transform: uppercase;
      color: #ffffff; }
    .flannel-local-front-page .flannel-widget-container .wall-of-fame-container .high-score-table thead th,
    .flannel-local-front-page .flannel-widget-container .wall-of-fame-container .high-score-table td {
      padding: 15px 30px; }
    .flannel-local-front-page .flannel-widget-container .wall-of-fame-container a:active, .flannel-local-front-page .flannel-widget-container .wall-of-fame-container a:hover, .flannel-local-front-page .flannel-widget-container .wall-of-fame-container a:focus {
      text-decoration: none; }

.flannel-local-front-page .social-links-parent-container {
  margin-top: 50px; }
  @media all and (min-width: 1200px) {
    .flannel-local-front-page .social-links-parent-container {
      margin-top: -30px; } }
  .flannel-local-front-page .social-links-parent-container h2 {
    padding: 0;
    margin: 0; }
  .flannel-local-front-page .social-links-parent-container .social-links-container {
    margin: 15px 0 50px; }
  .flannel-local-front-page .social-links-parent-container a {
    font-size: 30px;
    margin: 0 30px 0 0; }
    .flannel-local-front-page .social-links-parent-container a:active, .flannel-local-front-page .social-links-parent-container a:hover, .flannel-local-front-page .social-links-parent-container a:focus {
      color: #ce1f2e; }

.flannel-local-league-page {
  margin-top: 20px; }
  @media all and (min-width: 960px) {
    .flannel-local-league-page {
      margin-top: 60px; } }
  .flannel-local-league-page .content-container {
    padding-right: 0;
    max-width: 640px;
    margin: 0 auto; }
    @media all and (min-width: 960px) {
      .flannel-local-league-page .content-container {
        padding-right: 30px; } }
  .flannel-local-league-page .plaid-background {
    width: 300px;
    height: 100%;
    background: #ce1f2e;
    background-image: url("../images/patterns/pattern.png");
    z-index: -1;
    position: absolute;
    right: -150px;
    top: 50px; }
  .flannel-local-league-page .high-scores-container {
    z-index: 11;
    background: #000000;
    max-width: 640px;
    margin: 0 auto;
    box-shadow: -20px 20px 0 0 rgba(0, 0, 0, 0.2);
    padding: 80px 20px 20px;
    color: #ffffff; }
    .flannel-local-league-page .high-scores-container img {
      position: absolute;
      top: -30px;
      left: 0;
      right: 0;
      padding-left: 40px;
      box-sizing: content-box;
      margin: 0 auto; }
    .flannel-local-league-page .high-scores-container span {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #545454; }
  .flannel-local-league-page .flannel-media-slider-container {
    top: 0;
    background: #ffffff;
    padding: 0 15px;
    left: 0;
    margin-bottom: -50px;
    margin: 0 -15px -80px; }
    @media all and (min-width: 960px) {
      .flannel-local-league-page .flannel-media-slider-container {
        margin: 0 0 -150px;
        top: -150px;
        padding: 0 40px; } }
    .flannel-local-league-page .flannel-media-slider-container .media-slider-background {
      display: none; }
    .flannel-local-league-page .flannel-media-slider-container .media-slider-component {
      margin: 0; }
  .flannel-local-league-page .book-box-black-divider {
    background: #000000;
    width: 100%;
    height: 150px;
    margin-top: -150px;
    position: relative;
    z-index: -4; }
  .flannel-local-league-page .book-box-background {
    background: #000000;
    width: 100%;
    height: 150px;
    margin-top: -150px;
    position: relative;
    z-index: -4; }
  .flannel-local-league-page .promo-container {
    padding: 80px 0; }
    .flannel-local-league-page .promo-container p {
      color: #ffffff; }
    .flannel-local-league-page .promo-container a {
      font-family: 'Open Sans Condensed', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 19px;
      text-transform: uppercase;
      line-height: 1.47; }
  .flannel-local-league-page .flannel-red-book-box::after {
    content: "";
    background-image: url("../images/axes/axerays_black.svg");
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    opacity: .1;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0; }
  .flannel-local-league-page .league-form-box {
    max-width: 640px;
    margin: 0 auto;
    padding: 80px 15px 0; }
    @media all and (min-width: 960px) {
      .flannel-local-league-page .league-form-box {
        padding: 80px 0 50px;
        max-width: 930px; } }
    .flannel-local-league-page .league-form-box .league-form-container {
      padding: 20px; }
      @media all and (min-width: 960px) {
        .flannel-local-league-page .league-form-box .league-form-container {
          padding: 40px; } }
      .flannel-local-league-page .league-form-box .league-form-container form .gform_body .ginput_container_consent {
        margin-left: 0; }
      .flannel-local-league-page .league-form-box .league-form-container form .gform_body .validation_message {
        margin-top: 0; }
      .flannel-local-league-page .league-form-box .league-form-container form .gform_footer .button {
        padding: 10px 30px;
        font-size: 23px; }
  .flannel-local-league-page .flannel-pagination-container-bkgd {
    padding: 30px 0 0; }
    @media all and (min-width: 640px) {
      .flannel-local-league-page .flannel-pagination-container-bkgd {
        padding: 30px 30px 0; } }
    @media all and (min-width: 960px) {
      .flannel-local-league-page .flannel-pagination-container-bkgd {
        padding: 20px 40px 35px; } }
    .flannel-local-league-page .flannel-pagination-container-bkgd .flannel-pagination-container img {
      display: none; }

.flannel-pricing-page {
  margin-top: 20px; }
  @media all and (min-width: 960px) {
    .flannel-pricing-page {
      margin-top: 60px; } }
  .flannel-pricing-page .content-container {
    max-width: 640px;
    margin-bottom: 50px; }
    @media all and (min-width: 960px) {
      .flannel-pricing-page .content-container {
        max-width: 950px; } }
  .flannel-pricing-page .pricing-container {
    max-width: 990px;
    margin: 0 auto; }
    .flannel-pricing-page .pricing-container > div > div {
      padding-left: 18px; }
    .flannel-pricing-page .pricing-container .special-column-container {
      order: -1; }
      @media all and (min-width: 960px) {
        .flannel-pricing-page .pricing-container .special-column-container {
          order: 0; } }
    .flannel-pricing-page .pricing-container small {
      display: block;
      padding: 0 20px;
      text-align: left; }
      @media all and (min-width: 960px) {
        .flannel-pricing-page .pricing-container small {
          padding: 0; } }
    .flannel-pricing-page .pricing-container .column-container {
      background: #f3f3f3;
      padding-bottom: 10px;
      max-width: 500px;
      margin: 0 auto; }
      .flannel-pricing-page .pricing-container .column-container.special-column {
        transform: scale(1); }
        @media all and (min-width: 640px) {
          .flannel-pricing-page .pricing-container .column-container.special-column {
            transform: scale(1.05); }
            .flannel-pricing-page .pricing-container .column-container.special-column .button-container {
              margin-top: 0; } }
    @media all and (min-width: 640px) and (min-width: 960px) {
      .flannel-pricing-page .pricing-container .column-container.special-column .button-container {
        margin-top: 14px !important; } }
      .flannel-pricing-page .pricing-container .column-container .left-column-header,
      .flannel-pricing-page .pricing-container .column-container .special-column-header,
      .flannel-pricing-page .pricing-container .column-container .right-column-header {
        padding: 15px 30px 20px; }
        @media all and (min-width: 640px) {
          .flannel-pricing-page .pricing-container .column-container .left-column-header,
          .flannel-pricing-page .pricing-container .column-container .special-column-header,
          .flannel-pricing-page .pricing-container .column-container .right-column-header {
            padding: 35px 30px 50px; } }
      .flannel-pricing-page .pricing-container .column-container .left-column-header {
        clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%); }
      .flannel-pricing-page .pricing-container .column-container .special-column-header {
        clip-path: polygon(100% 0, 100% 85%, 50% 100%, 0 85%, 0 0);
        padding-bottom: 35px; }
        @media all and (min-width: 640px) {
          .flannel-pricing-page .pricing-container .column-container .special-column-header {
            padding: 35px 30px 35px; } }
        @media all and (min-width: 960px) {
          .flannel-pricing-page .pricing-container .column-container .special-column-header {
            padding: 35px 30px 40px; } }
        .flannel-pricing-page .pricing-container .column-container .special-column-header h3 {
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-weight: 700; }
      .flannel-pricing-page .pricing-container .column-container .right-column-header {
        clip-path: polygon(100% 0, 100% 100%, 0 85%, 0 0); }
      .flannel-pricing-page .pricing-container .column-container h3 {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 1.16;
        color: #ffffff;
        text-transform: none;
        margin: 0; }
      .flannel-pricing-page .pricing-container .column-container .column-info {
        padding: 0;
        max-width: 640px;
        margin: 0 auto; }
        .flannel-pricing-page .pricing-container .column-container .column-info .price {
          font-family: 'AxeHandel', sans-serif;
          text-transform: uppercase;
          font-style: normal;
          font-weight: normal;
          font-display: swap;
          text-rendering: optimizeLegibility;
          color: #000000;
          font-size: 60px;
          line-height: 50px;
          padding: 20px 0;
          display: inline-block; }
        .flannel-pricing-page .pricing-container .column-container .column-info small {
          display: inline-block;
          padding: 0;
          margin-left: -2px; }
        .flannel-pricing-page .pricing-container .column-container .column-info .button-container {
          margin: 0; }
          @media all and (min-width: 960px) {
            .flannel-pricing-page .pricing-container .column-container .column-info .button-container {
              margin: 20px 0 30px; } }
        .flannel-pricing-page .pricing-container .column-container .column-info dl {
          margin-bottom: 20px;
          border-top: 1px solid #ffffff;
          padding-top: 20px; }
          .flannel-pricing-page .pricing-container .column-container .column-info dl dt,
          .flannel-pricing-page .pricing-container .column-container .column-info dl dd {
            font-size: 12px;
            margin: 0;
            padding: 0 10px; }
          .flannel-pricing-page .pricing-container .column-container .column-info dl dt {
            color: #8E8E8E; }
  .flannel-pricing-page .small-text {
    color: #ffffff;
    font-size: 12px;
    display: block; }
  .flannel-pricing-page .right-angle-footer-border {
    position: relative;
    width: 100%;
    margin-top: -80px;
    height: 80px;
    background: #000000;
    z-index: -1;
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 20%); }
    @media all and (min-width: 960px) {
      .flannel-pricing-page .right-angle-footer-border {
        margin-top: -160px;
        height: 160px; } }
    @media all and (min-width: 1200px) {
      .flannel-pricing-page .right-angle-footer-border {
        margin-top: -250px;
        height: 250px; } }
  .flannel-pricing-page .flannel-pagination-container-bkgd {
    padding: 20px;
    margin-top: -20px; }
    @media all and (min-width: 640px) {
      .flannel-pricing-page .flannel-pagination-container-bkgd {
        padding: 20px 30px; } }
    @media all and (min-width: 960px) {
      .flannel-pricing-page .flannel-pagination-container-bkgd {
        padding: 80px 40px 35px;
        margin-top: -60px; } }
    .flannel-pricing-page .flannel-pagination-container-bkgd .flannel-pagination-container img {
      display: none; }

.flannel-review-page .uk-container {
  max-width: 1200px;
  margin: 0 auto; }
  .flannel-review-page .uk-container > div {
    padding-left: 0; }
    @media all and (min-width: 960px) {
      .flannel-review-page .uk-container > div {
        padding-left: 30px; } }

.flannel-review-page .pentagon-container::after {
  opacity: .85; }

.flannel-review-page .hours-and-review-container {
  margin-top: 20px; }
  @media all and (min-width: 960px) {
    .flannel-review-page .hours-and-review-container {
      margin-top: 45px; } }

.flannel-review-page .review-content-container {
  width: 100%; }
  @media all and (min-width: 960px) {
    .flannel-review-page .review-content-container {
      width: 66%; } }

.flannel-review-page .content-container {
  padding: 0;
  margin-top: 45px; }

.flannel-review-page .user-review-container {
  margin: 0 auto;
  padding-bottom: 60px;
  padding-right: 0; }
  @media all and (min-width: 960px) {
    .flannel-review-page .user-review-container {
      margin: 40px auto 0;
      padding-right: 30px; } }

.high-score-table {
  color: #ffffff;
  width: 100%; }
  .high-score-table thead th {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 16px; }
  .high-score-table tbody tr {
    border-bottom: 2px solid #545454;
    font-size: 20px;
    line-height: 24px; }
    @media all and (min-width: 960px) {
      .high-score-table tbody tr {
        font-size: 24px;
        line-height: 35px; } }
    .high-score-table tbody tr:nth-child(1) {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      border: 2px solid #ce1f2e;
      color: #ce1f2e; }
    .high-score-table tbody tr:last-child {
      border: none; }

.high-score-cta {
  font-family: 'Open Sans Condensed', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  color: #ffffff;
  margin-top: 10px;
  display: block;
  letter-spacing: 6.5px;
  text-transform: uppercase; }

.footer-content-container table.uk-table {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.9;
  max-width: 280px;
  margin: 0 auto; }
  .footer-content-container table.uk-table td {
    padding: 0;
    text-align: left; }
    .footer-content-container table.uk-table td:last-of-type {
      text-align: right; }

.flannel-franchise-front-page .uk-container {
  max-width: 930px;
  margin: 0 auto; }

.flannel-franchise-front-page .content-column {
  max-width: 640px;
  margin: 20px auto 50px; }
  @media all and (min-width: 960px) {
    .flannel-franchise-front-page .content-column {
      margin: 70px auto 50px;
      max-width: 930px; } }
  .flannel-franchise-front-page .content-column ul {
    list-style-type: none; }
    .flannel-franchise-front-page .content-column ul li {
      list-style-type: none;
      line-height: 1.17;
      font-size: 16px;
      margin-bottom: 10px; }
      @media all and (min-width: 960px) {
        .flannel-franchise-front-page .content-column ul li {
          font-size: 20px; } }
      .flannel-franchise-front-page .content-column ul li:before {
        content: '•';
        color: #ce1f2e;
        font-size: 20px;
        display: inline-block;
        text-indent: -25px; }

.flannel-franchise-front-page .uk-large-container {
  max-width: 1200px;
  margin: 0 auto; }

.flannel-franchise-front-page .franchise-tagline-container {
  margin-bottom: -10px;
  z-index: 20;
  position: relative;
  padding: 0 15px; }
  @media all and (min-width: 960px) {
    .flannel-franchise-front-page .franchise-tagline-container {
      margin-bottom: -25px; } }

.flannel-franchise-front-page .franchise-video-container {
  margin: 0 auto -45px;
  max-width: 930px; }
  .flannel-franchise-front-page .franchise-video-container .flannel-video-thumbnail, .flannel-franchise-front-page .franchise-video-container video {
    max-width: none; }
    .flannel-franchise-front-page .franchise-video-container .flannel-video-thumbnail:before, .flannel-franchise-front-page .franchise-video-container video:before,
    .flannel-franchise-front-page .franchise-video-container .flannel-video-thumbnail video:before,
    .flannel-franchise-front-page .franchise-video-container video video:before {
      color: #ffffff;
      text-shadow: 0 3px 3px #000000;
      background: rgba(206, 31, 46, 0.8);
      padding: 10px 15px;
      font-size: 20px;
      border-radius: 50%; }
      @media all and (min-width: 960px) {
        .flannel-franchise-front-page .franchise-video-container .flannel-video-thumbnail:before, .flannel-franchise-front-page .franchise-video-container video:before,
        .flannel-franchise-front-page .franchise-video-container .flannel-video-thumbnail video:before,
        .flannel-franchise-front-page .franchise-video-container video video:before {
          padding: 30px 40px;
          font-size: 40px; } }

.flannel-franchise-front-page .flannel-testimonial-container {
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%); }

.flannel-franchise-front-page .flannel-franchise-news-row {
  margin-bottom: 150px;
  z-index: 12;
  margin-top: -100px; }
  @media all and (min-width: 960px) {
    .flannel-franchise-front-page .flannel-franchise-news-row {
      margin-top: 0; } }
  .flannel-franchise-front-page .flannel-franchise-news-row .plaid-background {
    background: #ce1f2e;
    background-image: url("../images/patterns/pattern.png");
    margin-top: 50px;
    padding: 50px;
    position: absolute;
    height: 100%;
    clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%); }
  .flannel-franchise-front-page .flannel-franchise-news-row .flannel-news-container {
    margin-left: 0;
    position: relative;
    margin-bottom: 50px; }
    @media all and (min-width: 960px) {
      .flannel-franchise-front-page .flannel-franchise-news-row .flannel-news-container {
        margin-left: 20%; } }
    .flannel-franchise-front-page .flannel-franchise-news-row .flannel-news-container .news-background {
      background: #f3f3f3;
      padding: 20px; }
      @media all and (min-width: 960px) {
        .flannel-franchise-front-page .flannel-franchise-news-row .flannel-news-container .news-background {
          padding: 70px; } }
      .flannel-franchise-front-page .flannel-franchise-news-row .flannel-news-container .news-background .flannel-max-width {
        max-width: 640px;
        margin: 0 auto; }
        @media all and (min-width: 960px) {
          .flannel-franchise-front-page .flannel-franchise-news-row .flannel-news-container .news-background .flannel-max-width {
            max-width: 820px;
            margin: 0; } }
      .flannel-franchise-front-page .flannel-franchise-news-row .flannel-news-container .news-background .news-container {
        padding: 0 0 30px; }
        @media all and (min-width: 960px) {
          .flannel-franchise-front-page .flannel-franchise-news-row .flannel-news-container .news-background .news-container {
            padding: 30px 0 50px 30px; } }
        .flannel-franchise-front-page .flannel-franchise-news-row .flannel-news-container .news-background .news-container > div {
          padding-top: 10px; }
          @media all and (min-width: 640px) {
            .flannel-franchise-front-page .flannel-franchise-news-row .flannel-news-container .news-background .news-container > div {
              padding-top: 0; } }
        .flannel-franchise-front-page .flannel-franchise-news-row .flannel-news-container .news-background .news-container span {
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          margin-left: 0;
          font-size: 20px;
          display: block;
          color: #000000;
          margin-top: -20px;
          padding-left: 0; }
          @media all and (min-width: 640px) {
            .flannel-franchise-front-page .flannel-franchise-news-row .flannel-news-container .news-background .news-container span {
              margin-top: 0;
              padding-left: 20px; } }
          @media all and (min-width: 960px) {
            .flannel-franchise-front-page .flannel-franchise-news-row .flannel-news-container .news-background .news-container span {
              font-size: 24px; } }
          @media all and (min-width: 1200px) {
            .flannel-franchise-front-page .flannel-franchise-news-row .flannel-news-container .news-background .news-container span {
              padding-left: 0; } }
        .flannel-franchise-front-page .flannel-franchise-news-row .flannel-news-container .news-background .news-container img {
          max-height: 120px;
          max-width: 120px; }

.flannel-franchise-front-page .flannel-image-column img {
  margin-bottom: 30px; }
  @media all and (min-width: 960px) {
    .flannel-franchise-front-page .flannel-image-column img {
      margin-bottom: 90px; } }

.flannel-franchise-front-page .request-container {
  padding-bottom: 0;
  position: relative; }
  @media all and (min-width: 640px) {
    .flannel-franchise-front-page .request-container {
      padding-bottom: 75px; } }
  .flannel-franchise-front-page .request-container em, .flannel-franchise-front-page .request-container i {
    color: #ffffff; }

.flannel-franchise-front-page .franchise-form-container {
  padding: 10px 20px;
  background: #ffffff; }
  @media all and (min-width: 960px) {
    .flannel-franchise-front-page .franchise-form-container {
      padding: 20px 40px; } }
  .flannel-franchise-front-page .franchise-form-container .ginput_container_consent {
    margin-left: 25px; }
    .flannel-franchise-front-page .franchise-form-container .ginput_container_consent input[type="checkbox"] {
      -webkit-appearance: none;
      margin-left: -25px !important;
      height: 20px;
      width: 20px;
      position: relative;
      bottom: -4px;
      border: 2px solid #000000;
      border-radius: 0;
      background: #ffffff; }
      .flannel-franchise-front-page .franchise-form-container .ginput_container_consent input[type="checkbox"]:checked {
        background-image: url("../images/misc/check.svg");
        background-position: center;
        background-repeat: no-repeat; }
    .flannel-franchise-front-page .franchise-form-container .ginput_container_consent label {
      color: #000000 !important;
      font-size: 16px !important; }
    .flannel-franchise-front-page .franchise-form-container .ginput_container_consent a:active, .flannel-franchise-front-page .franchise-form-container .ginput_container_consent a:hover, .flannel-franchise-front-page .franchise-form-container .ginput_container_consent a:focus {
      color: #8E8E8E;
      text-decoration: none; }
    .flannel-franchise-front-page .franchise-form-container .ginput_container_consent i {
      display: none; }

.flannel-franchise-contact-page {
  margin-top: 20px; }
  @media all and (min-width: 960px) {
    .flannel-franchise-contact-page {
      margin-top: 70px; } }
  .flannel-franchise-contact-page .uk-container {
    max-width: 1050px;
    margin: 0 auto; }
  .flannel-franchise-contact-page .content-container {
    min-height: auto;
    padding-bottom: 20px;
    max-width: 640px;
    margin: 0 auto; }
    @media all and (min-width: 960px) {
      .flannel-franchise-contact-page .content-container {
        min-height: 500px;
        max-width: none;
        padding-bottom: 40px; } }
  .flannel-franchise-contact-page .contact-container {
    position: relative;
    padding: 0 0 75px;
    max-width: 450px;
    margin: 0 auto; }
    .flannel-franchise-contact-page .contact-container .form-container {
      padding: 15px;
      background: #ffffff;
      z-index: 11;
      position: relative;
      box-shadow: -20px 20px 0 0 rgba(0, 0, 0, 0.15); }
      @media all and (min-width: 960px) {
        .flannel-franchise-contact-page .contact-container .form-container {
          padding: 40px; } }
      .flannel-franchise-contact-page .contact-container .form-container .ginput_container_consent {
        margin-left: 25px; }
        .flannel-franchise-contact-page .contact-container .form-container .ginput_container_consent input[type="checkbox"] {
          -webkit-appearance: none;
          margin-left: -25px !important;
          height: 20px;
          width: 20px;
          position: relative;
          bottom: -4px;
          border: 2px solid #000000;
          border-radius: 0;
          background: #ffffff; }
          .flannel-franchise-contact-page .contact-container .form-container .ginput_container_consent input[type="checkbox"]:checked {
            background-image: url("../images/misc/check.svg");
            background-position: center;
            background-repeat: no-repeat; }
        .flannel-franchise-contact-page .contact-container .form-container .ginput_container_consent label {
          color: #000000 !important;
          font-size: 16px !important; }
        .flannel-franchise-contact-page .contact-container .form-container .ginput_container_consent a:active, .flannel-franchise-contact-page .contact-container .form-container .ginput_container_consent a:hover, .flannel-franchise-contact-page .contact-container .form-container .ginput_container_consent a:focus {
          color: #8E8E8E;
          text-decoration: none; }
        .flannel-franchise-contact-page .contact-container .form-container .ginput_container_consent i {
          display: none; }
  .flannel-franchise-contact-page .form-background {
    right: -30px;
    width: 100%;
    background: rgba(81, 12, 18, 0.83);
    background-image: url("../images/patterns/pattern.png");
    position: absolute;
    top: -40px;
    background-blend-mode: difference;
    height: 120%; }
    @media all and (min-width: 960px) {
      .flannel-franchise-contact-page .form-background {
        top: -70px; } }
    @media all and (min-width: 960px) {
      .flannel-franchise-contact-page .form-background {
        right: -130px;
        width: 120%; } }

.minus-eighty {
  margin-top: -80px; }

.flannel-franchise-pagination-container {
  background: #000000;
  padding: 40px 0;
  z-index: 0;
  position: relative;
  margin-bottom: -25px; }
  .flannel-franchise-pagination-container .nav-border {
    border-top: 3px solid #ffffff;
    border-bottom: 3px solid #ffffff; }
  .flannel-franchise-pagination-container .pagination-nav,
  .flannel-franchise-pagination-container .mobile-pagination-nav {
    display: flex;
    flex-direction: row;
    color: #ffffff;
    align-items: center;
    margin: 40px 0; }
    .flannel-franchise-pagination-container .pagination-nav .nav-icon,
    .flannel-franchise-pagination-container .mobile-pagination-nav .nav-icon {
      font-size: 35px; }
    .flannel-franchise-pagination-container .pagination-nav span,
    .flannel-franchise-pagination-container .mobile-pagination-nav span {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 2;
      text-transform: uppercase; }
    .flannel-franchise-pagination-container .pagination-nav p,
    .flannel-franchise-pagination-container .mobile-pagination-nav p {
      font-family: 'AxeHandel', sans-serif;
      text-transform: uppercase;
      font-style: normal;
      font-weight: normal;
      font-display: swap;
      text-rendering: optimizeLegibility;
      font-size: 45px;
      line-height: 1;
      margin: 0; }
  .flannel-franchise-pagination-container a:active, .flannel-franchise-pagination-container a:hover, .flannel-franchise-pagination-container a:focus {
    text-decoration: none; }
    .flannel-franchise-pagination-container a:active p,
    .flannel-franchise-pagination-container a:active i, .flannel-franchise-pagination-container a:hover p,
    .flannel-franchise-pagination-container a:hover i, .flannel-franchise-pagination-container a:focus p,
    .flannel-franchise-pagination-container a:focus i {
      color: #ce1f2e; }
  .flannel-franchise-pagination-container .mobile-pagination-nav {
    justify-content: center;
    margin: 0; }
    .flannel-franchise-pagination-container .mobile-pagination-nav span {
      padding: 0 15px; }
  .flannel-franchise-pagination-container img {
    opacity: 1; }

.nav-footer-container {
  padding: 30px;
  background: #ce1f2e;
  background-image: url("../images/patterns/pattern.png"); }
  .nav-footer-container a {
    font-family: 'Open Sans Condensed', sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 20px; }
  .nav-footer-container img.national-nav-header {
    width: auto;
    margin: 0 auto 50px; }

.franchise-main-nav-container {
  position: absolute;
  top: 30px;
  right: 70px; }
  @media all and (min-width: 640px) {
    .franchise-main-nav-container {
      right: 100px; } }

.franchise-mobile-nav-container {
  position: absolute;
  top: 50px;
  left: 100px; }

.franchise-menu-image {
  position: absolute;
  top: 0;
  right: 40px; }
