.flannel-local-detail-page {
  margin-top: 20px;
  @media all and ( min-width: $screen-md ) {
    margin-top: 60px;
  }
  
  .uk-container {
    max-width: 1050px;
    margin: 0 auto;
    
    .content-container {
      padding-right: 0;
      padding-bottom: 40px;
      max-width: $max-width;
      margin: 0 auto;
      @media all and ( min-width: $screen-md ) {
        max-width: none;
      }

      @media all and ( min-width: $screen-md ) {
        padding-right: 30px;
      }
    }
    
    .booking-container {
      position: relative; 
      padding: 0;
      margin: 0 -15px;
      @media all and ( min-width: $screen-md ) {
        margin: 0;
      }
      
      .pricing-container {
        z-index: 11; 
        position: relative;
        background: $black;
        padding: 40px; 
        margin: 0 0 -40px; 
        color: $white;
        @media all and ( min-width: $screen-md ) {
          margin: 0 20px -40px; 
        }
        
        dl {
          @include open-bold;
          font-size: 14px;
          color: $matterhorn;
        }
        
        dt {
          color: $grey;
        }
        
        dd {
          @include open-bold;
          font-size: 24px;
          color: $white;
        }
        
        a {
          @include open-con;
          font-size: 19px;
          line-height: 1.47;
          
          &:active,
          &:hover,
          &:focus {
            color: $white;
            text-decoration: none;
          }
        }
      }
      
      .book-box-container {
        z-index: 11;
        position: relative;
        
        // Override .flannel-red-book-box styles
        .flannel-red-book-box {
          clip-path: polygon( 0 0, 100% 0%, 100% 80%, 0% 100% );
          padding-bottom: 80px;
        }
      }
    }
  }

  // Override media-slider styles
  .flannel-media-slider-container {
    top: 0;
    background: $white;
    padding: 0;
    margin-bottom: -150px;
    @media all and ( min-width: $screen-md ) {
      padding: 0 40px;
      top: -150px;
    }

    .media-slider-background {
      display: none;
    }

    .media-slider-component {
      margin: 0;
    }
  }
  
  .additional-content-container {
    padding-top: 140px;
    max-width: $max-width;
    margin: 0 auto;  
    @media all and ( min-width: $screen-md ) {
      padding-top: 0;    
      max-width: none;
    }
  }
  
  .book-box-black-divider {
    background: $black; 
    width: 100%; 
    height: 150px;
    margin-top: -150px;
    position: relative;
    z-index: -4;
  }
  
  .book-box-background {
    background: $black;
    width: 100%;
    height: 150px;
    margin-top: -150px;
    position: relative;
    z-index: -4;
  }
  
  .promo-container {
    padding: 60px 15px 0;
    max-width: $max-width;
    margin: 0 auto;
    @media all and ( min-width: $screen-md ) {
      padding: 80px 0 0;
      max-width: 950px;
    }
    
    p {
      color: $white;
    }
    
    a {
      @include open-con;
      font-size: 19px;
      text-transform: uppercase;
      line-height: 1.47;
      
      &:after {
        @include fa;
        content: "\f30b";
        margin-left: 10px;        
      }
      
      &:active,
      &:hover,
      &:focus {
        color: $white;
        text-decoration: none;
      }
    }
  }
  
  .flannel-red-book-box {
     &::after {
      content: "";
      background-image: url( $assetImagePath + '/axes/axerays_black.svg' );
      background-position: center;
      background-size: auto;
      background-repeat: no-repeat;
      opacity: .1;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;    
    }
  }
  
  .flannel-pagination-container-bkgd {
    padding: 0;
    @media all and ( min-width: $screen-sm ) {
      padding: 50px 30px 0;
    }
    
    @media all and ( min-width: $screen-md ) {
      padding: 80px 40px 35px;
    }
    
    .flannel-pagination-container img {
      display: none;
    }
  }
}
