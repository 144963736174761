.flannel-black-book-box,
.flannel-red-book-box {
  text-align: center;
  color: $white;
  
  h2, h3, h4 {
    margin: 0;
    padding: 0;
  }  
}

.flannel-black-book-box {
  background: $black;
  padding: 30px 30px 100px;
  position: relative;
  margin: 40px auto 0;
  max-width: $max-width;
  clip-path: polygon( 0 0, 100% 0%, 100% 80%, 0% 100% );
  
  &::after {
    content: "";
    background-image: url( $assetImagePath + '/axes/axerays_white.svg' );
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: .1;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;    
  }
  
  p {
    margin: 0 0 30px;
  }
}

.flannel-red-book-box {
  background: $red;
  padding: 40px;
  margin: 40px auto 0;
  
  p {
    margin: 0 0 30px;
  }
  
  .flannel-button a {
    background: $black;
    
    &:active,
    &:hover,
    &:focus {
      color: $red !important;
    }
  }
}
