.footer-single-cta {
  padding: 0;
  margin: 50px 0;
  @media all and ( min-width: $screen-sm ) {
    padding: 0 40px 0 80px;
    margin: inherit;
  }
  
  @media all and ( min-width: $screen-md ) {
    padding: 0 120px;
  }

  .background-image-container {
    @include flex-center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    overflow: hidden;

    .radial-gradient {
      background: radial-gradient( circle, rgba( 255, 255, 255, 0 ) 0%, rgba( 0, 0, 0, 1 ) 100% );
      width: 100%; 
      height: 100%;
      position: absolute;
      z-index: 3;
    }

    .top-gradiant {
      background: linear-gradient( 0deg, rgba( 255, 255, 255, 0 ) 40%, rgba( 0, 0, 0, 1 ) 100%) ;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
    }

    .bottom-gradient {
      background: linear-gradient( 180deg, rgba( 255, 255, 255, 0 ) 40%, rgba( 0, 0, 0, 1 ) 100% ); 
      width: 100%;
      height: 100%; 
      position: absolute;
      z-index: 1;
    }
    
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: relative;
      top: 0px;
    }
  }
  
  h1, h2, h3, h4 {
    font-size: 32px;
    @media all and ( min-width: $screen-sm ) {
      font-size: 45px;
    }
  }
  
  .location-content-container {
    color: $white; 
    z-index: 99;
    padding: 50px 0 0;
    @media all and ( min-width: $screen-sm ) {
      padding: 90px 0;
    }
  }
}
