.flannel-promo-box {
  width: 100%;
  box-shadow: -20px 20px 0 0 rgba( 0, 0, 0, 0.15 );
  background-color: $whitesmoke;
  margin: 0 35px 30px;
  max-width: 320px;
  @media all and ( min-width: $screen-sm ) {
    width: 70%;
    max-width: none;
    margin: 0 50px 30px;
  }
  
  @media all and ( min-width: $screen-md ) {
    width: 60%;
  }
  
  .promo-image {
    margin: 0;
    padding: 0;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .promo-column {
    padding: 0 30px 30px;
    @media all and ( min-width: $screen-sm ) {
      padding: 30px;
    }
    
    .promo-text {
      @include open-bold;
      margin-bottom: 30px;
      color: $black;
      font-size: 18px;
      line-height: normal;
      @media all and ( min-width: $screen-md ) {
        font-size: 24px;      
      }
    }
  }
}
